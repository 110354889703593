import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ShadowFrame from "components/shadow-frame";
import Loader from "components/Loader/Loader";

const SideQuest = () => {
  const [instruction, setInstruction] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const onImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div class="my-10 lg:px-[30px] xl:px-[80px] 2xl:px-[220px] flex flex-col gap-y-[20px] justify-center">
      <ShadowFrame className="absolute top-1/3 left-0 md:w-[500px] h-[250px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.80]" />
      {!imageLoaded && (
        <div className="relative h-[calc(100vh-50px)] md:h-[450px] lg:h-[550px] xl:h-[700px] 2xl:h-[calc(100vh-50px)] grid place-content-center">
          <ShadowFrame className="absolute top-1/3 left-0 md:w-[500px] h-[250px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.80]" />
          <ShadowFrame className="absolute top-2/3 right-0 md:w-[500px] h-[250px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.80]" />
          <Loader />
        </div>
      )}

      <div className="relative">
        <picture className="" onLoad={onImageLoad}>
          <img
            src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/sidequest.png`}
            alt="network error"
            className="w-full h-auto  lg:w-auto object-cover"
          />
        </picture>

        <NavLink
          to="https://sidequestvr.com/app/31890"
          target="_blank"
          className="sidequest-button absolute left-[30px] sm:left-[150px] md:left-[190px] lg:left-[150px] xl:left-[180px] 2xl:left-[240px] font-bold text-xs py-0.5 px-3 lg:py-2.5 lg:px-7 rounded-[8px] bg-[#FBBC5E] text-[#1D1D1D]"
          style={{
            top: "calc(100% - 90px)",
            // xl: { top: "calc(100% - 130px)" },
            // "2xl": { top: "calc(100% - 130px)" },
          }}
        >
          Download Now
        </NavLink>

        <p
          className="sidequest-para absolute left-[10px] sm:left-[20px] md:left-[40px] lg:left-[60px] xl:left-[100px] 2xl:left-[150px] my-5 ml-2 text-[12px] md:text-[14px]"
          style={{
            top: "calc(100% - 70px)",
          }}
        >
          Don’t have SideQuest account?
          <span
            href="https://sidequestvr.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="my-5 text-[#FBBC5E] mx-1 cursor-pointer"
            onClick={() => setInstruction(!instruction)}
          >
            {instruction ? "Hide" : "See"} Instructions
          </span>
        </p>
      </div>
      {instruction && (
        <div className="bg-[#1D1F25] px-[20px] lg:px-[100px] ">
          <p className="py-5">
            Join the Fourth Star Open Beta on SideQuest! Be among the first to
            experience our immersive VR game before its official launch on the
            Meta Store. SideQuest is a platform that allows us to reach a wider
            audience and gather valuable feedback from users like you. Your
            feedback is crucial in making Fourth Star the best it can be. Join
            us on SideQuest today and be a part of this exciting journey!
          </p>
          <div className="flex gap-x-4 mt-10">
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id"
                className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
              />
              <svg
                className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <div>
              <h1 className="text-2xl font-heading ">
                Step 1 - Log in to Meta Quest.
              </h1>
              <p className="my-4 text-white/60 ">
                Go to{" "}
                <NavLink
                  to="https://developer.oculus.com/"
                  target="_blank"
                  className="text-[#FBBC5E] underline "
                >
                  dashboard.oculus.com
                </NavLink>{" "}
                and make an account there. If you already have an account
                proceed with signing in and if not, proceed with signing up.
              </p>
              <img
                src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/metaQuestStep1.png`}
                alt="network error"
                className="w-full h-auto  lg:w-auto"
              />
            </div>
          </div>
          <div className="flex gap-x-4 mt-10">
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id"
                className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
              />
              <svg
                className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <div>
              <h1 className="text-2xl font-heading ">
                Step 2 - Once logged in proceed with creating an Organization.
              </h1>

              <p className="my-5 text-white/60 ">
                You can write any organization name for now as it won’t impact
                our step. This step is important as this will let us accept the{" "}
                <span className="text-white font-semibold">
                  developer’s mode license
                </span>
                .
              </p>
              <img
                src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/metaQuestStep2.png`}
                alt="network error"
                className="w-full h-auto  lg:w-auto"
              />
            </div>
          </div>

          <ShadowFrame className="absolute top-3/3 right-0 md:w-[500px] h-[250px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.80]" />
          <div className="flex gap-x-4 mt-10">
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id"
                className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
              />
              <svg
                className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <div>
              <h1 className="text-2xl font-heading ">
                Step 3 - Check “I understand” and click on “Submit”.
              </h1>
              <p className="mt-4 mb-5 text-white/60 ">
                You will also be asked to{" "}
                <span className="text-white font-semibold">
                  “Developer Non-Disclosure Agreement”{" "}
                </span>
                after this step so please accept that too.
              </p>
              <img
                src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/metaQuestStep3.png`}
                alt="network error"
                className="w-full h-auto  lg:w-auto"
              />
            </div>
          </div>

          <div className="flex gap-x-4 mt-10">
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id"
                className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
              />
              <svg
                className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <div>
              <h1 className="text-2xl font-heading ">
                Step 4 - Then add Two-Factor Authentication to verify your
                account.
              </h1>
              <p className="my-4 text-white/60 ">
                This step is important for Oculus to verify your developer’s
                account.
              </p>
              <img
                src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/metaQuestStep4.png`}
                alt="network error"
                className="w-full h-auto  lg:w-auto"
              />
            </div>
          </div>
          {/* hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh */}
          <ShadowFrame className="absolute top-3/3 right-0 md:w-[500px] h-[250px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.80]" />
          <div className="flex gap-x-4 mt-10">
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id"
                className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
              />
              <svg
                className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <div>
              <h1 className="text-2xl font-heading ">
                Step 5 - Next we need to log in to your Headset with your Oculus
                account.
              </h1>
              <p className="mt-4 mb-5 text-white/60 ">
                Turn on your headset and log in with your Oculus account which
                you have just created in your headset.
              </p>
            </div>
          </div>

          <div className="flex gap-x-4 mt-10">
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id"
                className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
              />
              <svg
                className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <div>
              <h1 className="text-2xl font-heading ">
                Step 6 - Next go to your mobile and download the meta app.
              </h1>
              <p className="mt-4 text-white/60 ">
                If on Android go to -{" "}
                <NavLink
                  target="_blank"
                  to={
                    "https://play.google.com/store/apps/details?id=com.oculus.twilight"
                  }
                  className="text-[#FBBC5E] underline"
                >
                  https://play.google.com/store/apps/details?id=com.oculus.twilight
                </NavLink>
              </p>
              <p className="mb-4 text-white/60 ">
                If on iOS go to -{" "}
                <NavLink
                  target="_blank"
                  to={"https://apps.apple.com/in/app/meta-quest/id1366478176"}
                  className="text-[#FBBC5E] underline"
                >
                  https://apps.apple.com/in/app/meta-quest/id1366478176
                </NavLink>
              </p>
            </div>
          </div>
          <div className="flex gap-x-4 mt-10">
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id"
                className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
              />
              <svg
                className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <div>
              <h1 className="text-2xl font-heading ">
                Step 7 - Once Downloaded, log in with your Oculus account on
                your mobile.
              </h1>
            </div>
          </div>
          {/* hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh */}
          <ShadowFrame className="absolute top-4/3 left-0 md:w-[500px] h-[250px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.80]" />
          <div className="flex gap-x-4 mt-10">
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id"
                className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
              />
              <svg
                className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <div>
              <h1 className="text-2xl font-heading ">
                Step 8 - Then proceed with connecting your headset with the app.
              </h1>
              <p className="mt-4 mb-0 text-white/60 ">
                Since both are logged in with your same Oculus Account, you can
                pair your app with your Headset. Follow these steps for the
                same:
              </p>
              <ol className="ml-[20px] list-decimal text-white/60 ">
                <li>
                  Click on <span className="text-[#FBBC5E] ">‘Menu’</span>.
                </li>
                <li>
                  The click on{" "}
                  <span className="text-[#FBBC5E] ">‘Devices’</span>.
                </li>
                <li>
                  Then click on{" "}
                  <span className="text-[#FBBC5E] ">‘Connect new device’</span>.
                </li>
              </ol>
              <p className="my-5 text-white/60 ">
                Follow all the in-app instructions and instructions on your
                headset to connect both of them.
              </p>
              <img
                src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/metaQuestStep8.png`}
                alt="network error"
                className="w-full h-auto  lg:w-auto"
              />
            </div>
          </div>
          <div className="flex gap-x-4 mt-10">
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id"
                className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
              />
              <svg
                className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <div>
              <h1 className="text-2xl font-heading ">
                Step 9 - Once both are connected, we are ready to enable
                developer mode.
              </h1>
              <ol className="mt-4 ml-[20px] list-decimal text-white/60 ">
                <li>
                  Click on <span className="text-[#FBBC5E] ">‘Menu’</span>.
                </li>
                <li>
                  The click on{" "}
                  <span className="text-[#FBBC5E] ">‘Devices’</span>.
                </li>
                <li>
                  Click on the{" "}
                  <span className="text-[#FBBC5E] ">Quest device</span> that you
                  have just connected. 
                </li>
                <li>
                  Click on{" "}
                  <span className="text-[#FBBC5E] ">‘Headset Setting’</span>.
                </li>
                <li>
                  Then on{" "}
                  <span className="text-[#FBBC5E] ">‘Developer Mode’</span>.
                </li>
                <li>
                  Enable <span className="text-[#FBBC5E] ">‘Debug Mode’</span> .
                </li>
              </ol>
              <p className="mb-5 text-white/60 ">
                Congrats, the developer mode is enabled. Now we can move to Side
                Quest Setup.
              </p>
              <img
                src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/metaQuestStep9.png`}
                alt="network error"
                className="w-full h-auto  lg:w-auto"
              />
            </div>
          </div>
          <ShadowFrame className="absolute top-4/3 left-0 md:w-[500px] h-[250px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.80]" />
          <div className="flex gap-x-4 mt-10">
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id"
                className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
              />
              <svg
                className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <div>
              <h1 className="text-2xl font-heading ">
                Step 10 - SIGN IN/SIGN UP in SideQuest.
              </h1>
              <p className="my-4 text-white/60 ">
                Visit{" "}
                <NavLink
                  target="_blank"
                  to={"https://sidequestvr.com"}
                  className="text-[#FBBC5E] underline"
                >
                  https://sidequestvr.com
                </NavLink>{" "}
                to log in or sign up if you don’t have an account. After Signing
                in click on 'GET SIDEQUEST' to proceed.
              </p>
              <img
                src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/metaQuestStep10.png`}
                alt="network error"
                className="w-full h-auto  lg:w-auto"
              />
            </div>
          </div>

          <div className="flex gap-x-4 mt-10">
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id"
                className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
              />
              <svg
                className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <div>
              <h1 className="text-2xl font-heading ">
                Step 11 - Now download the ‘Advanced Installer’.
              </h1>
              <p className="my-4 text-white/60 ">
                Depending on your OS download the specific installer. After
                downloading, proceed with installing it.
              </p>
              <img
                src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/metaQuestStep11.png`}
                alt="network error"
                className="w-full h-auto  lg:w-auto"
              />
            </div>
          </div>

          <div className="flex gap-x-4 mt-10">
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id"
                className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
              />
              <svg
                className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <div>
              <h1 className="text-2xl font-heading ">
                Step 12 - Open the Side Quest application after installing it
                and log in with your Side Quest account.
              </h1>
              <p className="mt-4 text-white/60 ">
                Since your headset is not connected to your computer it should
                look like this currently.
              </p>
              <img
                src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/metaQuestStep12.png`}
                alt="network error"
                className="w-full h-auto  lg:w-auto"
              />
            </div>
          </div>
          <div className="flex gap-x-4 mt-10">
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id"
                className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
              />
              <svg
                className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <div>
              <h1 className="text-2xl font-heading ">
                Step 13 - Now connect your Headset with your system using a USB
                cable.
              </h1>
              <p className="my-4 text-white/60 ">
                Make sure you enable all the pop-ups that show up in your
                headset after connecting.
              </p>
              <img
                src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/metaQuestStep13.png`}
                alt="network error"
                className="w-full h-auto  lg:w-auto"
              />
            </div>
          </div>
          <div className="flex gap-x-4 mt-10">
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id"
                className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
              />
              <svg
                className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <div>
              <h1 className="text-2xl font-heading ">
                Step 14 - Check if your headset is detectable by the SideQuest
                Application.
              </h1>
              <p className="mt-4 text-white/60 ">
                If this is correct, your headset will be detected by the
                SideQuest.
              </p>
              <img
                src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/metaQuestStep14.png`}
                alt="network error"
                className="w-full h-auto  lg:w-auto"
              />
            </div>
          </div>
          <div className="flex gap-x-4 mt-10">
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id"
                className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
              />
              <svg
                className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <div>
              <h1 className="text-2xl font-heading ">
                Step 15 - Now type “Fourth Star” in the search results.
              </h1>
              <p className="mt-4 text-white/60 ">
                Make sure to type “Fourth star” exactly.
              </p>
              <p className=" text-white/60 ">OR</p>
              <p className=" text-white/60 ">
                Alternatively, you can click on “web browser” then click on “GO
                TO URL” and then paste this Url there -
              </p>
              <p className=" text-[#FBBC5E] ">
                <a
                  href="https://sidequestvr.com/app/31890"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline my-5 text-[#FBBC5E] mx-1"
                >
                  https://sidequestvr.com/app/31890
                </a>
              </p>
              <img
                src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/sidequest-step7.png`}
                alt="network error"
                className="w-full h-auto  lg:w-auto"
              />
            </div>
          </div>
          <div className="flex gap-x-4 mt-10">
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id"
                className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
              />
              <svg
                className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <div>
              <h1 className="text-2xl font-heading ">
                Step 16 - Now click on “SIDELOAD” to start the download.
              </h1>
              <p className="my-4 text-white/60 ">
                If you are coming from the browser, then after clicking on side
                load it will show a pop-up at the bottom right side saying
                “Sending to Side Quest” and then you will have to open the
                application for the download to start.
              </p>
              <img
                src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/metaQuestStep16.png`}
                alt="network error"
                className="w-full h-auto  lg:w-auto"
              />
              <p className="my-4 text-white/60 ">
                Downloading/ Installing should look like this.
              </p>
              <img
                src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/metaQuestStep12b.png`}
                alt="network error"
                className="w-full h-auto  lg:w-auto"
              />
            </div>
          </div>
          <div className="flex gap-x-4 mt-10">
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id"
                className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
              />
              <svg
                className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <div>
              <h1 className="text-2xl font-heading ">
                Step 17 - Then once it is done, check the ‘Unknown Sources’ in
                your Headset.
              </h1>
              <p className="my-4 text-white/60 ">
                Once it is installed, navigate to ‘Unknown Sources’ in your
                headset to find the game, and click it to launch the game.
              </p>

              <img
                src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/metaQuestStep17.png`}
                alt="network error"
                className="w-full h-auto  lg:w-auto"
              />
            </div>
          </div>
          <div className="flex gap-x-4 mt-10">
            <div className="flex gap-2">
              <input
                type="checkbox"
                id="some_id"
                className="relative peer shrink-0 appearance-none w-6 h-6 border border-white/50 rounded-sm bg-transparent mt-1 checked:bg-[#25B39E] checked:border-0"
              />
              <svg
                className="absolute w-6 h-6 mt-1 hidden peer-checked:block pointer-events-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
            <div>
              <h1 className="text-2xl font-heading pb-10 ">
                Step 18 - Congratulations, the game has been successfully
                installed on your headset. Enjoy!
              </h1>
            </div>
          </div>
          <ShadowFrame className="absolute top-4/3 left-0 md:w-[500px] h-[250px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.80]" />
        </div>
      )}
      <div className="min-h-[52vh] md:min-h-0">
        <img
          src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET_STATIC_CONTENT}/user-assets/comingSoonMeta.png`}
          alt="network error"
          className="w-full h-auto lg:h-full lg:w-auto"
        />
      </div>
    </div>
  );
};

export default SideQuest;
