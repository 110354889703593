/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Endpoints-Constant
 *@developer Aditya P
 *@desc Define all constant endpoints
 */

// export const BASE_URL = "https://api.fourthstarstaging.com/api/v1";
// export const BASE_URL_CREATOR = "https://api.fourthstarstaging.com";
export const BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const BASE_URL_CREATOR = process.env.REACT_APP_BASE_CREATOR_API_URL;
export const EndpointsSlug = {
  LOGIN: "/user/auth/signin",
  GOOGLESIGIN: "/user/auth/google-login",
  SIGNUP: "/user/auth/signup",
  VERIFY_OTP: "/user/auth/verifyUser",
  RESEND_OTP: "/user/auth/resendVerificationEmail",
  FORGOT_PASSWORD: "/user/auth/forgotPassword",
  DELETE_USER_OTP: "/user/auth/send-delete-otp",
  DELETE_USER: "/user/auth/delete-user",
  VERIFY_FORGOT_OTP: "/user/auth/verifyForgetPasswordOtp",
  RESET_PASSWORD: "/user/auth/reset-password",
  USERNAME_VALIDATOR: "/user/auth/usernameValidator",
  VERIFY_FORGET_PASSWORD_LINK: "/user/auth/verifyForgetPasswordLink",
  ADD_USER_INFO: "/user/profile/updateUsernameWithGenderAndDob",
  GET_USER_PROFILE: "/user/profile/getProfile",
  UPD_USER_INFO: "/user/profile/editUserProfileData",
  UPD_USER_PROFILE_PHOTO: "/user/profile/updateProfilePhoto",
  REM_USER_PROFILE_PHOTO: "/user/profile/removeProfilePhoto",
  UPD_USER_USER_CHANGE_PASSWORD: "/user/profile/changeUserPassword",
  GET_USER_PREFERENCE: "/user/profile/getUserPreference",
  GET_USER_TAGS: "/user/profile/getUserTags",
  GET_USER_SUGGESTED_TAGS: "/user/profile/getSuggestedTags",
  UPD_USER_PREFERENCE: "/user/profile/updateUserPreference",
  UPD_USER_TAGS: "/user/profile/updateUserTags",
  SEARCH_FRIEND: "user/friend/searchUser",
  FRIEND_REQUEST_SEND: "user/friend/sendFriendRequestToUser",
  ACCEPT_FRIEND_REQUEST: "user/friend/sendFriendRequestToUser",
  ACCEPT_FRIEND: "/user/friend/acceptFriendRequest",
  REJECT_FRIEND_REQUEST: "user/friend/rejectFriendRequest",
  GET_BLOCKED_FRIENDS: "user/friend/getBlockedFriend",
  CANCEL_REQUEST_FRIEND: "/user/friend/cancelRequestedRequest",
  GET_FRIENDS: "user/friend/getFriends",
  GET_PENDING_INVITES: "user/friend/getPendingRequest",
  GET_REQUESTED_FRIEND_REQUEST: "user/friend/getRequestedFriendRequest",
  REMOVE_FRIEND: "/user/friend/removeFriend",
  BLOCK_FRIEND: "/user/friend/blockFriend",
  UNBLOCK_FRIEND: "/user/friend/unBlockFriend",
  GET_180_VIDEOS: "/entertainment/get180Videos",
  GET_SINGLE_180_VIDEO: "/entertainment/getSingle180Video",
  GET_360_VIDEOS: "/entertainment/get360Videos",
  GET_SINGLE_360_VIDEO: "/entertainment/getSingle360Video",
  GET_VOL_VIDEOS: "/entertainment/getVolumetricVideos",
  GET_SINGLE_VOL_VIDEO: "/entertainment/getSingleVolumetricVideo",
  GET_2D_VIDEOS: "/entertainment/get2dvideos",
  GET_SINGLE_2D_VIDEO: "/entertainment/getSingle2dVideo",
  GET_MUSIC_VIDEOS: "/entertainment/getMusicVideos",
  GET_SINGLE_MUSIC_VIDEO: "/entertainment/getSingleMusicVideo",
  GET_ALL_CHANNELLIST: "/entertainment/getAllChannelList",
  GET_SINGLE_CHANNEL: "/entertainment/getSingleChannel",
  GET_CHANNEL_EMAIL: (channelSlug) =>
    "/entertainment/get-channel-email/" + channelSlug,
  GET_MOST_TRENDING_VIDEOS: "/entertainment/getMostTrendingVideos",
  GET_PREMIUM_VIDEOS: "/entertainment/getPremiumVideos",
  GET_MOST_TRENDING_CONTENT: "/analytics/get-most-trending-content",
  GET_PREMIUM_CHANNELS: "/analytics/premium-channel",
  GET_RECOMMENDED_CHANNELS: "/analytics/get-recommended-channel",
  GET_RECOMMENDED_CONTENT: "/analytics/get-recommended-content",
  POST_TRENDING_CHANNELS: "/analytics/trending-channel",
  GET_ALL_CAT_DATA: `/api/creator/get-allCategoryDataByChannelID`,
  POST_CHECK_USER_ASSETS_INFO: `/iap/userAssets`,
  POST_CHECK_USER_ADD_FREE_ASSET: `/iap/addToAsset`,
  GET_SINGLE_USER_ASSETS: "/iap/userAssetsLists",
  GET_SINGLE_USER_SUBSCRIBED_CHANNEL: "/subscription/userSubscribedChannels",
  GET_USER_ALL_NOTIFICATION: "/user/notification/getAllNotifications",
  GET_SINGLE_NOTIFICATION: "/user/notification/getSingleNotification",
  CREATE_USER_INTERACTION: "/analytics/create-user-interaction",
  READ_ALL_NOTIFICATION: "/user/notification/readAllNotification",
  FILE_NAME_GENERATE: "/user/profile/file-name-generate",
  FILE_USER_UPLOAD: "/user/profile/user-upload",
  REPORT_CONTENT: "/admin/report/create-reported-content",
  REPORT_CHANNEL: "/admin/report/create-reported-channel",
  GET_ALL_CHANNEL_CONTENT: "/entertainment/allChannelContent",
  GET_ALL_APARTMENT: "/apartment/get-apartment-list",
  GET_SINGLE_APARTMENT: "/apartment/get-one-apartment",
  GET_ALL_SHIPS: "/ship/get-ship-list",
  GET_CART_ITEMS: "/user/cart/getUsdCartItems",
  GET_CART_ITEMS_COUNT: "/user/cart/getCartItemsCount",
  GET_STAR_CART_ITEMS: "/user/cart/getStarCartItems",
  GET_SINGLE_SHIP: "/ship/get-one-ship",
  GET_SINGLE_CLOTHING: "/clothing/get-one-clothing",
  GET_SINGLE_AVATAR: "/avatar/get-one-avatar",
  GET_SINGLE_COMPANION: "/companion/get-one-companion",
  CREATE_CART_ITEMS: "/user/cart/addToCart",
  REMOVE_CART_ITEM: "/user/cart/removeFromCart",
  GET_EVENT_LIST: "/events/guest-user-event-list",
  GET_ONE_EVENT: "/events/get-one-event",
  GET_RECC_EVENT: "/analytics/get-recommended-event",
  GET_TODAY_EVENT_LIST: "/events/get-today-event-list",
  GET_USER_EVENT_LIST: "/events/get-user-event-list",
  ADD_PARTICIPANT_TO_EVENT: "/events/add-participant-event",
  IAP_ASSET_LIST: "/iap/userIAPAssetsLists/all",
  LIST_IAP_ASSETS: "iap/userIAPAssetsLists",
  EQUIP_DEFAULT_CLOTHING_AVATAR: "iap/equip-default-clothing-avatar",
  WEAR_ALL_ITEMS: "iap/clothing-avatar-equip-allwear",
  MARKETPLACE_CLOTHING: "clothing/get-clothing-list",
  MARKETPLACE_AVATAR: "avatar/get-avatar-list",
  MARKETPLACE_COMPANION: "companion/get-companion-list",
  INITIATE_TRANS: "/payment/transaction/initiateTransaction",
  INITIATE_EVENT_TRANS: "/payment/transaction/initiateEventPayment",
  VERIFY_GATEWAY_TRANSACTION: "/payment/transaction/verifyTransaction",
  CREATE_SUBSCRIPTION_MONTHLY: "/payment/transaction/subscribeChannel",
  GET_FRIEND_LIST_APARTMENT_ASSET:
    "/apartment/access/getFriendsWithGrantStatus",
  INVITE_FRIEND_APARTMENT_ASSET: "/apartment/access/apartmentGrantsToFriends",
  REMOVE_FRIEND_APARTMENT_ASSET: "/apartment/access/removeApartmentGrants",
  GET_FRIEND_LIST_SHIP_ASSET: "/ship/access/getFriendsWithShipGrantStatus",
  INVITE_FRIEND_SHIP_ASSET: "/ship/access/shipGrantsToFriends",
  REMOVE_FRIEND_SHIP_ASSET: "/ship/access/removeShipGrants",
  GET_CHANNEL_CONTENT_ASSET:
    "/entertainment/getUserSubscribedChannelWithTopContent",
  POST_CURRENCY_CONVERSION: "/guest/currency/usd-fstr-currency-exchange",
  GET_MY_ORDERS_ASSET: "/user/order/list",
  GET_MY_ORDER_DETAIL: "/user/order/detail",
  INITIATE_CRYPTO_TRANSACTION: "/payment/transaction/initiateCryptoTransaction",
  INITIATE_CRYPTO_SUBSCRIPTION:
    "/payment/transaction/initiateCryptoSubscription",
  INITIATE_CRYPTO_EVENT_PURCHASE:
    "/payment/transaction/initiateCryptoEventPurchase",
  VERIFY_CRYPTO_TRANSACTION: "/token/verifyCryptoTransaction",
  PROCESS_CRYPTO_TRANSACTION: "/token/processCryptoTransaction",
  GET_USER_SUBSCRIPTION_LIST: "/subscription/user/subscriptionList",
  GET_USER_SUBSCRIPTION_TRANS_DETAILS: "/subscription/user/transactiondetail",
  CHECK_USER_SUBSCRIPTION: "/payment/user/checksubscription",
  CANCEL_SUBSCRIPTION: "/subscription/cancelSubscription",
  ADD_TICKET_RAISE: "/user/helpCenter/raiseTicket",
  UPDATE_ACCOUNT_PRIVATE: "/user/profile/updateIsPrivateStatus",
  POST_REQUEST_REFUND: "/payment/withdraw/refund",
  CHECK_REFUND_STATUS: "/payment/withdraw/refundStatus",
};
