/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Avatar-item-component
 *@developer Shubham K
 */

import React from "react";
import topwearImg from "assets/images/topwear_item.png";
import bottomwearImg from "assets/images/bottomwear_item.png";
import suitImg from "assets/images/suit_item.png";

const ItemPreviewIcon = ({ img, type = "", onClickReplace }) => {
  const onClickHandler = () => {
    onClickReplace(type);
  };

  let defaultImg = topwearImg;
  if (type == "bottomwear") {
    defaultImg = bottomwearImg;
  }
  if (type.includes("suit")) {
    defaultImg = suitImg;
  }

  return (
    <div className="relative h-[113px] w-[113px] bg-[#332D23] rounded-lg transition-transform hover:scale-105">
      <img
        className="h-full w-full object-contain rounded-lg"
        src={img}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = defaultImg;
        }}
        alt=""
      />
      <div
        className="absolute bottom-0 left-0 w-full text-center text-[14px] py-1 px-2 bg-[#2E281F]/70 font-bold text-[#fbbc5e] cursor-pointer rounded-b-lg filter hover:brightness-125"
        onClick={onClickHandler}
      >
        Replace
      </div>
    </div>
  );
};

export default ItemPreviewIcon;
