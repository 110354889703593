/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module OtpVerification
 *@developer Sudhanshu <<sudhanshut@instaacoders.com>>
 */

import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyUser } from "store/slicer/userAuth/authActions";
import webRequestHandler from "constant/api/apiInstance";
import { BASE_URL, EndpointsSlug } from "constant/api/Endpoints";
import { clearMsgState } from "store/slicer/userAuth";
import Alert from "components/popup/Alert/Alert";

/* This code is a React component for getting OTP is you forgot account passwords.
 */
const OtpVerification = () => {
  const instance = webRequestHandler(BASE_URL);
  const [formData, setFormData] = useState({
    otp: "",
    email: localStorage.getItem("email") || localStorage.getItem("isForgot"),
  });
  const [formErrors, setFormErrors] = useState({
    otp: "",
    email: "",
  });
  const [isOTPexist, setIsOTPexist] = useState(true);

  const [alertMessage, setAlertMessage] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [successAlert, setSuccessAlert] = useState(true);

  // called when alert popup is closed
  const onCloseAlert = () => {
    setAlertMessage("");
    setSuccessAlert("");
    setAlertHeading("");
  };

  // Function to validate the form data
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.otp) {
      errors.otp = "Please enter OTP";
      isValid = false;
    } else {
      isValid = true;
      setIsOTPexist(isValid);
    }

    setIsOTPexist(isValid);
    setFormErrors(errors);
    return isValid;
  };

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear the error and remove the red border when the user starts typing
    if (name === "otp") {
      setFormErrors({ ...formErrors, otp: "" });
    }
  };
  const dispatch = useDispatch();
  const { loading, userInfo, userToken, error, success, successMsg } =
    useSelector((state) => state.auth);
  const navigate = useNavigate();

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(verifyUser(formData));
    }
  };
  useEffect(() => {
    if (
      !localStorage.getItem("isForgot") &&
      !localStorage.getItem("email") &&
      userInfo?.isVerified
    ) {
      navigate("/signin");
    }
  }, []);
  useEffect(() => {
    if (error) {
      setAlertMessage(error);
      setSuccessAlert(false);
    }
    if (
      userInfo &&
      userToken &&
      userInfo?.isVerified &&
      userInfo.statusFlag == 3
    ) {
      return navigate("/");
    }
    // redirect user to otp verification if registration was successful
    else if (success && successMsg && userInfo?.isVerified) {
      setAlertMessage(successMsg);
      setAlertHeading("OTP Verified!");
      setSuccessAlert(true);
      // old code

      // setTimeout(() => {
      //   dispatch(clearMsgState());
      //   if (localStorage.getItem("isForgot")) {
      //     navigate('/reset-password');
      //   } else {
      //     navigate('/add-details');
      //   }

      //   setFormData({
      //     otp: "",
      //     email: ""
      //   })
      // }, 2000)

      // testing pending for below code
      dispatch(clearMsgState());
      if (localStorage.getItem("isForgot")) {
        navigate("/reset-password");
      } // onboarding not started, send to first onboarding page
      else if (userInfo?.statusFlag == 0) {
        return navigate("/add-details");
      }
      // 1st step of onboarding completed, send to next page
      else if (userInfo?.statusFlag == 1) {
        return navigate("/select-preferences");
      }
      // 2nd step of onboarding completed, send to next page
      else if (userInfo?.statusFlag == 2) {
        return navigate("/add-tags");
      }
      // onboarding is completed
      else if (userInfo?.statusFlag == 3) {
        return navigate("/");
      }
    }
  }, [navigate, userInfo, userToken, error, success, successMsg]);

  const sendCodeAgain = async () => {
    try {
      const payload = {
        email: formData.email,
      };
      const data = await instance.post(EndpointsSlug.RESEND_OTP, payload);
      console.log("data", data);

      if (data?.response?.statusEnum === 1) {
        setAlertMessage(data?.response?.message);
        setAlertHeading("Verification Code Resent!");
        setSuccessAlert(true);
      } else {
        setAlertMessage(data?.response?.message);
        setSuccessAlert(false);
      }
    } catch (error) {
      setAlertMessage("Something went wrong");
      setSuccessAlert(false);
    }
  };

  return (
    <>
      <Box className="w-full max-w-[700px] rounded-lg border border-[#363636] p-5 md:p-[32px] lg:p-[58px] signin-form">
        <Box className="text-white max-w-[500px] mx-auto">
          <h1 className="text-lg lg:text-xl text-center font-heading mb-[28px]">
            OTP Verification
          </h1>
          <p className="text-center text-[#C4C4C4] mb-[50px]">
            Please Enter the verification code we sent to your Email <br />
            <b>{formData.email}</b>
          </p>
          <form className="auth-form mb-5" onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="mt-2">
                <input
                  type="text"
                  name="otp"
                  className={`rounded-lg w-full bg-transparent border border-white focus:border-[#f6f6f6] autofill:bg-transparent ${
                    !isOTPexist ? "!border-error" : ""
                  } font-normal py-3 px-5 leading-normal font-semibold outline-none pr-[40px] placeholder-normal`}
                  id="otp"
                  placeholder="Enter Verification Code"
                  value={formData.otp}
                  onChange={handleInputChange}
                />
                {formErrors.otp && (
                  <span className="text-error text-sm">{formErrors.otp}</span>
                )}
              </div>
            </div>

            <button
              type="submit"
              className="font-bold rounded-lg btn-gradient w-full text-black py-3 px-5 mt-6 flex justify-center"
              disabled={loading}
            >
              {loading ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Processing...
                </>
              ) : (
                "Verify"
              )}
            </button>
          </form>
          <button
            type="button"
            className="text-center font-semibold text-sec hover:text-secDark w-full mt-[14px]"
            onClick={sendCodeAgain}
          >
            Resend Code
          </button>
        </Box>
      </Box>
      {alertMessage && (
        <Alert
          open={Boolean(alertMessage)}
          onClose={onCloseAlert}
          message={alertMessage}
          heading={alertHeading}
          isSuccess={successAlert}
        />
      )}
    </>
  );
};

export default OtpVerification;
