import Swal from "sweetalert2";

const ErrorAlert = (errorMessage) => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn-primary",
      cancelButton: "btn-secondary",
    },
    buttonsStyling: false,
  });
  swalWithBootstrapButtons.fire({
    icon: "error",
    html: `<b style='font-family:Montserrat !important;'>${errorMessage}</b>`,
  });
};

export default ErrorAlert;
