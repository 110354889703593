import React, { useState } from "react";
import { useAddFriendRequestMutation } from "store/slicer/friends";

const FriendSearchCard = ({
  avtarImg,
  username,
  fullname,
  email,
  statustype,
}) => {
  const [sentFriendRequest, { isLoading }] = useAddFriendRequestMutation();
  const [sentRequestData, setRequestData] = useState(null);
  const [checkSentrequest, setCheckSentRequest] = useState(false);

  const sentFriendRequestHandle = (e, email) => {
    e.preventDefault();
    sentFriendRequest({
      email: email,
    })
      .then((res) => {
        setRequestData(res.data);
        setCheckSentRequest(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="__List_card_view flex border-b-[0.4px] border-[#7A7A7A33] py-[10px]">
      <div className="__list__avatar w-[50px] h-[50px] rounded-[50%]">
        <img
          src={avtarImg}
          alt="freinds_avatar"
          className="w-[50px] h-[50px] rounded-[50%] object-cover"
        />
      </div>
      <div className="__user_emailData w-[60%] pl-[10px]">
        <div className="__upperData">
          <span className="text-[15px] max-[768px]:text-xs">{username}</span>
          {/* <span className="text-[#FFFFFF80] text-[13px] max-[768px]:text-xs"> {fullname}</span> */}
        </div>
        <div className="__lowerData overflow-hidden w-[80%]">
          <span className="text-[13px] text-[#FFFFFF99] max-[768px]:text-xs truncate">
            {" "}
            {fullname}
          </span>
        </div>
      </div>
      <div className="__request_btn w-[30%] text-right">
        {statustype === 0 && !checkSentrequest && (
          <button
            onClick={(e) => sentFriendRequestHandle(e, email)}
            className="text-[12px] max-[768px]:text-xs text-right font-medium text-[#F3A151] px-[8px] rounded-[5px] hover:bg-[#494848]"
          >
            {isLoading ? "Sending..." : "Send Request"}
          </button>
        )}
        {statustype === 0 && checkSentrequest && (
          <button className="text-[13px] max-[768px]:text-xs text-right  px-[8px] font-medium text-[#fff] hover:cursor-not-allowed">
            Requested
          </button>
        )}
        {statustype === 1 && (
          <button className="text-[13px] max-[768px]:text-xs text-right  px-[8px] font-medium text-[#fff] hover:cursor-not-allowed">
            Requested
          </button>
        )}
        {statustype === 2 && (
          <button className="text-[13px] max-[768px]:text-xs text-right  px-[8px] font-medium text-[#5ad65e] hover:cursor-not-allowed">
            Already Friend
          </button>
        )}
        {statustype === 3 && (
          <button className="text-[13px] max-[768px]:text-xs text-right  px-[8px] font-medium text-[#d65a5a] hover:cursor-not-allowed">
            Blocked
          </button>
        )}
      </div>
    </div>
  );
};

export default FriendSearchCard;
