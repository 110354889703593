import { createSlice } from "@reduxjs/toolkit";
import {
  userLogin,
  registerUser,
  verifyUser,
  forgotPassword,
  resetPassword,
  verifyForgetPasswordLink,
  googleSigin,
} from "./authActions";

const userToken = localStorage.getItem("accessToken")
  ? localStorage.getItem("accessToken")
  : null;
const userInfo = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;
const initialState = {
  loading: false,
  userInfo, // for user object
  userToken, // for storing the JWT
  error: null,
  success: false, // for monitoring the registration process.
  successMsg: null,
  pwdTokenVerified: false, // to check if reset password token verified
  pwdResetComplete: false, // to check if password is reset successfully
};

// Handling State function or logics of User Authentication Module
// Replace the CreateSlice method with RTK function - createAPI for storing api data directly into slicer
export const UserAuthSlice = createSlice({
  name: "UserAuth",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("accessToken"); // delete token from storage
      localStorage.removeItem("userInfo");
      localStorage.removeItem("email");
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.error = null;
      state.successMsg = null;
    },
    setCredentials: (state, { payload }) => {
      if (payload?.message === "Ok") {
        if (payload?.response?.data) {
          state.userInfo = payload?.response?.data;
        }
      } else {
        state.userInfo = null;
      }
    },
    clearMsgState: (state) => {
      state.loading = false;
      state.error = null;
      state.successMsg = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMsg = null;
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userInfo = payload?.data?.user;
        state.success = true;
        state.successMsg = payload?.message;
        state.userToken = payload?.data?.accessToken;
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.message;
        state.successMsg = null;
      })
      .addCase(googleSigin.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMsg = null;
      })
      .addCase(googleSigin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userInfo = payload?.data?.user;
        state.success = true;
        state.successMsg = payload?.message;
        state.userToken = payload?.data?.accessToken;
      })
      .addCase(googleSigin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.message;
        state.successMsg = null;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMsg = null;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.successMsg = payload?.message;
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.message;
        state.successMsg = null;
      })
      .addCase(verifyUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMsg = null;
        state.success = false;
      })
      .addCase(verifyUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload?.data?.accessToken) {
          state.userToken = payload?.data?.accessToken;
        }
        if (payload?.data?.user) {
          state.userInfo = payload?.data?.user;
        }
        state.success = true;
        state.successMsg = payload?.message;
      })
      .addCase(verifyUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.message;
        state.successMsg = null;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMsg = null;
        state.success = false;
      })
      .addCase(forgotPassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.successMsg = payload?.message;
      })
      .addCase(forgotPassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.message;
        state.successMsg = null;
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMsg = null;
        state.success = false;
        state.pwdResetComplete = false;
      })
      .addCase(resetPassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.successMsg = payload?.message;
        state.pwdResetComplete = true;
      })
      .addCase(resetPassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.message;
        state.successMsg = null;
        state.pwdResetComplete = false;
      })
      .addCase(verifyForgetPasswordLink.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMsg = null;
        state.success = false;
        state.pwdTokenVerified = false;
      })
      .addCase(verifyForgetPasswordLink.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
        state.successMsg = payload?.message;
        state.pwdTokenVerified = true;
      })
      .addCase(verifyForgetPasswordLink.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload?.message;
        state.successMsg = null;
        state.pwdTokenVerified = false;
      });
  },
});

export const { logout, setCredentials, clearMsgState } = UserAuthSlice.actions;

export default UserAuthSlice.reducer;
