const ImageConstant = {
  APARTMENT_BANNER_IMAGE:
    "https://d30s60cgc31mft.cloudfront.net/apartment-front-banner-3.png",
  SHIP_BANNER_IMAGE:
    "https://d30s60cgc31mft.cloudfront.net/ship-front-banner.jpeg",
  ENTERTAINMENT_BANNER_IMAGE:
    "https://d30s60cgc31mft.cloudfront.net/entertainment-hero.png",
  CLOTHING_BANNER_IMAGE:
    "https://d30s60cgc31mft.cloudfront.net/clothing-banner-image.jpg",
  AVATAR_BANNER_IMAGE:
    "https://d30s60cgc31mft.cloudfront.net/avatar_banner_image.png",
  COMPANION_BANNER_IMAGE:
    "https://d30s60cgc31mft.cloudfront.net/companion+hero.png",
  GAMING_BANNER_IMAGE:
    "https://d30s60cgc31mft.cloudfront.net/Gaming+hero-section-image.jpg",
};

export default ImageConstant;
