import React, { useState, useEffect } from "react";
import PopupWrapper from "../PopupWrapper";
import { log } from "three/examples/jsm/nodes/Nodes";
const ConfirmDeletion = ({
  open,
  onClose,
  canClose,
  resendOtpHandler,
  resendOtpHandlerInfo,
  deleteBtnHandler,
  isLoading,
  otp,
  setOtp,
  password,
  setPassword,
}) => {
  const [acknowledged, setAcknowleged] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [isOtpPristine, setIsOtpPristine] = useState(true);
  const [isPasswordPristine, setIsPasswordPristine] = useState(true);
  const [formErrors, setFormErrors] = useState({
    otp: "",
    password: "",
  });
  const checkFormErrors = () => {
    // resetting the errors
    let errors = {
      otp: "",
      password: "",
    };
    setFormErrors(errors);
    let isValid = true;
    if (!otp) {
      errors.otp = "Please provide OTP which we sent you ";
      isValid = false;
    }
    if (!password) {
      errors.password = "Please provide your current password";
      isValid = false;
    }
    setFormErrors(errors);
    setIsFormValid(isValid);
  };

  useEffect(() => {
    if (resendOtpHandlerInfo.isSuccess) {
      console.log("resendOtpHandlerInfo", resendOtpHandlerInfo);
      setOtpSent(true);
      // setIsOtpPristine(false);
    }
  }, [resendOtpHandlerInfo]);

  useEffect(() => {
    checkFormErrors();
  }, [otp, password]);
  return (
    <PopupWrapper open={open} onClose={onClose} canClose={canClose}>
      <div className="relative  bg-[#2c2c2c] rounded-[8px] py-4 px-4">
        <div
          className="absolute top-1 right-1 bg-white/10 w-[28px] h-[28px] rounded-full grid place-content-center cursor-pointer"
          onClick={onClose}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6875 1.3125L1.3125 12.6875M1.3125 1.3125L12.6875 12.6875"
              stroke="white"
              strokeOpacity="0.5"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <h1 className="font-heading font-normal text-[20px] leading-[25px] text-white">
          Are you sure you want to delete your account?
        </h1>
        <p className="mt-8 font-body font-normal text-[16px] leading-[24px]">
          Are you sure you want to delete your account? If you proceed, your
          account and all associated data, including in-app purchases, will be
          permanently deleted after <strong>30 days</strong>. However, if you
          log in to your account within <strong>30 days</strong>, your deletion
          request will be canceled.
        </p>
        <p className="mt-4 font-body font-normal text-[16px] leading-[24px]">
          Please enter the One-Time Password (OTP) sent to your email.
        </p>
        <div className="__username_field relative flex flex-col pt-[20px] ">
          <input
            className={`bg-transparent  w-[100%] mt-[5px] ml-[3px] rounded-[8px] border border-[#FFFFFF1A] py-[10px] px-[15px] focus:border-[#f6f6f6] outline-none`}
            type="text"
            name="otp"
            placeholder="Enter OTP"
            onChange={(e) => setOtp(e.target.value)}
            onBlur={() => setIsOtpPristine(false)}
          />
          {resendOtpHandlerInfo.isLoading ? (
            <span className="vector  absolute left-[74%] max-[1024px]:left-[58%] max-[372px]:left-[48%] translate-y-1/2 pt-1">
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </span>
          ) : otpSent ? (
            <span
              className={`vector  absolute left-[54%] max-[1024px]:left-[38%] max-[372px]:left-[28%] translate-y-1/2 pt-1 text-[#206331]`}

              // onMouseEnter={(e) => toggleUserGuidelines(e)}
              // onMouseLeave={(e) => toggleUserGuidelines(e)}
            >
              <strong>OTP Sent Successfully</strong>
            </span>
          ) : (
            <span
              className={`vector  absolute left-[78%] max-[1024px]:left-[68%] max-[372px]:left-[58%] translate-y-1/2 cursor-pointer pt-1 text-[#FBBC5E]`}
              onClick={() => {
                resendOtpHandler();
              }}
              // onMouseEnter={(e) => toggleUserGuidelines(e)}
              // onMouseLeave={(e) => toggleUserGuidelines(e)}
            >
              Resend OTP
            </span>
          )}
          {!isOtpPristine && formErrors.otp && (
            <span className="__error__validation text-[14px] px-2 pt-[10px] text-[#FF5144]">
              {formErrors.otp}
            </span>
          )}
          {/* {userGuideLines && (
                    <div className={`notification-dropdown popover absolute ${screenSize.width > 1226 ? "top-14 left-[53%]" : "top-8 right-4 rotate-180"} delay-200 transition`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="23"
                        viewBox="0 0 20 23"
                        fill="none"
                      >
                        <path
                          d="M19.6752 0.847325L0.643729 11.8411L19.6803 22.826L19.6752 0.847325Z"
                          fill="#716046"
                        />
                      </svg>
                    </div>
                  )} */}
        </div>
        <div className="__lastname_field flex flex-col pt-[20px] w-full">
          <label className="max-[768px]:text-[12px]" htmlFor="password">
            Enter your current password
          </label>
          <input
            className={`bg-transparent  max-[768px]:w-[100%] mt-[20px]  max-[768px]:ml-0 ml-[3px] rounded-[8px] border border-[#FFFFFF1A] py-[10px] px-[15px] focus:border-[#f6f6f6] outline-none`}
            type="password"
            name="password"
            placeholder="Enter Password"
            onChange={(e) => setPassword(e.target.value)}
            onBlur={() => setIsPasswordPristine(false)}
            value={password}
            // required
            // disabled={!isformEditable}
          />
          {!isPasswordPristine && formErrors.password && (
            <span className="__error__validation text-[14px] px-2 pt-[10px] text-[#FF5144]">
              {formErrors.password}
            </span>
          )}
        </div>
        <div class="mt-8">
          <input
            type="checkbox"
            className="form-checkbox h-[18px] w-[18px] accent-[#FBBC5E] border rounded-md"
            checked={acknowledged}
            onChange={(e) => setAcknowleged(e.target.checked)}
          />
          <span className="text-white/60 text-[16px] ">
            {" "}
            I understand and agree that by deleting my account, I will
            permanently lose all my data and in-app purchases and this action
            cannot be undone.
          </span>
        </div>
        <div className="mt-8 flex items-center justify-between">
          <button
            className="w-[48%] btn-cancel rounded-[8px] font-Inter font-semibold text-[16px] leading-[19.36px] py-3"
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            className="w-[48%] btn-danger  rounded-[8px] font-Inter font-semibold text-[16px] leading-[19.36px] py-3 "
            onClick={deleteBtnHandler}
            disabled={isLoading || !isFormValid || !acknowledged}
          >
            {isLoading ? "Deleting..." : "Delete Account"}
          </button>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default ConfirmDeletion;
