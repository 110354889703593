/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module BuyContent
 *@developer Shubham K
 */

import * as React from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ComingSoonImage from "assets/images/star_icon.png";
import Cross from "assets/images/cross.png";
const Index = ({ status, handleData, text }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClick = () => {
    handleData(false);
  };
  return (
    <Dialog
      maxWidth={fullScreen}
      open={status}
      onClose={handleClick}
      aria-labelledby="responsive-dialog-title"
    >
      <div className="relative py-[70px] px-[84px] bg-[#262626] text-white lg:w-[650px] flex flex-col justify-between items-center">
        <div className="mb-9">
          <img src={ComingSoonImage} alt="star" className="h-[72px] w-[72px]" />
        </div>
        <div className="mb-9">
          <h2 className="font-heading md:text-[20px] text-center">
            COMING SOON
          </h2>
        </div>
        <div>
          <p className="text-[12px] md:text-[16px] leading-6 font-normal text-center">
            {text}
          </p>
        </div>
        <div className="absolute top-[8px] right-[20px] w-[28px] h-[28px] p-[6px] cursor-pointer">
          <div
            onClick={handleClick}
            className="bg-[#FFFFFF1A] hover:bg-[#FFFFFF4D] hover:cursor-pointer flex flex-col justify-center items-center rounded-full w-[30px] h-[30px]"
          >
            <img
              src={Cross}
              className="w-[10px] h-[10px] "
              alt="modal_close_btn"
            />
          </div>

          {/* <img src={Cross} alt="cancel" className=''/> */}
        </div>
      </div>
    </Dialog>
  );
};

export default Index;
