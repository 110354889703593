import React from "react";
import AssetCardOpen from "components/card/assets/AssetCardOpen";
import PremiumLockIcon from "assets/images/myassets/material-symbols_lock-outline.png";
import Explore from "components/Explore/Explore";

const EntertainmentCardClass = {
  cardMainclass:
    "w-full min-h-[136px] mb-[30px] hover:cursor-pointer hover:scale-[1.024] transition duration-300 ease-in-out max-[768px]:w-[100%] max-[768px]:p-0",
  cardMediaClass:
    "w-[100%] flex justify-center items-center text-center min-h-[136px] rounded-[10px] aspect-[456/239] relative rounded-2xl bg-[#2B2B2B] mb-2.5 bg-center bg-cover",
  cardtitleClass:
    "py-[12px] font-body text-[14px] font-light pl-[6px] max-[768px]:text-[13px]",
  cardButtonclass:
    "pl-[6px] [&>img]:w-[23px] gap-[10px] flex font-bold bg-[#FBBC5E0D] px-[15px] py-[7px] rounded-[8px] text-center text-[15px] hover:shadow-[0_0_3px_1px_rgba(251,188,94,0.47)] hover:border-[#FBBC5E] max-[768px]:text-[13px]",
};

const Music = ({ item }) => {
  const filtermusicData = item?.filter((vitem) => {
    return vitem?.content_type === "music";
  });

  return (
    <>
      {filtermusicData.length === 0 ? (
        <div class="__explore_default_button h-[500px] w-full grid place-content-center">
          <Explore />
        </div>
      ) : (
        <>
          {" "}
          <h2 className="mb-4 text-white text-[24px] leading-[36px] font-heading">
            Music
          </h2>
          <div className="w-full flex justify-between gap-[20px] gap-y-[32px] flex-wrap">
            {filtermusicData?.map((_vitem) => {
              return (
                <div key={_vitem?.content_id} className="w-full lg:w-[30%]">
                  <AssetCardOpen
                    key={_vitem?.content_id}
                    Mkey={"music"}
                    baseRoute={`/marketplace/entertainment/content/${_vitem?.content_type}`}
                    channelID={_vitem?.content_data?.channel.channelHandle}
                    contentID={_vitem?.content_data.contentSlug}
                    image={_vitem?.content_data?.thumbnail}
                    name=""
                    title={_vitem?.content_data?.contentName}
                    subtitleButton="Watch in VR"
                    cardClass={EntertainmentCardClass}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default Music;
