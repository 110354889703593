/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module My-Subscriptions-Page
 *@developer Shubham K
 */

import ShadowFrame from "components/shadow-frame";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { GlobalStore } from "store/GlobalStore";
import ActiveSubscriptions from "./components/tabs/ActiveSubscriptions";
import CancelledSubscriptions from "./components/tabs/CancelledSubscriptions";

const parentTabs = [
  { id: 0, key: "active-subscriptions", name: "Active Subscriptions" },
  { id: 1, key: "cancelled-subscriptions", name: "Cancelled Subscriptions" },
];

const Index = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = localStorage.getItem("accessToken");
  // need to use redux global state directly because two stores have been setup in the app
  const authState = GlobalStore.getState()?.auth;

  // user not signed in
  if (!accessToken) {
    navigate(`/signin?ref=${location.pathname}`, {
      replace: true,
    });
  }

  // user signed in but did not verify his email
  if (accessToken && !authState?.userInfo?.isVerified) {
    navigate(`/otp-verification`, {
      replace: true,
    });
  }

  const [parentActivetab, setParentActivetab] = useState({
    id: 0,
    activeTab: "active-subscriptions",
  });

  return (
    // <div data-testid="my-assets-main" className="__my_assets_main container mx-auto px-[80px] py-[25px] max-[768px]:px-[15px] max-[1280px]:px-0 bg-[#1D1D1D]">
    <div
      data-testid="my-assets-main"
      className="min-h-[calc(100vh-170px)] container app-container mx-auto px-2 md:px-3 xl:px-6 2xl:px-10 py-[25px] relative z-10"
    >
      <ShadowFrame
        data-testid="shadow-frame"
        className="pointer-events-none z-[0] w-[350px] md:w-[250px] h-[350px] md:h-[400px] rounded-[250px] md:rounded-[400px] left-[-10%] max-[768px]:left-[-50%] top-[50%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]"
      />
      <ShadowFrame
        data-testid="shadow-frame"
        className="pointer-events-none z-[0] w-[250px] md:w-[250px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[10%] max-[768px]:right-[50%] top-[100%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]"
      />

      <h1 className="my-4 pl-2 text-[18px] lg:text-[24px] leading-[36px] text-white font-heading">
        Manage Subscriptions
      </h1>

      <div
        data-testid="parent-tabs"
        className="__myassets_parentTabs_main 
                      border-gray-50 
                      px-[15px] 
                      py-[15px] 
                      max-[768px]:p-0
                      "
      >
        <ul
          data-testid="parenttab"
          className={`flex gap-6 [&>li]:font-medium [&>li]:font-body [&>li]:cursor-pointer`}
        >
          {parentTabs?.map((tabs, ind) => {
            return (
              <li
                role="parent-list-item"
                key={tabs.key}
                className={`${
                  parentActivetab.activeTab === tabs.key
                    ? "text-[#fff] text-[18px] max-[768px]:text-[12px] transition-all border-b-[2px] border-b-[#fbbc5e]"
                    : "text-[gray] text-[18px] max-[768px]:text-[12px] transition-all"
                } whitespace-nowrap pb-2`}
                onClick={() => setParentActivetab({ activeTab: tabs.key })}
              >
                {tabs.name}
              </li>
            );
          })}
        </ul>
        <hr className="border-[0.1px] mt-[-1px] border-[#5A5A5A80]" />
      </div>

      {parentActivetab.activeTab === "active-subscriptions" && (
        <div className="px-3">
          <ActiveSubscriptions />
        </div>
      )}

      {parentActivetab.activeTab === "cancelled-subscriptions" && (
        <div className="px-3">
          <CancelledSubscriptions />
        </div>
      )}
    </div>
  );
};

export default Index;
