import Flag from "components/report/Flag";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Zoom } from "react-reveal";
import BuyCards from "components/buy-cards";
import GamingDetailBanner from "assets/images/gaming_detail_banner.png";
import GamingProfileImg from "assets/images/gaming_detail_profile_img.png";
import SliderHeader from "components/partials/SliderHeader";
import SneakyPeak from "components/carousel/SneakyPeak";
import AssetsReportModal from "components/Modal/Report/AssetsReportModal";
import ComingSoon from "components/popup/comingsoon";

const mostTrending = [
  {
    title: "StellarVerse Spectacle: Cosmic Symphony",
    productType: "Paid",
    price: "Buy @US$ 9.99",
    id: "1",
  },
  {
    title: "StellarVerse Spectacle: Cosmic Symphony",
    productType: "Paid",
    price: "Buy @US$ 9.99",
    id: "2",
  },
  {
    title: "StellarVerse Spectacle: Cosmic Symphony",
    productType: "Paid",
    price: "Buy @US$ 9.99",
    id: "3",
  },
  {
    title: "StellarVerse Spectacle: Cosmic Symphony",
    productType: "Paid",
    price: "Buy @US$ 9.99",
    id: "4",
  },
  {
    title: "StellarVerse Spectacle: Cosmic Symphony",
    productType: "Paid",
    price: "Buy @US$ 9.99",
    id: "5",
  },
];
const GameCategoryDetail = () => {
  const navigate = useNavigate();
  const [IstooltipHover, setTooltipHover] = useState(false);

  const HandletooltipHover = () => {
    setTooltipHover(!IstooltipHover);
  };

  const [isreportModal, setReportModal] = useState(false);

  const handleCloseReportModal = () => {
    setReportModal(!isreportModal);
  };

  const [showComingSoonPopup, setShowComingSoonPopup] = useState(false);
  const handleClick = (e) => {
    if (e === "MyAsset") {
      // setContentType("MyAsset");
    }
    if (e === "Premium") {
      setShowComingSoonPopup(true);
      // This will open popup for purchasing when implemented, do not remove this will be used later
      // setOpen(true);
    }
    if (e === "BuyNow") {
      setShowComingSoonPopup(true);
      // This will open popup for purchasing when implemented, do not remove this will be used later
      // setOpen(true);
    }
  };

  return (
    <>
      <div className="px-4 mt-[13px]">
        <div className="__back__section flex justify-between">
          <button
            onClick={() => navigate(-1)}
            className="inline-flex gap-2 text-white font-semibold text-xs md:text-sm leading-normal bg-white/[0.10] hover:bg-white/[0.90] hover:text-dark rounded-3xl py-1.5 px-1.5 pr-3 items-center  top-3 left-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M12.5 15L7.5 10L12.5 5"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>Back</span>
          </button>

          {/* <div
            onMouseEnter={HandletooltipHover}
            onMouseLeave={HandletooltipHover}
            data-testid="report-button"
            className="flex justify-center items-center"
          >
            <div
              onClick={() => {
                setReportModal(!isreportModal);
              }}
              className={`__report__tooltip__section transition-all delay-100 ${
                IstooltipHover ? "" : "hidden"
              } hover:cursor-pointer border border-[#FBBC5E] bg-gradient-to-r from-[#FBBC5E33] to-[#F3A15133] px-[12px] py-[2px] rounded-[8px] text-[12px] font-medium font-body mr-[10px] max-[768px]:mr-[10px]`}
            >
              Report this asset
            </div>
            <Flag
            onClick={() => {
              setReportModal(!isreportModal);
            }}
          />
          </div> */}
        </div>
      </div>

      <div className="relative px-4 mb-[140px] max-[768px]:mb-[50px] flex flex-col lg:flex-row py-[16px] max-[1280px]:py-[25px] gap-4">
        <div className="w-[100%] lg:w-[59%]  max-[1024px]:w-auto max-[1024px]:p-0 max-[1280px]:p-0 ">
          <div className=" mb-6 md:mb-[19px] z-10 relative rounded flex justify-center items-center">
            <Zoom>
              <img
                src={GamingDetailBanner}
                alt="Network Problem"
                className="h-full w-full object-cover aspect-[826/380]"
              />
            </Zoom>
            <Zoom delay={500}></Zoom>
          </div>
          <div className="relative">
            <div className="volumetric-card-left-shadow "></div>
            <div className="lg:flex jusitfy-between mb-[21px] relative z-10 gap-10 md:gap-8 lg:gap-14 pt-[36px] max-[768px]:pt-[16px]">
              <div className="__music__detail__description__main">
                <div className="__header__main">
                  <span className="font-body font-medium text-[#FFFFFF66] text-[14px]">
                    Description:
                  </span>
                </div>
                <div className="__description__main pr-[40px] max-[768px]:p-0">
                  <p className="text-[15px] text-[#FFFFFFE5] pt-[10px] font-body font-light max-[768px]:text-[13px]">
                    In the distant reaches of the cosmos, a fierce conflict
                    unfolds. Engage in epic battles across galaxies, commanding
                    powerful starships and leading your army to victory. Cosmic
                    Warfare: Galactic Battles is a thrilling space-themed war
                    game where strategic maneuvers and tactical decisions will
                    determine the fate of the universe. Immerse yourself in
                    stunning visuals, engage in intense dogfights, and unleash
                    devastating weapons upon your enemies. Read more
                  </p>
                </div>

                <div className="__header__main pt-[30px]">
                  <h4 className="font-heading font-medium text-[#FFFFFF] text-[24px]">
                    Game Features
                  </h4>
                  <ul className="py-[25px] list-disc [&>li]:text-[15px] [&>li]:text-[#FFFFFFE5] pl-[20px]">
                    <li>
                      Engage in intense space battles against players from
                      around the world
                    </li>
                    <li>
                      Upgrade and customize your spaceship with advanced weapons
                      and equipment
                    </li>
                    <li>
                      Explore stunning and immersive interstellar environments
                    </li>
                    <li>
                      Team up with friends and form alliances to dominate the
                      cosmic battleground
                    </li>
                    <li>
                      Experience fast-paced dogfights and strategic gameplay
                    </li>
                    <li>Unleash powerful futuristic weapons and abilities</li>
                    <li>
                      Compete in epic multiplayer tournaments and climb the
                      ranks
                    </li>
                    <li>
                      Immerse yourself in a visually stunning and dynamic
                      universe
                    </li>
                    <li>
                      Become a legendary space pilot and leave your mark in the
                      cosmic warfare
                    </li>
                  </ul>
                </div>
                {/* <div className="__description__main pr-[40px] max-[768px]:p-0">
                  <h4 className="text-[20px] font-semibold font-body pt-[9px]">
                    witness celestial wonders
                  </h4>
                  <p className="text-[15px] text-[#FFFFFFCC] pt-[10px] font-body font-light max-[768px]:text-[13px]">
                    Celestial melodies by artist will take you on an
                    interstellar journey. Experience her enchanting performance
                    that transcends galaxies. Get ready to be captivated by her
                    celestial talent.
                  </p>
                </div>

                <div className="__header__main pt-[30px]">
                  <h4 className="text-[20px] font-semibold font-body pt-[9px]">
                    Galactic Soundscapes
                  </h4>
                </div>
                <div className="__description__main pr-[40px] max-[768px]:p-0">
                  <p className="text-[15px] text-[#FFFFFFCC] pt-[10px] font-body font-light max-[768px]:text-[13px]">
                    Journey through the cosmos with electrifying performances by
                    interstellar musicians. Witness the fusion of music and
                    technology in an otherworldly setting, where soundwaves
                    transcend the boundaries of space.
                  </p>
                </div>

                <div className="__header__main pt-[30px]">
                  <h4 className="text-[20px] font-semibold font-body pt-[9px]">
                    Dynamic Perspectives
                  </h4>
                </div>
                <div className="__description__main pr-[40px] max-[768px]:p-0">
                  <p className="text-[15px] text-[#FFFFFFCC] pt-[10px] font-body font-light max-[768px]:text-[13px]">
                    Experience a new level of storytelling with dynamic
                    perspectives. As the viewer, you have control over where to
                    look, allowing you to focus on the details that interest you
                    the most.
                  </p>
                </div> */}
              </div>
            </div>
            <div className="volumetric-card-right-shadow"></div>
          </div>
        </div>
        <div className=" w-[100%] lg:w-[41%] max-[1024px]:w-auto max-[1024px]:h-auto max-[1024px]:mt-[18px]">
          <div className="__entertainment_assets_heading flex flex-col mb-[16px] lg:mb-[20px] sm:flex justify-between px-[30px] max-[768px]:p-0">
            <div className="__heading__profile__image border w-max rounded-[16px]">
              <img
                src={GamingProfileImg}
                className="w-[190px] h-[210px] rounded-[16px]"
                alt="gaming__profile__img"
              />
            </div>
            <div className="__heading leading-[40px] pt-[17px] pr-[30px]">
              <h4 className="text-[24px] font-heading text-[#ffffff]">
                {/* {contentName} */}
                Capital Letter by Hailee Steinfield
              </h4>
            </div>
            {/* <div className="__creator__main pt-[28px]">
              <h6 className="text-[14px] text-[#FFFFFF66] font-body font-semibold">
                Creator:
              </h6>
              <Link to={`/marketplace/entertainment/channels/${channelId}`}>
                <div className="__creator__profiler__main pt-[14px] flex gap-[6px]">
                  <img
                    src={channelThumbnail || ChannelImage}
                    className="w-[52px] h-[52px] rounded-[50%]"
                    alt="__creator_profile__img"
                  />
                  <div>
                    <h5 className="text-[16px] font-body font-semibold">
                      {channelName}
                    </h5>
                    <span className="text-[14px] font-body font-medium text-[#FFFFFF99]">
                      @{channelHandle}
                    </span>
                  </div>
                </div>
              </Link>
            </div> */}
            <div className="__category__main pt-[28px]">
              <h6 className="text-[14px] text-[#FFFFFF66] font-body font-semibold">
                Category:
              </h6>
              <span className="font-body font-medium text-[#fff]">
                Holosuite game
              </span>
            </div>
            <div className="__tags__main pt-[28px]">
              <h6 className="text-[14px] text-[#FFFFFF66] font-body font-semibold">
                Tags:
              </h6>
              <span className="text-[16px] font-body font-medium">
                {/* {tags.join(",")} */}
                Concert, Event, Katty Perry, Volumetric concert, Live
                Performance
              </span>
            </div>

            <div className="__game__mode__main pt-[28px]">
              <h6 className="text-[14px] text-[#FFFFFF66] font-body font-semibold">
                Game Mode:
              </h6>
              <span className="text-[16px] font-body font-light">
                Multiplayer
              </span>
            </div>

            <div className="__buy__main pt-[28px] pr-[40px] max-[768px]:pr-0">
              <h6 className="text-[14px] text-[#FFFFFF66] font-body font-semibold">
                Buy:
              </h6>

              <div className="flex-1 w-full lg:mt-0 min-h-180">
                <BuyCards
                  // type={contentType}
                  type="BuyNow"
                  // title="This content is locked. To access this premium content, Buy <b>Fourth Star</b> Premium Subscription."
                  card="content"
                  price="$99.00"
                  handleData={(e) => {
                    handleClick(e);
                  }}
                />
                <ComingSoon
                  status={showComingSoonPopup}
                  text={`In-app purchases are on the way, and you'll soon have the opportunity to own exciting assets. Stay tuned!`}
                  handleData={() => {
                    setShowComingSoonPopup(false);
                  }}
                />
                {/* {contentType == "Premium" && <> <UnlockPremium status={open} text={`Unlock the access exclusive content and early releases curated just for you. Subscribe for <b>${price}/Month ( $288.00/year )</b>.`} heading="Unlock the Premium" handleData={() => setOpen(false)}
                handleSub={() => {setContentType("MyAsset"); setBuyCardTitle("You unlocked this content by subscribing to <b style='color:white'>Fourth Star</b> channel")}}
              />
              </>} */}

                {/* {contentType == "BuyNow" && <Popup status={open} text="Buy this content and get lifetime access to it." heading="Buy Content" button={`Buy now @$${price}`} handleData={() => setOpen(false)} handleSub={() => {setContentType("MyAsset"); setBuyCardTitle(`Purchased access on <b style="color:white">${dateFormat(new Date(), "d mmmm yyyy")}</b>`)}} />} */}
              </div>

              {/* <div className="__button__groups__main">
                <p className="pt-[28px] font-medium font-body text-[16px]">This content is locked. To access this premium content, Buy Fourth Star Premium Subscription.</p>
                <button
                  type="button"
                  onClick={() => {setOpen(true)}}
                  className="flex items-center w-full text-[16px] sm:text-[16px] justify-center font-bold rounded-lg btn-gradient leading-none text-black py-3 mt-6"
                >
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="24"
                     height="25"
                     viewBox="0 0 24 25"
                     fill="none"
                     className="mr-[10px]"
                   >
                     <path
                       d="M12 17.5C12.5304 17.5 13.0391 17.2893 13.4142 16.9142C13.7893 16.5391 14 16.0304 14 15.5C14 14.9696 13.7893 14.4609 13.4142 14.0858C13.0391 13.7107 12.5304 13.5 12 13.5C11.4696 13.5 10.9609 13.7107 10.5858 14.0858C10.2107 14.4609 10 14.9696 10 15.5C10 16.0304 10.2107 16.5391 10.5858 16.9142C10.9609 17.2893 11.4696 17.5 12 17.5ZM18 8.5C18.5304 8.5 19.0391 8.71071 19.4142 9.08579C19.7893 9.46086 20 9.96957 20 10.5V20.5C20 21.0304 19.7893 21.5391 19.4142 21.9142C19.0391 22.2893 18.5304 22.5 18 22.5H6C5.46957 22.5 4.96086 22.2893 4.58579 21.9142C4.21071 21.5391 4 21.0304 4 20.5V10.5C4 9.96957 4.21071 9.46086 4.58579 9.08579C4.96086 8.71071 5.46957 8.5 6 8.5H7V6.5C7 5.17392 7.52678 3.90215 8.46447 2.96447C9.40215 2.02678 10.6739 1.5 12 1.5C12.6566 1.5 13.3068 1.62933 13.9134 1.8806C14.52 2.13188 15.0712 2.50017 15.5355 2.96447C15.9998 3.42876 16.3681 3.97995 16.6194 4.58658C16.8707 5.19321 17 5.84339 17 6.5V8.5H18ZM12 3.5C11.2044 3.5 10.4413 3.81607 9.87868 4.37868C9.31607 4.94129 9 5.70435 9 6.5V8.5H15V6.5C15 5.70435 14.6839 4.94129 14.1213 4.37868C13.5587 3.81607 12.7956 3.5 12 3.5Z"
                       fill="black"
                     />
                   </svg>
                  Buy @$99.00
                </button>
                <button
                  type="button"
                  onClick={() => {}}
                  className="flex items-center w-full text-[16px] sm:text-[16px] justify-center font-bold rounded-lg border border-[#FBBC5E] leading-none text-[#fff] py-3 mt-6"
                >
                  <img
                    src={CartIcon}
                    alt="cart_icon"
                    className="w-[24px] h-[24px] mr-[10px]"
                  />
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="24"
                     height="25"
                     viewBox="0 0 24 25"
                     fill="none"
                     className="mr-[10px]"
                   >
                     <path
                       d="M12 17.5C12.5304 17.5 13.0391 17.2893 13.4142 16.9142C13.7893 16.5391 14 16.0304 14 15.5C14 14.9696 13.7893 14.4609 13.4142 14.0858C13.0391 13.7107 12.5304 13.5 12 13.5C11.4696 13.5 10.9609 13.7107 10.5858 14.0858C10.2107 14.4609 10 14.9696 10 15.5C10 16.0304 10.2107 16.5391 10.5858 16.9142C10.9609 17.2893 11.4696 17.5 12 17.5ZM18 8.5C18.5304 8.5 19.0391 8.71071 19.4142 9.08579C19.7893 9.46086 20 9.96957 20 10.5V20.5C20 21.0304 19.7893 21.5391 19.4142 21.9142C19.0391 22.2893 18.5304 22.5 18 22.5H6C5.46957 22.5 4.96086 22.2893 4.58579 21.9142C4.21071 21.5391 4 21.0304 4 20.5V10.5C4 9.96957 4.21071 9.46086 4.58579 9.08579C4.96086 8.71071 5.46957 8.5 6 8.5H7V6.5C7 5.17392 7.52678 3.90215 8.46447 2.96447C9.40215 2.02678 10.6739 1.5 12 1.5C12.6566 1.5 13.3068 1.62933 13.9134 1.8806C14.52 2.13188 15.0712 2.50017 15.5355 2.96447C15.9998 3.42876 16.3681 3.97995 16.6194 4.58658C16.8707 5.19321 17 5.84339 17 6.5V8.5H18ZM12 3.5C11.2044 3.5 10.4413 3.81607 9.87868 4.37868C9.31607 4.94129 9 5.70435 9 6.5V8.5H15V6.5C15 5.70435 14.6839 4.94129 14.1213 4.37868C13.5587 3.81607 12.7956 3.5 12 3.5Z"
                       fill="black"
                     />
                   </svg>
                  Add to Cart
                </button>
              </div> */}
            </div>
          </div>
          {/* <div className="grid grid-cols-1  gap-4 ">Content</div> */}
        </div>
      </div>

      <div className="__sneaky__peek__container px-4 ">
        <div className="__heading">
          <SliderHeader title="Sneaky Peek" />
        </div>
        <div className="__content__slider pt-[24px]">
          <SneakyPeak slider={[]} SlideToShow={3} type="trending"></SneakyPeak>
        </div>
      </div>

      {/* Divider */}

      <hr className="border-[0.1px] border-[#5A5A5A80] my-[100px]" />

      <div className="__suggested__video__container mb-[90px] px-4">
        <div className="__heading">
          <SliderHeader title="Suggested Gaming" />
        </div>
        <div className="__content__slider pt-[24px]">
          <SneakyPeak
            slider={mostTrending}
            SlideToShow={3}
            type="trending"
          ></SneakyPeak>
        </div>
      </div>
      {isreportModal ? (
        <AssetsReportModal handleModalStatus={handleCloseReportModal} />
      ) : (
        <></>
      )}
    </>
  );
};

export default GameCategoryDetail;
