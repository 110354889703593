/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module user
 *@developer Shubham K <shubhamkinstaacoders@gmail.com>
 */

import React from "react";
import PopupWrapper from "../PopupWrapper";
import SuccessIcon from "assets/images/success_icon.png";

const Cancelled = ({ open, onClose, canClose }) => {
  return (
    <PopupWrapper open={open} onClose={onClose} canClose={canClose}>
      <div className="relative bg-[#2c2c2c] rounded-[8px] py-4 px-4">
        <img
          src={SuccessIcon}
          className="w-[52px] h-[52px] mx-auto rounded-full object-cover"
          alt=""
        />
        <h1 className="mx-auto mt-8 font-body font-bold text-[24px] leading-[36px] text-center text-white">
          Subscription Cancellation Successful
        </h1>
        <p className="mt-8 font-body font-normal text-[16px] leading-[24px] text-center">
          Your subscription has been successfully cancelled. You will continue
          to have access to the service until the end of the current billing
          period. Thank you for being a part of our community.
        </p>
        <div className="mt-8 w-full md:w-3/4 lg:w-1/2 mx-auto flex justify-center items-center">
          <button
            to="/my-assets/subscriptions"
            className="w-full btn-gradient rounded-[8px] font-Inter font-semibold text-[16px] leading-[19.36px] text-center py-3 px-2 text-black"
            onClick={onClose}
          >
            Go back to Subscriptions
          </button>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default Cancelled;
