import React from 'react'

const AddToCart = ({type, handleData}) => {
    const handleClick = () => {
        handleData('BuyNow');
    }
  return <button type="button" className="font-bold rounded-lg  bg-[#FBBC5E0D]  bg-opacity-5 justify-center w-full py-4 px-5 flex gap-2 mt-5 items-center  text-[#25B39E]">
  <svg className='stroke-[#25B39E]' xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path d="M21.5 6.99984L9.5 18.9998L4 13.4998L5.41 12.0898L9.5 16.1698L20.09 5.58984L21.5 6.99984Z" fill="currentColor" />
  </svg>
  This content is already added to your Cart.</button>
}

export default AddToCart