import { useSelector } from "react-redux";
import { Provider } from "react-redux";
import { LocalStore } from "store/LocalStore";
import { Navigate, useLocation } from "react-router-dom";


/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Protected-Route-Wrapper
 *@developer Aditya P
 */

const ProtectedRoute = ({ children }) => {
  const { userInfo, userToken } = useSelector((state) => state.auth);
  const location = useLocation();
  
  if(!userToken && !userInfo){
    return <Navigate to="/" state={{from: location}} replace />
  }

  return <Provider store={LocalStore}>
    {children}
  </Provider>
};

export default ProtectedRoute;
