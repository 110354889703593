import React, { useState } from "react";
import AssetCardOpen from "components/card/assets/AssetCardOpen";
import { ClothingCardClass } from "constant";
import Explore from "components/Explore/Explore";
import Loader from "components/Loader/Loader";
import ComingSoon from "components/popup/comingsoon";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Assets-Clothing-Casual
 */

const Casual = ({
  type,
  belongTo,
  data,
  myAsset,
  pageNum,
  totalPages,
  isFetching,
  handlePageChange,
}) => {
  const [showComingSoonPopup,setShowComingSoonPopup] = useState(false)
  return (
    <>
      {data && data.length ? (
        <>
        {console.log("gsdg", data)}
          <div className="_assets_container grid grid-cols-4 md:grid-cols-4 max-[1200px]:md:grid-cols-3 max-[768px]:grid-cols-2 gap-x-5 gap-y-4 lg:gap-y-8 mb-14 md:mb-28 mx-auto relative z-10 mt-[30px]">
            {data?.map((_vitem, index) => {
              return (
                <AssetCardOpen
                  key={_vitem?.slug}
                  image={_vitem.img}
                  channelID={_vitem.iapType}
                  contentID={_vitem.slug}
                  itemId={_vitem._id}
                  baseRoute={`/marketplace/${_vitem.iapCategory}`}
                  name=""
                  title={_vitem.title}
                  cardClass={ClothingCardClass}
                  price={_vitem.price}
                  star={_vitem.star}
                  myAsset={myAsset}
                  isComing={_vitem.paymentType === "coming_soon"}
                  setShowComingSoonPopup={setShowComingSoonPopup}
                />
              );
            })}
          </div>
          <div className="flex justify-center">
            {!isFetching && totalPages && totalPages > pageNum ? (
              <button
                onClick={handlePageChange}
                className="inline-flex border cursor-pointer text-sm md:text-base leading-none rounded-2xl border-sec btn-outline hover:text-black hover:font-semibold px-6 py-3 md:py-4 md:px-8 mt-6 font-medium"
              >
                Load More
              </button>
            ) : null}
          </div>
        </>
      ) : (
        <div className="__explore_default_button flex justify-center items-center h-[25vh] max-[768px]:flex-none">
          {myAsset ? <Explore /> : <>No Data</>}
        </div>
      )}
      {isFetching ? (
        <div class="__explore_default_button flex justify-center items-center h-[70vh] max-[768px]:flex-none">
          <Loader />
        </div>
      ) : null}
      <ComingSoon
        status={showComingSoonPopup}
        text={`This cloth is on the way, and you'll soon have the opportunity to own this asset. Stay tuned!`}
        handleData={() => {
          setShowComingSoonPopup(false);
        }}
      />
    </>
  );
};

export default Casual;
