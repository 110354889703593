import React from "react";

import PopupWrapper from "../PopupWrapper";
import CheckIcon from "assets/images/check_icon.png";
import MetaMaskIcon from "assets/images/metamask-icon.png";

const WalletConnectSuccess = ({ open, onClose, canClose }) => {
  return (
    <PopupWrapper open={open} onClose={onClose} canClose={canClose}>
      <div className="bg-[#242424] w-full max-w-[636px] rounded-md p-8 font-body">
        <div className="mx-auto relative flex justify-between items-center w-[90%] lg:w-3/4 xl:w-1/2 2xl:w-1/3">
          <div className="w-[52px] h-[52px] rounded-full border border-[#898989] bg-[#1d1d1d] p-2 z-[5]">
            <img
              className="h-full w-full object-contain"
              src={MetaMaskIcon}
              alt=""
            />
          </div>
          <div className="w-[52px] h-[52px] rounded-full z-[5]">
            <img
              className="h-full w-full object-contain"
              src={CheckIcon}
              alt=""
            />
          </div>
          <div className="absolute top-1/2 left-0 w-full border border-dashed z-[4]"></div>
        </div>
        <p className="mt-12 font-bold text-[16px] leading-[24px] text-center">
          You have successfully connected your wallet!
        </p>
        <p className="mt-8 text-center font-normal text-[14px] leading-[21px]">
          Your MetaMask wallet is now connected. You can now seamlessly use your
          wallet for transactions on our platform.
        </p>
        <p
          className="mt-8 font-semibold font-heading text-[16px] leading-[19.36px] text-[#fbbc5e] text-center cursor-pointer hover:text-[#fbbc5e]/80"
          onClick={onClose}
        >
          Got it
        </p>
      </div>
    </PopupWrapper>
  );
};

export default WalletConnectSuccess;
