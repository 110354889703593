import React from "react";
import Explore from "components/Explore/Explore";

const EnvironGaming = () => {
  return (
    <div className="absolute h-[50vh] w-full ml-auto grid place-content-center">
      <div class="__explore_default_button flex justify-center items-center h-[50vh] max-[768px]:flex-none max-[1280px]:h-[100%]">
        <Explore />
      </div>
    </div>
  );
};

export default EnvironGaming;
