import React from "react";
import { Link } from "react-router-dom";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Companion-Landing-Slider-Header
 *@developer Aditya P
 */

const SliderHeader = ({ title, seeMoreUrl }) => {
  return (
    <div className="__slider_main_header mt-[60px] z-[10] flex justify-between items-center">
      <h4 className="text-[24px] font-heading max-[768px]:text-[15px] capitalize">
        {title}
      </h4>
      {seeMoreUrl && (
        <Link to={seeMoreUrl} className="text-[#fbbc5e] font-medium">
          See More
        </Link>
      )}
    </div>
  );
};

export default SliderHeader;
