import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, EndpointsSlug } from "constant/api/Endpoints";
// Custom base query with error handling
import { baseQueryWithReauth } from "constant/api/apiInstance";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module IAP-api-calls
 *@developer Shubham K
 */

export const IapSlice = createApi({
  reducerPath: "IAP",
  baseQuery: baseQueryWithReauth({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken") || "";
      headers.set("Content-type", "application/json; charset=UTF-8");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getListIapAssets: builder.query({
      query: ({ category = "all" }) => ({
        url: EndpointsSlug.LIST_IAP_ASSETS + "/" + category,
        method: "GET",
      }),
    }),
    postEquipDefaultAvatar: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.EQUIP_DEFAULT_CLOTHING_AVATAR,
        method: "POST",
        body: payload,
      }),
    }),
    postWearAllItems: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.WEAR_ALL_ITEMS,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetListIapAssetsQuery,
  usePostEquipDefaultAvatarMutation,
  usePostWearAllItemsMutation,
} = IapSlice;
