/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Avatar-wardrobe-component
 *@developer Shubham K
 */

import React, { useState, useContext } from "react";
import AvatarItem from "./AvatarItem";
import ItemPreviewIcon from "./ItemPreviewIcon";
import SuccessPopup from "components/popup/MyAvatar/CustomizeSuccess/Success";
import { WardrobeContext } from "./WardrobeContextProvider";
import {
  usePostEquipDefaultAvatarMutation,
  usePostWearAllItemsMutation,
} from "store/slicer/Iap";

import AvatarImg from "assets/images/avatar_full.png";
import avt_thumb_1 from "assets/images/avt_thumb_1.png";
import topwearImg from "assets/images/topwear_item.png";
import bottomwearImg from "assets/images/bottomwear_item.png";
import suitImg from "assets/images/suit_item.png";
import Loader from "components/Loader/Loader";

const dummyItemsData = [
  {
    type: "topwear",
    img: topwearImg,
  },
  {
    type: "bottomwear",
    img: bottomwearImg,
  },
  {
    type: "suits",
    img: suitImg,
  },
];

const PreviewTab = ({ type, onClickNext, onReplace }) => {
  const { selectedItems, isFetching, isError, setRefetchData } =
    useContext(WardrobeContext);

  const selectedAvatar = selectedItems.find(
    (item) => item.iapCategory == "avatar"
  );

  let otherSelectedItems = selectedItems.slice();
  otherSelectedItems = selectedItems.filter(
    (item) => item.iapCategory != "avatar"
  );

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const [postWearAllTrigger, postWearAllInfo] = usePostWearAllItemsMutation();
  const [postEquipDefaultAvatarTrigger, postEquipDefaultAvatarInfo] =
    usePostEquipDefaultAvatarMutation();

  const {
    isLoading: isSaving,
    isSuccess: isSaveSuccess,
    isError: isSaveError,
  } = postWearAllInfo;
  const {
    isLoading: isReseting,
    isSuccess: isResetSuccess,
    isError: isResetError,
  } = postEquipDefaultAvatarInfo;

  const onClickSave = async () => {
    let payload = {};

    selectedItems.forEach((item) => {
      if (item.iapCategory == "avatar") {
        payload["avatarId"] = item.iapId;
      } else {
        let key;
        switch (item.iapType) {
          case "topwear":
            key = "clothingIdTop";
            break;
          case "bottomwear":
            key = "clothingIdBottom";
            break;
          case "headwear":
            key = "clothingIdHead";
            break;
          case "footwear":
            key = "clothingIdFoot";
            break;
          case "suit":
            key = "clothingIdSuit";
            break;
          case "suits":
            key = "clothingIdSuit";
            break;
          case "accessory":
            key = "clothingIdAccessory";
            break;
        }
        payload[key] = item.iapId;
      }
    });

    let res = await postWearAllTrigger(payload);

    if (res?.data?.response?.statusEnum == 1) {
      setShowSuccessPopup(true);
      setRefetchData((prev) => !prev);
    }
  };

  const onClickReset = async () => {
    const res = await postEquipDefaultAvatarTrigger();
    if (res?.data?.response?.statusEnum == 1) {
      setRefetchData((prev) => !prev);
    }
  };

  const onPopupClose = () => {
    setShowSuccessPopup(false);
  };

  if (isFetching) {
    return (
      <div className="h-full w-full border border-[#656565] rounded-lg grid place-content-center">
        <Loader />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="h-full w-full border border-[#656565] rounded-lg grid place-content-center">
        <p className="text-white/50 font-bold">Something went wrong</p>
      </div>
    );
  }

  return (
    <>
      {showSuccessPopup && (
        <SuccessPopup
          open={showSuccessPopup}
          onClose={onPopupClose}
          canClose={false}
        />
      )}
      <div className="h-full w-full border border-[#656565] rounded-lg overflow-y-scroll">
        {type == "avatar" && (
          <div className="p-[27px] flex flex-col justify-start items-center gap-[40px]">
            <p className="text-[16px] leading-[24px] font-bold text-center">
              Your Avatar Selection
            </p>
            <p className="text-[14px] leading-[21px] font-medium text-center text-[#fbbc5e]">
              Clothing items will be suggested based on the type of avatar you
              selected.
            </p>
            <div className="h-[346px] w-[302px] bg-cover bg-no-repeat bg-center">
              <img
                className="h-full w-full object-cover"
                src={selectedAvatar?.largeThumbnail || AvatarImg}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = AvatarImg;
                }}
                alt="avt-image"
              />
            </div>
            <button
              className="btn-gradient py-[8px] px-[24px] lg:py-[12px] lg:px-[48px] rounded-[8px] text-black text-[14px] lg:text-[16px] font-bold"
              onClick={onClickNext}
            >
              Next
            </button>
          </div>
        )}
        {type != "avatar" && (
          <div className="h-full">
            <div className="__ITEMS_CONTAINER__ p-[27px] h-[90%] overflow-y-scroll flex flex-col justify-start gap-[40px]">
              <div className="__AVATAR_CONTAINER__">
                <p className="text-[14px] leading-[21px] font-bold">
                  Your Avatar Selection
                </p>
                <div className="mt-2" onClick={() => onReplace("avatar")}>
                  <AvatarItem
                    img={selectedAvatar?.thumbnail}
                    title={selectedAvatar?.iapName}
                    isPreview={true}
                  />
                </div>
              </div>
              <div className="__OTHER_ITEMS_CONTAINER__">
                <p className="text-[14px] leading-[21px] font-bold">
                  Your Wardrobe Selection
                </p>
                <div className="__OTHER_ITEMS__ mt-4 flex justify-start items-center flex-wrap gap-4">
                  {otherSelectedItems.map((item, ind) => {
                    return (
                      <ItemPreviewIcon
                        key={ind}
                        img={item?.thumbnail}
                        type={item.iapType}
                        onClickReplace={(type) => {
                          onReplace(type);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="__BUTTONS_CONTAINER__ h-[10%] bg-black flex justify-end py-2 px-4 gap-4">
              {!isSaving && !isReseting && (
                <>
                  <button
                    className="text-[16px] font-bold py-[4px] px-[24px] lg:py-[8px] lg:px-[48px] rounded-[8px] border-[1px] hover:bg-[#fbbc5e] hover:text-black disabled:bg-[grey] disabled:hover:border-[grey]"
                    type="button"
                    disabled
                    // onClick={onClickReset}
                  >
                    Reset
                  </button>
                  <button
                    className="btn-gradient py-[4px] px-[24px] lg:py-[8px] lg:px-[48px] rounded-[8px] text-black text-[14px] lg:text-[16px] font-bold"
                    onClick={onClickSave}
                    disabled={isSaving}
                  >
                    Save Wardrobe
                  </button>
                </>
              )}

              {(isSaving || isReseting) && (
                <button
                  className="btn-gradient py-[4px] px-[24px] lg:py-[8px] lg:px-[48px] rounded-[8px] text-black text-[14px] lg:text-[16px] font-bold"
                  disabled
                >
                  <svg
                    className="animate-spin mx-auto h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PreviewTab;
