/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module constant-variables-object
 *@developer Aditya P
 */

/**
 * @desc Use to avoid hard coding string in internal codebase and make it easier to update
 */

export const AssetsParentTab = [
  { id: 0, key: "my-iaps", name: "My Assets" },
  { id: 1, key: "my-contents", name: "My Entertainment" },
  { id: 2, key: "my-events", name: "My Events" },
  { id: 3, key: "my-avatar", name: "My Avatar" },
  { id: 4, key: "my-orders", name: "My Orders" },
];

export const ChannelContentTab = [
  { id: 0, key: "all", name: "All" },
  { id: 1, key: "premium", name: "Premium Content" },
];

export const MyAssetsDummy = [
  {
    id: 0,
    key: "APART",
    name: "Apartments",
    data: [
      {
        id: 0,
        title: "Nebula Apartment",
        img: "",
        desc: "Experience the epitome of luxury in these magnificent mansions inspired by the celestial luminaries, offering a sanctuary of prestige and grandeur.",
      },
      {
        id: 1,
        title: "Nebula Apartment - 1",
        img: "",
        desc: "Experience the epitome of luxury in these magnificent mansions inspired by the celestial luminaries, offering a sanctuary of prestige and grandeur.",
      },
      {
        id: 2,
        title: "Nebula Apartment - 2",
        img: "",
        desc: "Experience the epitome of luxury in these magnificent mansions inspired by the celestial luminaries, offering a sanctuary of prestige and grandeur.",
      },
      {
        id: 3,
        title: "Nebula Apartment - 3",
        img: "",
        desc: "Experience the epitome of luxury in these magnificent mansions inspired by the celestial luminaries, offering a sanctuary of prestige and grandeur.",
      },
    ],
  },
  {
    id: 0,
    key: "SHIP",
    name: "Ships",
    data: [
      {
        id: 0,
        title: "StarHawk",
        img: "",
        desc: "Experience the epitome of luxury in these magnificent mansions inspired by the celestial luminaries, offering a sanctuary of prestige and grandeur.",
      },
      {
        id: 1,
        title: "StarHawk - 1",
        img: "",
        desc: "Experience the epitome of luxury in these magnificent mansions inspired by the celestial luminaries, offering a sanctuary of prestige and grandeur.",
      },
      {
        id: 2,
        title: "StarHawk - 2",
        img: "",
        desc: "Experience the epitome of luxury in these magnificent mansions inspired by the celestial luminaries, offering a sanctuary of prestige and grandeur.",
      },
      {
        id: 3,
        title: "StarHawk - 3",
        img: "",
        desc: "Experience the epitome of luxury in these magnificent mansions inspired by the celestial luminaries, offering a sanctuary of prestige and grandeur.",
      },
    ],
  },
  {
    id: 0,
    key: "ENTER",
    name: "Entertainment",
    data: [
      {
        id: 0,
        title: "StarHawk",
        img: "",
        desc: "Experience the epitome of luxury in these magnificent mansions inspired by the celestial luminaries, offering a sanctuary of prestige and grandeur.",
      },
      {
        id: 1,
        title: "StarHawk - 1",
        img: "",
        desc: "Experience the epitome of luxury in these magnificent mansions inspired by the celestial luminaries, offering a sanctuary of prestige and grandeur.",
      },
      {
        id: 2,
        title: "StarHawk - 2",
        img: "",
        desc: "Experience the epitome of luxury in these magnificent mansions inspired by the celestial luminaries, offering a sanctuary of prestige and grandeur.",
      },
      {
        id: 3,
        title: "StarHawk - 3",
        img: "",
        desc: "Experience the epitome of luxury in these magnificent mansions inspired by the celestial luminaries, offering a sanctuary of prestige and grandeur.",
      },
    ],
  },
];

// Assets Entertainment Tabs
export const EntainTabs = [
  { id: 0, key: "volumetric-video", name: "Volumetric Videos" },
  { id: 1, key: "360-video", name: "360 Video" },
  { id: 2, key: "180-video", name: "180 Video" },
  { id: 3, key: "2D-video", name: "2D Video" },
  { id: 4, key: "music", name: "Music" },
  { id: 5, key: "subs", name: "Subscription" },
];

// Assets Entertainment classes

export const EntertainmentCardClass = {
  cardMainclass:
    "w-full min-h-[256px] hover:cursor-pointer hover:rounded-[16px] hover:scale-[1.024] transition duration-300 ease-in-out max-[668px]:w-[100%] max-[768px]:p-0 max-[1366px]:p-0 ",
  cardMediaClass:
    "w-[100%] bg-[#2B2B2B] h-[256px] min-h-[180px] rounded-[16px] max-[1366px]:h-[200px] max-[1200px]:h-[180px] ",
  cardtitleClass: "py-[12px] font-light pl-[6px] max-[768px]:text-[13px]",
  cardButtonclass:
    "pl-[6px] font-bold bg-[#FBBC5E0D] px-[15px] py-[7px] rounded-[8px] text-center text-[15px] hover:shadow-[0_0_3px_1px_rgba(251,188,94,0.47)] hover:border-[#FBBC5E] max-[768px]:text-[13px]",
};

// Assets Clothing Tabs
export const ClothTabs = [
  { id: 0, key: "spacesuit", name: "Space Suits" },
  { id: 1, key: "exosuit", name: "Exo Suits" },
  { id: 2, key: "formal", name: "Formal" },
  { id: 2, key: "casual", name: "Casual" },
];

export const companionCategories = [
  { label: "Pet", value: "pet" },
  { label: "Master", value: "master" },
  { label: "Bodyguard", value: "bodyguard" },
  { label: "AI", value: "ai" },
  { label: "Sidekick", value: "sideKick" },
];

export const ClothingCardClass = {
  cardMainclass:
    "w-full min-h-[257px] pl-[10px] mb-[0px] hover:cursor-pointer hover:scale-[1.024] transition duration-300 ease-in-out max-[768px]:w-[100%] max-[768px]:p-0",
  cardMediaClass:
    "w-[100%] bg-[#2B2B2B] min-h-[257px] h-[257px] rounded-[10px]",
  cardtitleClass: "pt-[6px] font-light pl-[6px] max-[768px]:text-[13px]",
  cardButtonclass: "",
};

export const AvatarCardClass = {
  cardMainclass:
    "w-full min-h-[257px] pl-[10px] mb-[0px] hover:cursor-pointer hover:scale-[1.024] transition duration-300 ease-in-out max-[768px]:w-[100%] max-[768px]:p-0",
  cardMediaClass:
    "w-[100%] bg-[#2B2B2B] min-h-[257px] h-[257px] rounded-[10px]",
  cardtitleClass: "pt-[6px] font-light pl-[6px] max-[768px]:text-[13px]",
  cardButtonclass: "",
};
export const GamingCardClass = {
  cardMainclass:
    "w-full min-h-[257px] mb-[0px] hover:cursor-pointer hover:scale-[1.024] transition duration-300 ease-in-out max-[768px]:w-[100%] max-[768px]:p-0",
  cardMediaClass: "w-[100%] bg-[#2B2B2B] min-h-[257px] rounded-[10px]",
  cardtitleClass: "py-[12px] font-light pl-[6px] max-[768px]:text-[13px]",
  cardButtonclass: "",
};

export const FriendSearchList = [
  {
    userId: "USER-1",
    username: "xavier29",
    name: "Xavier Samuel",
    email: "xasa@gmail.com",
    status: "FULLFILL",
  },
  {
    userId: "USER-2",
    username: "xavier29",
    name: "Xavier Samuel",
    email: "xasa@gmail.com",
    status: "PENDING",
  },
  {
    userId: "USER-3",
    username: "xavier29",
    name: "Xavier Samuel",
    email: "xasa@gmail.com",
    status: null,
  },
  {
    userId: "USER-4",
    username: "xavier29",
    name: "Xavier Samuel",
    email: "xasa@gmail.com",
    status: null,
  },
  {
    userId: "USER-5",
    username: "xavier29",
    name: "Xavier Samuel",
    email: "xasa@gmail.com",
    status: null,
  },
  {
    userId: "USER-6",
    username: "xavier29",
    name: "Xavier Samuel",
    email: "xasa@gmail.com",
    status: null,
  },
  {
    userId: "USER-7",
    username: "xavier29",
    name: "Xavier Samuel",
    email: "xasa@gmail.com",
    status: null,
  },
  {
    userId: "USER-8",
    username: "xavier29",
    name: "Xavier Samuel",
    email: "xasa@gmail.com",
    status: null,
  },
  {
    userId: "USER-9",
    username: "xavier29",
    name: "Xavier Samuel",
    email: "xasa@gmail.com",
    status: null,
  },
];

export const Preferencelist = [
  {
    key: "musicNEntertainment",
    name: "Music & Entertainment",
  },
  {
    key: "fashionNStyle",
    name: "Fashion and Style",
  },
  {
    key: "socialVREvents",
    name: "Social VR Events",
  },
  {
    key: "spaceNScifi",
    name: "Space and Sci-Fi",
  },
  {
    key: "meditationNMindfulness",
    name: "Meditation and Mindfulness",
  },
  {
    key: "astronomyNSpace",
    name: "Astronomy and Space",
  },
  {
    key: "travelExplorer",
    name: "Travel Explorer",
  },
  {
    key: "artNDesign",
    name: "Art and Design",
  },
  {
    key: "gamingNSports",
    name: "Gaming and Esports",
  },
  {
    key: "digitalFashionNAvatars",
    name: "Digital Fashion and Avatars",
  },
  {
    key: "nft",
    name: "NFT",
  },
  {
    key: "digitalAssetTrading",
    name: "Digital Asset Trading",
  },
  {
    key: "techNGadgets",
    name: "Tech and Gadgets",
  },
  {
    key: "cryptocurrencyInvestments",
    name: "Cryptocurrency Investments",
  },
  {
    key: "holographicTechnology",
    name: "Holographic Technology",
  },
  {
    key: "blockchainGaming",
    name: "Blockchain Gaming",
  },
  {
    key: "virtualRealEstate",
    name: "Virtual Real Estate",
  },
  {
    key: "immersiveStorytelling",
    name: "Immersive Storytelling",
  },
];

export function timeAgo(timestamp) {
  const currentDate = new Date();
  const inputDate = new Date(timestamp * 1000); // Assuming the timestamp is in seconds

  const timeDifferenceInSeconds = Math.floor((currentDate - inputDate) / 1000);

  const minute = 60;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;

  if (timeDifferenceInSeconds < minute) {
    const secondsAgo = timeDifferenceInSeconds;
    return `${secondsAgo} ${secondsAgo === 1 ? "second" : "seconds"} ago`;
  } else if (timeDifferenceInSeconds < hour) {
    const minutesAgo = Math.floor(timeDifferenceInSeconds / minute);
    return `${minutesAgo} ${minutesAgo === 1 ? "minute" : "minutes"} ago`;
  } else if (timeDifferenceInSeconds < day) {
    const hoursAgo = Math.floor(timeDifferenceInSeconds / hour);
    return `${hoursAgo} ${hoursAgo === 1 ? "hour" : "hours"} ago`;
  } else if (timeDifferenceInSeconds < week) {
    const daysAgo = Math.floor(timeDifferenceInSeconds / day);
    return `${daysAgo} ${daysAgo === 1 ? "day" : "days"} ago`;
  } else {
    const weeksAgo = Math.floor(timeDifferenceInSeconds / week);
    return `${weeksAgo} ${weeksAgo === 1 ? "week" : "weeks"} ago`;
  }
}
export function formatTimestamp(timestamp) {
  const currentDate = new Date();
  const inputDate = new Date(timestamp * 1000); // Assuming the timestamp is in seconds

  const today = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  if (inputDate >= today && inputDate < tomorrow) {
    return "Today";
  } else if (
    inputDate >= tomorrow &&
    inputDate < today.getTime() + 2 * 24 * 60 * 60 * 1000
  ) {
    return "Tomorrow";
  } else {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return inputDate.toLocaleDateString("en-US", options);
  }
}

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

// If there is a rotation in image then we adjust the image rotation angle
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  filename,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement("canvas");

  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) {
    return null;
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // As Base64 string
  // return croppedCanvas.toDataURL('image/png');

  // As a blob
  const croppedBlob = await new Promise((resolve) => {
    croppedCanvas.toBlob((blob) => {
      resolve(blob);
    }, "image/jpeg");
  });

  const newFile = new File([croppedBlob], filename, {
    type: croppedBlob.type,
  });

  return newFile;
}
