/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Channel;
 *@developer sudhanshu
 */

import React, { useState, useEffect, Fragment, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import LargeCarousel from "components/carousel/MostTreading";
import ChannelImage from "assets/images/channel.png";
import nureal from "assets/images/nureal.png";
import bigBang from "assets/images/bigBang.png";
import { useGetAllChannelContentQuery } from "store/slicer/Entertainment";
import { BASE_URL } from "constant/api/Endpoints";
import { EndpointsSlug } from "constant/api/Endpoints";
import Loader from "components/Loader/Loader";
import ChannelsAllContent from "components/channels/ChannelsAllContent";
/*It defines a
component called `Index` that renders a list of volumetric cards. */
const Index = () => {
  const [searchText, setSearchText] = useState("");
  const [searchInputText, setSearchInputText] = useState("");
  const searchTimeout = useRef(null);
  const inputRef = useRef(null);

  const handleSearchInputChange = (e) => {
    setSearchInputText(inputRef.current.value);

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    const ts = setTimeout(() => {
      setSearchText(inputRef.current.value);
    }, 1000);

    searchTimeout.current = ts;
  };

  // let {
  //   data: channelListData = [],
  //   isFetching,
  //   isSuccess: IsDataAvailable,
  //   isError,
  //   error,
  // } = useGetAllChannelsListQuery({
  //   searchQuery: searchText,
  // });
  let {
    data: channelListData = [],
    isFetching,
    isSuccess: IsDataAvailable,
    isError,
    error,
  } = useGetAllChannelContentQuery({
    searchQuery: searchText,
  });

  if (IsDataAvailable && channelListData?.response?.statusEnum == 1) {
    channelListData = channelListData?.response?.data?.content;
  } else {
    channelListData = [];
  }

  const mostTrending = [
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Free",
      id: "1",
    },
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Paid",
      price: "US$ 9.99",
      id: "3",
    },
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Free",
      id: "2",
      tags: "",
    },
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Free",
      id: "4",
    },
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Free",
      id: "5",
    },
  ];
  return (
    <>
      <div className="__entertainment_assets_main  pt-4 max-[768px]:p-0">
        <div className="relative ml-auto w-full sm:max-w-[380px]">
          <svg
            className="absolute top-1/2 -translate-y-1/2 left-3"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.4351 10.0629H10.7124L10.4563 9.8159C11.3838 8.7401 11.8935 7.36667 11.8925 5.94626C11.8925 4.7702 11.5438 3.62055 10.8904 2.64269C10.237 1.66484 9.30832 0.902692 8.22179 0.452634C7.13525 0.00257642 5.93966 -0.115179 4.7862 0.114258C3.63274 0.343696 2.57322 0.910021 1.74162 1.74162C0.910021 2.57322 0.343696 3.63274 0.114258 4.7862C-0.115179 5.93966 0.00257642 7.13525 0.452634 8.22179C0.902692 9.30832 1.66484 10.237 2.64269 10.8904C3.62055 11.5438 4.7702 11.8925 5.94626 11.8925C7.4191 11.8925 8.77301 11.3528 9.8159 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94626 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94626C1.82962 3.66838 3.66838 1.82962 5.94626 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94626C10.0629 8.22413 8.22413 10.0629 5.94626 10.0629Z"
              fill="#CFCFCF"
            />
          </svg>
          <input
            className="w-full px-3 py-2 sm:py-3 pl-[34px] bg-transparent border border-[#5C5C5C] rounded-lg placeholder:text-[#cfcfcf] placeholder:text-[14px] placeholder:leading-[9.57px]"
            type="text"
            name="searchquery"
            id="searchquery"
            placeholder="Search"
            onChange={handleSearchInputChange}
            value={searchInputText}
            ref={inputRef}
          />
        </div>
        <div className="__entertainment_assets_heading mb-[29px] lg:mb-[39px] sm:flex justify-between">
          <h4 className="font-medium font-heading text-[18px] lg:text-[28px] tracking-[1px]  max-[768px]:p-0 max-[768px]:text-[15px] mb-4 sm:mb-0">
            Channel
          </h4>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 "></div>
        <div className="volumetric-card-right-shadow"></div>

        {channelListData.length > 0 &&
          IsDataAvailable &&
          !isFetching &&
          channelListData.map((channelData, index) => {
            return (
              <div key={channelData._id}>
                <ChannelsAllContent channelDetails={channelData} />
                {index < channelListData.length - 1 &&
                channelData?.content?.length > 0 ? (
                  <hr className="border-[0.1px] border-[#5A5A5A80] my-[35px] sm:mt-[40px] sm:mb-[56px]" />
                ) : null}
              </div>
            );
          })}

        {isFetching && (
          <div className="h-[70vh] grid place-items-center">
            <Loader />
          </div>
        )}
        {!isFetching &&
          channelListData.length == 0 &&
          searchText.length == 0 && (
            <div className="flex h-[70vh] flex-col gap-3 items-center justify-center">
              <p className="font-heading font-semibold clip-text">
                Coming Soon
              </p>
              <p className="text-[#7A7A7A] font-semibold">
                Try exploring other exciting contents in marketplace.
              </p>
              <Link
                to="/marketplace"
                className="border-[1.5px] border-sec text-sm mt-4 rounded-lg max-w-[250px] hover:bg-sec hover:text-dark font-bold text-center w-full py-3 px-4"
              >
                Browse other content
              </Link>
            </div>
          )}
        {channelListData.length == 0 &&
          !isFetching &&
          searchText.length != 0 && (
            <div className="h-[60vh] flex flex-col gap-3 items-center justify-center ">
              <p className="text-[#7A7A7A] font-semibold">No results found</p>
            </div>
          )}

        {/* {(channelListData.length == 0 || (!IsDataAvailable && !isFetching)) && <div className="h-[60vh] flex flex-col gap-3 items-center justify-center ">
               <p className="font-heading font-semibold clip-text">
                 Coming Soon
               </p>
               <p className="text-[#7A7A7A] font-semibold">
                 Try exploring other exciting contents in marketplace.
               </p>
               <Link
                 to="/marketplace"
                 className="border-[1.5px] border-sec text-sm mt-4 rounded-lg max-w-[250px] hover:bg-sec hover:text-dark font-bold text-center w-full py-3 px-4"
               >
                 Browse other content
               </Link>
             </div>} */}

        {/* <div className="">
          <div className="flex items-center mb-[17px]">
            <img
              className="w-[39px] h-[39px]  sm:w-[59px] sm:h-[59px] rounded-full mr-[20px] "
              src={ChannelImage}
              alt="load image"
            />
            <p className="text-sm sm:text-xl font-semibold mr-[10px] sm:mr-[20px]">
              Fourth Star Entertainment
            </p>
            <NavLink
              to="/marketplace/entertainment/channels/fourth-star-entertainment"
              className={`clip-text text-[12px] sm:text-[15px] font-semibold`}
            >
              See More
            </NavLink>
          </div>
          <div className="item-section  relative z-10">
            <LargeCarousel
              slider={mostTrending}
              SlideToShow={2}
              type="trending"
            ></LargeCarousel>
          </div>
        </div>

        <hr className="border-[0.1px] border-[#5A5A5A80] my-[35px] sm:mt-[40px] sm:mb-[56px]" />
        <div className="">
          <div className="flex items-center mb-[17px]">
            <img
              className="w-[39px] h-[39px]  sm:w-[59px] sm:h-[59px] rounded-full mr-[20px] "
              src={nureal}
              alt="load image"
            />
            <p className="text-sm sm:text-xl font-semibold mr-[10px] sm:mr-[20px]">
              Nureal Entertainment

            </p>
            <NavLink
              to="/marketplace/entertainment/channels/fourth-star-entertainment"
              className={`clip-text text-[12px] sm:text-[15px] font-semibold`}
            >
              See More
            </NavLink>
          </div>
          <div className="item-section  relative z-10">
            <LargeCarousel
              slider={mostTrending}
              SlideToShow={2}
              type="trending"
            ></LargeCarousel>
          </div>
        </div>

        <hr className="border-[0.1px] border-[#5A5A5A80] my-[35px] sm:mt-[40px] sm:mb-[56px]" />
        <div className="">
          <div className="flex items-center mb-[17px]">
            <img
              className="w-[39px] h-[39px]  sm:w-[59px] sm:h-[59px] rounded-full mr-[20px] "
              src={bigBang}
              alt="load image"
            />
            <p className="text-sm sm:text-xl font-semibold mr-[10px] sm:mr-[20px]">
              Big Bang Entertainment

            </p>
            <NavLink
              to="/marketplace/entertainment/channels/fourth-star-entertainment"
              className={`clip-text text-[12px] sm:text-[15px] font-semibold`}
            >
              See More
            </NavLink>
          </div>
          <div className="item-section  relative z-10">
            <LargeCarousel
              slider={mostTrending}
              SlideToShow={2}
              type="trending"
            ></LargeCarousel>
          </div>
        </div> */}
      </div>
      {/* <div className="">
             <div className="flex h-screen flex-col gap-3 items-center ">
               <p className="font-heading font-semibold clip-text">
                 Coming Soon
               </p>
               <p className="text-[#7A7A7A] font-semibold">
                 Try exploring other exciting contents in marketplace.
               </p>
               <a
                 to="/marketplace"
                 className="border-[1.5px] border-sec text-sm mt-4 rounded-lg max-w-[250px] hover:bg-sec hover:text-dark font-bold text-center w-full py-3 px-4"
               >
                 Browse other content
               </a>
             </div>
           </div> */}
    </>
  );
};

export default Index;
