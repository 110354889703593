/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module user
 *@developer sudhanshu  <sameer@shadowcast.io>
 */

import React, { useState } from "react";
import Explore from "components/Explore/Explore";
import { EntertainmentCardClass } from "constant";
import PremiumLockIcon from "assets/images/myassets/material-symbols_lock-outline.png";
// import AssetCardOpen from "components/card/assets/AssetCardOpen";
// import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import VolumetricCard from "components/card/marketplace/entertainment/VolumetricCard";
import UnlockPremium from "components/popup/unlockPremium";
import Loader from "components/Loader/Loader";
// change this to corrrect api for a specific channel when the api goes live
import { useGet360VideosQuery } from "store/slicer/Entertainment";
import { useParams, Link } from "react-router-dom";
import { generateVideoPageUrl } from "utils/generateUrls";
/*It defines a
 component called `Index` that renders a list of volumetric cards. */
const Index = ({ labelStatus, justAdded }) => {
  const [selectedFilter, setSelectedFilter] = useState(
    labelStatus ? labelStatus : "All"
  );
  const [premiumContent, setPremiumContent] = useState(false);
  const [premiumPrice, setPremiumPrice] = useState(40);
  const { channelId } = useParams();

  const handleFilterChange = (selectedValue) => {
    setSelectedFilter(selectedValue);
  };
  const [open, setOpen] = useState(false);

  let {
    data: entertainmentData = [],
    isFetching,
    isSuccess: IsDataAvailable,
    isError,
    error,
  } = useGet360VideosQuery({ channelId }); // change to correct api for specific channel api when it goes live

  if (IsDataAvailable) {
    entertainmentData = entertainmentData.response.data.content;
    entertainmentData = entertainmentData.map((item) => {
      const contentPref = item.contentPreference;
      const title = item.contentName;
      let subtitleButton = "Free";
      let tagType = "Free";

      if (contentPref == "subscription") {
        subtitleButton = `<img src=${PremiumLockIcon} class="w-[24px] h-[24px] mr-4 " alt='lockIcon'/> Unlock Premium`;
        tagType = "Premium";
      }

      if (contentPref == "premium" || contentPref == "paid") {
        subtitleButton = `US$ ${item.price}`;
        tagType = "Paid";
      }

      return {
        ...item,
        channel: item.channel.channelHandle || item.channel._id,
        title,
        subtitleButton,
        tagType,
        cardClass: EntertainmentCardClass,
      };
    });
  } else {
    entertainmentData = [];
  }

  const filterOptions = ["All", "Free", "Premium", "Buy"];

  // const entertainmentData = [
  //   {
  //     title: "StellarVerse Spectacle: Cosmic Symphony",
  //     subtitleButton: `<img src=${PremiumLockIcon} class="w-[24px] h-[24px] mr-4 " alt='lockIcon'/> Unlock Premium`,
  //     cardClass: EntertainmentCardClass,
  //     tagType: "Premium",
  //   },
  //   {
  //     title: "StellarVerse Spectacle: Cosmic Symphony",
  //     subtitleButton: "Free",
  //     cardClass: EntertainmentCardClass,
  //     tagType: "Free",
  //   },
  //   {
  //     title: "StellarVerse Spectacle: Cosmic Symphony",
  //     subtitleButton: "US$ 9.99",
  //     cardClass: EntertainmentCardClass,
  //     tagType: "Paid",
  //   },
  //   {
  //     title: "StellarVerse Spectacle: Cosmic Symphony",
  //     subtitleButton: `<img src=${PremiumLockIcon} class="w-[24px] h-[24px] mr-4 " alt='lockIcon'/> Unlock Premium`,
  //     cardClass: EntertainmentCardClass,
  //     tagType: "Premium",
  //   },
  //   {
  //     title: "StellarVerse Spectacle: Cosmic Symphony",
  //     subtitleButton: `<img src=${PremiumLockIcon} class="w-[24px] h-[24px] mr-4 " alt='lockIcon'/> Unlock Premium`,
  //     cardClass: EntertainmentCardClass,
  //     tagType: "Premium",
  //   },
  //   {
  //     title: "StellarVerse Spectacle: Cosmic Symphony",
  //     subtitleButton: `<img src=${PremiumLockIcon} class="w-[24px] h-[24px] mr-4 " alt='lockIcon'/> Unlock Premium`,
  //     cardClass: EntertainmentCardClass,
  //     tagType: "Premium",
  //   },
  //   {
  //     title: "StellarVerse Spectacle: Cosmic Symphony",
  //     subtitleButton: "Free",
  //     cardClass: EntertainmentCardClass,
  //     tagType: "Free",
  //   },
  //   {
  //     title: "StellarVerse Spectacle: Cosmic Symphony",
  //     subtitleButton: "US$ 9.99",
  //     cardClass: EntertainmentCardClass,
  //     tagType: "Paid",
  //   },
  //   {
  //     title: "StellarVerse Spectacle: Cosmic Symphony",
  //     subtitleButton: "Free",
  //     cardClass: EntertainmentCardClass,
  //     tagType: "Free",
  //   },
  // ];
  const premiumData = [
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      subtitleButton: `Unlocked`,
      cardClass: EntertainmentCardClass,
      tagType: "Unlocked",
    },
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      subtitleButton: `Unlocked`,
      cardClass: EntertainmentCardClass,
      tagType: "Unlocked",
    },
  ];

  // Filter the entertainmentData based on the selected filter
  const filteredData = entertainmentData.filter((item) => {
    if (
      selectedFilter === "premiumOnly" &&
      item.subtitleButton.includes("Unlock Premium")
    ) {
      return true;
    } else if (selectedFilter === "All") {
      return true;
    } else if (
      selectedFilter === "Free" &&
      item.subtitleButton.includes("Free")
    ) {
      return true;
    } else if (
      selectedFilter === "Premium" &&
      item.subtitleButton.includes("Unlock Premium")
    ) {
      return true;
    } else if (
      selectedFilter === "Buy" &&
      item.subtitleButton.includes("USD")
    ) {
      return true;
    }
    return false;
  });

  const mostTrending = [
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Free",
      id: "1",
    },
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Free",
      id: "2",
      tags: "Volcap",
    },
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Paid",
      price: "US$ 9.99",
      id: "3",
    },
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Free",
      id: "4",
    },
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Free",
      id: "5",
    },
  ];

  return (
    <>
      {IsDataAvailable && filteredData.length ? (
        <div className="__entertainment_assets_main  pt-4 max-[768px]:p-0">
          <div className="__entertainment_assets_heading mb-[29px] lg:mb-[39px] sm:flex justify-between">
            <h4 className="font-medium font-heading text-[18px] px-[0] lg:px-[20px] lg:text-[28px] tracking-[1px]  max-[768px]:p-0 max-[768px]:text-[15px] mb-4 sm:mb-0">
              360 Video
            </h4>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
            {filteredData.map((item, index) => (
              <VolumetricCard
                key={item._id}
                tags="360 Video"
                // slider ={mostTrending}
                type="trending"
                name=""
                title={item.title}
                subtitleButton={item.subtitleButton}
                tagType={item.tagType}
                // tags ="vol"
                thumbnailIcon={false}
                imageUrl={item.thumbnail || item.mediaFile}
                handleData={() => setOpen(true)}
                link={`/marketplace/entertainment/${generateVideoPageUrl(
                  "360-video",
                  item.channel,
                  item.contentSlug
                )}`}
                justAdded={justAdded}
              />
            ))}
          </div>
          <UnlockPremium
            status={open}
            text={`Unlock the access exclusive content and early releases curated just for you. Subscribe for <b>$${premiumPrice.toFixed(
              2
            )}/Month ( $${(premiumPrice * 12).toFixed(2)}/year )</b>.`}
            heading="Unlock the Premium"
            price={premiumPrice}
            handleData={() => setOpen(false)}
            handleSub={() => setPremiumContent(true)}
          />
        </div>
      ) : (
        <div class="__explore_default_button flex justify-center items-center h-[50vh] max-[768px]:flex-none max-[1280px]:h-[100%]">
          {isFetching && <Loader />}
          {filteredData.length == 0 && !isFetching && (
            <div className="">
              <div className="flex flex-col gap-3 items-center ">
                <p className="font-heading font-semibold clip-text">
                  Coming Soon
                </p>
                <p className="text-[#7A7A7A] font-semibold">
                  Try exploring other exciting contents in marketplace.
                </p>
                <Link
                  to="/marketplace"
                  className="border-[1.5px] border-sec text-sm mt-4 rounded-lg max-w-[250px] hover:bg-sec hover:text-dark font-bold text-center w-full py-3 px-4"
                >
                  Browse other content
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Index;
