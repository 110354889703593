import React, { useState } from "react";
import PopupWrapper from "../PopupWrapper";

const SelectWalletAccount = ({
  open,
  onClose,
  canClose,
  accounts,
  onSelectAccount,
}) => {
  const [selectedAccount, setSelectedAccount] = useState(null);

  return (
    <PopupWrapper open={open} onClose={onClose} canClose={canClose}>
      <div className="relative px-3 pt-4 pb-4  bg-[#2c2c2c] rounded-[8px] ">
        <h2 className="text-white text-[20px] leading-[25px] font-heading mb-6 pl-2">
          Select Your Account
        </h2>
        <div className="showScrollbar styled_scrollbar overflow-y-auto max-h-[500px] flex flex-col justify-start items-stretch gap-4 pb-[60px]">
          {accounts.map((item, index) => {
            console.log(item);
            return (
              <div
                key={index}
                onClick={() => {
                  localStorage.setItem("web3FromAddress", index);
                  setSelectedAccount(index);
                }}
                className={`px-2 py-5 border ${
                  selectedAccount == index
                    ? "border-[#fbbc5e] bg-[#fbb5ce]/5 [&>*]:break-all [&>*]:max-w-full [&>*]:overflow-visible [&>*]:whitespace-normal"
                    : "border-[#444444] hover:border-[#fbbc5e]/30 [&>*]:overflow-hidden [&>*]:whitespace-nowrap [&>*]:text-ellipsis"
                } rounded-[6px] cursor-pointer  hover:[&>*]:break-all hover:[&>*]:max-w-full hover:[&>*]:overflow-visible hover:[&>*]:whitespace-normal mx-2`}
              >
                <p className="font-body font-medium text-white/80 leading-[18px]">
                  {item}
                </p>
              </div>
            );
          })}
        </div>

        <div className="absolute bottom-0 left-0 w-full px-4">
          <button
            className="w-full btn-gradient px-3 py-3 text-black font-bold rounded-[6px]"
            disabled={selectedAccount == null}
            onClick={() => {
              onSelectAccount(selectedAccount);
              if (typeof onClose == "function" && canClose) {
                onClose();
              }
            }}
          >
            Confirm Account
          </button>
        </div>
      </div>
    </PopupWrapper>
  );
};

// overflow-hidden whitespace-nowrap text-ellipsis hover:break-all hover:max-w-full hover:overflow-visible hover:whitespace-normal

export default SelectWalletAccount;
