import React from "react";
import CoinIcon from "assets/images/coin-icon.png";
import CoinStarIcon from "assets/images/coin_star.png";
import { convertUsdToFstrPrice } from "utils";
import { convertUsdToMarketFstrUsd } from "utils";
import { getFstrDiscount, removeTrailingZeroes } from "utils";
import DummyCart from "assets/images/dummy-cart.png";
import { useLocation, useNavigate } from "react-router-dom";

const BuyNowNoTheme = ({
  title,
  price,
  type,
  handleData,
  onBuyHandle,
  isLocked,
}) => {
  const handleClick = () => {
    handleData("BuyNow");
  };
  const navigate = useNavigate();
  const location = useLocation();

  let sanitizedPrice = price?.replace(/[^0-9.]/g, "");

  // if more than one decimal is present
  if (sanitizedPrice.split(".").length > 2) {
    sanitizedPrice =
      sanitizedPrice.split(".")[0] + "." + sanitizedPrice.split(".")[1];
  }

  return (
    <>
      <p className="my-5 text-white/80 text-[14px] font-bold leading-[17.07px]">
        Purchase:
      </p>
      <div className="mt-3 mb-3 text-[20px] leading-[30px] font-bold">
        <img
          src={CoinStarIcon}
          className="inline h-[38px] w-[38px] object-cover rounded-full"
          alt="coin_icon"
        />
        <span className="text-[#FBBC5E] pl-2">{sanitizedPrice} USD | </span>
        <span className="text-[#FFDF6E]">
          {sanitizedPrice &&
            removeTrailingZeroes(convertUsdToFstrPrice(sanitizedPrice))}{" "}
          FSTR{" "}
          <span className="text-[16px] leading-[24px]">
            (≈{" "}
            {sanitizedPrice &&
              removeTrailingZeroes(
                convertUsdToMarketFstrUsd(sanitizedPrice)
              )}{" "}
            USD)
          </span>
        </span>
      </div>
      {/* {title && (
        <h6
          className="mt-4 text-[14px] text-[#FFFFFF66] font-body font-semibold"
          dangerouslySetInnerHTML={{ __html: title }}
        ></h6>
      )} */}
      {/* {price && type === "product" && (
        <div className="my-4 flex gap-2 items-center">
          <img alt="coin" src={CoinIcon} className="w-auto h-auto" />
          <span className="font-extrabold">{price}</span>
        </div>
      )} */}
      <div className="mt-7 mb-3 bg-[#473e30] p-4 rounded-[8px]">
        <p className="text-[#FFCE1F] font-bold">
          Purchase in FSTR to receive a DISCOUNT of {getFstrDiscount() || "NA"}%
        </p>
        <p className="mt-2">
          Select FSTR at checkout to make your purchase and unlock a special
          discount.{" "}
        </p>
      </div>
      {isLocked ? (
        <div className="relative">
          <img src={DummyCart} className="blur w-full" alt="" />
          <div
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-white font-bold w-full flex justify-center items-center"
            style={{
              zIndex: 10, // Ensures the text is on top of the image
            }}
          >
            <button
              type="button"
              className="flex font-bold text-sm md:text-base rounded-lg btn-gradient hover:text-white w-auto text-black py-3 px-5 mt-3 items-center"
              onClick={() =>
                navigate(`/signin?ref=${location.pathname}`, {
                  replace: true,
                })
              }
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className="mb-1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.7734 10.9092C11.3506 10.9092 11.0078 10.5664 11.0078 10.1436V5.6853C11.0078 4.18271 9.78534 2.9602 8.28271 2.9602C6.78012 2.9602 5.55762 4.18267 5.55762 5.6853V8.27833C5.55762 8.70117 5.21484 9.04395 4.79199 9.04395C4.36915 9.04395 4.02637 8.70117 4.02637 8.27833V5.6853C4.02637 3.33833 5.93574 1.42896 8.28271 1.42896C10.6297 1.42896 12.5391 3.33833 12.5391 5.6853V10.1436C12.5391 10.5664 12.1963 10.9092 11.7734 10.9092Z"
                  fill="currentColor"
                />
                <path
                  d="M12.9411 15.5455H3.62432C2.75447 15.5455 2.04932 14.8404 2.04932 13.9705V8.58403C2.04932 7.71419 2.75447 7.00903 3.62432 7.00903H12.9411C13.811 7.00903 14.5161 7.71419 14.5161 8.58403V13.9705C14.5161 14.8404 13.811 15.5455 12.9411 15.5455Z"
                  fill="currentColor"
                />
                <path
                  d="M3.41587 14.2762H3.33696C3.08843 14.2762 2.88696 14.0747 2.88696 13.8262V8.72832C2.88696 8.47979 3.08843 8.27832 3.33696 8.27832H3.41587C3.6644 8.27832 3.86587 8.47979 3.86587 8.72832V13.8262C3.86587 14.0747 3.6644 14.2762 3.41587 14.2762Z"
                  fill="currentColor"
                />
              </svg>
              &nbsp; Sign In To Continue
            </button>
          </div>
        </div>
      ) : (
        <>
          <button
            type="button"
            className="font-bold text-sm md:text-base rounded-lg btn-gradient hover:text-white w-full text-black py-3 px-5 mt-5"
            onClick={onBuyHandle}
          >
            {type === "content" ? "Proceed to Checkout" : "Buy Now"}
          </button>
          <button
            type="button"
            className="font-bold text-sm md:text-base rounded-lg border-[1.5px] border-sec justify-center w-full py-2.5 px-5 flex gap-2 mt-5 items-center btn-outline hover:text-dark"
            onClick={handleClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M22.322 7.931C22.23 7.79808 22.1072 7.68943 21.964 7.61436C21.8209 7.5393 21.6616 7.50006 21.5 7.5H7.833L6.679 4.73C6.52769 4.36523 6.27147 4.05359 5.94282 3.83462C5.61418 3.61564 5.22791 3.49918 4.833 3.5H2.5V5.5H4.833L9.577 16.885C9.65299 17.0672 9.78118 17.2228 9.94542 17.3322C10.1097 17.4416 10.3026 17.5 10.5 17.5H18.5C18.917 17.5 19.29 17.241 19.437 16.852L22.437 8.852C22.4937 8.70063 22.5129 8.53776 22.4928 8.37735C22.4728 8.21695 22.4142 8.06379 22.322 7.931Z"
                fill="currentColor"
              />
              <path
                d="M11 21.5C11.8284 21.5 12.5 20.8284 12.5 20C12.5 19.1716 11.8284 18.5 11 18.5C10.1716 18.5 9.5 19.1716 9.5 20C9.5 20.8284 10.1716 21.5 11 21.5Z"
                fill="currentColor"
              />
              <path
                d="M18 21.5C18.8284 21.5 19.5 20.8284 19.5 20C19.5 19.1716 18.8284 18.5 18 18.5C17.1716 18.5 16.5 19.1716 16.5 20C16.5 20.8284 17.1716 21.5 18 21.5Z"
                fill="currentColor"
              />
            </svg>
            Add to Cart
          </button>
        </>
      )}
    </>
  );
};

export default BuyNowNoTheme;
