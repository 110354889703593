/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module ChannelsAllContent;
 *@developer Shubham K
 */
import LargeCarousel from "components/carousel/MostTreading";
import ChannelImage from "assets/images/channel.png";
import { NavLink } from "react-router-dom";
import { useGetAllCategoryDataByChannelIdQuery } from "store/slicer/Creator";
import Loader from "components/Loader/Loader";

/* It defines a component called index which will show all channel content except music in the channels tab of entertainment page */

const Index = ({ channelDetails }) => {
  let channelContent = [];

  if (channelDetails) {
    console.log(channelDetails);
    channelContent = channelDetails?.content;

    channelContent = channelContent.map((item, index) => {
      const title = item.contentName;
      let productType = "Free";
      if (
        item.contentPreference == "paid" ||
        item.contentPreference == "premium"
      ) {
        productType = "Paid";
      }
      if (item.contentPreference == "subscription") {
        productType = "Premium";
      }

      return {
        ...item,
        title,
        productType,
        price: "$" + item.price,
        id: item._id,
        channel: channelDetails.channelHandle || channelDetails._id,
      };
    });
  }

  // exclude music videos from this tab
  channelContent = channelContent.filter(
    (item) => !item.category.includes("music")
  );

  if (channelContent?.length == 0) {
    return null;
  }

  return (
    <>
      <div className="">
        <div className="flex items-center mb-[17px]">
          <img
            className="w-[39px] h-[39px]  sm:w-[59px] sm:h-[59px] rounded-full mr-[20px] "
            src={channelDetails.channelThumbnail || ChannelImage}
            alt="load image"
          />
          <p className="text-sm sm:text-xl font-semibold mr-[10px] sm:mr-[20px]">
            {channelDetails.channelName}
          </p>
          <NavLink
            to={`/marketplace/entertainment/channels/${channelDetails.channelHandle}`}
            className={`clip-text text-[12px] sm:text-[15px] font-semibold`}
          >
            See More
          </NavLink>
        </div>
        <div className="item-section  relative z-10">
          <LargeCarousel
            slider={channelContent}
            SlideToShow={2}
            type="trending"
          ></LargeCarousel>
        </div>
      </div>
    </>
  );
};

export default Index;
