import Loader from "components/Loader/Loader";
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useGetAllUserNotificationQuery } from "store/slicer/notification";
import ShadowFrame from "components/shadow-frame";
import { timeAgo, formatTimestamp } from "constant";

const MyNotification = () => {
  const [isNotificationAvailable, setNotification] = useState(true);
  const { data, isLoading, error } = useGetAllUserNotificationQuery({ count: 1 },{ refetchOnMountOrArgChange: true });

  return (
    <div className="__my_notification__container__main mb-[100px] min-h-[calc(100vh-170px)] container mx-auto px-[100px] py-[60px] max-[768px]:px-[15px] max-[768px]:py-[40px] max-[1280px]:px-[15px] relative">
      <ShadowFrame
        className="z-[0] w-[350px] md:w-[250px] h-[350px] md:h-[400px] rounded-[250px] md:rounded-[400px] left-[-10%] max-[768px]:left-[-50%] top-[50%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]"
      />
      <ShadowFrame
        className="z-[0] w-[250px] md:w-[250px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[10%] max-[768px]:right-[50%] top-[60%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]"
      />
      <div className="__notification__header__main relative">
        <h4 className="font-heading text-[24px] max-[768px]:text-[18px]">
          Notification
        </h4>
        <h5 className="font-body text-[18px] font-medium pt-[40px] underline underline-offset-8 decoration-[#FBBC5E] max-[768px]:text-[15px] max-[768px]:pt-[25px]">
          All Notification
        </h5>
        {isNotificationAvailable ? (
          <></>
        ) : (
          <hr className="mt-[24px] border-[0.1px] border-[#5A5A5A80]" />
        )}
      </div>

      {isLoading ? (
        <div className="flex pt-[20px]">
          <Loader />
        </div>
      ) : (
        <div className="__notification__content__main h-[90%] relative">
          {data?.response?.statusEnum === 1 ? (
            data?.response?.data?.notifications.length === 0 ? (
              <div className="__notification__empty__container flex flex-col justify-center items-center h-[100%] max-[768px]:justify-start max-[768px]:pt-[30px] max-[1280px]:pt-[30px] max-[1280px]:justify-start">
                <span className="text-[#FFFFFF99] text-[16px] font-medium font-body max-[768px]:text-[14px]">
                  You don't have any Notification yet.
                </span>
              </div>
            ) : (
              <div className="__notification__content_main w-[100%]">
                <div className="pt-[25px]">
                  <h5 className="text-[#FFFFFF99]">Recent Notification</h5>
                </div>

                {
                    data?.response?.data?.notifications?.slice(0)?.reverse()?.map((_notifyItem) => {
                        return <div key={_notifyItem?._id} className="__notification__card__container mt-[25px] rounded-[8px] bg-gradient-to-r from-[#FBBC5E0D] to-[#F3A1510D] hover:scale-[1.014] transition duration-300 ease-in-out">
                  <div className="__notification__card__body px-[20px] py-[15px]">
                    <h4 className="font-heading max-[768px]:text-[14px]">
                      {_notifyItem?.title}
                    </h4>
                    <p className="font-body font-light text-[13px] max-[768px]:text-[12px] text-[#FFFFFF99] w-[80%] max-[768px]:w-[70%] truncate">
                      {_notifyItem?.message}
                    </p>
                    <div className="__notification__action__center__container flex justify-between items-baseline">
                      <span className="font-body font-medium text-[#FFFFFF99] text-[13px]">
                        {timeAgo(_notifyItem?.receivedAt)}
                      </span>
                      <NavLink to={`/me/notification/${_notifyItem?._id}`}>
                        <button className="__notify__btn max-[768px]:text-[12px] px-[16px] py-[4px] rounded-[6px] border border-[#7A7A7A] text-[#FBBC5E] transition-all hover:border-[#FBBC5E] hover:bg-gradient-to-r hover:from-[#FBBC5E] hover:to-[#F3A151] hover:text-[#000]">
                          Open
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
                    })
                }
                {/* <div className="__notification__card__container mt-[25px] rounded-[8px] bg-gradient-to-r from-[#FBBC5E0D] to-[#F3A1510D] hover:scale-[1.014] transition duration-300 ease-in-out">
                  <div className="__notification__card__body px-[20px] py-[15px]">
                    <h4 className="font-heading max-[768px]:text-[14px]">
                      Notification - 1
                    </h4>
                    <p className="font-body font-light text-[13px] max-[768px]:text-[12px] text-[#FFFFFF99]">
                      Merrianne has invited you to explore their apartment. Join
                      the party now!
                    </p>
                    <div className="__notification__action__center__container flex justify-between items-baseline">
                      <span className="font-body font-medium text-[#FFFFFF99] text-[13px]">
                        35 mins ago
                      </span>
                      <NavLink to={`/me/notification/notification1`}>
                        <button className="__notify__btn max-[768px]:text-[12px] px-[16px] py-[4px] rounded-[6px] border border-[#7A7A7A] text-[#FBBC5E] transition-all hover:border-[#FBBC5E] hover:bg-gradient-to-r hover:from-[#FBBC5E] hover:to-[#F3A151] hover:text-[#000]">
                          Open
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div> */}

                {/* <div className="__notification__card__container mt-[25px] rounded-[8px] bg-gradient-to-r from-[#FBBC5E0D] to-[#F3A1510D] hover:scale-[1.014] transition duration-300 ease-in-out">
                  <div className="__notification__card__body px-[20px] py-[15px]">
                    <h4 className="font-heading max-[768px]:text-[14px]">
                      Notification - 2
                    </h4>
                    <p className="font-body font-light text-[13px] max-[768px]:text-[12px] text-[#FFFFFF99]">
                      Merrianne has invited you to explore their apartment. Join
                      the party now!
                    </p>
                    <div className="__notification__action__center__container flex justify-between items-baseline">
                      <span className="font-body font-medium text-[#FFFFFF99] text-[13px] max-[768px]:text-[12px]">
                        35 mins ago
                      </span>
                      <NavLink to={`/me/notification/notification2`}>
                        <button className="__notify__btn max-[768px]:text-[12px] px-[16px] py-[4px] rounded-[6px] border border-[#7A7A7A] text-[#FBBC5E] transition-all hover:border-[#FBBC5E] hover:bg-gradient-to-r hover:from-[#FBBC5E] hover:to-[#F3A151] hover:text-[#000]">
                          Open
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div> */}
              </div>
            )
          ) : (
            <div className="__transaction__empty__container flex flex-col justify-center items-center h-[100%] max-[768px]:justify-start max-[768px]:pt-[30px] max-[1280px]:pt-[30px] max-[1280px]:justify-start">
              <span className="text-[#FFFFFF99] text-[16px] font-medium font-body max-[768px]:text-[14px]">
                There is some problem while fetching the notification
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MyNotification;
