import Swal from "sweetalert2";

const SuccessAlert = (title) => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn-primary",
      cancelButton: "btn-secondary",
    },
    buttonsStyling: false,
  });
  swalWithBootstrapButtons.fire({
    title: `${title}`,
    icon: "success",
  });
};

export default SuccessAlert;
