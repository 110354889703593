import React from "react";
import PopupWrapper from "../PopupWrapper";
import { useGetUserSubscriptionTransactionsQuery } from "store/slicer/MyAssets/Subscriptions";
import Loader from "components/Loader/Loader";
import dateFormat from "dateformat";

const TransactionsTable = ({ open, onClose, canClose, channelId }) => {
  let filteredData = [];

  let { data, isFetching, isSuccess, isError } =
    useGetUserSubscriptionTransactionsQuery({
      channelId,
      page: 1,
      limit: 9999,
    });

  if (!isFetching && data?.response?.statusEnum == 1) {
    filteredData = data?.response?.data?.transactions;
    filteredData = filteredData.map((item) => {
      return {
        transactionMethod:
          item?.currencyType?.toLowerCase() == "fiat"
            ? "USD- Payment gateway"
            : "FSTR- MetaMask wallet",
        amount:
          typeof item?.price == "object"
            ? item?.price?.$numberDecimal
            : item?.price,
        currency: item?.currencyType?.toLowerCase() == "fiat" ? "usd" : "fstr",
        transactionId: item?.paymentId,
        dateTime: dateFormat(new Date(item?.purchaseTime), "mm/dd/yyyy, hh:mm"),
        status: item?.purchaseStatus,
      };
    });
  }

  return (
    <PopupWrapper
      open={open}
      onClose={onClose}
      canClose={canClose}
      position="top"
    >
      <div className="relative bg-[#2c2c2c] rounded-[8px] py-4 px-2 z-[100]">
        <div
          className="absolute top-1 right-1 bg-white/10 w-[28px] h-[28px] rounded-full grid place-content-center cursor-pointer"
          onClick={onClose}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6875 1.3125L1.3125 12.6875M1.3125 1.3125L12.6875 12.6875"
              stroke="white"
              strokeOpacity="0.5"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <h1 className="font-semibold text-[20px] leading-[24.38px]">
          Transactions Details
        </h1>
        {isFetching && (
          <div className="h-[400px] grid place-content-center">
            <Loader />
          </div>
        )}
        {!isFetching && data?.response?.statusEnum != 1 && (
          <div className="h-[400px] grid place-content-center">
            <p className="text-[16px] leading-[21px] text-white/70">
              Something went wrong
            </p>
          </div>
        )}
        {!isFetching && data?.response?.statusEnum == 1 && (
          <>
            {" "}
            <div className="__HEADING_CONTAINER__ mt-6 flex justify-start items-center [&>*]:w-1/5 py-4 bg-[#484848] pl-4 pr-1">
              <p className="font-Inter text-[8px] md:text-[10px] leading-[12.1px] text-white/50">
                Transaction Method
              </p>
              <p className="font-Inter text-[8px] md:text-[10px] leading-[12.1px] text-white/50">
                Payment
              </p>
              <p className="font-Inter text-[8px] md:text-[10px] leading-[12.1px] text-white/50">
                Transaction ID
              </p>
              <p className="font-Inter text-[8px] md:text-[10px] leading-[12.1px] text-white/50">
                Date & Time
              </p>
              <p className="font-Inter text-[8px] md:text-[10px] leading-[12.1px] text-white/50">
                Status
              </p>
            </div>
            <div
              className="__MAIN_DATA_CONTAINER__ [&>:not(:last-child)]:border-b-[0.5px] [&>:not(:last-child)]:border-b-[#595959] showScrollbar styled_scrollbar overflow-y-auto max-h-[600px]"
              id="notification"
            >
              {filteredData.map((item, ind) => (
                <div className="flex justify-start items-center [&>*]:w-1/5 [&>*]:break-all py-6 pr-1 pl-4 bg-[#3A3A3A]">
                  <div className="__TRANSACTION__METHOD__CONT__ ">
                    <p className="font-body font-normal text-[10px] md:text-[12px] leading-[14.64px]">
                      {item.transactionMethod}
                    </p>
                  </div>
                  <div className="__PAYMENT__CONT__">
                    <p className="font-Inter font-medium text-[10px] md:text-[12px] leading-[14.64px]">
                      {item.amount}{" "}
                      {item.currency?.toLowerCase() == "usd" ? "USD" : "FSTR"}
                    </p>
                  </div>
                  <div className="__TRANSACTION__ID__CONT__">
                    <p className="font-Inter text-start whitespace-nowrap overflow-hidden text-ellipsis pr-2 hover:whitespace-normal hover:overflow-auto  font-medium text-[12px] md:text-[14px] leading-[14.64px]  break-all">
                      {item.transactionId}
                    </p>
                  </div>
                  <div className="__DATE__TIME__CONT__">
                    <p className="font-body font-normal text-[10px] md:text-[12px] leading-[14.64px]">
                      {item.dateTime}
                    </p>
                  </div>
                  <div className="__TRANSACTION__STATUS__CONT__">
                    <p
                      className={`font-Inter font-medium text-[12px] md:text-[14px] leading-[14.64px] capitalize ${
                        item.status == "success"
                          ? "text-[#25B39E]"
                          : "text-[#FF9393]"
                      }`}
                    >
                      {item.status}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </PopupWrapper>
  );
};

export default TransactionsTable;
