/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Avatar-wardrobe-component
 *@developer Shubham K
 */

import React, { useState } from "react";
import avatar_icon from "assets/images/avatar.png";
import topwear_icon from "assets/images/top.png";
import bottomwear_icon from "assets/images/bottom.png";
import suit_icon from "assets/images/suit.png";
import hat_icon from "assets/images/hat.png";
import shoe_icon from "assets/images/shoe.png";
import glasses_icon from "assets/images/glasses.png";
import SideNavIcon from "./components/SideNavIcon";
import ItemList from "./components/ItemList";
import PreviewTab from "./components/PreviewTab";

import WardrobeContextProvider from "./components/WardrobeContextProvider";

const tabs_list = [
  {
    title: "avatar",
    img: avatar_icon,
  },
  {
    title: "topwear",
    img: topwear_icon,
  },
  {
    title: "bottomwear",
    img: bottomwear_icon,
  },
  {
    title: "suits",
    img: suit_icon,
  },
  {
    title: "headwear",
    img: hat_icon,
  },
  {
    title: "footwear",
    img: shoe_icon,
  },
  {
    title: "accessories",
    img: glasses_icon,
  },
];

const Wardrobe_New = () => {
  const [selectedTab, setSelectedTab] = useState("avatar");

  return (
    <WardrobeContextProvider>
      <div className="mt-[20px] flex gap-4 justify-start flex-col lg:flex-row">
        <div className="__NAV_ICONS_AND_LISTING_CONTAINER__ flex gap-4 w-full lg:w-1/2">
          <div className="__NAV_ICONS_CONTAINER__ flex flex-col justify-start items-center gap-2">
            {tabs_list.map((item) => (
              <SideNavIcon
                key={item.title}
                onClickHandler={() => {
                  setSelectedTab(item.title);
                }}
                img={item.img}
                selected={selectedTab == item.title}
              />
            ))}
          </div>
          <div className="__ITEMS_LISTING_CONTAINER__ h-[600px] w-full">
            <ItemList type={selectedTab} />
          </div>
        </div>

        <div className="__PREVIEW_CONTAINER__ max-lg:self-end w-[calc(100%-81px)] lg:w-1/2 h-[600px]">
          <PreviewTab
            type={selectedTab}
            onClickNext={() => setSelectedTab("topwear")}
            onReplace={(type = "topwear") => {
              setSelectedTab(type);
            }}
          />
        </div>
      </div>
    </WardrobeContextProvider>
  );
};

export default Wardrobe_New;
