// src/setupEnv.js
const setupEnv = () => {
  window.NREUM = window.NREUM || {};
  window.NREUM.loader_config = JSON.parse(
    process.env.REACT_APP_NREUM_LOADER_CONFIG || "{}"
  );
  window.NREUM.info = JSON.parse(process.env.REACT_APP_NREUM_INFO || "{}");
};

export default setupEnv;
