import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PreferenceList from "components/partials/PreferenceList";
import { Preferencelist } from "constant";
import TagsChip from "components/partials/TagsChip";
import CloseTagIcon from "assets/images/cross_tag.png";
import PlusTagIcon from "assets/images/plus_tag.png";
import ShadowFrame from "components/shadow-frame";
import {
  useGetUserTagsByTokenQuery,
  useGetUserSuggestedTagsByTokenQuery,
  useUpdateUserTagsByTokenMutation,
} from "store/slicer/userProfile";
import Loader from "components/Loader/Loader";
import { useUpdateUserPreferenceByTokenMutation } from "store/slicer/userProfile";
import ModalClose from "assets/images/myassets/charm_cross.png";
import Alert from "components/popup/Alert/Alert";

const MyPreference = () => {
  const { data, isLoading, error } = useGetUserTagsByTokenQuery(
    { count: 1 },
    { refetchOnMountOrArgChange: true }
  );
  const {
    data: suggestedData,
    isLoading: suggestedLoading,
    error: suggestedError,
  } = useGetUserSuggestedTagsByTokenQuery();

  const [updateUsertagsQuery, { isLoading: updateLoader }] =
    useUpdateUserTagsByTokenMutation(
      { count: 1 },
      { refetchOnMountOrArgChange: true }
    );

  const [
    updateUserPrefereneceQuery,
    { isLoading: updatePLoader, refetch: Prefetch },
  ] = useUpdateUserPreferenceByTokenMutation(
    { count: 1 },
    { refetchOnMountOrArgChange: true }
  );

  const [tags, setTags] = useState("");
  const [addTags, setAddTags] = useState([]);
  const [suggestedTags, setSuggestedTags] = useState([]);
  const [defaultList, setDefaultList] = useState({
    musicNEntertainment: 0,
    fashionNStyle: 0,
    socialVREvents: 0,
    spaceNScifi: 0,
    meditationNMindfulness: 0,
    astronomyNSpace: 0,
    travelExplorer: 0,
    artNDesign: 0,
    gamingNSports: 0,
    digitalFashionNAvatars: 0,
    nft: 0,
    digitalAssetTrading: 0,
    techNGadgets: 0,
    cryptocurrencyInvestments: 0,
    holographicTechnology: 0,
    blockchainGaming: 0,
    virtualRealEstate: 0,
    immersiveStorytelling: 0,
  });

  const [userError, setUserError] = useState("");
  const [openErrorModal, setErrorModal] = useState(false);

  // console.log(suggestedData);

  const navigate = useNavigate();

  // handle open and close ebent for error modal
  const handleModalEvent = () => {
    setErrorModal(!openErrorModal);
  };

  const handleAddTagsSubmit = (e) => {
    e.preventDefault();
    if (tags.length === 0) return;
    if (addTags?.includes(tags?.toLowerCase())) {
      setErrorModal(!openErrorModal);
      setUserError(`"${tags}"  is already added to the list`);
      return;
    }
    // console.log('aditya')
    let tempArr = [
      ...addTags.slice(0, addTags.length - 1),
      ...addTags.slice(addTags.length - 1),
      tags,
    ];
    setAddTags(tempArr);
    setTags("");
    setUserError("");
  };

  useEffect(() => {
    setAddTags(data?.response?.data?.tags);
    setSuggestedTags(suggestedData?.response?.data?.tags);
  }, [data, suggestedData]);

  const handleOnchangePreferneceList = (e) => {
    const checkboxKey = e.target.name;
    const isChecked = e.target.checked;

    setDefaultList(() => {
      return {
        ...defaultList,
        [checkboxKey]: isChecked ? 1 : 0,
      };
    });
  };

  const handleOnSave = (e) => {
    e.preventDefault();
    updateUserPrefereneceQuery(defaultList)
      .then((res) => {
        if (res.data.response.statusEnum === 1) {
          // console.log(res.data)
          Prefetch();
          navigate("/me");
        }
      })
      .catch((err) => {
        console.log(err);
      });

    updateUsertagsQuery(addTags)
      .then((res) => {
        if (res.data.response.statusEnum === 1) {
          navigate("/me");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const OnRemoveUserTags = (tagName) => {
    let removeAddtags = addTags.filter((item) => item.trim() != tagName.trim());
    setAddTags(removeAddtags);
  };

  const onAddUserTags = (tagName) => {
    if (addTags?.includes(tagName)) {
      setErrorModal(!openErrorModal);
      setUserError(`"${tagName}" is already added to the list`);
      return;
    }

    let tempArr = [
      ...addTags.slice(0, addTags.length - 1),
      ...addTags.slice(addTags.length - 1),
      tagName,
    ];

    let removeSuggestedTags = suggestedTags.filter(
      (item) => item.trim() != tagName.trim()
    );
    setSuggestedTags(removeSuggestedTags);
    setAddTags(tempArr);
    setUserError("");
  };

  const handleFilterSuggestedtags = () => {
    return suggestedTags?.filter((item, ind) => !addTags?.includes(item));
  };

  return (
    <>
      <ShadowFrame
        data-testid="shadow-frame"
        className="z-[0] w-[350px] md:w-[450px] h-[350px] md:h-[400px] rounded-[250px] md:rounded-[400px] left-[-10%] max-[768px]:left-[-50%] top-[20%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]"
      />
      <ShadowFrame
        data-testid="shadow-frame"
        className="z-[0] w-[250px] md:w-[250px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[10%] max-[768px]:right-[50%] top-[60%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]"
      />
      <div className="__preference&tags__container__main py-[100px] px-[12%] max-[1280px]:px-[6%] max-[768px]:py-[60px] max-[768px]:px-[5%]">
        <div className="__back__section">
          <button
            onClick={() => navigate(-1)}
            className="inline-flex gap-2 text-white font-semibold text-xs md:text-sm leading-normal bg-white/[0.10] hover:bg-white/[0.90] hover:text-dark rounded-3xl py-1.5 px-1.5 pr-3 items-center  top-3 left-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M12.5 15L7.5 10L12.5 5"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>Back</span>
          </button>
        </div>

        <div className="__user__preference">
          <h4 className="font-heading text-[20px] py-[43px] max-[768px]:py-[25px] max-[768px]:text-[16px]">
            Preferences and Tags
          </h4>

          <form>
            <div className="__preferences__section">
              <h5 className="font-heading text-[16px] max-[768px]:text-[13px]">
                1. Select your preferences
              </h5>
              <PreferenceList
                list={Preferencelist}
                changeInput={handleOnchangePreferneceList}
                listStateFunction={setDefaultList}
              />
            </div>

            <div className="__tags__section">
              <h5 className="font-heading text-[16px] max-[768px]:text-[13px]">
                2. Tell us more about your preferences
              </h5>

              <h5 className="font-body font-light text-[16px] pt-[30px] max-[768px]:text-[13px] max-[768px]:pt-[15px]">
                Added tags:
              </h5>

              <div className="__added__tags__container__main w-[80%] max-[768px]:w-full flex flex-wrap justify-start gap-[12px] pt-[34px] max-[768px]:pt-[17px]">
                {isLoading ? (
                  <Loader />
                ) : (
                  addTags?.map((item, ind) => {
                    return (
                      <TagsChip
                        keyId={ind}
                        styleClass="bg-gradient-to-r from-[#FBBC5E33] to-[#F3A15133]"
                        text={item}
                        icon={CloseTagIcon}
                        onRemoveHandle={OnRemoveUserTags}
                        type="ADDED"
                      />
                    );
                  })
                )}
              </div>

              <h5 className="font-body font-light text-[16px] pt-[30px] max-[768px]:text-[13px] max-[768px]:pt-[15px]">
                Add tags:
              </h5>

              <div className="__add__tags__container pt-[16px]">
                <input
                  type="text"
                  onChange={(e) => setTags(e.target.value)}
                  value={tags}
                  placeholder="Add tags..."
                  className="w-[80%] max-[768px]:w-full grow-[2] rounded-[8px] py-[8px] text-[14px] font-light px-[13px] focus:outline-none border border-[#FBBC5E] bg-transparent"
                />
                <button
                  onClick={handleAddTagsSubmit}
                  className="ml-[-50px] text-[16px] text-[#FBBC5E] font-semibold"
                >
                  Add
                </button>
                {updateLoader ? (
                  <span>
                    <Loader />
                  </span>
                ) : (
                  <></>
                )}
              </div>

              <h5 className="font-body font-light text-[16px] pt-[30px] max-[768px]:text-[13px] max-[768px]:pt-[15px]">
                Suggested tags:
              </h5>

              <div className="__added__tags__container__main w-[80%] max-[768px]:w-full flex flex-wrap justify-start gap-[12px] pt-[34px] max-[768px]:pt-[17px]">
                {suggestedLoading ? (
                  <Loader />
                ) : (
                  handleFilterSuggestedtags()?.map((item, ind) => {
                    return (
                      <TagsChip
                        keyId={ind}
                        styleClass="bg-[#262422]"
                        text={item}
                        icon={PlusTagIcon}
                        onAddHandle={onAddUserTags}
                        type="SUGGESTED"
                      />
                    );
                  })
                )}
              </div>
            </div>
            <div className="__save__button__container pt-[50px]">
              <button
                onClick={handleOnSave}
                className="bg-gradient-to-r from-[#FBBC5E] to-[#F3A151] rounded-[8px] py-[7px] px-[30px] text-[#000] text-[16px] font-semibold"
              >
                {updatePLoader ? "Updating..." : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div>
      {openErrorModal ? (
        <Alert
          open={openErrorModal}
          onClose={handleModalEvent}
          message={userError}
          heading={""}
          isSuccess={false}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default MyPreference;
