import React, { useState } from "react";
import { Zoom } from "react-reveal";
import SliderHeader from "components/partials/SliderHeader";
import ShadowFrame from "components/shadow-frame";
import SliderCards from "components/partials/SliderCards";
// import AvatarBannerImage from "assets/images/companions_banner_image.png";
import Slider from "react-slick";
import { useGetListMarketplaceCompanionQuery } from "store/slicer/Marketplace";
import Loader from "components/Loader/Loader";
import ImageConstant from "constant/imageConstant";
import ComingSoon from "components/popup/comingsoon";
import { companionCategories } from "constant";

const sliderSettings = {
  slidesToShow: 4.5,
  slidesToScroll: 1,
  dots: false,
  arrows: true,
  swipeToSlide: true,
  infinite: false,
  focusOnSelect: false,
  // arrows: false,
  responsive: [
    {
      breakpoint: 1535,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3.5,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 820,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 670,
      settings: {
        slidesToShow: 1.5,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.2,
      },
    },
    {
      breakpoint: 360,
      settings: {
        slidesToShow: 1.1,
      },
    },
  ],
};

const Index = () => {
  const [showComingSoonPopup, setShowComingSoonPopup] = useState(false);

  let {
    data: dataPet,
    isFetching: isFetchingPet,
    isSuccess: isSuccessPet,
    isError: isErrorPet,
  } = useGetListMarketplaceCompanionQuery({
    searchQuery: "",
    species: "pet",
    page: 1,
    limit: 12,
  });
  let {
    data: dataSidekick,
    isFetching: isFetchingSidekick,
    isSuccess: isSuccessSidekick,
    isError: isErrorSidekick,
  } = useGetListMarketplaceCompanionQuery({
    searchQuery: "",
    species: "sideKick",
    page: 1,
    limit: 12,
  });

  let {
    data: dataMaster,
    isFetching: isFetchingMaster,
    isSuccess: isSuccessMaster,
    isError: isErrorMaster,
  } = useGetListMarketplaceCompanionQuery({
    searchQuery: "",
    species: "master",
    page: 1,
    limit: 12,
  });

  let {
    data: dataBodyguard,
    isFetching: isFetchingBodyguard,
    isSuccess: isSuccessBodyguard,
    isError: isErrorBodyguard,
  } = useGetListMarketplaceCompanionQuery({
    searchQuery: "",
    species: "bodyguard",
    page: 1,
    limit: 12,
  });

  let {
    data: dataAI,
    isFetching: isFetchingAI,
    isSuccess: isSuccessAI,
    isError: isErrorAI,
  } = useGetListMarketplaceCompanionQuery({
    searchQuery: "",
    species: "ai",
    page: 1,
    limit: 12,
  });
  if (
    isFetchingPet ||
    isFetchingSidekick ||
    isFetchingMaster ||
    isFetchingBodyguard ||
    isFetchingAI
  ) {
    return (
      <div className="h-screen w-full grid place-content-center">
        <Loader />
      </div>
    );
  }

  if (
    isErrorSidekick ||
    dataSidekick?.response?.statusEnum != 1 ||
    isErrorPet ||
    dataPet?.response?.statusEnum != 1
  ) {
    return (
      <div className="h-screen w-full text-white/70 grid place-content-center">
        <p>Something went wrong</p>
      </div>
    );
  }
  let filteredDataSidekick = [];
  if (isSuccessSidekick && dataSidekick?.response?.statusEnum == 1) {
    dataSidekick = dataSidekick.response.data.data;
    filteredDataSidekick = dataSidekick.map((item) => ({
      ...item,
      heading: item.assetName,
      price: item.priceFiat ? `${item.priceFiat} USD` : "Free",
      star: item.priceToken,
      thumbnail: item.thumbnail?.url,
    }));
  }

  let filteredDataBodyguard = [];
  if (isSuccessBodyguard && dataBodyguard?.response?.statusEnum == 1) {
    dataBodyguard = dataBodyguard?.response.data.data;
    filteredDataBodyguard = dataBodyguard?.map((item) => ({
      ...item,
      heading: item.assetName,
      price: item.priceFiat ? `${item.priceFiat} USD` : "Free",
      star: item.priceToken,
      thumbnail: item.thumbnail?.url,
    }));
  }

  let filteredDataMaster = [];
  if (isSuccessMaster && dataMaster?.response?.statusEnum == 1) {
    dataMaster = dataMaster?.response.data.data;
    filteredDataMaster = dataMaster?.map((item) => ({
      ...item,
      heading: item.assetName,
      price: item.priceFiat ? `${item.priceFiat} USD` : "Free",
      star: item.priceToken,
      thumbnail: item.thumbnail?.url,
    }));
  }

  let filteredDataAI = [];
  if (isSuccessAI && dataAI?.response?.statusEnum == 1) {
    dataAI = dataAI?.response.data.data;
    filteredDataAI = dataAI?.map((item) => ({
      ...item,
      heading: item.assetName,
      price: item.priceFiat ? `${item.priceFiat} USD` : "Free",
      star: item.priceToken,
      thumbnail: item.thumbnail?.url,
    }));
  }

  let filteredDataPet = [];
  if (isSuccessPet && dataPet?.response?.statusEnum == 1) {
    dataPet = dataPet?.response.data.data;
    filteredDataPet = dataPet?.map((item) => ({
      ...item,
      heading: item.assetName,
      price: item.priceFiat ? `${item.priceFiat} USD` : "Free",
      star: item.priceToken,
      thumbnail: item.thumbnail?.url,
    }));
  }
  // let humnaoidData = [];
  // let alienData = [];

  // if (filteredData?.length > 0) {
  //   alienData = filteredData.filter((item) => item.species === "alien");
  //   humnaoidData = filteredData.filter((item) => item.species !== "alien");
  // }
  return (
    <div
      data-testid="__companion_landing_page"
      className="__companion_landing_page px-2"
    >
      <ShadowFrame
        data-testid="shadow-frame"
        className="z-[0] w-[350px] md:w-[250px] h-[350px] md:h-[400px] rounded-[250px] md:rounded-[400px] left-[-10%] max-[768px]:left-[-50%] top-[50%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]"
      />
      <ShadowFrame
        data-testid="shadow-frame"
        className="z-[0] w-[250px] md:w-[250px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[10%] max-[768px]:right-[50%] top-[60%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]"
      />
      <Zoom>
      <div
          className="min-h-[250px] xl:min-h-[390px] bg-[#2D2D2D] h-full mb-10 md:mb-20 z-10 relative rounded bg-cover"
          style={{
            backgroundImage: `url('${ImageConstant.COMPANION_BANNER_IMAGE}')`,
            backgroundSize: "cover", // Ensures the image is fully visible
            backgroundPosition: "center", // Centers the image
            backgroundRepeat: "no-repeat", // Prevents repeating
          }}
        >
        {/* <div
          className="min-h-[200px] xl:min-h-[390px] bg-[#2D2D2D] h-full mb-10 md:mb-20 z-10 relative rounded bg-cover"
          style={{
            backgroundImage: `url('${ImageConstant.COMPANION_BANNER_IMAGE}')`,
          }}
        > */}
          <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center p-4">
            <h1 className="font-heading text-xl md:text-2xl lg:text-3xl text-center bg-gradient-to-r from-[#FBBC5E] to-[#F3A151] bg-[length:100%_2px]  text-transparent bg-clip-text">
              Coming Soon
            </h1>
          </div>
        </div>
      </Zoom>

      {/* Slider Section */}

      {/* Pets */}
      {filteredDataPet.length > 0 && (
        <>
          <SliderHeader
            title="Pets"
            seeMoreUrl={
              filteredDataPet.length > 4 ? "/marketplace/companions/pet" : null
            }
          />
          <div className="__pets__slider__container py-[40px] max-[768px]:py-[10px] relative z-[10]">
            <div data-testid="slider-card" className="py-[10px]">
              <Slider {...sliderSettings} className="carousel-section">
                {" "}
                {filteredDataPet.map((data, index) => {
                  return (
                    <SliderCards
                      key={index}
                      item={data}
                      id="pet"
                      type="COMP"
                      baseRoute="marketplace/companions"
                      setShowComingSoonPopup={setShowComingSoonPopup}
                    />
                  );
                })}
              </Slider>
            </div>
          </div>
        </>
      )}
      {/* Bodyguards */}
      {filteredDataBodyguard.length > 0 && (
        <>
          <SliderHeader
            title="Butlers and Bodyguards"
            seeMoreUrl={
              filteredDataBodyguard.length > 4
                ? "/marketplace/companions/bodyguard"
                : null
            }
          />
          <div className="__butler__slider__container py-[40px] max-[768px]:py-[10px] relative z-[10]">
            <div
              data-testid="slider-card"
              className="__slider_card_container py-[10px]"
            >
              <Slider {...sliderSettings} className="carousel-section">
                {filteredDataBodyguard.map((data, index) => {
                  return (
                    <SliderCards
                      key={index}
                      item={data}
                      id="bodyguard"
                      type="COMP"
                      baseRoute="marketplace/companions"
                      setShowComingSoonPopup={setShowComingSoonPopup}
                    />
                  );
                })}
              </Slider>
            </div>
          </div>
        </>
      )}

      {/* Masters */}
      {filteredDataMaster.length > 0 && (
        <>
          <SliderHeader
            title="Masters"
            seeMoreUrl={
              filteredDataMaster.length > 4
                ? "/marketplace/companions/master"
                : null
            }
          />
          <div className="__butler__slider__container py-[40px] max-[768px]:py-[10px] relative z-[10]">
            <div
              data-testid="slider-card"
              className="__slider_card_container py-[10px]"
            >
              <Slider {...sliderSettings} className="carousel-section">
                {filteredDataMaster.map((data, index) => {
                  return (
                    <SliderCards
                      key={index}
                      item={data}
                      id="master"
                      type="COMP"
                      baseRoute="marketplace/companions"
                      setShowComingSoonPopup={setShowComingSoonPopup}
                    />
                  );
                })}
              </Slider>
            </div>
          </div>
        </>
      )}

      {/* SideKicks */}
      {filteredDataSidekick.length > 0 && (
        <>
          <SliderHeader
            title="Sidekicks"
            seeMoreUrl={
              filteredDataSidekick.length > 4
                ? "/marketplace/companions/sidekick"
                : null
            }
          />
          <div className="__butler__slider__container py-[40px] max-[768px]:py-[10px] relative z-[10]">
            <div
              data-testid="slider-card"
              className="__slider_card_container py-[10px]"
            >
              <Slider {...sliderSettings} className="carousel-section">
                {filteredDataSidekick.map((data, index) => {
                  return (
                    <SliderCards
                      key={index}
                      item={data}
                      id="sidekick"
                      type="COMP"
                      baseRoute="marketplace/companions"
                      setShowComingSoonPopup={setShowComingSoonPopup}
                    />
                  );
                })}
              </Slider>
            </div>
          </div>
        </>
      )}

      {/* AIs */}
      {filteredDataAI.length > 0 && (
        <>
          <SliderHeader
            title="AIs"
            seeMoreUrl={
              filteredDataAI.length > 4 ? "/marketplace/companions/ai" : null
            }
          />
          <div className="__butler__slider__container py-[40px] max-[768px]:py-[10px] relative z-[10]">
            <div
              data-testid="slider-card"
              className="__slider_card_container py-[10px]"
            >
              <Slider {...sliderSettings} className="carousel-section">
                {filteredDataAI.map((data, index) => {
                  return (
                    <SliderCards
                      key={index}
                      item={data}
                      id="ai"
                      type="COMP"
                      baseRoute="marketplace/companions"
                      setShowComingSoonPopup={setShowComingSoonPopup}
                    />
                  );
                })}
              </Slider>
            </div>
          </div>
        </>
      )}
      <ComingSoon
        status={showComingSoonPopup}
        text={`This companion is on the way, and you'll soon have the opportunity to own this asset. Stay tuned!`}
        handleData={() => {
          setShowComingSoonPopup(false);
        }}
      />
    </div>
  );
};

export default Index;
