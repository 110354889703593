import React, { useState } from "react";
import ModalCloseIcon from "assets/images/myassets/charm_cross.png";
import DefaultFriend from "assets/images/default_friend.png";
import { useRemoveFriendRequestMutation } from "store/slicer/friends";
import { useBlockFriendRequestMutation } from "store/slicer/friends";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Friend-Profile-Modal
 *@developer Aditya P
 */

const FriendProfileModal = ({ handleModalStatus, modalData, refresh }) => {
  const [removefriendQuery, { isLoading }] = useRemoveFriendRequestMutation();
  const [blockFriendQuery, { isLoading: blockLoader }] =
    useBlockFriendRequestMutation();
  const [isRemoved, setIsRemoved] = useState({
    key: "REM",
    status: false,
  });
  const [isBlocked, setIsBlocked] = useState({
    key: "BLOC",
    status: false,
  });

  const removefriendHandler = (senderId) => {
    removefriendQuery(senderId)
      .then((res) => {
        if (res.data.response.statusEnum === 1) {
          setIsRemoved(() => {
            return {
              status: true,
            };
          });
          refresh();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const blockfriendHandler = (senderId) => {
    blockFriendQuery(senderId)
      .then((res) => {
        if (res.data.response.statusEnum === 1) {
          setIsBlocked(() => {
            return {
              status: true,
            };
          });
          refresh();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="flex bg-[#00000099] w-[100%] opacity-100 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[9998] outline-none focus:outline-none">
        Overlay
      </div>

      <div className="hs-overlay flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[9999] outline-none focus:outline-none max-[768px]:p-[20px]">
        <div className="bg-[#2C2C2C] __modal__container w-[35%] max-[768px]:w-[100%] h-auto max-[768px]:h-auto max-[1280px]:w-[50%] rounded-[8px] transition-all hs-overlay-open:opacity-100 hs-overlay-open:duration-500">
          <div className="__modal_close_icon_container flex justify-end p-[10px]">
            <div
              onClick={handleModalStatus}
              className="bg-[#FFFFFF1A] hover:bg-[#FFFFFF4D] hover:cursor-pointer flex flex-col justify-center items-center rounded-[50%] w-[20px] h-[20px]"
            >
              <img
                src={ModalCloseIcon}
                className="w-[10px] h-[10px] "
                alt="modal_close_btn"
              />
            </div>
          </div>

          <div className="__modal__content_main flex flex-col justify-center items-center mt-[20px] mb-[50px]">
            <img
              src={modalData?.photo === null ? DefaultFriend : modalData?.photo}
              alt="avatar_profile"
              className="w-[150px] h-[150px] rounded-full transition duration-300 ease-in-out object-cover"
            />
            <h4 className="text-[20px] mt-[15px]">@{modalData?.userName}</h4>
            <span className="text-[16px] text-[#FFFFFF80]">{`${
              modalData?.firstName
            } ${
              modalData?.lastName === null ? "" : modalData?.lastName
            }`}</span>

            {isRemoved?.status || isBlocked.status ? (
              <div className="pt-[40px] text-center">
                {isRemoved.status &&
                  "User has been Removed from your friends list."}
                {isBlocked.status && "User has been blocked."}
              </div>
            ) : (
              <div className="flex py-[30px] [&>button]:border gap-[20px] [&>button]:text-[16px] [&>button]:w-[135px] [&>button]:p-[5px] [&>button]:rounded-[5px] [&>button]:border-[#F3A151]">
                <button
                  onClick={() => removefriendHandler(modalData?._id)}
                  className="hover:bg-[#F3A151] hover:text-[#000]"
                >
                  {isLoading ? "Removing..." : "Remove"}
                </button>
                <button
                  onClick={() => blockfriendHandler(modalData?._id)}
                  className="hover:bg-[#F3A151] hover:text-[#000]"
                >
                  {blockLoader ? "Blocking..." : "Block"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FriendProfileModal;
