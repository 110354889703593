/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Clothing-Landing-component
 *@developer Aditya P
 */

import React, { useState, useEffect } from "react";
import { Zoom } from "react-reveal";
import { ClothTabs } from "constant";
import ClothingBannerImg from "assets/images/clothing-banner-image.jpg";
import {
  Formal,
  Casual,
  SpaceSuit,
  ExoSuit,
} from "components/assets/myassets/clothing/index";
import ShadowFrame from "components/shadow-frame";
import { useGetListMarketplaceClothingQuery } from "store/slicer/Marketplace";
import Loader from "components/Loader/Loader";
import ImageConstant from "../../../constant/imageConstant";
import { calculateTotalPages } from "utils";
import { useSearchParams, useHistory } from "react-router-dom";

const Index = () => {
  const [marketplaceData, setMarketplaceData] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const limit = 8;
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryQuery = searchParams.get("category");

  const handlePageChange = () => {
    setPageNum((prevPageNum) => prevPageNum + 1);
  };

  const [activeKey, setActiveKey] = useState(categoryQuery || "spacesuit");

  const { data, isFetching, isError, isSuccess, refetch } =
    useGetListMarketplaceClothingQuery(
      {
        page: pageNum,
        limit: limit,
        clothType: activeKey,
        searchQuery: "",
      }
      // { refetchOnMountOrArgChange: true }
    );
  const [activeTabs, setActivetab] = useState({
    id: 0,
    key: "spacesuit",
    name: "Space Suits",
    component: (
      <SpaceSuit
        pageNum={pageNum}
        totalPages={totalPages}
        isFetching={isFetching}
        handlePageChange={handlePageChange}
        data={marketplaceData}
      />
    ),
  });
  if (!isFetching && data.response.statusEnum != 1) {
    isError = true;
  }

  // const getData = () => {
  //   setIsLoading(true);
  //   setClothingData([]);
  // };
  const handleClickAsset = (tabs) => {
    setActiveKey(tabs.key);
    if (searchParams.has("category")) {
      searchParams.delete("category");
      setSearchParams(searchParams);
    }
  };

  // const ClothingTabs = [
  //   {
  //     id: 0,
  //     key: "spacesuit",
  //     name: "Space Suits",
  //     component: <SpaceSuit data={marketplaceData} />,
  //   },
  //   {
  //     id: 1,
  //     key: "exosuit",
  //     name: "Exo Suits",
  //     component: <ExoSuit data={marketplaceData} />,
  //   },
  //   {
  //     id: 2,
  //     key: "formal",
  //     name: "Formal",
  //     component: <Formal data={marketplaceData} />,
  //   },
  //   {
  //     id: 3,
  //     key: "casual",
  //     name: "Casual",
  //     component: <Casual data={marketplaceData} />,
  //   },
  // ];
  useEffect(() => {
    setTotalPages(data?.response.data.pagination.totalPages);
    // Fetch data and then execute the following code
    let updatedNewData = data?.response.data.data.map((item) => ({
      _id: item._id,
      slug: item.IAPslug ?? item.iapId,
      img: item.thumbnail.url,
      title: item.assetName,
      desc: Array.isArray(item.shortDescription)
        ? item.shortDescription.join(",")
        : item.shortDescription,
      iapCategory: item.category,
      iapType: item.clothSubType,
      price: item.priceFiat ? `${item.priceFiat} USD` : "Free",
      star: item.priceToken,
      paymentType: item.paymentType,
    }));
    if (pageNum > 1) {
      setMarketplaceData((prevEntertainmentData) => [
        ...prevEntertainmentData,
        ...updatedNewData,
      ]);
    } else {
      setMarketplaceData(updatedNewData);
    }
    // setMarketplaceData(updatedNewData);
    switch (activeKey) {
      case "exosuit":
        setActivetab({
          key: "exosuit",
          name: "Exo Suits",
          component: (
            <ExoSuit
              data={
                pageNum > 1
                  ? [...marketplaceData, ...updatedNewData]
                  : updatedNewData
              }
              pageNum={pageNum}
              totalPages={data?.response.data.pagination.totalPages}
              isFetching={isFetching}
              handlePageChange={handlePageChange}
            />
          ),
        });
        break;
      case "formal":
        setActivetab({
          key: "formal",
          name: "Formals",

          component: (
            <Formal
              data={
                pageNum > 1
                  ? [...marketplaceData, ...updatedNewData]
                  : updatedNewData
              }
              pageNum={pageNum}
              totalPages={data?.response.data.pagination.totalPages}
              isFetching={isFetching}
              handlePageChange={handlePageChange}
            />
          ),
        });
        break;
      case "casual":
        setActivetab({
          key: "casual",
          name: "Casuals",

          component: (
            <Casual
              data={
                pageNum > 1
                  ? [...marketplaceData, ...updatedNewData]
                  : updatedNewData
              }
              pageNum={pageNum}
              totalPages={data?.response.data.pagination.totalPages}
              isFetching={isFetching}
              handlePageChange={handlePageChange}
            />
          ),
        });
        break;
      default:
        setActivetab({
          key: "spacesuit",
          name: "Space Suits",

          component: (
            <SpaceSuit
              data={
                pageNum > 1
                  ? [...marketplaceData, ...updatedNewData]
                  : updatedNewData
              }
              pageNum={pageNum}
              totalPages={data?.response.data.pagination.totalPages}
              isFetching={isFetching}
              handlePageChange={handlePageChange}
            />
          ),
        });
        break;
    }
  }, [data]);
  useEffect(() => {
    setPageNum(1);
    // refetch();
  }, [activeKey]);
  return (
    <div className="px-2">
      <Zoom>
        <div
          className="min-h-[250px] xl:min-h-[390px] bg-[#2D2D2D] h-full mb-10 md:mb-20 z-10 relative rounded bg-cover"
          style={{
            backgroundImage: `url('${ImageConstant.CLOTHING_BANNER_IMAGE}')`,
            backgroundSize: "cover", // Ensures the image is fully visible
            backgroundPosition: "center", // Centers the image
            backgroundRepeat: "no-repeat", // Prevents repeating
          }}
        >
          <div className="absolute top-0 left-0 w-full h-full flex flex-col items-left justify-center p-2 lg:p-6 pl-2 md:pl-10">
            <h1 className="font-heading text-lg md:text-2xl lg:text-3xl text-left mb-2 md:mb-4">
              Discover Your Cosmic Look
            </h1>
            <div className="w-full md:w-[47%] py-4 lg:py-10 text-xs sm:text-sm md:text-base lg:text-lg">
              Embark on a sartorial journey through the cosmos with our
              collection of interstellar fashion. From sleek space suits to
              futuristic formal wear, our clothing line is designed to elevate
              your style as you traverse the stars.
            </div>
          </div>
        </div>
      </Zoom>

      {/* Clothing Content Tabs and Data Cards */}

      <div className="flex max-[768px]:flex-col max-[768px]:p-0">
        <ShadowFrame
          data-testid="shadow-frame"
          className="z-[0] w-[350px] md:w-[250px] h-[350px] md:h-[400px] rounded-[250px] md:rounded-[400px] left-[-10%] max-[768px]:left-[-50%] top-[50%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]"
        />
        <ShadowFrame
          data-testid="shadow-frame"
          className="z-[0] w-[250px] md:w-[250px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[10%] max-[768px]:right-[50%] top-[60%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]"
        />
        <div className="__clothing_landing_category_panel w-[20%] py-[20px] max-[768px]:w-auto max-[768px]:p-0 max-[1280px]:p-0 relative">
          <h5 className="text-[grey] font-body font-bold tracking-[1px] max-[768px]:hidden">
            Category
          </h5>
          <ul
            role="cloth-category-list"
            className="overflow-y-scroll
                     px-[0px]
                     [&>li]:px-[12px] 
                     [&>li]:py-[10px] 
                     [&>li]:font-light 
                     [&>li]:font-body
                     [&>li]:my-[15px] 
                     hover:[&>li]:bg-[#FBBC5E1A]
                     hover:[&>li]:rounded-[5px]
                     hover:[&>li]:cursor-pointer
                     max-[768px]:overflow-x-scroll
                     max-[768px]:flex
                     max-[768px]:[&>li]:py-[5px]
                     max-[768px]:[&>li]:my-[10px]
                     max-[768px]:px-0
                     max-[768px]:text-[11px]
                     "
          >
            {ClothTabs?.map((tabs, index) => {
              return (
                <li
                  key={tabs.key}
                  className={`hover:bg-[#FBBC5E4D] rounded-[5px] ${
                    activeTabs.key === tabs.key ? "!bg-[#FBBC5E4D]" : ""
                  }`}
                  onClick={() => handleClickAsset(tabs)}
                >
                  {tabs.name}
                </li>
              );
            })}
          </ul>
        </div>

        {/* Tabs container data */}

        <div className="__clothing_landing_tabs_container_panel pl-[50px] max-[768px]:pl-0 pt-[20px] max-[768px]:pt-0 w-[80%] p-[10px] max-[768px]:w-auto max-[768px]:h-auto max-[768px]:mt-[0px]">
          <div className="font-heading font-bold tracking-[1px] text-[20px] max-[768px]:text-[15px] max-[768px]:mt-[15px] pl-[10px]">
            <h4>{activeTabs.name}</h4>
          </div>
          {isError && (
            <div className="h-[250px] w-full grid place-content-center">
              <p className="text-white/50 font-bold">Something went wrong</p>
            </div>
          )}
          {activeTabs.component}
        </div>
      </div>
    </div>
  );
};

export default Index;
