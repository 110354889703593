/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Apartment-Detail-Page
 *@developer Sudhanshu
 */

import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PremImg1 from "assets/images/ship-l.png";
import PremImg2 from "assets/images/machraider_l.jpg";
import PremImg3 from "assets/images/starhawk_l.jpg";
import { Fade, Zoom } from "react-reveal";
import SlickSlider from "react-slick";
import MarketPlaceCard from "components/card/marketplace";
import ShadowFrame from "components/shadow-frame";
import GameRoom1 from "assets/images/game-room-1.png";
import GameRoom2 from "assets/images/game-room-2.png";
import GameRoom3 from "assets/images/game-room-3.png";
import ShipTheme from "assets/images/ship-theme.png";
import StandredAppartment from "assets/images/StandredAppartment.png";
import BuyCards from "components/buy-cards";
import Slider from "assets/images/ship-large.png";

import SneakyPeakStarHawkImg1 from "assets/images/sp1 Starhawk.jpg";
import SneakyPeakStarHawkImg2 from "assets/images/sp2 Starhawk.jpg";
import SneakyPeakStarHawkImg3 from "assets/images/sp3 Starhawk.jpg";
import SneakyPeakStarHawkImg4 from "assets/images/sp4 Starhawk.jpg";

import SneakyPeakPrometheusImg1 from "assets/images/sp1 Prometheus.png";
import SneakyPeakPrometheusImg2 from "assets/images/sp2 Prometheus.png";
import SneakyPeakPrometheusImg3 from "assets/images/sp3 Prometheus.png";
import SneakyPeakPrometheusImg4 from "assets/images/sp4 Prometheus.png";

import SneakyPeakMachraiderImg1 from "assets/images/sp1 machraider.jpg";
import SneakyPeakMachraiderImg2 from "assets/images/sp2 machraider.jpg";
import SneakyPeakMachraiderImg3 from "assets/images/sp3 machraider.jpg";
import SneakyPeakMachraiderImg4 from "assets/images/sp4 machraider.jpg";

import Thumb1 from "assets/images/ship-thumb-1.png";
import Thumb2 from "assets/images/ship-thumb-2.png";
import Thumb3 from "assets/images/ship-thumb-3.png";
import Preview from "components/popup/Preview";
import Ship1 from "assets/images/ship-1.png";
import Ship2 from "assets/images/ship-2.png";
import Ship3 from "assets/images/ship-3.png";
import Flag from "components/report/Flag";
import AssetsReportModal from "components/Modal/Report/AssetsReportModal";
import ComingSoon from "components/popup/comingsoon";
import { useGetSingleShipQuery } from "store/slicer/Ships";
import BuyPopupIap from "components/popup/BuyPopupIap/BuyPopupIap";
import {
  useLazyGetCartItemByTokenQuery,
  useUpdateCartItembyTokenMutation,
} from "store/slicer/Cart";
import { usePostCheckUserAssetsMutation } from "store/slicer/userAssets";
import Loader from "components/Loader/Loader";
import DOMPurify from "dompurify";
import { convertUsdToFstrPrice, convertActualUsdToFstrPrice } from "utils";
/* The above code is a React component called "Index". It renders a webpage that displays information
about an apartment, including its image, price, description, themes, gaming and streaming options,
and suggested apartments. It also includes a dialog box that allows the user to preview the
apartment in a 3D model. The component uses various React hooks and components, such as useState,
useNavigate, Grid, Typography, Button, Dialog, AppBar, Toolbar, Canvas, and Stage, to create the
desired UI and functionality. */

const sliderSettings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: false,
  swipeToSlide: true,
  infinite: false,
  focusOnSelect: true,
  // arrows: false,
  // responsive: [
  //   {
  //     breakpoint: 1921,
  //     settings: {
  //       slidesToShow: 3,
  //     },
  //   },
  //   {
  //     breakpoint: 1200,
  //     settings: {
  //       slidesToShow: 2,
  //     },
  //   },

  //   {
  //     breakpoint: 1025,
  //     settings: {
  //       slidesToShow: 1.55,
  //     },
  //   },
  //   {
  //     breakpoint: 913,
  //     settings: {
  //       slidesToShow: 1.4,
  //     },
  //   },
  //   {
  //     breakpoint: 821,
  //     settings: {
  //       slidesToShow: 1.2,
  //     },
  //   },
  //   {
  //     breakpoint: 768,
  //     settings: {
  //       slidesToShow: 1.1,
  //     },
  //   },
  //   {
  //     breakpoint: 616,
  //     settings: {
  //       slidesToShow: 1,
  //     },
  //   },
  // ],
};

const Index = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { ship_name, shipid } = params;
  const { data, isLoading, refetch } = useGetSingleShipQuery(shipid);
  const [AddToCartQuery, { isLoading: addtoCartLoader }] =
    useUpdateCartItembyTokenMutation();

  const sliderContainerRef = useRef(null);
  const [sneakPeakItemWidthRatio, setSneakPeakItemWidthRatio] = useState(1);

  useEffect(() => {
    const updateSliderItemWidth = () => {
      if (!sliderContainerRef.current) {
        return;
      }

      const container = sliderContainerRef.current;
      let sliderImageWidth = 615;

      if (container.clientWidth <= 615) {
        sliderImageWidth = container.clientWidth;
      }

      const updatedItemWidthRatio =
        (container.clientWidth / sliderImageWidth) * 0.95;
      setSneakPeakItemWidthRatio(updatedItemWidthRatio);
    };

    if (isLoading) {
      return;
    }

    updateSliderItemWidth();

    window.addEventListener("resize", updateSliderItemWidth);

    return () => {
      return window.removeEventListener("resize", updateSliderItemWidth);
    };
  }, [data]);

  const [gameImage, setGameImage] = useState([
    {
      img: GameRoom1,
      title: "Ultimate Gaming Experience",
    },
    {
      img: "https://d30s60cgc31mft.cloudfront.net/assets/ship-small.png",
      title: "Ultimate Gaming Experience",
    },
    {
      img: GameRoom3,
      title: "Ultimate Gaming Experience",
    },
  ]);

  const [themes, setThemes] = useState([
    {
      img: ShipTheme,
      title: "Classic theme",
      active: true,
    },
  ]);

  const StarHawkslider = [
    {
      id: 0,
      largeImg: SneakyPeakStarHawkImg1,
      thumbImg: Thumb1,
    },
    {
      id: 1,
      largeImg: SneakyPeakStarHawkImg2,
      thumbImg: Thumb2,
    },

    {
      id: 2,
      largeImg: SneakyPeakStarHawkImg3,
      thumbImg: Thumb3,
    },
    {
      id: 3,
      largeImg: SneakyPeakStarHawkImg4,
      thumbImg: Thumb1,
    },
  ];

  const Prometheusslider = [
    {
      id: 0,
      largeImg: SneakyPeakPrometheusImg1,
      thumbImg: Thumb1,
    },
    {
      id: 1,
      largeImg: SneakyPeakPrometheusImg2,
      thumbImg: Thumb2,
    },

    {
      id: 2,
      largeImg: SneakyPeakPrometheusImg3,
      thumbImg: Thumb3,
    },
    {
      id: 3,
      largeImg: SneakyPeakPrometheusImg4,
      thumbImg: Thumb1,
    },
  ];

  const MachraiderSlider = [
    {
      id: 0,
      largeImg: SneakyPeakMachraiderImg1,
      thumbImg: Thumb1,
    },
    {
      id: 1,
      largeImg: SneakyPeakMachraiderImg2,
      thumbImg: Thumb2,
    },

    {
      id: 2,
      largeImg: SneakyPeakMachraiderImg3,
      thumbImg: Thumb3,
    },
    {
      id: 3,
      largeImg: SneakyPeakMachraiderImg4,
      thumbImg: Thumb1,
    },
  ];

  const [open, setOpen] = useState(false);
  const [showComingSoonPopup, setShowComingSoonPopup] = useState(false);
  // const handleClick = (e) => {
  //   if (e === "MyAsset") {
  //     // setContentType("MyAsset");
  //   }
  //   if (e === "Premium") {
  //     setShowComingSoonPopup(true);
  //     // This will open popup for purchasing when implemented, do not remove this will be used later
  //     // setOpen(true);
  //   }
  //   if (e === "BuyNow") {
  //     setShowComingSoonPopup(true);
  //     // This will open popup for purchasing when implemented, do not remove this will be used later
  //     // setOpen(true);
  //   }
  // };

  const handleClick = (e) => {
    if (e === "MyAsset") {
      // setContentType("MyAsset");
    }
    if (e === "Premium") {
      setShowComingSoonPopup(true);
      // This will open popup for purchasing when implemented, do not remove this will be used later
      // setOpen(true);
    }
    if (e === "BuyNow") {
      setBuyPopupOpen(true);
      setIsPopupCart(true);
    }
  };

  const [isreportModal, setReportModal] = useState(false);

  const handleCloseReportModal = () => {
    setReportModal(!isreportModal);
  };

  const [buyPopupOpen, setBuyPopupOpen] = useState(false);
  const [isPopupCart, setIsPopupCart] = useState(false);
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [popupCurrency, setPopupCurrency] = useState("fstr");
  const [isAssetOwned, setIsAssetOwned] = useState(false);
  const [selectedThemeIndex, setSelectedThemIndex] = useState(0);

  const [checkUserAsset, userAssetInfo] = usePostCheckUserAssetsMutation();
  // This needs to be updated from the api side first, we need to be able to pass the theme id to check too
  useEffect(() => {
    async function checkIfOwnedAsset() {
      try {
        const res = await checkUserAsset({
          content_id: data?.response?.data?._id,
          themeId: themesArr[selectedThemeIndex]?._id,
          isIap: true,
        });

        if (
          res?.data?.response?.statusEnum == 1 ||
          res?.data?.response?.statusEnum == 0
        ) {
          // check here if user owns the asset
          const isOwned = res?.data?.response?.data;

          setIsAssetOwned(isOwned);
        }
      } catch (e) {}
    }
    if (
      localStorage.getItem("accessToken") &&
      data?.response?.statusEnum == 1
    ) {
      checkIfOwnedAsset();
    }
  }, [data, selectedThemeIndex]);

  const onPopupClose = () => {
    setBuyPopupOpen(false);
  };

  const onPurchaseCurrencyChange = (currency) => {
    setPopupCurrency(currency);
  };

  const onPopupActionHandler = () => {
    // handle logic for usd currency here
    if (popupCurrency === "usd") {
      // add iap to cart
      if (isPopupCart) {
        addIapToCart();
      }
      // purchase iap
      else {
        checkoutIap();
      }
    }

    // handle logic for purchasing from wallet here
    else if (popupCurrency === "fstr") {
      // add iap to cart
      if (isPopupCart) {
        addIapToCart();
      }
      // purchase iap
      else {
        checkoutIap();
      }
    }
  };

  const addIapToCart = () => {
    AddToCartQuery({
      category: "ship",
      itemId: data?.response?.data?._id,
      currencyType: popupCurrency === "usd" ? "usd" : "fstr",
      itemThemeId: themesArr[selectedThemeIndex]._id,
    })
      .then((res) => {
        console.log(res.data);
        if (res?.data?.response?.statusEnum === 1) {
          setIsAddedToCart(true);
          refetchCartItems();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkoutIap = () => {
    if (popupCurrency == "usd") {
      navigate(
        `/checkout?productType=ship&productName=${data?.response?.data?.assetName}&amount=${themesArr[selectedThemeIndex]?.priceFiat}&productId=${data?.response?.data?._id}&themeId=${themesArr[selectedThemeIndex]._id}`
      );
    } else if (popupCurrency == "fstr") {
      navigate(
        `/checkoutfstr?productType=ship&productName=${
          data?.response?.data?.assetName
        }&amount=${convertActualUsdToFstrPrice(
          themesArr[selectedThemeIndex]?.priceFiat
        )}&productId=${data?.response?.data?._id}&themeId=${
          themesArr[selectedThemeIndex]._id
        }`
      );
    }
  };

  // fetching all cart items
  const [
    GetCartItemByToken,
    {
      data: GetCartItemQuery,
      isLoading: getCartItemLoader,
      refetch: refetchCartItems,
      isSuccess: isFetchCartSuccess,
    },
  ] = useLazyGetCartItemByTokenQuery();

  // this effect will check if this item is already added to user's cart
  useEffect(() => {
    if (isFetchCartSuccess && GetCartItemQuery?.response?.statusEnum == 1) {
      let foundItem = GetCartItemQuery?.response?.data?.find((item) => {
        return (
          item?.itemId == data?.response?.data?._id &&
          item?.itemsThemeId == themesArr[selectedThemeIndex]._id
        );
      });

      // found an item in cart list with same item and theme id
      if (foundItem) {
        setIsAddedToCart(true);
      } else {
        setIsAddedToCart(false);
      }
    }
  }, [getCartItemLoader, data, selectedThemeIndex]);
  useEffect(() => {
    localStorage.getItem("accessToken") && GetCartItemByToken();
  }, []);

  let buyCardType = "BuyNowTheme";
  if (isAddedToCart) {
    // buyCardType = "AddToCart";
  } else if (isAssetOwned) {
    // buyCardType = "MyAsset";
  }

  if (data?.response && data.response.statusEnum !== 1) {
    navigate("/marketplace/ships");
    // console.log("ifff con");
  }

  let themesArr = [];

  if (data?.response?.statusEnum == 1) {
    themesArr = data?.response?.data?.themesArr.slice();
  }

  if (isLoading) {
    return (
      <div className="h-[500px] w-full grid place-content-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="relative px-4">
      {buyPopupOpen && (
        <BuyPopupIap
          open={buyPopupOpen}
          onClose={onPopupClose}
          isCart={isPopupCart}
          addedToCart={isAddedToCart}
          loading={addtoCartLoader}
          onCurrencyChange={onPurchaseCurrencyChange}
          actionBtnHandler={onPopupActionHandler}
          proceedToCheckoutHandler={checkoutIap}
          priceUsd={themesArr[selectedThemeIndex]?.priceFiat}
        />
      )}
      <div className="__back__container__section flex justify-between pb-[12px]">
        <button
          onClick={() => navigate(-1)}
          className="inline-flex gap-2 text-white font-semibold text-xs md:text-sm leading-normal bg-white/[0.10] hover:bg-white/[0.90] hover:text-dark rounded-3xl py-1.5 px-1.5 pr-3 items-center  top-3 left-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M12.5 15L7.5 10L12.5 5"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Back</span>
        </button>
        {/* <div
          onMouseEnter={HandletooltipHover}
          onMouseLeave={HandletooltipHover}
          className="flex justify-center items-center"
        >
          <div
            onClick={() => {
              setReportModal(!isreportModal);
            }}
            className={`__report__tooltip__section transition-all delay-100 ${
              IstooltipHover ? "" : "hidden"
            } hover:cursor-pointer border border-[#FBBC5E] bg-gradient-to-r from-[#FBBC5E33] to-[#F3A15133] px-[12px] py-[2px] rounded-[8px] text-[12px] font-medium font-body mr-[10px] max-[768px]:mr-[10px]`}
          >
            Report this asset
          </div>
          <Flag
            onClick={() => {
              setReportModal(!isreportModal);
            }}
          />
        </div> */}
      </div>
      <div className="lg:min-h-[350px] h-[350px] mb-6 md:mb-9 z-10 relative rounded flex justify-center items-center">
        <Zoom>
          {" "}
          <img
            src={data?.response?.data?.largeThumbnail?.url}
            alt={data?.response?.data?.largeThumbnail?.fileName}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.style.display = "none";
            }}
            className="h-full w-full object-cover"
          />
        </Zoom>
      </div>
      <div className="relative">
        <ShadowFrame className="w-[250px] md:w-[775px] h-[250px] md:h-[775px] rounded-[250px] md:rounded-[775px] -left-[80px] md:-left-[300px] -top-[80px] md:-top-[150px]" />
        <div className="block md:flex justify-stretch xl:justify-between items-start mb-7 md:mb-14 relative z-10 gap-10 md:gap-8 lg:gap-14">
          <div className="sm:max-w-[48%] mb-10">
            {/* <p className="clip-text font-medium mb-3">About Ship</p> */}
            <h1 className="font-heading text-md mb-3">
              {data?.response?.data?.assetName}
            </h1>
            <h3 className="text-gray-400 font-semibold mt-8">Description:</h3>

            {data?.response?.data?.longDescription?.map((item, i) => (
              <div
                key={i}
                className="inner-html-headings [&>p]:text-white/90 max-md:[&>p]:text-[13px] [&>ul]:list-disc [&>ul]:ml-5 [&>ol]:list-decimal [&>ol]:ml-5"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(item),
                }}
              ></div>
            ))}
            {/* <p
              dangerouslySetInnerHTML={{
                __html: data?.response?.data?.longDescription?.[0],
              }}
              className="text-sm text-white text-opacity-90"
            ></p> */}
          </div>
          <div className="flex-auto sm:max-w-[48%] py-[34px] px-7 bg-gradient-to-r from-[#312d26] to-[#30261d] rounded-[20px]">
            {/* Type : BuyNow || Free || Premium || MyAsset */}
            <BuyCards
              type={buyCardType}
              isAddedToCart={isAddedToCart}
              isOwned={isAssetOwned}
              isLoading={userAssetInfo.isLoading ?? false}
              card="product"
              title="Buy Ship"
              // price={`$${data?.response?.data?.themesArr?.[0]?.priceFiat}`}
              price={`$${themesArr[selectedThemeIndex]?.priceFiat}`}
              star={convertUsdToFstrPrice(
                data?.response?.data?.themesArr?.[0]?.priceFiat
              )}
              handleData={(e) => {
                // if (process.env.REACT_APP_ENVIRONMENT === "developer") {
                handleClick(e);
                // } else {
                //   setShowComingSoonPopup(true);
                // }
              }}
              onBuyHandle={() => {
                //if (process.env.REACT_APP_ENVIRONMENT === "developer") {
                setBuyPopupOpen(true);
                setIsPopupCart(false);
                // } else {
                //  setShowComingSoonPopup(true);
                // }
              }}
              selectedThemeIndex={selectedThemeIndex}
              onThemeChange={(ind) => setSelectedThemIndex(ind)}
              themes={themesArr.map((item) => item.themeName)}
            />
            <ComingSoon
              status={showComingSoonPopup}
              text={`Ship purchases are on the way, and you'll soon have the opportunity to own exciting assets. Stay tuned!`}
              handleData={() => {
                setShowComingSoonPopup(false);
              }}
            />
          </div>
        </div>
        <div className="streaming-section my-10 md:my-20 z-10 relative">
          <h2 className="font-heading text-lg md:text-2xl mb-5">Themes</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {themesArr?.map((item, i) => (
              <div className="relative" key={i}>
                <div
                  className={`aspect-[247/149] rounded-lg border-2 ${
                    i === selectedThemeIndex
                      ? "border-sec"
                      : " border-transparent"
                  } ${item.comingSoon ? "coming-soon" : ""} cursor-pointer`}
                  key={i}
                  onClick={() => setSelectedThemIndex(i)}
                >
                  <img
                    src={item.thumbnail?.url}
                    alt={item.themeName}
                    className="h-full w-full object-cover rounded-lg"
                  />
                </div>
                <p className="font-medium mt-3">{item?.themeName}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="streaming-section my-10 md:my-20 z-10 relative">
          <h2 className="font-heading text-lg md:text-2xl mb-5">Sneaky Peek</h2>
          <div className="max-w-full" ref={sliderContainerRef}>
            <SlickSlider
              {...sliderSettings}
              slidesToShow={sneakPeakItemWidthRatio}
              className="carousel-section"
            >
              {themesArr[selectedThemeIndex].sneakPeakImages.map((item) => {
                return (
                  <div
                    key={item._id}
                    className="w-full max-w-[615px] h-[292px]"
                  >
                    <img
                      className="h-full w-full object-cover"
                      src={item.url}
                      alt=""
                    />
                  </div>
                );
              })}
            </SlickSlider>
          </div>
          <div className="mt-12">
            <button
              type="button"
              onClick={() => setOpen(true)}
              className="rounded-lg font-medium bg-gradient-3 border border-sec text-sm md:text-base justify-center py-3 px-5 md:px-10 flex gap-2 mt-4"
            >
              Preview in Shuttle dealership
            </button>
            <Preview
              status={open}
              text="To preview the ship, login to Oculus to have full ship view inside Shuttle Dealership."
              heading="Preview in Shuttle Dealership"
              handleData={() => setOpen(false)}
            />
          </div>
        </div>
        <ShadowFrame className="w-[250px] md:w-[775px] h-[250px] md:h-[775px] rounded-[250px] md:rounded-[775px] -right-[80px] md:-right-[300px] -bottom-[80px] md:-bottom-[150px]" />
      </div>
      <div className="streaming-section my-12 mt-14 md:my-20 md:mt-28 z-10 relative">
        <div className="max-w-3xl mb-12">
          <h2 className="font-heading text-lg md:text-2xl text-white mb-5">
            Explore Gaming & Streaming inside Ship
          </h2>
          <p className="text-white/[0.90] text-sm md:text-base leading-normal">
            Experience next-level gaming and streaming. Dive into immersive
            worlds, challenge friends in multiplayer battles, or simply relax
            and enjoy your favorite content. With cutting-edge technology and a
            vast selection of games and entertainment.
          </p>
          <h4 className="font-heading text-sm md:text-base clip-text mt-10 mb-3">
            Explore Holo-Suite gaming
          </h4>
          <p className="text-white/[0.90] text-sm md:text-base leading-normal">
            Embark on thrilling adventures within our Holo Suite, where gaming
            reaches new dimensions. Immerse yourself in futuristic challenges
            and interactive experiences like never before right inside your
            living space.
          </p>
          <h4 className="font-heading text-sm md:text-base clip-text mt-10 mb-3">
            Explore Environmental gaming
          </h4>
          <p className="text-white/[0.90] text-sm md:text-base leading-normal">
            Embark on epic adventures in otherworldly environments with
            Environmental Gaming. Explore alien planets and futuristic
            landscapes in a gaming experience like no other.
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {gameImage.map((item, i) => (
            <div className="aspect-[403/207] bg-[#2D2D2D]" key={i}>
              <img
                src={item.img}
                alt={item.title}
                className="h-full w-full object-cover"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="h-[1px] bg-[#5a5a5a]/[0.20]"></div>
      {/* <h2 className="font-heading text-lg md:text-2xl mt-10 md:mt-20 mb-7">
        Suggested Ships
      </h2> */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-8 lg:gap-y-16 mb-14 md:mb-28 mx-auto relative z-10">
        {/* {ships?.map((item, i) => (
          <Fade key={i}>
            <MarketPlaceCard item={item} type="large" />
          </Fade>
        ))} */}
      </div>

      {isreportModal ? (
        <AssetsReportModal
          handleModalStatus={handleCloseReportModal}
          idToReport={data?.response?.data?._id}
          contentType={"ship"}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Index;
