import React from "react";
import Video2D from "../components/2d";
import Video180 from "../components/180";
import Video360 from "../components/360";
import Channels from "../components/channels";
import Music from "../components/music";
import Volumetric from "../components/volumetric";
import EventsPage from "../events/Events";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";

// const myAssetsTabs = [
//   {
//     id: 0,
//     parentId: 0,
//     key: "volumetric-video",
//     name: "Volumetric Video",
//     children: null,
//     component: <Volumetric />,
//   },
//   {
//     id: 1,
//     parentId: 0,
//     key: "180-video",
//     name: "180 Video",
//     children: null,
//     component: <Video180 />,
//   },
//   {
//     id: 2,
//     parentId: 0,
//     key: "360-video",
//     name: "360 Video",
//     children: null,
//     component: <Video360 />,
//   },
//   {
//     id: 3,
//     parentId: 0,
//     key: "2D-video",
//     name: "2D Video",
//     children: null,
//     component: <Video2D />,
//   },
//   {
//     id: 4,
//     parentId: 0,
//     key: "music",
//     name: "Music",
//     children: null,
//     component: <Music />,
//   },
//   {
//     id: 5,
//     parentId: 0,
//     key: "channels",
//     name: "Channels",
//     children: null,
//     component: <Channels />,
//   },
// ];

const myAssetsTabs = [
  {
    id: 0,
    parentId: 0,
    key: "events",
    name: "Events",
    children: null,
    component: <EventsPage />,
  },
  {
    id: 1,
    parentId: 0,
    key: "volumetric-video",
    name: "Volumetric Video",
    children: null,
    component: <Volumetric />,
  },
  {
    id: 2,
    parentId: 0,
    key: "180-video",
    name: "180 Video",
    children: null,
    component: <Video180 />,
  },
  {
    id: 3,
    parentId: 0,
    key: "360-video",
    name: "360 Video",
    children: null,
    component: <Video360 />,
  },
  {
    id: 4,
    parentId: 0,
    key: "2D-video",
    name: "2D Video",
    children: null,
    component: <Video2D />,
  },
  {
    id: 5,
    parentId: 0,
    key: "music",
    name: "Music",
    children: null,
    component: <Music />,
  },
  {
    id: 6,
    parentId: 0,
    key: "channels",
    name: "Channels",
    children: null,
    component: <Channels />,
  },
];

const Index = () => {
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        navigate("/marketplace/entertainment/events");
        break;
      case 1:
        navigate("/marketplace/entertainment/volumetric-video");
        break;
      case 2:
        navigate("/marketplace/entertainment/180-video");
        break;
      case 3:
        navigate("/marketplace/entertainment/360-video");
        break;
      case 4:
        navigate("/marketplace/entertainment/2D-video");
        break;
      case 5:
        navigate("/marketplace/entertainment/music");
        break;
      case 6:
        navigate("/marketplace/entertainment/channels");
        break;
      default:
        break;
    }
    // switch (newValue) {
    //   case 0:
    //     navigate("/marketplace/entertainment/volumetric-video");
    //     break;
    //   case 1:
    //     navigate("/marketplace/entertainment/180-video");
    //     break;
    //   case 2:
    //     navigate("/marketplace/entertainment/360-video");
    //     break;
    //   case 3:
    //     navigate("/marketplace/entertainment/2D-video");
    //     break;
    //   case 4:
    //     navigate("/marketplace/entertainment/music");
    //     break;
    //   case 5:
    //     navigate("/marketplace/entertainment/channels");
    //     break;
    //   default:
    //     break;
    // }
  };
  const { activeTab } = useParams();

  const [activeTabs, setActivetab] = useState(() => {
    const tab = myAssetsTabs.find((tab) => tab.key === activeTab);
    return tab ? tab : myAssetsTabs[0]; // Default to the first tab if activeTab is not found
  });

  return (
    <div className="relative px-2 md:px-4">
      <hr className="border-[0.1px] border-[#5A5A5A80]" />

      {activeTabs.key == "events" ? (
        <div className="mt-[16px]">{activeTabs.component}</div>
      ) : (
        <div className="__myassets_container_main  flex flex-col md:flex-row max-[768px]:flex-col max-[768px]:p-0 max-[1280px]:py-[25px]">
          <div className="__assets_category_panel w-[100%] md:w-[20%]  max-[768px]:w-auto max-[768px]:p-0 max-[1280px]:p-0 py-[35px]">
            <h5 className="text-[grey] font-body mb-[10px] font-bold tracking-[1px] max-[768px]:hidden text-sm">
              Category
            </h5>
            <div className="volumetric-card-left-shadow "></div>
            <div className="overflow-hidden">
              <ul
                data-testid="tablist"
                className="overflow-y-scroll
            flex md:flex-col
                     px-[0px]
                     [&>li]:px-[12px] 
                     [&>li]:py-[10px] 
                     [&>li]:font-light 
                     [&>li]:font-body
                     [&>li]:my-[15px] 
                     hover:[&>li]:bg-[#FBBC5E1A]
                     hover:[&>li]:rounded-[5px]
                     hover:[&>li]:cursor-pointer
                     max-[768px]:overflow-x-scroll
                     max-[768px]:flex
                     max-[768px]:[&>li]:py-[5px]
                     max-[768px]:[&>li]:my-[10px]
                     max-[768px]:px-0
                     "
              >
                {myAssetsTabs?.map((tabs, index) => {
                  return (
                    <li
                      role="tab"
                      key={tabs.key}
                      className={`mr-8 md:mr-[0] whitespace-nowrap hover:bg-[#FBBC5E4D] rounded-[5px] ${
                        activeTabs.key === tabs.key ? "!bg-[#FBBC5E4D]" : ""
                      }`}
                      onClick={() => {
                        setActivetab(tabs);
                        handleChange(tabs.key, index);
                      }}
                    >
                      {tabs.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="__assets_tabs_container_panel  w-[100%] md:w-[80%] sm:p-[10px] md:pl-[56px] xl:pl-[96px] max-[768px]:w-auto max-[768px]:h-auto xl:min-h-[768px] max-[768px]:mt-[18px]">
            {activeTabs.key === "volumetric-video" && activeTabs.component}
            {activeTabs.key === "180-video" && activeTabs.component}
            {activeTabs.key === "360-video" && activeTabs.component}
            {activeTabs.key === "2D-video" && activeTabs.component}
            {activeTabs.key === "music" && activeTabs.component}
            {activeTabs.key === "channels" && activeTabs.component}
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
