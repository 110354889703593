import React from "react";
import AssetCardOpen from "components/card/assets/AssetCardOpen";
import { EntertainmentCardClass } from "constant";
import Explore from "components/Explore/Explore";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Assets-Entertainment-2D-video
 *@developer Aditya P
 */

const _2DVideo = ({ item }) => {
  const filter2DData = item?.filter((vitem) => {
    return vitem?.content_type === "2D-video";
  });
  return (
    <>
      {filter2DData.length === 0 ? (
        <div class="__explore_default_button h-[500px] w-full grid place-content-center">
          <Explore />
        </div>
      ) : (
        <>
          <h2 className="mb-4 text-white text-[24px] leading-[36px] font-heading">
            2D Videos
          </h2>
          <div className="w-full flex justify-between gap-[20px] gap-y-[32px] flex-wrap">
            {filter2DData?.map((_vitem) => {
              return (
                <div key={_vitem?.content_id} className="w-full lg:w-[48%]">
                  {" "}
                  <AssetCardOpen
                    baseRoute={`/marketplace/entertainment/content/${_vitem?.content_type}`}
                    channelID={_vitem?.content_data?.channel.channelHandle}
                    contentID={_vitem?.content_data.contentSlug}
                    image={_vitem?.content_data?.thumbnail}
                    name=""
                    title={_vitem?.content_data?.contentName}
                    subtitleButton="Watch in VR"
                    cardClass={EntertainmentCardClass}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default _2DVideo;
