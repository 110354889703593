/* eslint-disable jsx-a11y/img-redundant-alt */

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useRequestRefundMutation } from "store/slicer/MyOrders";
import ErrorAlert from "utils/errorAlert";
import SuccessAlert from "utils/successAlert";
/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Refund-Item-Modal
 *@developer Amardeep Singh
 */

// order item modal component
const RefundItemModal = ({ cancelRefund, orderId, productDetail }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [reportIssue, setReportIssue] = useState(false);
  const [disableRefund, setDisableRefund] = useState(true);
  const [addRequestRefund] = useRequestRefundMutation();
  const [reason, setReason] = useState("");
  const [requestSubmit, setRequestSubmit] = useState(false);

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
    setDisableRefund(false);
  };

  const handleSubmit = async () => {
    setRequestSubmit(true);
    await addRequestRefund({
      gatewayTransactionId: orderId,
      productId: productDetail?.productId,
      productType: productDetail?.productType,
      userReasonForReturn: reason,
    })
      .then((res) => {
        if (res?.data?.response?.statusEnum === 1) {
          cancelRefund();
          SuccessAlert("Refund Request Successful");
        } else {
          ErrorAlert(res?.data?.response?.message);
        }
        setRequestSubmit(false);
      })
      .catch((error) => {
        console.log("Error occured while requesting refund", error);
        setRequestSubmit(false);
        ErrorAlert(error?.message);
      });
  };

  useEffect(() => {
    if (productDetail.productType === "iap") {
      setReportIssue(true);
    }
  }, []);

  return (
    <>
      {/* <div className="flex bg-[#00000099] opacity-100 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[9998] outline-none focus:outline-none">
        Overlay
      </div> */}
      <div
        data-testid="invite-friend-modal"
        className="__invite_search_component hs-overlay flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[9999] outline-none focus:outline-none max-[768px]:p-[20px]"
      >
        <div className="bg-[#2C2C2C] __modal__container w-[40%] max-[768px]:w-[100%] max-h-[400px] max-[1920px]:max-h-[500px] max-[768px]:h-auto max-[1280px]:w-[50%] rounded-[8px] transition-all hs-overlay-open:opacity-100 hs-overlay-open:duration-500">
          <div className="__modal_content_main my-[60px] px-[50px] mb-[20px] max-[768px]:p-0">
            <div className="__modal_heading text-left ">
              <h4 className="font-heading text-[24px] ">
                Report Issues related to this item
              </h4>
            </div>
            <div className="mt-[40px] ">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio accent-[#FBBC5E] h-6 w-6"
                  value="option1"
                  checked={reportIssue ? true : selectedOption === "option1"}
                  onChange={handleRadioChange}
                />
                <span className="ml-2">Cancel and Refund this Item</span>
              </label>
            </div>
            {!reportIssue && (
              <>
                <div className="mt-[30px]  flex justify-start items-center">
                  <p className="text-white/80 font-medium ">
                    Explain the reason
                  </p>
                </div>
                <textarea
                  className="my-[10px] p-[15px] w-full bg-[transparent] rounded-[4px] border border-white/70 resize-none text-[14px] font-medium "
                  name="response"
                  value={reason}
                  placeholder="Item was different from the product description."
                  onChange={(e) => setReason(e.target.value)}
                ></textarea>
                <div className="my-[30px] ">
                  <label className="flex items-center space-x-2">
                    {/* <input
                      type="checkbox"
                      className="form-checkbox h-6 w-6 accent-[#FBBC5E] border border-[#1a1919] rounded-md"
                    /> */}
                    <span className="text-white/50 font-medium">
                      Read
                      <NavLink
                        to="/refund-cancellation-policy"
                        className="ml-2 text-[#FBBC5E] underline "
                      >
                        Refund & Cancellation Policy
                      </NavLink>
                      .
                    </span>
                  </label>
                </div>
              </>
            )}
            {reportIssue && (
              <>
                <div className="mt-[20px]  flex justify-start items-center">
                  <p className="text-[#EC4134] font-bold ">
                    Refunds are not available for this item.
                  </p>
                </div>
                <div className="mt-[20px]  flex justify-start items-center">
                  <p className="text-[#7A7A7A] font-medium ">
                    Don't worry; you can still reach out to our team for
                    assistance.
                  </p>
                </div>
                <div className="my-[30px] ">
                  <span className="text-white/50 font-medium">
                    Read{" "}
                    <NavLink
                      to="/refund-cancellation-policy"
                      className="ml-2 text-[#FBBC5E] underline "
                    >
                      Refund & Cancellation Policy
                    </NavLink>
                    .
                  </span>
                </div>
              </>
            )}

            <div className="my-6 text-[10px] md:text-[12px] xl:text-[16px] flex gap-x-5 ">
              <button
                className="px-2 md:px-4 lg:px-8 xl:px-12 py-3 text-center  font-bold rounded-lg bg-[#7a7a7a] w-[50%] "
                onClick={cancelRefund}
              >
                Cancel
              </button>
              {!reportIssue && (
                <button
                  className="px-2 md:px-6 lg:px-8 xl:px-3 py-3 text-center text-[#000000]  font-bold rounded-lg bg-gradient-to-r from-[#FBBC5E] to-[#F3A151] w-[50%] "
                  disabled={disableRefund}
                  onClick={handleSubmit}
                >
                  {requestSubmit ? "Requesting Refund ..." : "Request Refund"}
                </button>
              )}
              {reportIssue && (
                <button
                  className="px-2 md:px-6 lg:px-8 xl:px-3 py-3 text-center text-[#000000]  font-bold rounded-lg bg-[#94764c] w-[50%] "
                  disabled
                >
                  Request Refund
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundItemModal;
