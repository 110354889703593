/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module RotateItemPopup
 *@developer Shubham K
 */

import PopupWrapper from "components/popup/PopupWrapper";
import React from "react";
import BgImg from "assets/images/avt_customize_success.png";

const Success = ({ open, onClose, canClose }) => {
  return (
    <PopupWrapper open={open} onClose={onClose} canClose={canClose}>
      <div className="h-[572px] max-h-[calc(100vh-170px)] border border-white/30 rounded-lg">
        <div className="h-[46%] w-full rounded-t-lg">
          <img
            className="w-full h-full object-cover rounded-t-lg"
            src={BgImg}
            alt="success_img"
          />
        </div>
        <div className="h-[54%] p-4 bg-[#2C2C2C] rounded-b-lg flex flex-col justify-evenly items-center">
          <div>
            <svg
              className="mx-auto"
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 4C12.0589 4 4 12.0589 4 22C4 31.9412 12.0589 40 22 40C31.9412 40 40 31.9412 40 22C40 12.0589 31.9412 4 22 4ZM0 22C0 9.84974 9.84974 0 22 0C34.1502 0 44 9.84974 44 22C44 34.1502 34.1502 44 22 44C9.84974 44 0 34.1502 0 22ZM34.4142 13.5858C35.1952 14.3668 35.1952 15.6332 34.4142 16.4142L20.4142 30.4142C19.6332 31.1952 18.3668 31.1952 17.5858 30.4142L9.58578 22.4142C8.80474 21.6332 8.80474 20.3668 9.58578 19.5858C10.3668 18.8048 11.6332 18.8048 12.4142 19.5858L19 26.1716L31.5858 13.5858C32.3668 12.8047 33.6332 12.8047 34.4142 13.5858Z"
                fill="#25B39E"
              />
            </svg>
          </div>
          <p className=" text-center text-[20px] leading-[25px] font-heading font-normal">
            Avatar customization is Successful
          </p>
          <p className=" text-center text-[16px] leading-[26.24px]">
            Your Wardrobe Selection is Saved. Discover your full avatar look on
            Oculus!{" "}
          </p>
          <div className="">
            <button
              className="block mx-auto text-[16px] font-bold py-[4px] px-[24px] lg:py-[8px] lg:px-[48px] rounded-[8px] border-[1px] border-[#fbbc5e] hover:bg-[#fbbc5e] hover:text-black"
              type="button"
              onClick={onClose}
            >
              Got It
            </button>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default Success;
