/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Avatar-Landing-Component
 *@developer Shubham K
 */
import React, { useState, useEffect } from "react";

import Loader from "components/Loader/Loader";
import SliderCards from "components/partials/SliderCards";
import SliderHeader from "components/partials/SliderHeader";
import { useNavigate, useParams } from "react-router-dom";
import { useGetListMarketplaceAvatarQuery } from "store/slicer/Marketplace";
import ComingSoon from "components/popup/comingsoon";
import { calculateTotalPages } from "utils";

const dummyData = [
  {
    id: 0,
    heading: "Lumina Vox",
    price: "US$ 9.99",
    isComing: false,
  },
  {
    id: 1,
    heading: "Lumina Vox",
    price: "US$ 9.99",
    isComing: false,
  },
  {
    id: 2,
    heading: "Lumina Vox",
    price: "US$ 9.99",
    isComing: false,
  },
  {
    id: 3,
    heading: "Lumina Vox",
    price: "US$ 9.99",
    isComing: false,
  },
  {
    id: 4,
    heading: "Lumina Vox",
    price: "US$ 9.99",
    isComing: false,
  },
  {
    id: 5,
    heading: "Lumina Vox",
    price: "US$ 9.99",
    isComing: false,
  },
  {
    id: 6,
    heading: "Lumina Vox",
    price: "US$ 9.99",
    isComing: false,
  },
  {
    id: 7,
    heading: "Lumina Vox",
    price: "US$ 9.99",
    isComing: false,
  },
];

const AvatarsList = () => {
  const [showComingSoonPopup, setShowComingSoonPopup] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const categorytype = params.categorytype;
  const [pageNum, setPageNum] = useState(1);
  const [allData, setAllData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const limit = 8;

  let { data, isFetching, isSuccess, isError } =
    useGetListMarketplaceAvatarQuery({
      searchQuery: "",
      species: categorytype,
      page: pageNum,
      limit: limit,
    });
  const handlePageChange = () => {
    setPageNum((prevPageNum) => prevPageNum + 1);
  };
  useEffect(() => {
    let filteredData = [];
    if (isSuccess && data?.response?.statusEnum == 1) {
      setTotalPages(
        calculateTotalPages(data.response?.data?.pagination.totalCount, limit)
      );
      data = data.response.data.data;
      filteredData = data.map((item) => ({
        ...item,
        heading: item.assetName,
        price: item.priceFiat ? `${item.priceFiat} USD` : "Free",
        star: item.priceToken,
        thumbnail: item.thumbnail?.url,
      }));
      // filteredData = filteredData.filter(
      //   (item) => item.species.toLowerCase() == categorytype
      // );
      if (pageNum > 1) {
        console.log("tottal", pageNum, filteredData);

        setAllData((prevData) => [...prevData, ...filteredData]);
      } else {
        setAllData(() => [...filteredData]);
      }
    }
  }, [isFetching, data]);

  if (!isFetching && (isError || data?.response?.statusEnum != 1)) {
    return (
      <div className="h-screen w-full text-white/70 grid place-content-center">
        <p>Something went wrong</p>
      </div>
    );
  }

  return (
    <div className="px-2">
      <div className="__BACK_BTN_CONTAINER__">
        <button
          onClick={() => navigate(-1)}
          className="inline-flex gap-2 text-white font-semibold text-xs md:text-sm leading-normal bg-white/[0.10] hover:bg-white/[0.90] hover:text-dark rounded-3xl py-1.5 px-1.5 pr-3 items-center  top-3 left-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M12.5 15L7.5 10L12.5 5"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Back</span>
        </button>
      </div>
      <div className="mt-[60px]">
        <SliderHeader title={`${categorytype} Avatars`} />
        <div className="__butler__slider__container py-[40px] max-[768px]:py-[10px] relative z-[10]">
          <>
            <div
              data-testid="slider-card"
              className="__slider_card_container py-[10px] flex justify-start items-center flex-wrap gap-[60px]"
            >
              {allData?.map((data) => {
                return (
                  <SliderCards
                    key={data._id}
                    item={data}
                    id={categorytype}
                    type="COMP"
                    baseRoute="marketplace/avatars"
                    setShowComingSoonPopup={setShowComingSoonPopup}
                  />
                );
              })}
            </div>
            <div className="flex justify-center">
              {!isFetching && totalPages && totalPages > pageNum ? (
                <button
                  onClick={handlePageChange}
                  className="inline-flex border cursor-pointer text-sm md:text-base leading-none rounded-2xl border-sec btn-outline hover:text-black hover:font-semibold px-6 py-3 md:py-4 md:px-8 mt-6 font-medium"
                >
                  Load More
                </button>
              ) : null}
            </div>
          </>
        </div>
      </div>
      {isFetching ? (
        <div class="__explore_default_button flex justify-center items-center h-[30vh] max-[768px]:flex-none">
          <Loader />
        </div>
      ) : null}
      <ComingSoon
        status={showComingSoonPopup}
        text={`This avatar is on the way, and you'll soon have the opportunity to own this asset. Stay tuned!`}
        handleData={() => {
          setShowComingSoonPopup(false);
        }}
      />
    </div>
  );
};

export default AvatarsList;
