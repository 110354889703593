/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module utility-function
 *@developer Shubham K
 */

export const generateVideoPageUrl = (type, channelId, contentId) => {
  const base_url = "content";
  let url = base_url + "/" + type + "/" + channelId + "/" + contentId;
  return url;
};

export const generateMusicPageUrl = (channelId, contentId) => {
  const base_url = "content/music";
  let url = base_url + "/" + channelId + "/" + contentId;
  return url;
};

export const generateIapUrls = (
  contentType,
  contentIdOrSlug,
  contentName = "",
  contentSubType
) => {
  const base_url = "/marketplace";
  let url;

  if (!contentType || !contentIdOrSlug) {
    throw new Error("must pass contentType and id/slug");
  }

  contentType = contentType.toLowerCase();

  switch (contentType) {
    case "apartment":
      url = base_url + `/apartments/${contentIdOrSlug}/${contentName}`;
      break;
    case "ship":
      url = base_url + `/ships/${contentIdOrSlug}/${contentName}`;
      break;
    case "clothing":
      url = base_url + `/clothing/${contentSubType}/${contentIdOrSlug}`;
      break;
    case "avatar":
      url = base_url + `/avatars/${contentSubType}/${contentIdOrSlug}`;
      break;
    default:
      url = "#";
  }

  return url;
};
