import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import HelpCenterModal from "components/Modal/HelpCenter/HelpCenterModal";
import { uploadToS3, removeFromS3 } from "utils/s3";
import ErrorAlert from "utils/errorAlert";
import { useAddTicketMutation } from "store/slicer/HelpCenter";

const timestamp = Date.now();

const RequestTicket = () => {
  const screenshotInputRef = useRef(null);
  const [screenshotPristine, setScreenshotPristine] = useState(true);
  const [screenshotPreview, setScreenshotPreview] = useState("");
  const [screenshotFileName, setScreenshotFileName] = useState("");
  const screenshotDraggableRef = useRef(null);
  const [screenshot, setScreenshot] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [addTicketRequest] = useAddTicketMutation();
  const [ticketResponse, setTicketResponse] = useState(null);

  const togglePopup = async () => {
    if (!title) {
      ErrorAlert("Please add title.");
      return;
    } else if (!description) {
      ErrorAlert("Please add description.");
      return;
    }
    await addTicketRequest({
      ticketType: 0,
      title: title,
      description: description,
      screenshots: screenshot?.url,
    })
      .then((res) => {
        if (res?.data?.response?.statusEnum === 1) {
          setTicketResponse(res.data.response.data.ticketId);
          setIsOpen(!isOpen);
        } else {
          ErrorAlert(res?.data?.response?.message);
        }
      })
      .catch((error) => {
        ErrorAlert("Api Error Occured.");
      });
  };

  const onScreenshotDragOver = (ev) => {
    ev.preventDefault();
  };

  const onScreenshotInputChange = (ev) => {
    setScreenshotPristine(false);
    const items = ev.target.files;
    if (items.length > 0) {
      const file = items[0];
      const type = file.type;
      // dropped something other than an image
      if (
        type !== "image/png" &&
        type !== "image/jpg" &&
        type !== "image/jpeg"
      ) {
        ErrorAlert("Please upload image only.");
        return;
      }

      // to show thumbnail preview
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        // Image Validation
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          // Delete if there is any thumbnail
          if (screenshot?.fileName) removeScreenshot();

          // Upload to s3
          uploadToS3(
            file,
            `${process.env.REACT_APP_AWS_S3_BUCKET_NAME}/help-center`,
            timestamp + "**" + file.name
          )
            .then((s3upload) => {
              setScreenshotPreview(e.target.result);
              setScreenshotFileName(file.name);
              setScreenshot({
                url: s3upload.Location,
                fileName: timestamp + "**" + file.name,
              });
            })
            .catch((error) => {
              console.log("Error while uploading ticket screenshot", error);
            });
        };
      };
    }
  };

  const onScreenshotDrop = (ev) => {
    setScreenshotPristine(false);
    ev.preventDefault();
    const items = ev.dataTransfer.files;
    // succcessfully dropped
    if (items.length > 0) {
      const file = items[0];
      const type = file.type;
      // dropped something other than an image
      if (
        type !== "image/png" &&
        type !== "image/jpg" &&
        type !== "image/jpeg"
      ) {
        ErrorAlert("Please upload image only.");
        return;
      }
      // to show thumbnail preview
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        // Image Validation
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          // Delete if there is any thumbnail
          if (screenshot?.fileName) removeScreenshot();

          // Upload to s3
          uploadToS3(
            file,
            `${process.env.REACT_APP_AWS_S3_BUCKET_NAME}/help-center`,
            timestamp + "**" + file.name
          )
            .then((s3upload) => {
              setScreenshotPreview(e.target.result);
              setScreenshotFileName(file.name);
              setScreenshot({
                url: s3upload.Location,
                fileName: timestamp + "**" + file.name,
              });
            })
            .catch((error) => {
              console.log("Error while Droping ticket screenshot", error);
            });
        };
      };
    }
  };

  const removeScreenshot = () => {
    removeFromS3(
      `${process.env.REACT_APP_AWS_S3_BUCKET_NAME}/help-center`,
      screenshot.fileName
    );
    setScreenshot(null);
    setScreenshotPreview(null);
  };

  return (
    <>
      <div className="mt-[50px] mb-[30px] w-full text-center text-[24px] font-semibold">
        Raise a Ticket
      </div>
      <div className="w-full flex flex-col items-center">
        <div className="mt-[20px] mb-[140px] w-[90%] md:w-[40%] xl:w-[33%] flex flex-col gap-y-[20px] bg-[#262626] rounded-[8px] py-[25px] px-[30px]">
          <div className="flex flex-col gap-y-[5px]">
            <label
              className="font-bold text-[14px] max-[768px]:text-[12px] text-[#e2dada]"
              htmlFor="title"
            >
              Provide Title
            </label>
            <input
              className={`bg-transparent rounded-[8px] border border-[#535353] py-[10px] px-[15px] focus:border-[#f6f6f6] outline-none placeholder-[#666262]`}
              type="text"
              name="title"
              placeholder="Please add title"
              required
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-y-[5px]">
            <label
              className="font-bold text-[14px] max-[768px]:text-[12px] text-[#e2dada]"
              htmlFor="description"
            >
              Provide Description
            </label>
            <textarea
              className={`bg-transparent h-[100px] rounded-[8px] border border-[#535353] py-[10px] px-[15px] focus:border-[#f6f6f6] outline-none resize-none placeholder-[#666262]`}
              type="text"
              name="description"
              placeholder="Please describe the issue in detail."
              required
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-y-[5px]">
            <label
              className="font-bold text-[14px] max-[768px]:text-[12px] text-[#e2dada]"
              htmlFor="screenshot"
            >
              Upload Screenshot (optional)
            </label>
            {/* ********************Show these when small thumbnail is not uploaded******************** */}
            {!screenshotPreview && (
              <div
                ref={screenshotDraggableRef}
                className="__DRAG_AND_DROP_SMALL_CONT__ relative outline-none border border-[#535353] focus:border-theme bg-transparent rounded-[8px] py-[25px]"
                onDragOver={onScreenshotDragOver}
                onDrop={onScreenshotDrop}
              >
                <p className="text-[12px] text-center">Drag & Drop file</p>
                <p className="text-[12px] text-center mt-4">Or</p>
                <button
                  className="block border border-[#FFFFFF] mt-4 mb-2 mx-auto bg-transparent rounded-2xl px-8 py-2 font-medium text-[12px] text-[#fff] cursor-pointer hover:bg-[#fff] hover:text-[#000]"
                  onClick={() => {
                    setScreenshotPristine(false);
                    screenshotInputRef.current.click();
                  }}
                >
                  Upload
                </button>
              </div>
            )}
            <input
              ref={screenshotInputRef}
              className="absolute opacity-0 pointer-events-none"
              type="file"
              name=""
              id=""
              accept="image/png, image/jpeg, image/jpg"
              onChange={onScreenshotInputChange}
              // value={(e) => e.target.name}
            />
            {screenshotPreview && (
              <div className="__UPLOADED_SMALL_THUMB_CONT__ mt-[14px] mb-[8px] px-6 py-9 rounded-[20px] border-[1px] border-dashed border-[#707070] w-full max-w-[650px]">
                <div className="w-[400px] max-w-full">
                  <div
                    className="__UPLOADED_THUMB__ h-[160px] bg-[#3f3f3f] bg-no-repeat bg-center bg-contain"
                    style={{
                      backgroundImage: `url(${screenshotPreview})`,
                    }}
                  ></div>
                  <p className="__UPLOADED_SMALL_THUMB_NAME__ text-start text-[10px] text-[#7A7A7A] leading-[14.5px] overflow-hidden text-ellipsis">
                    {screenshotFileName}
                  </p>
                </div>
                <div className="__UPLOADED_THUMB_BTN_CONT__ mt-2 flex justify-end items-center">
                  <button
                    className="text-[12px] leading-[14.5px] font-bold cursor-pointer"
                    type="button"
                    onClick={() => screenshotInputRef.current.click()}
                  >
                    Replace
                  </button>
                  <button
                    className="ml-4 text-[12px] leading-[14.5px] font-bold text-error cursor-pointer"
                    type="button"
                    onClick={removeScreenshot}
                  >
                    Delete
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="my-[20px] flex gap-x-[4%]">
            <NavLink
              to="/help-center"
              className="px-2 md:px-4 lg:px-8 xl:px-12 py-3 text-center font-bold rounded-lg border border-[#FFFFFF] hover:bg-[#EC4134] hover:border-transparent w-[48%]"
            >
              Cancel
            </NavLink>
            <button
              className="px-2 md:px-6 lg:px-8 xl:px-3 py-3 text-center font-bold rounded-lg text-black bg-gradient-to-r from-[#FBBC5E] to-[#F3A151] w-[48%] hover:from-[#DB9149] hover:to-[#DB9149]"
              onClick={togglePopup}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {isOpen && <HelpCenterModal taskId={0} ticketId={ticketResponse} />}
    </>
  );
};

export default RequestTicket;
