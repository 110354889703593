/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module user
 *@developer Shubham K <shubhamk@instaacoders.com>
 */

import React, { useState } from "react";

// This component defines a wrapper component called PopupWrapper, it will wrap other components and display them as a popup
// will only display the popup if open is true
// onClose will be called on the parent component when the popup closes, click anywhere outside the children to close popup
// pass canClose=false from parent component if need to prevent popup from closing
const PopupWrapper = (props) => {
  const {
    open,
    onClose,
    canClose = true,
    position = "center",
    children,
  } = props;

  const [isOpen, setIsOpen] = useState(open);

  if (!isOpen) {
    return null;
  }

  const closePopup = () => {
    if (canClose) {
      if (typeof onClose == "function") {
        onClose();
      }
      setIsOpen(false);
    }
  };

  let position_classes =
    "top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full md:w-1/2 max-w-[650px] mt-[30px] overflow-y-auto showScrollbar";
  if (position == "top") {
    position_classes =
      "top-[100px] left-1/2 -translate-x-1/2 mx-auto w-full max-w-[1025px] max-h-[675px]";
  }

  return (
    <div
      className="__DIALOG_CONTAINER__ !z-[9999] fixed top-0 left-0 w-screen h-screen !bg-black/70"
      onClick={closePopup}
    >
      <div
        className={`__MAIN_CONTAINER__ absolute max-h-[80vh]  ${position_classes}`}
        onClick={(e) => e.stopPropagation()}
      >
        {React.cloneElement(children, {
          ...props,
        })}
      </div>
    </div>
  );
};

export default PopupWrapper;
