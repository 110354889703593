import React, { useState, useRef, useEffect } from "react";
import ModalCloseIcon from "assets/images/myassets/charm_cross.png";
import DownArrowIcon from "assets/images/down-scroll-arrow.png";
import SuccessTickIcon from "assets/images/check_icon.png";
import WarningTickIcon from "assets/images/mdi_tick.png";
import ErrorCrossIcon from "assets/images/alert_icon.png";
import styles from "./AssetsReportModal.module.css";
import { useSelector } from "react-redux";
import {
  usePostReportContentMutation,
  usePostReportChannelMutation,
} from "store/slicer/Admin";
import PopupWrapper from "components/popup/PopupWrapper";

const ReportListData = [
  {
    id: "report-1",
    reportName: "Inappropriate Content",
  },
  {
    id: "report-2",
    reportName: "Hateful or Abusive Content",
  },
  {
    id: "report-3",
    reportName: "Harassments or Bullying",
  },
  {
    id: "report-4",
    reportName: "Threats of harm or encouragement of self harm",
  },
  {
    id: "report-5",
    reportName: "Promotion or glorification of real life violence",
  },
  {
    id: "report-6",
    reportName: "Infringes on the rights of another",
  },
  {
    id: "report-7",
    reportName: "Infringes on my rights",
  },
  {
    id: "report-8",
    reportName: "Spam",
  },
];

const AssetsReportModal = ({ handleModalStatus, idToReport, contentType }) => {
  const [isReportSuccess, setReportSuccess] = useState(false);
  const [isReportedAlready, setReportedAlready] = useState(false);
  const [isReportError, setReportError] = useState(false);
  const [isSubmitActive, setSubmitActive] = useState(false);
  const [reportType, setReportType] = useState("");
  const textRef = useRef();
  // const firstNameRef = useRef();
  // const lastNameRef = useRef();
  // const emailRef = useRef();

  let userInfo = localStorage.getItem("userInfo");
  // console.log("user info", userInfo);
  userInfo = JSON.parse(userInfo);
  let userId = userInfo?.id;

  const [reportContentTrigger, reportContentInfo] =
    usePostReportContentMutation();
  const [reportChannelTrigger, reportChannelInfo] =
    usePostReportChannelMutation();
  const {
    data: reportContentData,
    isLoading: isReportContentLoading,
    isSuccess: isReportContentSuccess,
    isError: isReportContentError,
  } = reportContentInfo;
  const {
    data: reportChannelData,
    isLoading: isReportChannelLoading,
    isSuccess: isReportChannelSuccess,
    isError: isReportChannelError,
  } = reportChannelInfo;
  const handleRadioChange = (e) => {
    setReportType(e.target.value);
    setSubmitActive(true);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    // setReportSuccess(true);
    setReportedAlready(false);
    setReportSuccess(false);
    setReportError(false);
    if (contentType?.includes("channel") || contentType?.includes("creator")) {
      // call channel report here
      const payload = {
        channelId: idToReport,
        reportType,
        reportById: userId,
        userComment: textRef?.current?.value || "",
        reporterEntityType: localStorage.getItem("userInfo") ? "User" : "Guest",
      };

      reportChannelTrigger(payload);
    } else {
      // call content report here
      const payload = {
        contentId: idToReport,
        contentType: contentType,
        reportType,
        reportById: userId,
        userComment: textRef?.current?.value || "",
        reporterEntityType: localStorage.getItem("userInfo") ? "User" : "Guest",
      };

      reportContentTrigger(payload);
    }
  };
  useEffect(() => {
    console.log("statusChanged", isReportContentSuccess, isReportContentError);
    if (
      isReportContentSuccess &&
      reportContentData &&
      reportContentData?.response?.statusEnum === 1
    ) {
      console.log(reportContentData);
      setReportSuccess(true);
    } else if (
      isReportContentSuccess &&
      reportContentData?.response?.statusEnum === 0
    ) {
      setReportedAlready(true);
      setReportSuccess(false);
      setReportError(false);
    } else if (isReportContentError) {
      setReportSuccess(false);
      setReportedAlready(false);
      setReportError(true);
    }
  }, [reportContentData, isReportContentError, isReportContentSuccess]);

  useEffect(() => {
    console.log("statusChanged", isReportChannelSuccess, isReportChannelError);
    if (
      isReportChannelSuccess &&
      reportChannelData &&
      reportChannelData?.response?.statusEnum === 1
    ) {
      console.log(reportChannelData);
      setReportSuccess(true);
    } else if (
      isReportChannelSuccess &&
      reportChannelData?.response?.statusEnum === 0
    ) {
      setReportedAlready(true);
      setReportSuccess(false);
      setReportError(false);
    } else if (isReportChannelError) {
      setReportSuccess(false);
      setReportedAlready(false);
      setReportError(true);
    }
  }, [reportChannelData, isReportChannelError, isReportChannelSuccess]);
  return (
    <>
          <PopupWrapper open={()=>handleModalStatus} onClose={()=>handleModalStatus} canClose={true}>
      <div className="relative bg-[#2c2c2c] rounded-[8px] py-4 px-4 ">
        <div
          className="absolute top-1 right-1 bg-white/10 w-[28px] h-[28px] rounded-full grid place-content-center cursor-pointer"
          onClick={handleModalStatus}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6875 1.3125L1.3125 12.6875M1.3125 1.3125L12.6875 12.6875"
              stroke="white"
              strokeOpacity="0.5"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
          {isReportError && (
            <div className="__reportFlag__success__section flex flex-col justify-center items-center">
              <img
                src={ErrorCrossIcon}
                alt="success__flag"
                className="w-[75px] h-[75px] max-[768px]:w-[55px] max-[768px]:h-[55px]"
              />
              <h4 className="text-[16px] max-[768px]:text-[13px] font-heading pt-[40px] text-center">
                Failed to send your report.
              </h4>
              <span className="text-[16px] max-[768px]:text-[13px] font-normal font-body text-center pt-[27px]">
                Please retry after some time or raise a ticket.
              </span>
              <button
                onClick={handleModalStatus}
                className="mt-[50px] rounded-[8px] py-[10px] px-[18px] bg-gradient-to-r from-[#FBBC5E] to-[#F3A151] text-[#000] text-[16px] font-semibold"
              >
                Go Back
              </button>
            </div>
          )}
          {isReportedAlready && (
            <div className="__reportFlag__success__section flex flex-col mt-[55px] justify-center items-center">
              <img
                src={WarningTickIcon}
                alt="success__flag"
                className="w-[75px] h-[75px] max-[768px]:w-[55px] max-[768px]:h-[55px]"
              />
              <h4 className="text-[16px] max-[768px]:text-[13px] font-heading pt-[40px] text-center">
                Thankyou, you have already reported for this content.
              </h4>
              <span className="text-[16px] max-[768px]:text-[13px] font-normal font-body text-center pt-[27px]">
                Report already submitted. Thank you for helping us maintain a
                safe environment.
              </span>
              <button
                onClick={handleModalStatus}
                className="mt-[50px] rounded-[8px] py-[10px] px-[18px] bg-gradient-to-r from-[#FBBC5E] to-[#F3A151] text-[#000] text-[16px] font-semibold"
              >
                Go Back
              </button>
            </div>
          )}
          {!isReportError &&
            !isReportedAlready &&
            (isReportSuccess ? (
              <div className="__reportFlag__success__section flex flex-col justify-center items-center">
                <img
                  src={SuccessTickIcon}
                  alt="success__flag"
                  className="w-[75px] h-[75px] max-[768px]:w-[55px] max-[768px]:h-[55px]"
                />
                <h4 className="text-[16px] max-[768px]:text-[13px] font-heading pt-[40px] text-center">
                  Thankyou, We received your report.
                </h4>
                <span className="text-[16px] max-[768px]:text-[13px] font-normal font-body text-center pt-[27px]">
                  Report submitted. Thank you for helping us maintain a safe
                  environment.
                </span>
                <button
                  onClick={handleModalStatus}
                  className="mt-[50px] rounded-[8px] py-[10px] px-[18px] bg-gradient-to-r from-[#FBBC5E] to-[#F3A151] text-[#000] text-[16px] font-semibold"
                >
                  Go Back
                </button>
              </div>
            ) : (
              <>
                <div className="__modal_close_icon_container flex justify-end ">
                  {/* <div onClick={handleModalStatus} className="bg-[#FFFFFF1A] hover:bg-[#FFFFFF4D] hover:cursor-pointer flex flex-col justify-center items-center rounded-[50%] w-[20px] h-[20px]">
                <img  
                  src={ModalCloseIcon}
                  className="w-[10px] h-[10px] "
                  alt="modal_close_btn"
                />
              </div> */}
                </div>
                <div
                  className={`px-4 max-h-[100%]`}
                >
                  <form onSubmit={onSubmitHandler}>
                    <div className="__modal__heading__main">
                      <h4 className="text-[16px] font-heading">Report Asset</h4>
                    </div>
                    {!localStorage.getItem("userInfo") && (
                      <div className="__USERINFO_CONT__ mt-[28px]">
                        <div className="flex justify-between">
                          <div className="w-[48%] flex flex-col gap-2">
                            <label
                              htmlFor="First Name"
                              className="text-[14px] font-semibold"
                            >
                              First Name
                            </label>
                            <input
                              className="py-2 px-[6px] bg-transparent border border-[#535353] rounded-[4px] placeholder:text-[12px] placeholder:text-white/20"
                              placeholder="John"
                              type="text"
                              name="First Name"
                              id="First Name"
                            />
                          </div>
                          <div className="w-[48%] flex flex-col gap-2">
                            <label
                              htmlFor="Last Name"
                              className="text-[14px] font-semibold"
                            >
                              Last Name
                            </label>
                            <input
                              className="py-2 px-[6px] bg-transparent border border-[#535353] rounded-[4px] placeholder:text-[12px] placeholder:text-white/20"
                              placeholder="Doe"
                              type="text"
                              name="Last Name"
                              id="Last Name"
                            />
                          </div>
                        </div>
                        <div className="mt-2 flex flex-col gap-2">
                          <label
                            htmlFor="Email ID"
                            className="text-[14px] font-semibold"
                          >
                            Email ID
                          </label>
                          <input
                            className="py-2 px-[6px] bg-transparent border border-[#535353] rounded-[4px] placeholder:text-[12px] placeholder:text-white/20"
                            placeholder="johndoe123@gmail.com"
                            type="text"
                            name="Email ID"
                            id="Email ID"
                          />
                        </div>
                      </div>
                    )}
                    <h6 className="text-[14px] font-body font-medium py-[17px]">
                      Select Report Type
                    </h6>
                    <div className="__radio__list__report__section">
                      {ReportListData?.map((reportItem, ind) => {
                        return (
                          <div
                            className={`${
                              ind === 0 ? "" : "pt-[19px] max-[768px]:pt-[12px]"
                            } flex gap-[8px]`}
                            key={ind + reportItem?.id}
                          >
                            <input
                              key={ind + reportItem?.id}
                              type="radio"
                              id={`report${ind}`}
                              name="report"
                              onChange={handleRadioChange}
                              value={reportItem.reportName}
                              required
                            />
                            <label
                              className="text-[14px] max-[768px]:text-[11px] font-body font-normal"
                              htmlFor={`report${ind}`}
                            >
                              {reportItem?.reportName}
                            </label>
                            <br />
                          </div>
                        );
                      })}
                    </div>
                    <div className="__extra__information__section pt-[34px]">
                      <h5 className="text-[14px] font-body font-medium">
                        Provide Additional Information
                      </h5>
                      <textarea
                        ref={textRef}
                        placeholder="Write specific details about the issue."
                        rows={8}
                        className="w-full px-6 py-[7px] min-h-[150px] font-normal rounded-[4px] mt-[17px] bg-transparent border border-[#535353] placeholder:text-[14px] placeholder:text-[#FFFFFF33]"
                      ></textarea>
                    </div>

                    <div className="flex justify-between gap-[28px] py-[42px] max-[768px]:py-[30px] max-[768px]:text-[12px] max-[768px]:[&>button]:py-[6px] [&>button]:w-[50%] [&>button]:rounded-[8px] [&>button]:py-[10px] [&>button]:font-semibold">
                      <button
                        onClick={handleModalStatus}
                        className="__cancel__btn border"
                      >
                        Cancel
                      </button>
                      <button
                        disabled={isSubmitActive ? "" : "disabled"}
                        // onClick={() => setReportSuccess(!isReportSuccess)}
                        className={`__submitreport__btn ${
                          isSubmitActive
                            ? "bg-gradient-to-r text-[#000] from-[#FBBC5E] to-[#F3A151]"
                            : "bg-[#7A7A7A] text-[#fff]"
                        } `}
                      >
                        Submit Report
                      </button>
                    </div>
                  </form>
                </div>
                {/* <div className="__down__arrow__section flex flex-col justify-center items-center">
                <img
                  src={DownArrowIcon}
                  alt="down__arrow__icon"
                  className="w-[50px] h-[20px]"
                />
                <span className="text-[12px]">Scroll down</span>
              </div> */}
              </>
            ))}
        </div>
      </PopupWrapper>
    </>
  );
};

export default AssetsReportModal;
