/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Gaming-Landing-Page
 *@developer Aditya P
 */

import React from "react";
import { Zoom } from "react-reveal";
import GamingBannerImg from "assets/images/Gaming hero-section-image.jpg";
import SliderCards from "components/partials/SliderCards";
import EnvironmentGamingImg from "assets/images/Environmental-Gaming.png";
import HoloSuiteImg from "assets/images/game_landingPage-1.png";
import { Link } from "react-router-dom";
import ShadowFrame from "components/shadow-frame";
import ImageConstant from "constant/imageConstant";

const TopGameData = [
  {
    id: 0,
    heading: "Galactic battles: Multiverse War",
    isComing: true,
  },
  {
    id: 0,
    heading: "Galactic battles: Multiverse War",
    isComing: true,
  },
  {
    id: 0,
    heading: "Galactic battles: Multiverse War",
    isComing: true,
  },
  {
    id: 0,
    heading: "Galactic battles: Multiverse War",
    isComing: true,
  },
  {
    id: 0,
    heading: "Galactic battles: Multiverse War",
    isComing: true,
  },
];

const Index = () => {
  return (
    <div
      data-testid="__gaming__landing__main"
      className="__gaming__landing__main px-2"
    >
      <Zoom>
        <div
          className="min-h-[200px] xl:min-h-[390px] bg-[#2D2D2D] h-full mb-10 md:mb-20 z-10 relative rounded bg-cover"
          style={{
            backgroundImage: `url('${ImageConstant.GAMING_BANNER_IMAGE}')`,
          }}
        >
          <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center p-4">
            <h1 className="font-heading text-xl md:text-2xl lg:text-3xl text-center bg-gradient-to-r from-[#FBBC5E] to-[#F3A151] bg-[length:100%_2px]  text-transparent bg-clip-text">
              Coming Soon
            </h1>
          </div>
        </div>
      </Zoom>
      <ShadowFrame className="w-[250px] md:w-[500px] h-[250px] md:h-[500px] rounded-[250px] md:rounded-[500px] -left-1/3 top-[350px] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.60]" />
      <ShadowFrame className="w-[250px] md:w-[500px] h-[250px] md:h-[500px] rounded-[250px] md:rounded-[500px] -right-1/3 top-[40%] !bg-[#FFE9C9] md:!bg-[#FFE9C9]/[0.60]" />
      {/* Game Category Content Section */}
      <div className="__holoSuite__gaming__container flex w-[90%] mx-auto justify-between pt-[50px] max-[668px]:pt-[30px] max-[668px]:flex-col max-[768px]:gap-[30px]">
        <div className="__holosuite__image__section w-[55%] max-[1280px]:w-max max-[768px]:w-[100%] pl-[30px] md:pl-0 relative">
          <div
            className="absolute top-1/2 left-1/2 w-[150%] h-[150%] -translate-x-1/2 -translate-y-1/2 bg-no-repeat bg- bg-center bg-reflection filter blur-lg brightness-125 saturate-150 opacity-10 z-10"
            style={{
              backgroundImage: `url(${HoloSuiteImg})`,
            }}
          ></div>
          <img
            src={HoloSuiteImg}
            alt="environ_game_img"
            className="w-full h-full object-cover z-20"
          />
        </div>
        <div className="__holosuite__content__section flex flex-col justify-start pl-[30px] w-[40%] max-[768px]:w-[100%] max-[1280px]:w-[50%]">
          <div className="__content__heading">
            <h4 className="font-heading text-[34px] leading-10 max-[768px]:text-[24px] max-[1280px]:text-[24px] max-[768px]:leading-7">
              <span>Holosuite Gaming: </span>
              <span>Gaming Inside </span>
              <span>Apartment and Ships</span>
            </h4>
          </div>
          <div className="__content__description py-[40px] font-light w-[70%] max-[768px]:w-[100%] max-[1280px]:w-[100%]">
            <p>
              With a wide range of games and adventures awaiting you, get ready
              to level up your gaming experience. whether you're exploring
              virtual apartments or commanding powerful ships.
            </p>
          </div>
          {/* <div className="__content__explore__link">
            <Link
              to="/marketplace/gaming/holo-suite-gaming"
              className="underline underline-offset-4 text-[16px] text-[#F3A151] font-body"
            >
              Explore Holosuite Gaming
            </Link>
          </div> */}
        </div>
      </div>

      <div className="__holoSuite__gaming__container flex w-[90%] mx-auto justify-between pt-[300px] max-[668px]:pt-[30px] max-[668px]:flex-col max-[768px]:gap-[30px]">
        <div className="__holosuite__content__section flex flex-col justify-start w-[40%] pl-[30px] lg:pl-0  max-[768px]:w-[100%]">
          <div className="__content__heading">
            <h4 className="font-heading text-[34px] leading-10 max-[768px]:text-[24px] max-[1280px]:text-[24px] max-[768px]:leading-7">
              Environmental Gaming
            </h4>
          </div>
          <div className="__content__description py-[40px] font-light w-[70%] max-[768px]:w-[100%] max-[1280px]:w-[100%]">
            <p>
              Unleash your gaming prowess in breathtaking environments. Conquer
              challenges, battle foes, and create unforgettable memories. Coming
              soon, prepare for immersive adventures beyond imagination.
            </p>
          </div>
          {/* <div className="__content__explore__link">
            <Link
              to="/marketplace/gaming/environmental-gaming"
              className="underline underline-offset-4 text-[16px] text-[#F3A151] font-body"
            >
              Explore Envrionmental Gaming
            </Link>
          </div> */}
        </div>

        <div className="__holosuite__image__section w-[55%] max-[1280px]:w-max max-[768px]:w-[100%] pr-[30px] md:pr-0 relative">
          <div
            className="absolute top-1/2 left-1/2 w-[150%] h-[150%] -translate-x-1/2 -translate-y-1/2 bg-no-repeat bg- bg-center bg-reflection filter blur-lg brightness-125 saturate-150 opacity-10 z-10"
            style={{
              backgroundImage: `url(${EnvironmentGamingImg})`,
            }}
          ></div>
          <img
            src={EnvironmentGamingImg}
            alt="environ_game_img"
            className="w-full h-full left-0 object-cover z-20"
          />
        </div>
      </div>

      {/* Top Games Section */}
      {/* <div className="__top_games__slider__container pt-[200px] max-[768px]:pt-20 max-[1280px]:pt-16">
        <div className="__slider__header flex justify-between">
          <h4 className="font-heading text-[24px] max-[768px]:text-[15px]">
            Top Pick Games for you
          </h4>
          <Link to="/marketplace/gaming/all">
            <span className="font-body max-[768px]:text-[14px] text-[#F3A151] font-medium hover:cursor-pointer hover:underline underline-offset-4">
              See more
            </span>
          </Link>
        </div>
        <div className="__slider_card__container flex gap-[20px] overflow-x-scroll py-[30px]">
          {TopGameData.map((data) => {
            return <SliderCards item={data} id="top-game" />;
          })}
        </div>
      </div> */}
    </div>
  );
};

export default Index;
