/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Premium
 *@developer Sudhanshu
 */

import React from "react";
import CoinIcon from "assets/images/coin-icon.png";
import CoinStarIcon from "assets/images/coin_star.png";
import { convertUsdToFstrPrice } from "utils";
import { convertUsdToMarketFstrUsd } from "utils";
import { getFstrDiscount, removeTrailingZeroes } from "utils";
import DummyCart from "assets/images/dummy-cart.png";
import { useLocation, useNavigate } from "react-router-dom";

const Premium = ({ title, price, plan = "Month", handleData, justAdded,  isLocked}) => {
  const handleClick = () => {
    handleData("Premium");
  };
  const navigate = useNavigate();
  const location = useLocation();

  let sanitizedPrice = price?.replace(/[^0-9.]/g, "");

  // if more than one decimal is present
  if (sanitizedPrice.split(".").length > 2) {
    sanitizedPrice =
      sanitizedPrice.split(".")[0] + "." + sanitizedPrice.split(".")[1];
  }

  return justAdded ? (
    <button
      type="button"
      className="font-bold rounded-lg  bg-[#FBBC5E0D]  bg-opacity-5 justify-center w-full py-4 px-5 flex gap-2 mt-5 items-center  text-[#25B39E]"
    >
      <svg
        className="stroke-[#25B39E]"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M21.5 6.99984L9.5 18.9998L4 13.4998L5.41 12.0898L9.5 16.1698L20.09 5.58984L21.5 6.99984Z"
          fill="currentColor"
        />
      </svg>
      You've already subscribed this channel.
    </button>
  ) : (
    <>
      <h4
        className="text-white text-base mb-0"
        dangerouslySetInnerHTML={{ __html: title }}
      ></h4>
      <p className="mt-[25px] text-[#FFFFFF66] text-[14px] font-bold leading-[17.07px] font-body">
        Monthly Subscription:
      </p>
      <div className="mt-3 mb-3 text-[20px] leading-[30px] font-bold">
        <img
          src={CoinStarIcon}
          className="inline h-[38px] w-[38px] object-cover rounded-full"
          alt="coin_icon"
        />
        <span className="text-[#FBBC5E] pl-2">{sanitizedPrice} USD | </span>
        <span className="text-[#FFDF6E]">
          {sanitizedPrice &&
            removeTrailingZeroes(convertUsdToFstrPrice(sanitizedPrice))}{" "}
          FSTR{" "}
          <span className="text-[16px] leading-[24px]">
            (≈{" "}
            {sanitizedPrice &&
              removeTrailingZeroes(
                convertUsdToMarketFstrUsd(sanitizedPrice)
              )}{" "}
            USD)
          </span>
        </span>
      </div>
      <div className="mt-5 mb-5 bg-[#4d4232] p-4 rounded-[8px]">
        <p className="text-[#FFCE1F] font-bold">
          Purchase in FSTR to receive a DISCOUNT of {getFstrDiscount() || "NA"}%
        </p>
        <p className="mt-2">
          Select FSTR at checkout to make your purchase and unlock a special
          discount.{" "}
        </p>
      </div>
      {isLocked ? (
        <div className="relative">
          <img src={DummyCart} className="blur w-full" alt="" />
          <div
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-white font-bold w-full flex justify-center items-center"
            style={{
              zIndex: 10, // Ensures the text is on top of the image
            }}
          >
            <button
              type="button"
              className="flex font-bold text-sm md:text-base rounded-lg btn-gradient hover:text-white w-auto text-black py-3 px-5 mt-3 items-center"
              onClick={() =>
                navigate(`/signin?ref=${location.pathname}`, {
                  replace: true,
                })
              }
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className="mb-1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.7734 10.9092C11.3506 10.9092 11.0078 10.5664 11.0078 10.1436V5.6853C11.0078 4.18271 9.78534 2.9602 8.28271 2.9602C6.78012 2.9602 5.55762 4.18267 5.55762 5.6853V8.27833C5.55762 8.70117 5.21484 9.04395 4.79199 9.04395C4.36915 9.04395 4.02637 8.70117 4.02637 8.27833V5.6853C4.02637 3.33833 5.93574 1.42896 8.28271 1.42896C10.6297 1.42896 12.5391 3.33833 12.5391 5.6853V10.1436C12.5391 10.5664 12.1963 10.9092 11.7734 10.9092Z"
                  fill="currentColor"
                />
                <path
                  d="M12.9411 15.5455H3.62432C2.75447 15.5455 2.04932 14.8404 2.04932 13.9705V8.58403C2.04932 7.71419 2.75447 7.00903 3.62432 7.00903H12.9411C13.811 7.00903 14.5161 7.71419 14.5161 8.58403V13.9705C14.5161 14.8404 13.811 15.5455 12.9411 15.5455Z"
                  fill="currentColor"
                />
                <path
                  d="M3.41587 14.2762H3.33696C3.08843 14.2762 2.88696 14.0747 2.88696 13.8262V8.72832C2.88696 8.47979 3.08843 8.27832 3.33696 8.27832H3.41587C3.6644 8.27832 3.86587 8.47979 3.86587 8.72832V13.8262C3.86587 14.0747 3.6644 14.2762 3.41587 14.2762Z"
                  fill="currentColor"
                />
              </svg>
              &nbsp; Sign In To Continue
            </button>
          </div>
        </div>
      ) : (
        <button
          type="button"
          onClick={handleClick}
          className="font-bold rounded-lg btn-gradient w-full text-black py-3 px-5 mt-5 flex justify-center gap-2 items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M6 22C5.45 22 4.979 21.804 4.587 21.412C4.195 21.02 3.99934 20.5493 4 20V10C4 9.45 4.196 8.979 4.588 8.587C4.98 8.195 5.45067 7.99933 6 8H7V6C7 4.61667 7.48767 3.43733 8.463 2.462C9.43834 1.48667 10.6173 0.999334 12 1C13.3833 1 14.5627 1.48767 15.538 2.463C16.5133 3.43833 17.0007 4.61733 17 6V8H18C18.55 8 19.021 8.196 19.413 8.588C19.805 8.98 20.0007 9.45067 20 10V20C20 20.55 19.804 21.021 19.412 21.413C19.02 21.805 18.5493 22.0007 18 22H6ZM6 20H18V10H6V20ZM12 17C12.55 17 13.021 16.804 13.413 16.412C13.805 16.02 14.0007 15.5493 14 15C14 14.45 13.804 13.979 13.412 13.587C13.02 13.195 12.5493 12.9993 12 13C11.45 13 10.979 13.196 10.587 13.588C10.195 13.98 9.99934 14.4507 10 15C10 15.55 10.196 16.021 10.588 16.413C10.98 16.805 11.4507 17.0007 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
              fill="black"
            />
          </svg>
          {/* Unlock Premium @{price}/{plan} */}
          Subscribe now
        </button>
      )}
    </>
  );
};

export default Premium;
