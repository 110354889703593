import Alert from "components/popup/Alert/Alert";
import React from "react";
import { useNavigate } from "react-router-dom";

const Failed = () => {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen">
      {" "}
      <Alert
        open={true}
        canClose={false}
        heading="Failed"
        message="Transaction Failed"
        buttonText="Go To Marketplace"
        onClose={() => navigate("/marketplace")}
        isSuccess={false}
      />
    </div>
  );
};

export default Failed;
