/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Events-Main-Page
 *@developer Shubham K
 */

import Slider from "react-slick";
import CarouselCard from "./components/carouselcard/CarouselCard";
import { useNavigate, useParams } from "react-router-dom";
import All from "./list/All";
import ArtCulture from "./list/ArtCulture";
import Education from "./list/Education";
import Gaming from "./list/Gaming";
import DanceMusic from "./list/DanceMusic";
import Party from "./list/Party";
import Concerts from "./list/Concerts";
import LiveQuest from "./list/LiveQuest";
import Adventure from "./list/Adventure";
import Nature from "./list/Nature";

import { useEffect, useState } from "react";
import { useGetTodayEventsListQuery } from "store/slicer/Events";
import dateFormat from "dateformat";

import CarouselImage1 from "assets/images/carousel_event_1.png";
import CarouselImage2 from "assets/images/carousel_event_2.png";
import Loader from "components/Loader/Loader";

const Events = () => {
  const navigate = useNavigate();

  let {
    data: eventsData,
    isFetching,
    isSuccess: IsDataAvailable,
    isError,
  } = useGetTodayEventsListQuery();

  if (!isFetching && IsDataAvailable && eventsData.response.statusEnum == 1) {
    eventsData = eventsData.response.data.data.map((item) => {
      let eventType = "Volumetric";
      if (item.venueType?.includes("360")) {
        eventType = "360";
      }
      if (item.venueType?.includes("180")) {
        eventType = "180";
      }
      if (item.venueType?.includes("2d")) {
        eventType = "2D";
      }
      if (item.venueType?.includes("music")) {
        eventType = "Music";
      }
      return {
        ...item,
        id: item._id,
        type: eventType,
        title: item.name,
        channelName: item.creatorDetails.channelName,
        dateAndTime: dateFormat(item.startTime, "mmmm dd, yyyy, h:MM TT"),
        startTime: item.startTime,
        location: item.venueName,
        price: item.priceFiat,
        imgUrl: item.promoBanner,
      };
    });
  } else {
    eventsData = [];
    isError = true;
  }

  const dummyCarouselData = [
    {
      id: 1,
      type: "Volumetric",
      title: "Cave Culinary Quest: 360 Culinary Adventure",
      channelName: "Stellar Sounds Entertainment",
      dateAndTime: "November 12, 2024, 8:30 PM GMT + 5.3",
      eta: "00D: 12H: 45M: 45S",
      location: "360 Lounge",
      price: "48.29",
      imgUrl: CarouselImage1,
    },
    {
      id: 2,
      type: "180",
      title: "Cave Culinary Quest: 360 Culinary Adventure",
      channelName: "Stellar Sounds Entertainment",
      dateAndTime: "November 12, 2024, 8:30 PM GMT + 5.3",
      eta: "00D: 12H: 45M: 45S",
      location: "180 Lounge",
      price: "48.29",
      imgUrl: CarouselImage2,
    },
    {
      id: 3,
      type: "360",
      title: "Cave Culinary Quest: 360 Culinary Adventure",
      channelName: "Stellar Sounds Entertainment",
      dateAndTime: "November 12, 2024, 8:30 PM GMT + 5.3",
      eta: "00D: 12H: 45M: 45S",
      location: "360 Lounge",
      price: "48.29",
      imgUrl: CarouselImage1,
    },
    {
      id: 4,
      type: "Volumetric",
      title: "Cave Culinary Quest: 360 Culinary Adventure",
      channelName: "Stellar Sounds Entertainment",
      dateAndTime: "November 12, 2024, 8:30 PM GMT + 5.3",
      eta: "00D: 12H: 45M: 45S",
      location: "360 Lounge",
      price: "48.29",
      imgUrl: CarouselImage2,
    },
    {
      id: 5,
      type: "360",
      title: "Cave Culinary Quest: 360 Culinary Adventure",
      channelName: "Stellar Sounds Entertainment",
      dateAndTime: "November 12, 2024, 8:30 PM GMT + 5.3",
      eta: "00D: 12H: 45M: 45S",
      location: "360 Lounge",
      price: "48.29",
      imgUrl: CarouselImage1,
    },
  ];

  const sliderSettings = {
    slidesToShow: 1.71,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    swipeToSlide: true,
    // infinite: false,
    focusOnSelect: true,
    autoplay: true,
    cssEase: "ease-in",
    // arrows: false,
    responsive: [
      {
        breakpoint: 1507,
        settings: {
          slidesToShow: 1.5,
        },
      },
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 1.25,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1.15,
        },
      },
      {
        breakpoint: 913,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const myAssetsTabs = [
    {
      id: 0,
      parentId: 0,
      key: "all",
      name: "All",
      children: null,
      component: <All />,
    },
    {
      id: 1,
      parentId: 0,
      key: "art-culture",
      name: "Art & Culture",
      children: null,
      component: <ArtCulture />,
    },
    {
      id: 2,
      parentId: 0,
      key: "education",
      name: "Education",
      children: null,
      component: <Education />,
    },
    {
      id: 3,
      parentId: 0,
      key: "gaming",
      name: "Gaming",
      children: null,
      component: <Gaming />,
    },
    {
      id: 4,
      parentId: 0,
      key: "dance-music",
      name: "Dance & Music",
      children: null,
      component: <DanceMusic />,
    },
    {
      id: 5,
      parentId: 0,
      key: "party",
      name: "Party",
      children: null,
      component: <Party />,
    },
    {
      id: 6,
      parentId: 0,
      key: "concerts",
      name: "Concerts",
      children: null,
      component: <Concerts />,
    },
    {
      id: 7,
      parentId: 0,
      key: "live-quest",
      name: "Live Quest",
      children: null,
      component: <LiveQuest />,
    },
    {
      id: 8,
      parentId: 0,
      key: "nature",
      name: "Nature",
      children: null,
      component: <Nature />,
    },
    {
      id: 9,
      parentId: 0,
      key: "adventure",
      name: "Adventure",
      children: null,
      component: <Adventure />,
    },
  ];

  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        navigate("/marketplace/entertainment/events/all");
        break;
      case 1:
        navigate("/marketplace/entertainment/events/art-culture");
        break;
      case 2:
        navigate("/marketplace/entertainment/events/education");
        break;
      case 3:
        navigate("/marketplace/entertainment/events/gaming");
        break;
      case 4:
        navigate("/marketplace/entertainment/events/dance-music");
        break;
      case 5:
        navigate("/marketplace/entertainment/events/party");
        break;
      case 6:
        navigate("/marketplace/entertainment/events/concerts");
        break;
      case 7:
        navigate("/marketplace/entertainment/events/live-quest");
        break;
      case 8:
        navigate("/marketplace/entertainment/events/nature");
        break;
      case 9:
        navigate("/marketplace/entertainment/events/adventure");
        break;
      default:
        break;
    }
  };
  const { activeTab } = useParams();

  const [activeTabs, setActivetab] = useState(() => {
    const tab = myAssetsTabs.find((tab) => tab.key === activeTab);
    return tab ? tab : myAssetsTabs[0]; // Default to the first tab if activeTab is not found
  });

  return (
    <div className="">
      <div className="__back_btn_container__">
        <button
          onClick={() => navigate(-1)}
          className="inline-flex gap-2 text-white font-semibold text-xs md:text-sm leading-normal bg-white/[0.10] hover:bg-white/[0.30] hover:text-dark rounded-3xl py-1.5 px-1.5 pr-3 items-center  top-3 left-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M12.5 15L7.5 10L12.5 5"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Back</span>
        </button>
      </div>
      {!isFetching && eventsData && eventsData.length > 0 && (
        <div className="__CAROUSEL_CONTAINER__ mt-[22px]">
          <h3 className=" text-white/50 font-bold text-[16px] leading-[24px]">
            Today's Events
          </h3>
          <div className="__CAROUSEL__ mt-[12px] lg:pl-[10px]">
            <Slider
              {...sliderSettings}
              infinite={eventsData?.length > 1}
              className="carousel-section"
            >
              {eventsData.map((item, index) => {
                return <CarouselCard key={index} data={item} />;
              })}
            </Slider>
          </div>
        </div>
      )}
      {isFetching && (
        <div className="h-[400px] grid place-content-center">
          <Loader />
        </div>
      )}
      <div
        className={`__myassets_container_main  ${
          eventsData && eventsData?.length > 0 ? "mt-[40px]" : "mt-[22px]"
        } py-[35px] flex flex-col md:flex-row max-[768px]:flex-col max-[768px]:p-0 max-[1280px]:py-[25px]`}
      >
        <div className="__assets_category_panel w-[100%] md:w-[20%]  max-[768px]:w-auto max-[768px]:p-0 max-[1280px]:p-0">
          <h5 className="text-[grey] font-body mb-[10px] font-bold tracking-[1px] max-[768px]:hidden text-sm">
            Category
          </h5>
          <div className="volumetric-card-left-shadow "></div>
          <div className="overflow-hidden">
            <ul
              data-testid="tablist"
              className="overflow-y-scroll
            flex md:flex-col
                     px-[0px]
                     [&>li]:px-[12px] 
                     [&>li]:py-[10px] 
                     [&>li]:font-light 
                     [&>li]:font-body
                     [&>li]:my-[15px] 
                     hover:[&>li]:bg-[#FBBC5E1A]
                     hover:[&>li]:rounded-[5px]
                     hover:[&>li]:cursor-pointer
                     max-[768px]:overflow-x-scroll
                     max-[768px]:flex
                     max-[768px]:[&>li]:py-[5px]
                     max-[768px]:[&>li]:my-[10px]
                     max-[768px]:px-0
                     "
            >
              {myAssetsTabs?.map((tabs, index) => {
                return (
                  <li
                    role="tab"
                    key={tabs.key}
                    className={`mr-8 md:mr-[0] whitespace-nowrap hover:bg-[#FBBC5E4D] rounded-[5px] ${
                      activeTabs.key === tabs.key ? "!bg-[#FBBC5E4D]" : ""
                    }`}
                    onClick={() => {
                      setActivetab(tabs);
                      handleChange(tabs.key, index);
                    }}
                  >
                    {tabs.name}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <div className="__assets_tabs_container_panel w-[100%] md:w-[80%] sm:p-[10px] md:pl-[56px] xl:pl-[96px] max-[768px]:w-auto max-[768px]:h-auto xl:min-h-[768px] max-[768px]:mt-[18px]">
          {activeTabs.component}
        </div>
      </div>
    </div>
  );
};

export default Events;
