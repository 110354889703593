/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Global-Redux-Store
 *@developer Aditya P
 *@desc Handling Global state management for FourthStar Project
 */

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import UserAuthSlice from "./slicer/userAuth";
import { UserNotification } from "./slicer/notification";
import { UserProfile } from "./slicer/userProfile";
import {ApartmentSlice} from "./slicer/Apartments"
import { ShipSlice } from "./slicer/Ships";
import { CartSlice } from "./slicer/Cart";

// Only Public Data and User Authentication will be used under this Store
// Configure the middleware and dynamic reducer function name according to your API needs
export const GlobalStore = configureStore({
    reducer: {
        auth: UserAuthSlice,
        [UserNotification.reducerPath]: UserNotification.reducer,
        [UserProfile.reducerPath]: UserProfile.reducer,
        [ApartmentSlice.reducerPath]: ApartmentSlice.reducer,
        [ShipSlice.reducerPath]: ShipSlice.reducer,
        [CartSlice.reducerPath]: CartSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(UserNotification.middleware).concat(UserProfile.middleware).concat(ApartmentSlice.middleware).concat(ShipSlice.middleware).concat(CartSlice.middleware)
})

setupListeners(GlobalStore.dispatch)