import React from "react";
import "./privacy.css";
import { NavLink } from "react-router-dom";

const CookiesPolicy = () => (
  <div className="main-container">
    <div className="privacy-container">
      <h1 className="privacy-title-text">COOKIE POLICY</h1>
      <p className="privacy-text-desc">Last updated July 05, 2024</p>
      <p className="privacy-text-desc">
        This Cookie Policy explains how Fourth Star ("Company" "we" "us" and
        "our") uses cookies and similar technologies to recognize you when you
        visit our website at{" "}
        <NavLink
          to="https://www.fourthstar.com"
          target="_blank"
          className="ml-2 text-[#FBBC5E]"
        >
          https://www.fourthstar.com
        </NavLink>{" "}
        ("Website"). It explains what these technologies are and why we use them
        as well as your rights to control our use of them.
      </p>
      <p className="privacy-text-desc">
        In some cases we may use cookies to collect personal information or that
        becomes personal information if we combine it with other information.
      </p>
      <h2 className="privacy-sub-title">What are cookies?</h2>
      <p className="privacy-text-desc">
        Cookies are small data files that are placed on your computer or mobile
        device when you visit a website. Cookies are widely used by website
        owners in order to make their websites work or to work more efficiently
        as well as to provide reporting information.
      </p>
      <p className="privacy-text-desc">
        Cookies set by the website owner (in this case Fourth Star) are called
        "first-party cookies." Cookies set by parties other than the website
        owner are called "third-party cookies." Third-party cookies enable
        third-party features or functionality to be provided on or through the
        website (e.g. advertising interactive content and analytics). The
        parties that set these third-party cookies can recognize your computer
        both when it visits the website in question and also when it visits
        certain other websites.
      </p>
      <h2 className="privacy-sub-title">Why do we use cookies?</h2>
      <p className="privacy-text-desc">
        We use first- and third-party cookies for several reasons. Some cookies
        are required for technical reasons in order for our Website to operate
        and we refer to these as "essential" or "strictly necessary" cookies.
        Other cookies also enable us to track and target the interests of our
        users to enhance the experience on our Online Properties. Third parties
        serve cookies through our Website for advertising analytics and other
        purposes. This is described in more detail below.
      </p>
      <h2 className="privacy-sub-title">How can I control cookies?</h2>
      <p className="privacy-text-desc">
        You have the right to decide whether to accept or reject cookies. You
        can exercise your cookie rights by setting your preferences in the
        Cookie Consent Manager. The Cookie Consent Manager allows you to select
        which categories of cookies you accept or reject. Essential cookies
        cannot be rejected as they are strictly necessary to provide you with
        services.
      </p>
      <p className="privacy-text-desc">
        The Cookie Consent Manager can be found in the notification banner and
        on our website. If you choose to reject cookies you may still use our
        website though your access to some functionality and areas of our
        website may be restricted. You may also set or amend your web browser
        controls to accept or refuse cookies.
      </p>
      <p className="privacy-text-desc">
        The specific types of first- and third-party cookies served through our
        Website and the purposes they perform are described in the table below
        (please note that the specific cookies served may vary depending on the
        specific Online Properties you visit):
      </p>
      <h2 className="privacy-sub-title">
        Performance and functionality cookies:
      </h2>
      These cookies are used to enhance the performance and functionality of our
      Website but are non-essential to their use. However, without these cookies
      certain functionality (like videos) may become unavailable.
      <ul className="privacy-text-desc border py-10">
        <li>Name: NRBA_SESSION</li>
        <li>Purpose: Tracks the user's session for YouTube</li>
        <li>Provider: www.fourthstar.com</li>
        <li>Service: YouTube View Service Privacy Policy</li>
        <li>Type: html_local_storage</li>
        <li>Expires in: Persistent</li>
      </ul>
      <h2 className="privacy-sub-title">
        Analytics and customization cookies:
      </h2>
      These cookies collect information that is used either in aggregate form to
      help us understand how our Website is being used or how effective our
      marketing campaigns are or to help us customize our Website for you.
      <ul className="privacy-text-desc border py-10">
        <li>Name: _ga</li>
        <li>
          Purpose: Records a particular ID used to come up with data about
          website usage by the user
        </li>
        <li>Provider: .fourthstar.com</li>
        <li>Service: Google Analytics View Service Privacy Policy</li>
        <li>Type: http_cookie</li>
        <li>Expires in: 1 year 1 month 4 days</li>
      </ul>
      <ul className="privacy-text-desc border py-10">
        <li>Name: blobs</li>
        <li>Provider: bam.nr-data.net</li>
        <li>Type: pixel_tracker</li>
        <li>Expires in: session</li>
      </ul>
      <ul className="privacy-text-desc border py-10">
        <li>Name: NRJS-8b3063b939449777cc7</li>
        <li>Provider: bam.nr-data.net</li>
        <li>Type: pixel_tracker</li>
        <li>Expires in: session</li>
      </ul>
      <ul className="privacy-text-desc border py-10">
        <li>Name: _ga_#</li>
        <li>
          Purpose: Used to distinguish individual users by means of designation
          of a randomly generated number as a client identifier which allows
          calculation of visits and sessions
        </li>
        <li>Provider: .fourthstar.com</li>
        <li>Service: Google Analytics View Service Privacy Policy</li>
        <li>Type: http_cookie</li>
        <li>Expires in: 1 year 1 month 4 days</li>
      </ul>
      <h2 className="privacy-sub-title">Unclassified cookies:</h2>
      These are cookies that have not yet been categorized. We are in the
      process of classifying these cookies with the help of their providers.
      <ul className="privacy-text-desc  border py-10">
        <li>Name: USDMARKETFSTRKEY</li>
        <li>Provider: www.fourthstar.com</li>
        <li>Type: html_session_storage</li>
        <li>Expires in: session</li>
      </ul>
      <ul className="privacy-text-desc border py-10">
        <li>Name: FSTRDISCOUNT</li>
        <li>Provider: www.fourthstar.com</li>
        <li>Type: html_session_storage</li>
        <li>Expires in: session</li>
      </ul>
      <ul className="privacy-text-desc border py-10">
        <li>Name: USDPLATFORMFSTRKEY</li>
        <li>Provider: www.fourthstar.com</li>
        <li>Type: html_session_storage</li>
        <li>Expires in: session</li>
      </ul>
      <h2 className="privacy-sub-title">
        How can I control cookies on my browser?
      </h2>
      <p className="privacy-text-desc">
        As the means by which you can refuse cookies through your web browser
        controls vary from browser to browser you should visit your browser's
        help menu for more information. The following is information about how
        to manage cookies on the most popular browsers:
      </p>
      <ul className="privacy-text-desc ulCustom">
        <li>Chrome</li>
        <li>Internet Explorer</li>
        <li>Firefox</li>
        <li>Safari</li>
        <li>Edge</li>
        <li>Opera</li>
      </ul>
      <p className="privacy-text-desc">
        In addition most advertising networks offer you a way to opt out of
        targeted advertising. If you would like to find out more information
        please visit:
      </p>
      <ul className="privacy-text-desc ulCustom">
        <li>Digital Advertising Alliance</li>
        <li>Digital Advertising Alliance of Canada</li>
        <li>European Interactive Digital Advertising Alliance</li>
      </ul>
      <h2 className="privacy-sub-title">
        What about other tracking technologies like web beacons?
      </h2>
      <p className="privacy-text-desc">
        Cookies are not the only way to recognize or track visitors to a
        website. We may use other similar technologies from time to time like
        web beacons (sometimes called "tracking pixels" or "clear gifs"). These
        are tiny graphics files that contain a unique identifier that enables us
        to recognize when someone has visited our Website or opened an email
        including them. This allows us for example to monitor the traffic
        patterns of users from one page within a website to another to deliver
        or communicate with cookies to understand whether you have come to the
        website from an online advertisement displayed on a third-party website
        to improve site performance and to measure the success of email
        marketing campaigns. In many instances these technologies are reliant on
        cookies to function properly and so declining cookies will impair their
        functioning.
      </p>
      <h2 className="privacy-sub-title">
        Do you use Flash cookies or Local Shared Objects?
      </h2>
      <p className="privacy-text-desc">
        Websites may also use so-called "Flash Cookies" (also known as Local
        Shared Objects or "LSOs") to among other things collect and store
        information about your use of our services fraud prevention and for
        other site operations.
      </p>
      <p className="privacy-text-desc">
        If you do not want Flash Cookies stored on your computer you can adjust
        the settings of your Flash player to block Flash Cookies storage using
        the tools contained in the Website Storage Settings Panel. You can also
        control Flash Cookies by going to the Global Storage Settings Panel and
        following the instructions (which may include instructions that explain
        for example how to delete existing Flash Cookies (referred to
        "information" on the Macromedia site) how to prevent Flash LSOs from
        being placed on your computer without your being asked and (for Flash
        Player 8 and later) how to block Flash Cookies that are not being
        delivered by the operator of the page you are on at the time).
      </p>
      <p className="privacy-text-desc">
        Please note that setting the Flash Player to restrict or limit
        acceptance of Flash Cookies may reduce or impede the functionality of
        some Flash applications including potentially Flash applications used in
        connection with our services or online content.
      </p>
      <h2 className="privacy-sub-title">Do you serve targeted advertising?</h2>
      <p className="privacy-text-desc">
        Third parties may serve cookies on your computer or mobile device to
        serve advertising through our Website. These companies may use
        information about your visits to this and other websites in order to
        provide relevant advertisements about goods and services that you may be
        interested in. They may also employ technology that is used to measure
        the effectiveness of advertisements. They can accomplish this by using
        cookies or web beacons to collect information about your visits to this
        and other sites in order to provide relevant advertisements about goods
        and services of potential interest to you. The information collected
        through this process does not enable us or them to identify your name
        contact details or other details that directly identify you unless you
        choose to provide these.
      </p>
      <h2 className="privacy-sub-title">
        How often will you update this Cookie Policy?
      </h2>
      <p className="privacy-text-desc">
        We may update this Cookie Policy from time to time in order to reflect
        for example changes to the cookies we use or for other operational legal
        or regulatory reasons. Please therefore revisit this Cookie Policy
        regularly to stay informed about our use of cookies and related
        technologies.
      </p>
      <p className="privacy-text-desc">
        The date at the top of this Cookie Policy indicates when it was last
        updated.
      </p>
      <h2 className="privacy-sub-title">
        Where can I get further information?
      </h2>
      <p className="privacy-text-desc">
        If you have any questions about our use of cookies or other technologies
        please email us at engineering@shadowcast.io or by post to:
      </p>
      <p className="privacy-text-desc">
        Fourth Star
        <br />
        Earth Headquarters
        <br />
        San Francisco, CA 94103
        <br />
        United States
        <br />
        Phone: 4154199590
        <br />
        engineering@shadowcast.io
      </p>
    </div>
  </div>
);

export default CookiesPolicy;
