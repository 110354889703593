import React from "react";
import ReportFlagIcon from "assets/images/flag_report.png";

const Flag = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="__report__flag__section
  hover:cursor-pointer 
  hover:border
  hover:border-[#FBBC5E]
  flex 
  flex-col 
  justify-center 
  items-center 
  rounded-[50%] 
  w-[30px] 
  h-[30px] 
  bg-[#FFFFFF1A]
  "
    >
      <img
        src={ReportFlagIcon}
        className="w-[15px] h-[15px]"
        alt="__report_flag"
      />
    </div>
  );
};

export default Flag;
