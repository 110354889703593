import { createAsyncThunk } from "@reduxjs/toolkit";
import webRequestHandler from "constant/api/apiInstance";
import packageInfo from "../../../../package.json";
import { BASE_URL, EndpointsSlug } from "constant/api/Endpoints";
const instance = webRequestHandler(BASE_URL);
export const userLogin = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const data = await instance.post(EndpointsSlug.LOGIN, {
        email,
        password,
        loginFrom: "web",
        version: packageInfo.version,
      });

      if (data?.response && data?.response?.statusEnum === 1) {
        // store user's token in local storage
        localStorage.setItem("accessToken", data?.response?.data?.accessToken);
        let userInfo = {
          ...(data?.response?.data?.user?._id && {
            id: data?.response?.data?.user?._id,
          }),
          ...(data?.response?.data?.user?.email && {
            email: data?.response?.data?.user?.email,
          }),
          ...(data?.response?.data?.user?.firstName && {
            firstName: data?.response?.data?.user?.firstName,
          }),
          ...(data?.response?.data?.user?.lastName && {
            lastName: data?.response?.data?.user?.lastName,
          }),
          ...(data?.response?.data?.user?.userName && {
            userName: data?.response?.data?.user?.userName,
          }),
          ...(data?.response?.data?.user?.gender && {
            gender: data?.response?.data?.user?.gender,
          }),
          ...(data?.response?.data?.user?.dob && {
            dob: data?.response?.data?.user?.dob,
          }),
          ...(data?.response?.data?.user?.statusFlag && {
            statusFlag: data?.response?.data?.user?.statusFlag,
          }),
          ...(data?.response?.data?.user?.isVerified && {
            isVerified: data?.response?.data?.user?.isVerified,
          }),
          ...(data?.response?.data?.user?.photo && {
            photo: data?.response?.data?.user?.photo,
          }),
        };
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        localStorage.setItem("email", email);
        return data?.response;
      } else {
        return rejectWithValue(data.response);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const googleSigin = createAsyncThunk(
  "auth/googlesigin",
  async ({ access_token }, { rejectWithValue }) => {
    try {
      const data = await instance.post(EndpointsSlug.GOOGLESIGIN, {
        access_token,
      });
      if (data?.response && data?.response?.statusEnum === 1) {
        // store user's token in local storage
        localStorage.setItem("accessToken", data?.response?.data?.accessToken);
        // let userInfo = {
        //   ...(data?.response?.data?.userData?._id && {
        //     id: data?.response?.data?.userData?._id,
        //   }),
        //   ...(data?.response?.data?.userData?.email && {
        //     email: data?.response?.data?.userData?.email,
        //   }),
        //   ...(data?.response?.data?.userData?.firstName && {
        //     firstName: data?.response?.data?.userData?.firstName,
        //   }),
        //   ...(data?.response?.data?.userData?.lastName && {
        //     lastName: data?.response?.data?.userData?.lastName,
        //   }),
        //   ...(data?.response?.data?.userData?.userName && {
        //     userName: data?.response?.data?.userData?.userName,
        //   }),
        //   ...(data?.response?.data?.userData?.gender && {
        //     gender: data?.response?.data?.userData?.gender,
        //   }),
        //   ...(data?.response?.data?.userData?.dob && {
        //     dob: data?.response?.data?.userData?.dob,
        //   }),
        //   ...(data?.response?.data?.userData?.statusFlag && {
        //     statusFlag: data?.response?.data?.userData?.statusFlag,
        //   }),
        //   ...(data?.response?.data?.userData?.isVerified && {
        //     isVerified: data?.response?.data?.userData?.isVerified,
        //   }),
        //   ...(data?.response?.data?.userData?.photo && {
        //     photo: data?.response?.data?.userData?.photo,
        //   }),
        // };
        let userInfo = data?.response?.data?.user;
        userInfo = {
          ...userInfo,
          id: userInfo?._id,
        };
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        localStorage.setItem("email", data?.response?.data?.user?.email);
        return data?.response;
      } else {
        return rejectWithValue(data.response);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const registerUser = createAsyncThunk(
  "auth/register",
  async ({ name, email, password, isPromotion }, { rejectWithValue }) => {
    try {
      const data = await instance.post(EndpointsSlug.SIGNUP, {
        name,
        email,
        password,
        isPromotion,
      });
      if (data?.response && data?.response?.statusEnum === 1) {
        localStorage.setItem("email", data?.response?.data?.email);
        return data?.response;
      } else {
        return rejectWithValue(data.response);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const verifyUser = createAsyncThunk(
  "auth/verify",
  async ({ email, otp }, { rejectWithValue }) => {
    try {
      const path = localStorage.getItem("isForgot")
        ? EndpointsSlug.VERIFY_FORGOT_OTP
        : EndpointsSlug.VERIFY_OTP;
      const data = await instance.post(path, { email, otp });
      if (data?.response && data?.response?.statusEnum === 1) {
        localStorage.removeItem("email");
        if (data?.response?.data?.accessToken) {
          localStorage.setItem(
            "accessToken",
            data?.response?.data?.accessToken
          );
        }
        if (data?.response?.data?.user) {
          let userInfo = {
            ...(data?.response?.data?.user?._id && {
              id: data?.response?.data?.user?._id,
            }),
            ...(data?.response?.data?.user?.email && {
              email: data?.response?.data?.user?.email,
            }),
            ...(data?.response?.data?.user?.firstName && {
              firstName: data?.response?.data?.user?.firstName,
            }),
            ...(data?.response?.data?.user?.lastName && {
              lastName: data?.response?.data?.user?.lastName,
            }),
            ...(data?.response?.data?.user?.userName && {
              userName: data?.response?.data?.user?.userName,
            }),
            ...(data?.response?.data?.user?.gender && {
              gender: data?.response?.data?.user?.gender,
            }),
            ...(data?.response?.data?.user?.dob && {
              dob: data?.response?.data?.user?.dob,
            }),
            ...(data?.response?.data?.user?.statusFlag && {
              statusFlag: data?.response?.data?.user?.statusFlag,
            }),
            ...(data?.response?.data?.user?.isVerified && {
              isVerified: data?.response?.data?.user?.isVerified,
            }),
          };
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
        }
        return data?.response;
      } else {
        return rejectWithValue(data.response);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgot",
  async ({ email }, { rejectWithValue }) => {
    try {
      const data = await instance.post(EndpointsSlug.FORGOT_PASSWORD, {
        email,
      });
      if (data?.response && data?.response?.statusEnum === 1) {
        localStorage.setItem("isForgot", email);
        return data?.response;
      } else {
        return rejectWithValue(data.response);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// export const resetPassword = createAsyncThunk(
//     'auth/reset',
//     async ({ email, password }, { rejectWithValue }) => {
//         try {
//             const data = await instance.post(EndpointsSlug.RESET_PASSWORD, {email, password});
//             if (data?.response && data?.response?.statusEnum === 1) {
//                 localStorage.removeItem('isForgot');
//                 return data?.response;
//             } else {
//                 return rejectWithValue(data.response)
//             }

//         } catch (error) {
//             if (error.response && error.response.data.message) {
//                 return rejectWithValue(error.response.data.message)
//             } else {
//                 return rejectWithValue(error.message)
//             }
//         }
//     }

export const resetPassword = createAsyncThunk(
  "auth/reset",
  async ({ passwordResetToken, password }, { rejectWithValue }) => {
    try {
      const data = await instance.post(EndpointsSlug.RESET_PASSWORD, {
        passwordResetToken,
        password,
      });
      if (data?.response && data?.response?.statusEnum === 1) {
        localStorage.removeItem("isForgot");
        return data?.response;
      } else {
        return rejectWithValue(data.response);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const verifyForgetPasswordLink = createAsyncThunk(
  "auth/verifyforgetpasswordlink",
  async ({ passwordResetToken }, { rejectWithValue }) => {
    try {
      const data = await instance.post(
        EndpointsSlug.VERIFY_FORGET_PASSWORD_LINK,
        { passwordResetToken }
      );
      if (data?.response && data?.response?.statusEnum === 1) {
        return data?.response;
      } else {
        return rejectWithValue(data.response);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
