/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Cart-Reducer-functions
 *@developer Aditya P
 */

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, EndpointsSlug } from "constant/api/Endpoints";
// Custom base query with error handling
import { baseQueryWithReauth } from "constant/api/apiInstance";

export const CartSlice = createApi({
  reducerPath: "cart",
  baseQuery: baseQueryWithReauth({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken") || "";
      headers.set("Content-type", "application/json; charset=UTF-8");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    GetCartItemByToken: builder.query({
      query: () => ({
        url: EndpointsSlug.GET_CART_ITEMS,
        method: "GET",
      }),
    }),
    GetStarCartItemByToken: builder.query({
      query: () => ({
        url: EndpointsSlug.GET_STAR_CART_ITEMS,
        method: "GET",
      }),
    }),
    UpdateCartItembyToken: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.CREATE_CART_ITEMS,
        method: "POST",
        body: {
          itemCategory: payload.category,
          itemId: payload.itemId,
          currencyType: payload.currencyType,
          itemThemeId: payload?.itemThemeId || null,
        },
      }),
    }),
    DeleteCartItemByToken: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.REMOVE_CART_ITEM,
        method: "DELETE",
        body: {
          cartItemId: payload.id,
        },
      }),
    }),
    GetCartItemsCount: builder.query({
      query: () => ({
        url: EndpointsSlug.GET_CART_ITEMS_COUNT,
        method: "GET",
      }),
      extraOptions: { showError: false },
    }),
  }),
});

export const {
  useGetCartItemByTokenQuery,
  useLazyGetCartItemByTokenQuery,
  useGetCartItemsCountQuery,
  useGetStarCartItemByTokenQuery,
  useUpdateCartItembyTokenMutation,
  useDeleteCartItemByTokenMutation,
} = CartSlice;
