import { NavLink } from "react-router-dom";

const content = [
  {
    title: "Ticket successfully raised",
    description: `Your ticket has been successfully submitted! Our team will review your
    request and get back to you as soon as possible. Thank you for
    contacting us!`,
  },
  {
    title: "Bug Reported Successfully",
    description: `Your bug report has been submitted successfully. Thank you for taking the time to report this issue. Our team will review the report and work to resolve the issue.`,
  },
  {
    title: "Feature Request Submitted",
    description: `Your feature request has been successfully submitted! Our team will review your suggestion and consider it for future updates. Thank you for helping us improve!`,
  },
];

const HelpCenterModal = ({ taskId, ticketId }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-[#00000099] bg-opacity-50">
      <div className="flex flex-col items-center gap-y-[20px] bg-[#242424] p-10 rounded-[8px] shadow-lg text-center w-[90%] md:w-[40%] 2xl:w-[35%]">
        <svg
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_539_17592)">
            <path
              d="M26 52C40.3594 52 52 40.3594 52 26C52 11.6406 40.3594 0 26 0C11.6406 0 0 11.6406 0 26C0 40.3594 11.6406 52 26 52Z"
              fill="#51AF4A"
            />
            <path
              d="M26.0006 11.9102C29.6016 11.9102 33.2026 13.286 35.9586 16.0398C37.2685 17.3468 38.3077 18.8993 39.0167 20.6084C39.7257 22.3175 40.0907 24.1497 40.0907 26C40.0907 27.8503 39.7257 29.6825 39.0167 31.3916C38.3077 33.1007 37.2685 34.6532 35.9586 35.9602C34.6517 37.27 33.0992 38.3092 31.3901 39.0182C29.681 39.7273 27.8488 40.0922 25.9985 40.0922C24.1481 40.0922 22.3159 39.7273 20.6068 39.0182C18.8977 38.3092 17.3453 37.27 16.0383 35.9602C14.7285 34.6532 13.6893 33.1007 12.9803 31.3916C12.2712 29.6825 11.9063 27.8503 11.9062 26C11.9063 24.1497 12.2712 22.3175 12.9803 20.6084C13.6893 18.8993 14.7285 17.3468 16.0383 16.0398C17.3449 14.729 18.8976 13.6894 20.6073 12.9807C22.317 12.2719 24.1499 11.9081 26.0006 11.9102ZM32.414 21.6645C32.2089 21.6841 32.0135 21.7615 31.8506 21.8877L23.9336 27.8243L20.2655 24.1583C19.4703 23.3307 17.9038 24.895 18.7336 25.6902L23.067 30.0235C23.2548 30.2011 23.4983 30.3083 23.7562 30.3269C24.0141 30.3455 24.2705 30.2742 24.4818 30.1253L33.1485 23.6253C33.8765 23.0945 33.428 21.6797 32.5266 21.6667C32.4898 21.6648 32.4529 21.6648 32.4161 21.6667L32.414 21.6645Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_539_17592">
              <rect width="52" height="52" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <p className="text-2xl font-semibold">{content[taskId]?.title}</p>
        <p>{content[taskId]?.description}</p>
        <p className="font-semibold text-[14px]">Ticket id: {ticketId}</p>
        <NavLink
          to="/"
          className="px-2 md:px-6 lg:px-8 xl:px-3 py-3 max-md:text-[12px] text-center font-bold rounded-lg text-black bg-gradient-to-r from-[#FBBC5E] to-[#F3A151] w-[60%] hover:from-[#DB9149] hover:to-[#DB9149]"
        >
          Return to Home Page
        </NavLink>
      </div>
    </div>
  );
};

export default HelpCenterModal;
