import React from 'react'
import AssetCardOpen from 'components/card/assets/AssetCardOpen'
import { GamingCardClass } from 'constant'
import Explore from 'components/Explore/Explore'

const AllGaming = () => {
  return <>
  <div className='absolute h-[50vh] w-full ml-auto grid place-content-center'>
    <div class="__explore_default_button flex justify-center items-center h-[50vh] max-[768px]:flex-none max-[1280px]:h-[100%]">
        <Explore />
      </div>
  </div>
  {/* <AssetCardOpen
  title="Galactic Warfare: Multiverse war"
  cardClass={GamingCardClass}
  baseRoute=""
  />
  <AssetCardOpen
  title="Galactic Warfare: Multiverse war"
  cardClass={GamingCardClass}
  baseRoute=""
  />
  <AssetCardOpen
  title="Galactic Warfare: Multiverse war"
  cardClass={GamingCardClass}
  baseRoute=""
  />
  <AssetCardOpen
  title="Galactic Warfare: Multiverse war"
  cardClass={GamingCardClass}
  baseRoute=""
  />
   <AssetCardOpen
  title="Galactic Warfare: Multiverse war"
  cardClass={GamingCardClass}
  baseRoute=""
  /> */}
</>

}

export default AllGaming