/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Avatar-item-component
 *@developer Shubham K
 */

import { useContext } from "react";
import { WardrobeContext } from "./WardrobeContextProvider";

import BgGreen from "assets/images/avt_gradient_green.png";
import BgSky from "assets/images/avt_gradient_sky.png";
import BgBlue from "assets/images/avt_gradient_blue.png";
import BgYellow from "assets/images/avt_gradient_yellow.png";
import avt_thumb_1 from "assets/images/avt_thumb_1.png";

const AvatarItem = ({
  equipped = false,
  img = avt_thumb_1,
  title,
  index = 0,
  isPreview = false,
  id,
}) => {
  index += 1;
  let bgImage;
  if (index % 4 == 0) {
    bgImage = BgSky;
  } else if (index % 2 == 0) {
    bgImage = BgBlue;
  } else if (index % 3 == 0) {
    bgImage = BgGreen;
  } else {
    bgImage = BgYellow;
  }

  const { selectItem } = useContext(WardrobeContext);

  return (
    <div
      className={`w-[107px]  hover:scale-110 transition-all overflow-hidden`}
      onClick={() => {
        selectItem("avatar", id);
      }}
    >
      <div
        className={`relative rounded-full h-[107px] cursor-pointer ${
          equipped || isPreview
            ? "border border-[#fbbc5e]"
            : "hover:border hover:border-[#fbbc5e]/50"
        }`}
        style={{
          backgroundImage: `url('${bgImage}')`,
        }}
      >
        {equipped && (
          <div className="absolute top-[5px] right-[5px] bg-[#fbbc5e] p-1 rounded-full">
            <svg
              width="12"
              height="10"
              viewBox="0 0 12 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.1805 2.01409L11.3462 1.84116L11.1805 1.66823L10.3748 0.827069L10.1944 0.638674L10.0138 0.826983L4.14272 6.95061L1.98604 4.70455L1.80549 4.51652L1.62517 4.70477L0.81946 5.54593L0.653816 5.71887L0.81946 5.8918L3.96232 9.17293L4.14286 9.36141L4.3234 9.17293L11.1805 2.01409Z"
                fill="black"
                stroke="black"
                strokeWidth="0.5"
              />
            </svg>
          </div>
        )}
        <div className="h-full w-full rounded-full bg-contain bg-center bg-no-repeat overflow-hidden">
          <img
            className="h-full w-full object-cover"
            src={img}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = avt_thumb_1;
            }}
          />
        </div>
      </div>
      <p
        className={`mt-1 text-[12px] text-center font-semibold ${
          equipped ? "text-[#fbbc5e]" : ""
        }`}
      >
        {title}
      </p>
    </div>
  );
};

export default AvatarItem;
