import React from "react";
import PopupWrapper from "../PopupWrapper";
import CrossIcon from "assets/images/red_cross.png";
import CheckIcon from "assets/images/check_icon.png";
import ErrorCrossIcon from "assets/images/alert_icon.png";

const Alert = ({
  open,
  onClose,
  canClose,
  heading,
  message,
  isSuccess = true,
  buttonText = "OK",
}) => {
  return (
    <PopupWrapper open={open} onClose={onClose} canClose={canClose}>
      <div className="pt-8 pb-4 px-12 rounded-lg bg-[#262626]">
        <div className="__ICON_CONTAINER__ grid place-content-center">
          {isSuccess ? (
            <div className="h-[70px] w-[70px] rounded-full">
              <img
                className="h-full w-full object-cover"
                src={CheckIcon}
                alt="processing"
              />
            </div>
          ) : (
            <div className="h-[70px] w-[70px] rounded-full">
              <img
                className="h-full w-full object-cover"
                src={ErrorCrossIcon}
                alt="processing"
              />
            </div>
          )}
        </div>
        {isSuccess && (
          <p className="__HEADING__ mt-8 text-xl font-heading text-center">
            {heading}
          </p>
        )}
        <p
          className={`mt-8 font-body text-center ${
            isSuccess ? "text-md" : "text-lg"
          }`}
        >
          {message}
        </p>
        <div className="__ACTION_BTN_CONT__">
          <button
            className={`mt-8 mx-auto font-bold rounded-lg btn-gradient text-black py-3 px-12 flex justify-center items-center`}
            type="button"
            onClick={onClose}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default Alert;
