/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module SingUp
 *@developer Sudhanshu <sudhanshut@instaacoders.com>
 */

import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import GoogleLogin from "components/buttons/Google";
import {
  isValidEmail,
  isStrongPassword,
  togglePasswordVisibility,
} from "utils/index";
import { isContainsSpecialChars, isContainsNumber } from "utils";

import eyeOff from "assets/images/eye-off.svg";
import CheckList from "components/password/checklist";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "store/slicer/userAuth/authActions";
import { clearMsgState } from "store/slicer/userAuth";
import Alert from "components/popup/Alert/Alert";
/* This code is a React component for user registration with the option to switch between "User" and "Creator" accounts. It performs real-time validation for name, email, and password, including password strength checks. Users can toggle the visibility of the password, and the form is enabled for submission when all requirements are met.
 */
const SignUp = () => {
  const [signUpType, setSignUpType] = useState("user");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [alertMessage, setAlertMessage] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [successAlert, setSuccessAlert] = useState(true);
  const [successBtnText, setSuccessBtnText] = useState("OK");

  const [isMobile, setIsMobile] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    terms: false,
    cookies: false,
    promotions: false,
  });

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [name]: checked,
    }));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // called when alert popup is closed
  const onCloseAlert = () => {
    if (successAlert) {
      navigate("/otp-verification");
      setFormData({
        name: "",
        email: "",
        password: "",
      });
    }
    dispatch(clearMsgState());

    setAlertMessage("");
    setSuccessAlert(true);
    setAlertHeading("");
    setSuccessBtnText("OK");
  };

  const isRequired = (value) => (value === "" ? false : true);
  // Function to toggle between user and creator sign-up
  const toggleSignUpType = () => {
    setSignUpType((prevSignUpType) =>
      prevSignUpType === "user" ? "creator" : "user"
    );
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Function to validate the form data
  const validateForm = () => {
    let isValid =
      checkName(formData.name) &&
      checkEmail(formData.email) &&
      checkPassword(formData.password);
    return isValid;
  };
  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to check if the form is valid
  const isFormValid = () => {
    return (
      formData.name &&
      !isContainsNumber(formData.name) &&
      !isContainsSpecialChars(formData.name) &&
      isValidEmail(formData.email) &&
      isStrongPassword(formData.password) &&
      checkboxes.terms &&
      checkboxes.cookies
    );
  };

  // api
  const { loading, userInfo, userToken, error, success, successMsg } =
    useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      setAlertMessage(error);
      setSuccessAlert(false);
    }
    if (userInfo && userToken) {
      navigate("/me");
    }
    // redirect user to otp verification if registration was successful
    if (success && successMsg) {
      setAlertMessage(successMsg);
      setAlertHeading("Account Created!");
      setSuccessAlert(true);
      setSuccessBtnText("Verify OTP");
      // setTimeout(() => {
      //   dispatch(clearMsgState());
      //   navigate("/otp-verification");
      //   setFormData({
      //     name: "",
      //     email: "",
      //     password: "",
      //   });
      // }, 1000);

      //
    }
  }, [navigate, userInfo, userToken, error, success, successMsg]);

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      formData.email = formData.email.toLowerCase();
      formData.isPromotion = checkboxes.promotions;
      dispatch(registerUser(formData));
    }
  };
  // checkName
  const checkName = (value) => {
    let valid = false;
    const fieldInput = value.trim();
    if (!isRequired(fieldInput)) {
      setFormErrors({
        ...formErrors,
        name: "Name is required",
      });
    } else if (isContainsNumber(value.trim())) {
      setFormErrors({
        ...formErrors,
        name: "Name must not contain any numbers",
      });
    } else if (isContainsSpecialChars(value.trim())) {
      setFormErrors({
        ...formErrors,
        name: "Name must not contain any special characters",
      });
    } else {
      setFormErrors({
        ...formErrors,
        name: "",
      });
      valid = true;
    }
    return valid;
  };

  // checkEmail
  const checkEmail = (value) => {
    let valid = false;
    const fieldInput = value.trim();
    if (!isRequired(fieldInput)) {
      setFormErrors({
        ...formErrors,
        email: "Email is required",
      });
    } else if (!isValidEmail(fieldInput)) {
      setFormErrors({
        ...formErrors,
        email: "Invalid Email Address",
      });
    } else {
      setFormErrors({
        ...formErrors,
        email: "",
      });
      valid = true;
    }
    return valid;
  };

  // checkPassword
  const checkPassword = (value) => {
    let valid = false;
    const fieldInput = value.trim();
    if (!isRequired(fieldInput)) {
      setFormErrors({
        ...formErrors,
        password: "Password is required",
      });
    }
    // else if (!isStrongPassword(fieldInput)) {
    //   setFormErrors({
    //     ...formErrors,
    //     password: 'Password is not strong enough'
    //   });
    // }
    else {
      setFormErrors({
        ...formErrors,
        password: "",
      });
      valid = true;
    }
    return valid;
  };

  return (
    <>
      <Box
        className={`w-full max-w-[700px] rounded-lg p-5 md:p-[32px] lg:p-[58px] ${
          isMobile ? "border border-[#363636] signin-form" : ""
        } `}
      >
        <Box className="text-white max-w-[500px] mx-auto">
          <h1 className="text-lg lg:text-xl text-center font-heading mb-[36px]">
            Create User Account
          </h1>

          <form className="auth-form mb-5" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="font-medium text-white/[.80] text-sm"
              >
                Name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="name"
                  className={`rounded-lg w-full bg-transparent border border-white focus:border-[#f6f6f6] font-normal py-3 px-5 leading-normal font-semibold outline-none ${
                    formErrors.name ? "!border-error" : ""
                  }`}
                  id="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  onBlur={(e) => checkName(e.target.value)}
                />
                {formErrors.name && (
                  <span className="text-error text-sm">{formErrors.name}</span>
                )}
              </div>
            </div>

            <div className="mb-4">
              <label
                htmlFor="email"
                className="font-medium text-white/[.80] text-sm"
              >
                Email
              </label>
              <div className="mt-2">
                <input
                  type="email"
                  name="email"
                  data-testid="email"
                  className={`rounded-lg w-full bg-transparent border border-white focus:border-[#f6f6f6] font-normal py-3 px-5 leading-normal font-semibold outline-none ${
                    formErrors.email ? "!border-error" : ""
                  }`}
                  id="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  onBlur={(e) => checkEmail(e.target.value)}
                />
                {formErrors.email && (
                  <span className="text-error text-sm">{formErrors.email}</span>
                )}
              </div>
            </div>

            <div className="mb-6">
              <label
                htmlFor="password"
                className="font-medium text-white/[.80] text-sm"
              >
                Password
              </label>
              <div className="mt-2 flex relative">
                <input
                  type="password"
                  name="password"
                  data-testid="Password"
                  className={`rounded-lg w-full bg-transparent border border-white focus:border-[#f6f6f6] font-normal py-3 px-5 leading-normal font-semibold outline-none pr-[40px] ${
                    formErrors.password ? "!border-error" : ""
                  }`}
                  id="password"
                  value={formData.password}
                  onInput={handleInputChange}
                  onBlur={(e) => checkPassword(e.target.value)}
                />
                <span
                  data-testid="PasswordVisibility"
                  className="vector absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer text-white"
                  onClick={(e) => togglePasswordVisibility(e)}
                >
                  <img src={eyeOff} alt="PasswordVisibility" />
                </span>
              </div>
              <CheckList value={formData.password} />
              {formErrors.password && (
                <span className="text-error text-sm">
                  {formErrors.password}
                </span>
              )}
            </div>
            <div className="mb-[30px] mt-[-30px]">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  name="terms"
                  className="form-checkbox h-4 w-4 accent-[#FBBC5E] border border-[#DCDCDC] rounded-md"
                  checked={checkboxes.terms}
                  onChange={handleCheckboxChange}
                />
                <span className="text-white text-[14px]">
                  I have read and agree to the{" "}
                  <NavLink
                    to="/terms-condition"
                    className="text-[#FBBC5E] underline "
                  >
                    Terms & Conditions{" "}
                  </NavLink>
                  and{" "}
                  <NavLink
                    to="/privacy-policy"
                    className="text-[#FBBC5E] underline "
                  >
                    Privacy Policy
                  </NavLink>
                  .
                </span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  name="cookies"
                  className="form-checkbox h-4 w-4 accent-[#FBBC5E] border border-[#DCDCDC] rounded-md"
                  checked={checkboxes.cookies}
                  onChange={handleCheckboxChange}
                />
                <span className="text-white text-[14px]">
                  I agree with the use of cookies for a better browsing
                  experience.
                </span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  name="promotions"
                  className="form-checkbox h-4 w-4 accent-[#FBBC5E] border border-[#DCDCDC] rounded-md"
                  checked={checkboxes.promotions}
                  onChange={handleCheckboxChange}
                />
                <span className="text-white text-[14px]">
                  I agree to receive Promotional and Marketing information.
                </span>
              </label>
            </div>

            <button
              type="submit"
              className="font-bold rounded-lg btn-gradient w-full text-black py-3 px-5 flex justify-center"
              disabled={!isFormValid() || loading}
            >
              {loading ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Processing...
                </>
              ) : (
                "Create my account"
              )}
            </button>
          </form>

          <div className="divider flex gap-x-2 mb-5">
            <span className="text-sm font-medium">Or</span>
          </div>

          <GoogleLogin
            type="button"
            text="Continue with Google"
            className="mb-6"
          />

          <p className="mb-5 text-sm text-center">
            Already have an account?{" "}
            <NavLink
              to="/signin"
              className="text-sec hover:text-secDark font-medium"
            >
              Sign In
            </NavLink>
          </p>

          <p className="text-center text-sm text-sec hover:text-secDark font-bold">
            <button onClick={toggleSignUpType}>
              {signUpType === "creator" ? (
                "Create User Account"
              ) : (
                <a
                  href={`${process.env.REACT_APP_CREATOR_WEB_URL}/auth/signup`}
                >
                  Become a Creator
                </a>
              )}
            </button>
          </p>
        </Box>
      </Box>
      {alertMessage && (
        <Alert
          open={Boolean(alertMessage)}
          onClose={onCloseAlert}
          message={alertMessage}
          heading={alertHeading}
          isSuccess={successAlert}
          buttonText={successBtnText}
          canClose={!successAlert}
        />
      )}
    </>
  );
};

export default SignUp;
