import React, { useState } from "react";
import SearchIcon from "assets/images/search_icon.png";
import FriendProfileCard from "components/card/friend/FriendProfileCard";
import { FriendSearchList } from "constant";
import FriendProfileModal from "components/Modal/InviteFriend/FriendProfileModal";
import { useGetUserFriendRequestQuery } from "store/slicer/friends";
import Loader from "components/Loader/Loader";
import InviteFriendModal from "components/Modal/InviteFriend/InviteFriendModal";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Your-Friend-Component
 *@developer Aditya P
 */

const YourFriend = () => {
  const { data, isLoading, error, status, refetch } =
    useGetUserFriendRequestQuery(
      { count: 1 },
      { refetchOnMountOrArgChange: true }
    );

  const [openProfile, setOpenProfile] = useState(false);
  const [userProfileData, setUserProfileData] = useState({});
  const [isInviteModal, setIsInviteModal] = useState(false);

  const handleOpenProfileModal = (userData) => {
    setUserProfileData(userData);
    setOpenProfile(!openProfile);
  };

  const handleIsInviteModal = () => {
    setIsInviteModal(!isInviteModal);
  };

  return (
    <div className="__yourFriend_container">
      <div className="__header_container_main flex max-[768px]:flex-col justify-between">
        <div className="w-[50%] max-[768px]:w-[100%]">
          <h4 className="font-heading text-[20px] max-[768px]:text-[15px]">
            Your Friends
          </h4>
        </div>
        {/* <div className="__friend_search_container w-[50%] relative max-[768px]:w-[100%] max-[768px]:mt-[15px]">
          <input
            className="w-[100%] bg-[#383838] border border-[#FFFFFF0D] rounded-[20px] px-[35px] py-[3px] text-[11px] outline-none focus:bg-[#fff] focus:text-[#000]"
            type="text"
            placeholder="Search"
            name="fr_search"
            onChange={() => {}}
          />
          <img
            src={SearchIcon}
            alt="search_ico"
            className="w-[14px] h-[14px] absolute top-[10px] left-[12px]"
          />
        </div> */}
      </div>

      {isLoading ? (
        <div className="__default_container mt-[20px] min-h-[300px] flex flex-col justify-center items-center">
          <div className="__no_friend_container flex flex-col text-center">
            <h4 className="text-[16px] max-[768px]:text-[14px] text-[#7A7A7A] font-body font-medium">
              <Loader />
            </h4>
          </div>
        </div>
      ) : data?.response.statusEnum === 1 ? (
        data?.response.data.length === 0 ? (
          <div className="__default_container mt-[20px] min-h-[300px] flex flex-col justify-center items-center">
            <div className="__no_friend_container flex flex-col text-center">
              <h4 className="text-[16px] max-[768px]:text-[14px] text-[#7A7A7A] font-body font-medium">
                You Don't have a friend yet
              </h4>
              <button
                onClick={handleIsInviteModal}
                className="w-[222px] 
                                   py-[6px] 
                                   border 
                                   border-[#F3A151] 
                                   mt-[20px] 
                                   rounded-[8px] 
                                   hover:bg-[#F3A151] 
                                   hover:text-[#000] 
                                   font-medium 
                                   font-body
                                   max-[768px]:py-[3px]
                                   max-[768px]:text-[13px]
                                   "
              >
                Invite friends
              </button>
            </div>
          </div>
        ) : (
          <div className="__friend_list_card_container flex flex-wrap justify-normal gap-[20px] mt-[20px] max-[1180px]:justify-evenly">
            {data?.response?.data?.map((f) => {
              return (
                <FriendProfileCard
                  key={f?.friend._id}
                  fkey="FRIEND"
                  h_card="215px"
                  userData={f?.friend}
                  handleModal={() => handleOpenProfileModal(f?.friend)}
                />
              );
            })}
          </div>
        )
      ) : (
        "There is some problem while fetching the friends! Try again"
      )}

      {openProfile ? (
        <FriendProfileModal
          handleModalStatus={handleOpenProfileModal}
          modalData={userProfileData}
          refresh={refetch}
        />
      ) : (
        ""
      )}

      {isInviteModal ? (
        <InviteFriendModal handleModalStatus={handleIsInviteModal} />
      ) : (
        ""
      )}
    </div>
  );
};

export default YourFriend;
