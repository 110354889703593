import React, { useEffect,useRef } from "react";
import { useState } from "react";
import { useGetUserPreferenceByTokenQuery } from "store/slicer/userProfile";
import Loader from "components/Loader/Loader";

const PreferenceList = ({list, changeInput, listStateFunction}) => {

  const {data, isLoading, error, refetch} = useGetUserPreferenceByTokenQuery({ count: 1 },
    { refetchOnMountOrArgChange: true })
  const checkInputRef = useRef([])

  useEffect(() => {
    if(data){
      listStateFunction(() => {
        return data?.response?.data?.preference
      })
    }
  },[data])

  return (
    isLoading ? <div className="py-[30px]"><Loader/></div> : <div className="__checkboxes__list__container py-[48px] grid grid-cols-2 gap-x-[200px] max-[768px]:gap-x-[50px] max-[768px]:py-[30px] max-[1980px]:gap-x-[100px] gap-y-[8px]">
    {list.map((__list, ind) => {
      return (
        <div key={ind} className={`__checkbox_${ind}`}>
          <input
          ref={checkInputRef}
          // checked={defaultList[__list?.key] === 1 ? "checked" : ""}
            // __list?.key === data?.response?.data?.preference[__list?.key] ? check : ''
            name={__list?.key}
            onChange={(e) => changeInput(e)}
            type="checkbox"
            defaultChecked={(data?.response?.data?.preference[__list?.key] === 1) ? "checked" : ""}
            id={`checkbox${ind}`}
            className="
      w-5 h-5
      max-[768px]:w-4
      max-[768px]:h-4
      relative
      bottom-[1px]
      align-middle
      accent-[#FBBC5E]
      "
          />
          <label
            htmlFor="checkbox0"
            className="text-[15px] font-medium font-body pl-[12px] max-[768px]:text-[12px] max-[768px]:pl-[6px]"
          >
            {__list?.name}
          </label>
        </div>
      );
    })}
  </div>
  );
};

export default PreferenceList;
