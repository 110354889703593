import Alert from "components/popup/Alert/Alert";
import React from "react";
import { useNavigate } from "react-router-dom";

const AlreadyInAsset = () => {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen">
      {" "}
      <Alert
        open={true}
        canClose={false}
        heading="Failed"
        message="Already in Assets"
        buttonText="Go To My Assets"
        onClose={() => navigate("/my-assets")}
        isSuccess={false}
      />
    </div>
  );
};

export default AlreadyInAsset;
