/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module user
 *@developer Shubham K <shubhamkinstaacoders@gmail.com>
 */

import React from "react";
import PopupWrapper from "../PopupWrapper";

const ConfirmCancel = ({ open, onClose, canClose, cancelBtnHandler, isLoading }) => {
  return (
    <PopupWrapper open={open} onClose={onClose} canClose={canClose}>
      <div className="relative bg-[#2c2c2c] rounded-[8px] py-4 px-4">
        <div
          className="absolute top-1 right-1 bg-white/10 w-[28px] h-[28px] rounded-full grid place-content-center cursor-pointer"
          onClick={onClose}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6875 1.3125L1.3125 12.6875M1.3125 1.3125L12.6875 12.6875"
              stroke="white"
              strokeOpacity="0.5"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <h1 className="font-heading font-normal text-[20px] leading-[25px] text-white">
          Cancel Subscription
        </h1>
        <p className="mt-8 font-body font-normal text-[16px] leading-[24px]">
          Are you sure you want to cancel your subscription? This action cannot
          be undone. Your subscription will remain active until the end of the
          current billing period.
        </p>
        <p className="mt-4 font-body font-normal text-[16px] leading-[24px]">
          Auto-debit will stop, and your subscription will become inactive.
        </p>
        <div className="mt-8 flex items-center justify-between">
          <button
            className="w-[48%] btn-outline rounded-[8px] font-Inter font-semibold text-[16px] leading-[19.36px] py-3 border border-[#fbbc5e] hover:text-black"
            onClick={onClose}
            disabled={isLoading}
          >
            Keep Subscription
          </button>
          <button
            className="w-[48%] btn-gradient rounded-[8px] font-Inter font-semibold text-[16px] leading-[19.36px] py-3 text-black"
            onClick={cancelBtnHandler}
            disabled={isLoading}
          >
            {isLoading ? "Cancelling..." : "Cancel Subscription"}
          </button>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default ConfirmCancel;
