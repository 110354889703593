import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InvitefriendIcon from "assets/icons/team 1.png";
import Alert from "components/popup/Alert/Alert";
import {
  useGetFriendListApartmentAssetQuery,
  useInviteFriendApartmentAssetMutation,
  useRemoveFriendApartmentAssetMutation,
  useGetFriendListShipAssetQuery,
  useInviteFriendShipAssetMutation,
  useRemoveFriendShipAssetMutation,
} from "store/slicer/friends";
import Loader from "components/Loader/Loader";

const FriendErrorLimitModal = ({ onClose }) => {
  return (
    <>
      <Alert
        open={true}
        onClose={() => onClose()}
        message={
          "Sorry, you've reached the maximum limit of 4 friends for invitations. Please remove a friend before adding another."
        }
        heading={"You can only invite 4 friends."}
        isSuccess={false}
      />
    </>
  );
};

const EmptyFriendListView = () => {
  return (
    <div className="flex flex-col justify-center items-center h-[300px] max-h-[400px] text-center p-8">
      <img
        src={InvitefriendIcon}
        className="w-[61px] h-[61px]"
        alt="Invite friend icon"
      />
      <h4 className="text-[16px] font-bold font-body pt-[15px]">
        0 Friends Invited
      </h4>
      <p className="pt-[20px] text-[14px] text-[#7A7A7A] font-bold font-body">
        To invite a friend, simply click on the toggle button next to their
        name.
      </p>
    </div>
  );
};

const ListView = ({ data, onInvitedClick, isInvited, type, isLoading }) => {
  return (
    <div className="__list__item__section flex justify-between border-b border-b-[#7A7A7A33] py-4">
      <div className="__user__data__section flex gap-[8px]">
        <div className="__avatar__profile__section">
          <img
            src={
              data.photo ||
              "static/media/_user_default_profile.07300db72dd3c12efff9.png"
            }
            className="w-[56px] h-[56px] rounded-full"
            alt={`${data.userName}'s profile`}
          />
        </div>
        <div className="__content__section flex flex-col justify-center items-start">
          <h4 className="text-[16px] font-Inter font-medium">
            {data?.userName}
          </h4>
          <p className="text-[16px] font-Inter font-normal text-[#FFFFFF80]">
            {data.firstName || ""} {data.lastName || ""}
          </p>
        </div>
      </div>
      <div className="__action__switch__section flex justify-center items-center">
        {isLoading ? (
          <div role="status">
            <svg
              aria-hidden="true"
              class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#FBBC5E]"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              onClick={() => onInvitedClick()}
              type="checkbox"
              value=""
              className="sr-only peer"
              checked={isInvited}
              readOnly
            />
            <div
              className="
                      w-11
                      h-6
                      bg-[#474747] 
                      peer-focus:outline-none 
                      rounded-full 
                      peer 
                      peer-checked:after:translate-x-full 
                      rtl:peer-checked:after:-translate-x-full 
                      peer-checked:after:border-[#FBBC5E] 
                      after:content-[''] 
                      after:absolute 
                      after:top-[3px] 
                      after:start-[4px] 
                      after:bg-[#7E7E7E] 
                      peer-checked:after:bg-[#FBBC5E] 
                      after:border-[#7E7E7E] 
                      after:border 
                      after:rounded-full 
                      after:h-[18px] 
                      after:w-[18px] 
                      after:transition-all 
                      dark:border-gray-600 
                      peer-checked:bg-[#F3A1514D]"
            ></div>
          </label>
        )}
      </div>
    </div>
  );
};

const AssetInvite = ({ type, iapId, particularData, handelBack }) => {
  const navigate = useNavigate();
  const useAssetQueryHook =
    type === "APARTMENT"
      ? useGetFriendListApartmentAssetQuery
      : useGetFriendListShipAssetQuery;

  const useAssetInviteQueryHook =
    type === "APARTMENT"
      ? useInviteFriendApartmentAssetMutation
      : useInviteFriendShipAssetMutation;

  const useAssetRemoveQueryHook =
    type === "APARTMENT"
      ? useRemoveFriendApartmentAssetMutation
      : useRemoveFriendShipAssetMutation;
  const {
    data: friendData,
    isLoading,
    error,
    status,
    refetch,
  } = useAssetQueryHook(
    type === "APARTMENT"
      ? {
          apartmentAssetId: particularData?.assetId,
        }
      : {
          shipAssetId: particularData?.assetId,
        }
  );
  const friendWithoutInvitation = friendData?.response.data.filter(
    (item) => item.access === false
  );
  const friendWithInvitation = friendData?.response.data.filter(
    (item) => item.access === true
  );
  const [FrienderrorModal, setFriendErrorModal] = useState(false);
  const [addInviteFriend] = useAssetInviteQueryHook();
  const [removeInviteFriend] = useAssetRemoveQueryHook();
  const [loadingStates, setLoadingStates] = useState({});

  const handleInviteSwitch = async (id) => {
    if (friendWithInvitation.length > 3) {
      setFriendErrorModal(!FrienderrorModal);
      console.log("Greater than 4 limit");
      return;
    }
    setLoadingStates((prev) => ({ ...prev, [id]: true }));
    await addInviteFriend(
      type === "APARTMENT"
        ? {
            apartmentAssetId: particularData?.assetId,
            friendId: id,
          }
        : {
            shipAssetId: particularData?.assetId,
            friendId: id,
          }
    );
    await refetch();
    setLoadingStates((prev) => ({ ...prev, [id]: false }));
  };

  const handleRemoveSwitch = async (id) => {
    setLoadingStates((prev) => ({ ...prev, [id]: true }));
    await removeInviteFriend(
      type === "APARTMENT"
        ? {
            apartmentAssetId: particularData?.assetId,
            friendId: id,
          }
        : {
            shipAssetId: particularData?.assetId,
            friendId: id,
          }
    );
    await refetch();
    setLoadingStates((prev) => ({ ...prev, [id]: false }));
  };

  return (
    <>
      <div className="__assets__invite__main pl-[15px] max-sm:pl-0">
        <div className="__back__container__section flex justify-between pb-[12px]">
          <button
            onClick={() => handelBack()}
            className="inline-flex gap-2 text-white font-semibold text-xs md:text-sm leading-normal bg-white/[0.10] hover:bg-white/[0.90] hover:text-dark rounded-3xl py-1.5 px-1.5 pr-3 items-center  top-3 left-3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M12.5 15L7.5 10L12.5 5"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>Back</span>
          </button>
        </div>

        <div className="__heading pt-[30px] max-sm:pt-[15px]">
          <h4 className="text-2xl max-sm:text-sm font-normal font-heading">
            Invite Friends to your {type === "APARTMENT" ? "Apartment" : "Ship"}{" "}
          </h4>
          <p className="text-[#7A7A7A] text-sm max-sm:text-xs font-bold font-body pt-[16px] max-sm:pt-[8px]">
            Extend Invitations to Your Friends and Join the Immersive Experience
            Together.
          </p>
        </div>

        <div className="__invite__dialog__section__main grid grid-cols-2 max-sm:grid-cols-1 gap-x-[20px] gap-y-[20px] pt-[50px]">
          <div className="__list__of__friends__section bg-[#FBBC5E0D] h-[900px] max-h-[960px] rounded-[8px] p-6 overflow-y-hidden">
            <h4 className="text-[20px] font-bold font-body text-[#FFFFFFCC]">
              List of friends:
            </h4>
            <div className="__listing__of__friends__section pt-[30px]">
              {isLoading ? (
                <div className="__default_container mt-[20px] min-h-[300px] flex flex-col justify-center items-center">
                  <div className="__no_friend_container flex flex-col text-center">
                    <h4 className="text-[16px] max-[768px]:text-[14px] text-[#7A7A7A] font-body font-medium">
                      <Loader />
                    </h4>
                  </div>
                </div>
              ) : friendData?.response.statusEnum === 1 ? (
                friendWithoutInvitation.length === 0 ||
                friendData.response.data.length === 0 ? (
                  <div className="__default_container mt-[20px] min-h-[300px] flex flex-col justify-center items-center">
                    <div className="__no_friend_container flex flex-col text-center">
                      <h4 className="text-[16px] max-[768px]:text-[14px] text-[#7A7A7A] font-body font-medium">
                        {friendData.response.data.length === 0
                          ? "You Don't have a friend yet"
                          : "All your friends are invited"}
                      </h4>
                      <button
                        onClick={() => navigate("/friends")}
                        className="w-[222px] 
                                   py-[6px] 
                                   border 
                                   border-[#F3A151] 
                                   mt-[20px] 
                                   rounded-[8px] 
                                   hover:bg-[#F3A151] 
                                   hover:text-[#000] 
                                   font-medium 
                                   font-body
                                   max-[768px]:py-[3px]
                                   max-[768px]:text-[13px]
                                   "
                      >
                        Add
                        {friendData.response.data.length ? " more":""} friends
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    {friendWithoutInvitation.map((item, index) => {
                      return (
                        <ListView
                          key={index}
                          data={item}
                          onInvitedClick={() =>
                            handleInviteSwitch(item?.friendId)
                          }
                          isInvited={false}
                          type="INVITE"
                          isLoading={loadingStates[item?.friendId]}
                        />
                      );
                    })}
                  </>
                )
              ) : (
                "There is some problem while fetching the friends! Try again"
              )}
            </div>
          </div>

          <div
            className={`__invite__to__${
              type === "APARTMENT" ? "Apartment" : "Ship"
            }__section bg-[#282828] py-6 px-[24px] border border-[#656565] h-[900px] max-h-[960px] overflow-y-hidden rounded-[8px]`}
          >
            <h4 className="text-[20px] font-bold font-body text-[#FFFFFFCC] text-center">
              Invite to {type === "APARTMENT" ? "Apartment" : "Ship"}
            </h4>

            <div className="__asset__info__section">
              <div className="__asset__banner__section pt-[24px]">
                <img src={particularData?.img} alt="Asset banner" />
              </div>
              <div className="__info__section pt-[12px] flex gap-[20px]">
                <div className="__key__name [&>p]:text-[16px] [&>p]:font-semibold [&>p]:text-[#7A7A7A] flex flex-col">
                  <p>
                    {type === "APARTMENT" ? "Apartment Name:" : "Ship Name:"}
                  </p>
                  <p>
                    {type === "APARTMENT"
                      ? "Apartment Number:"
                      : "Ship Number:"}
                  </p>
                </div>
                <div className="__key__data flex flex-col">
                  <p>{particularData?.iapName}</p>
                  <p>
                    {type === "APARTMENT"
                      ? particularData?.iapDetails?.apartmentNumber
                      : particularData?.iapDetails?.shipNumber}
                  </p>
                </div>
              </div>
            </div>

            <h5 className="pt-[36px] text-[#FBBC5E] text-[14px] font-medium font-body">
              You can Invite up to 4 Friends granting them access to your
              {type === "APARTMENT" ? "apartment" : "ship"}.
            </h5>
            <h5 className="pt-[20px] text-[#7A7A7A] text-[14px] font-bold font-body">
              Invited friends:
            </h5>

            <div className="__invited__friends__list__section">
              {isLoading ? (
                <div className="__default_container mt-[20px] min-h-[300px] flex flex-col justify-center items-center">
                  <div className="__no_friend_container flex flex-col text-center">
                    <h4 className="text-[16px] max-[768px]:text-[14px] text-[#7A7A7A] font-body font-medium">
                      <Loader />
                    </h4>
                  </div>
                </div>
              ) : (
                friendWithInvitation?.map((item, index) => {
                  return (
                    <ListView
                      key={index}
                      data={item}
                      onInvitedClick={() => handleRemoveSwitch(item?.friendId)}
                      isInvited={true}
                      type="INVITED"
                      isLoading={loadingStates[item?.friendId]}
                    />
                  );
                })
              )}
              {!isLoading &&
                friendWithInvitation &&
                friendWithInvitation.length === 0 && <EmptyFriendListView />}
            </div>
          </div>
        </div>
      </div>

      {FrienderrorModal && (
        <FriendErrorLimitModal
          onClose={() => setFriendErrorModal(!FrienderrorModal)}
        />
      )}
    </>
  );
};

export default AssetInvite;
