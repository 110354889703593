import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { GlobalStore } from "store/GlobalStore";

import { EndpointsSlug } from "constant/api/Endpoints";
import Loader from "components/Loader/Loader";
import ShadowFrame from "components/shadow-frame";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { BASE_URL } from "constant/api/Endpoints";
const Checkout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = localStorage.getItem("accessToken");
  // need to use redux global state directly because two stores have been setup in the app
  const authState = GlobalStore.getState()?.auth;

  // user not signed in
  if (!accessToken) {
    navigate(`/signin?ref=${location.pathname}`, {
      replace: true,
    });
  }

  // user signed in but did not verify his email
  if (accessToken && !authState?.userInfo?.isVerified) {
    navigate(`/otp-verification`, {
      replace: true,
    });
  }

  const [searchParams] = useSearchParams();

  const productType = searchParams.get("productType");
  const productName = searchParams.get("productName");
  const amount = searchParams.get("amount");
  const productId = searchParams.get("productId");
  const themeId = searchParams.get("themeId");
  const isSubscription = searchParams.get("isSubscription");
  const channelId = searchParams.get("channelId");
  const [transactionDetail, setTransactionDetail] = useState({});
  const transactionDetailRef = useRef({});

  // for paid items, NOT subscription
  async function initiateTrans() {
    try {
      setLoading(true);
      let product = {
        amount: Number(amount),
        productId: productId,
        productType: productType,
        name: productName,
      };

      // for apartments and ships
      if (themeId) {
        product = {
          ...product,
          themeId,
        };
      }

      // payload for checking out a single paid product
      let payload = {
        products: [product],
        paymentGatewayName: "paypal",
        total_amount: Number(amount),
      };

      if (productType === "event") payload["eventId"] = productId;

      // if we are checking out a cart, we are getting the payload stored in session storage from the cart page and usingit here
      if (searchParams.get("isCart")) {
        console.log("cart checkout");
        payload = {
          products: JSON.parse(sessionStorage.getItem("cartCheckoutData")),
          paymentGatewayName: "paypal",
          total_amount: JSON.parse(
            sessionStorage.getItem("cartCheckoutData")
          ).reduce((acc, current) => {
            return acc + current.amount;
          }, 0),
        };
      }

      let res = await fetch(
        productType === "event"
          ? BASE_URL + EndpointsSlug.INITIATE_EVENT_TRANS
          : BASE_URL + EndpointsSlug.INITIATE_TRANS,
        {
          method: "POST",
          body: JSON.stringify(payload),
          mode: "cors",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (res.ok) {
        res = await res.json();
      } else {
        throw new Error("Transaction initiation failed");
      }

      if (res?.response?.statusEnum !== 1) {
        throw new Error(
          res.response?.message || "Transaction initiation failed"
        );
      }

      transactionDetailRef.current = res.response?.data;
      return res.response?.data?.gatewayTransaction?.paymentGatewayId;
    } catch (e) {
      console.log(e.message, e.statusEnum);
      // alert(e);
      if (e.message.toString() == "Failed to create PayPal order")
        navigate("/checkout/cancelled");
      else navigate("/checkout/already-in-asset");
      // /checkout/cancelled
      setIsError(true);
    } finally {
      setLoading(false);
    }
  }

  // for subscription
  async function initiateSubscriptionTrans() {
    let payload = {
      channelId: channelId,
      paymentGatewayName: "paypal",
    };

    try {
      setLoading(true);

      let res = await fetch(
        BASE_URL + EndpointsSlug.CREATE_SUBSCRIPTION_MONTHLY,
        {
          method: "POST",
          body: JSON.stringify(payload),
          mode: "cors",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (res.ok) {
        res = await res.json();
      }

      if (res?.response?.statusEnum != 1) {
        throw new Error(res.response?.message);
      }
      // setTransactionDetail(res.response?.data);
      transactionDetailRef.current = res.response?.data;

      // setPaymentUrl(res.response?.data?.session?.url);
      return res.response?.data?.gatewayTransaction?.paymentGatewayId;
    } catch (e) {
      console.log(e);
      navigate("/checkout/already-in-asset");
      setIsError(true);
    } finally {
      setLoading(false);
    }
  }

  if (
    !searchParams.get("isCart") &&
    !searchParams.get("isSubscription") &&
    (!productType || !amount || !productId)
  ) {
    navigate(-1);
  }

  const [loading, setLoading] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState("");
  const [isError, setIsError] = useState(false);
  async function createOrder() {
    let orderId;
    if (!isSubscription) {
      orderId = await initiateTrans();
    } else {
      orderId = await initiateSubscriptionTrans();
    }

    // If no order ID is returned, throw an error
    if (!orderId) {
      throw new Error("Order creation failed: No order ID returned");
    }

    return orderId; // PayPal will use this ID for the next steps

    // .then((response) => response.json())
    // .then((order) => order.id);
  }
  function onApprove(data) {
    if (!data.orderID) {
      alert("Invalid order ID");
      return;
    }

    navigate("/checkout/success");
  }

  // useEffect(() => {
  //   if (!loading && paymentUrl) {
  //     const a = document.createElement("a");
  //     a.href = paymentUrl;
  //     a.click();
  //   }
  // }, [loading]);
  const initialOptions = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  };

  return (
    <div className="relative min-h-screen w-full px-8 py-4">
      <div className="flex flex-col justify-center items-center h-full">
        <div className="bg-black max-w-[620px] flex flex-col items-center gap-8 p-10 rounded-md">
          <div className="flex flex-col gap-8">
            {isSubscription && (
              <p className="font-bold text-lg md:text-xl text-center text-green-600">
                Coming Soon !
              </p>
            )}
            <p className="font-bold text-xl md:text-2xl text-center">
              Choose Your Payment Mode!
            </p>
            <p className="text-sm sm:text-md text-center">
              Select your preferred payment option below to complete your
              transaction in USD. You can choose to pay securely through{" "}
              <span className="font-bold">PayPal</span> or use your{" "}
              <span className="font-bold">debit card</span> for a quick and easy
              checkout.
            </p>
          </div>
          <div className=" bg-[#E1DBCB] p-3 rounded-md z-[20]">
            <PayPalScriptProvider options={initialOptions}>
              {!isSubscription ? (
                <PayPalButtons
                  createOrder={createOrder}
                  onApprove={onApprove}
                  className="min-w-[250px] md:w-[400px]"
                  onError={(err) => console.log(err)}
                />
              ) : (
                <PayPalButtons
                  createOrder={createOrder}
                  onApprove={onApprove}
                  vault={isSubscription ? true : false} // Enable vaulting to store the payment method
                  className="min-w-[250px] md:w-[400px] !cursor-not-allowed"
                  disabled={true}
                />
              )}
            </PayPalScriptProvider>
          </div>
        </div>

        {loading && (
          <>
            <span className="block mx-auto">
              <Loader />
            </span>
            <p className="mt-2 text-lg text-white/50 font-body">
              You will soon be redirected to payments page.
            </p>
          </>
        )}
        {isError && (
          <p className="text-lg text-white/50 font-body">
            Something went wrong.
          </p>
        )}
      </div>
      <ShadowFrame className="w-[250px] md:w-[400px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[60%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
    </div>
  );
};

export default Checkout;
