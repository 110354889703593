import React from "react";
import AlertIcon from "assets/images/alert_icon.png";
import PopupWrapper from "../PopupWrapper";
import { Link } from "react-router-dom";

const TransactionFailed = ({
  open,
  onClose,
  canClose,
  message,
  currency = "fstr",
  errorTip
}) => {
  return (
    <PopupWrapper open={open} onClose={onClose} canClose={canClose}>
      <div className="px-4 py-8 flex flex-col justify-center items-center gap-4 font-body bg-[#242424] rounded-[16px]">
        <div className="h-[55px] w-[55px] rounded-full">
          <img
            className="h-full w-full object-cover"
            src={AlertIcon}
            alt="processing"
          />
        </div>
        <p className="text-[24px] leading-[36px] text-center font-bold">
          Transaction Failed
        </p>
        <p className="text-[16px] leading-[24px] text-center font-normal text-white">
          {message ||
            "We're sorry, but there was an issue processing your purchase. Please try again later or contact support for assistance."}
        </p>
        {errorTip && (
          <p className="text-[16px] leading-[24px] text-center font-normal text-white">
            Before the retry reject or cancel any pending transaction from you
            wallet.
          </p>
        )}

        {currency != "fstr" && (
          <Link
            to={`/marketplace`}
            className="mt-5 font-semibold text-[16px] leading-[19.36px] text-center text-[#fbbc5e]"
          >
            Go back to IAP
          </Link>
        )}
        {currency == "fstr" && (
          <button
            className="mt-5 w-full max-w-[150px] font-bold text-black rounded-[6px] btn-gradient px-3 py-2"
            onClick={onClose}
          >
            OK
          </button>
        )}
      </div>
    </PopupWrapper>
  );
};

export default TransactionFailed;
