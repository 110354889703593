import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, EndpointsSlug } from "constant/api/Endpoints";
// Custom base query with error handling
import { baseQueryWithReauth } from "constant/api/apiInstance";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Help-Center
 *@developer Amardeep Singh
 */

// Handling State function or logics of my orders Module
// Replace the CreateSlice method with RTK function - createAPI for storing api data directly into slicer
export const HelpCenter = createApi({
  reducerPath: "HelpCenter",
  baseQuery: baseQueryWithReauth({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken") || "";
      headers.set("Content-type", "application/json; charset=UTF-8");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    AddTicket: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.ADD_TICKET_RAISE,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useAddTicketMutation } = HelpCenter;
