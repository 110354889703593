/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Avatar-wardrobe-component
 *@developer Shubham K
 */

import React from "react";

import Item from "./Item";
import AvatarItem from "./AvatarItem";
import { useContext } from "react";
import { WardrobeContext } from "./WardrobeContextProvider";

import topwearImg from "assets/images/topwear_item.png";
import bottomwearImg from "assets/images/bottomwear_item.png";
import suitImg from "assets/images/suit_item.png";

import avt_thumb_1 from "assets/images/avt_thumb_1.png";
import avt_thumb_2 from "assets/images/avt_thumb_2.png";
import avt_thumb_3 from "assets/images/avt_thumb_3.png";
import avt_thumb_4 from "assets/images/avt_thumb_4.png";

import hangerImg from "assets/images/hanger.png";
import Loader from "components/Loader/Loader";

const dummyAvatarList = [
  { title: "Human Male", img: avt_thumb_1 },
  {
    title: "Human Female",
    img: avt_thumb_2,
  },
  {
    title: "Alien Royality",
    img: avt_thumb_3,
  },
  {
    title: "Goblin",
    img: avt_thumb_4,
  },
  { title: "Human Male", img: avt_thumb_1 },
  {
    title: "Alien Royality",
    img: avt_thumb_3,
  },
  {
    title: "Alien Royality",
    img: avt_thumb_3,
  },
  {
    title: "Alien Royality",
    img: avt_thumb_3,
  },
  {
    title: "Alien Royality",
    img: avt_thumb_3,
  },
  {
    title: "Alien Royality",
    img: avt_thumb_3,
  },
  {
    title: "Alien Royality",
    img: avt_thumb_3,
  },
  {
    title: "Alien Royality",
    img: avt_thumb_3,
  },
  {
    title: "Alien Royality",
    img: avt_thumb_3,
  },
  {
    title: "Alien Royality",
    img: avt_thumb_3,
  },
];

const ItemList = ({ type }) => {
  const contextValues = useContext(WardrobeContext);

  let { wardrobeData, selectedItems, isFetching, isError, isSuccess } =
    contextValues;

  if (!isFetching && isSuccess && wardrobeData.response.statusEnum == 1) {
    wardrobeData = wardrobeData.response.data;
    wardrobeData = wardrobeData.filter((item) => {
      if (type == "avatar") {
        return item.iapCategory == type;
      }
      return item.iapType && type.includes(item.iapType.toLowerCase());
    });
  } else {
    wardrobeData = [];
  }

  let dummyItemsData = [];

  if (type == "avatar") {
    dummyItemsData = dummyAvatarList;
  }

  if (type == "topwear") {
    dummyItemsData = new Array(10);
    dummyItemsData.fill({ img: topwearImg });
  }
  if (type == "bottomwear") {
    dummyItemsData = new Array(10);
    dummyItemsData.fill({ img: bottomwearImg });
  }
  if (type == "suits") {
    dummyItemsData = new Array(10);
    dummyItemsData.fill({ img: suitImg });
  }

  const isEquipped = (id) => {
    const index = selectedItems.findIndex((item) => item.iapId == id);
    return index != -1;
  };

  if (isFetching) {
    return (
      <div className="h-full grid place-content-center border border-[#656565] rounded-lg">
        <Loader />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="h-full w-full border border-[#656565] rounded-lg grid place-content-center">
        <p className="text-white/50 font-bold">Something went wrong</p>
      </div>
    );
  }

  return (
    <div className="p-[27px] border border-[#656565] rounded-lg h-full overflow-y-scroll">
      <p className="text-[14px] font-bold capitalize mb-[32px]">
        {type == "avatar" ? "Select Avatar Type" : "Select " + type}
      </p>
      {type == "avatar" && wardrobeData?.length > 0 && (
        <div className="__AVATAR_LIST_CONTAINER grid grid-cols-[repeat(auto-fit,minmax(min(107px,100%),1fr))] justify-items-center gap-[20px] gap-y-[50px]">
          {wardrobeData.map((item, ind) => {
            return (
              <AvatarItem
                key={item._id}
                id={item.iapId}
                equipped={isEquipped(item.iapId)}
                img={item.thumbnail}
                title={item.iapName}
                index={ind}
              />
            );
          })}
        </div>
      )}
      {type != "avatar" && wardrobeData?.length > 0 && (
        <div className="__ITEM_LIST_CONTAINER grid grid-cols-[repeat(auto-fit,minmax(min(150px,100%),1fr))] justify-items-center gap-[20px] gap-y-[50px]">
          {wardrobeData.map((item, ind) => {
            return (
              <Item
                key={item._id}
                img={item.thumbnail}
                largeImg={item.largeThumbnail}
                type={type}
                id={item.iapId}
                equipped={isEquipped(item.iapId)}
              />
            );
          })}
        </div>
      )}

      {wardrobeData?.length == 0 && (
        <div className="flex flex-col justify-start mt-[100px] items-center gap-4 overflow-hidden">
          <img className="w-[68px] h-[68px]" src={hangerImg} alt="hanger" />
          <p className="text-[14px] leading-[21px] font-semibold">
            Waiting for New Styles
          </p>
          <p className="text-[14px] leading-[21px] font-medium text-[#7A7A7A]">
            No Items are available at the moment
          </p>
        </div>
      )}
    </div>
  );
};

export default ItemList;
