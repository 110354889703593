import React from "react";
import ChannelsAllContent from "components/channels/ChannelsAllContent";
import { useGetChannelContentAssetQuery } from "store/slicer/Entertainment";
import Explore from "components/Explore/Explore";
import Loader from "components/Loader/Loader";

// when need to implement api go to "/src/pages/marketplace/entertainment/components/channels.jsx" and copy the required code from there to map the api data in a format similar to the dummy data below

let dummyChannelsData = [
  {
    _id: "65adff283f8112ef049cb8f5",
    user: "65adfdb33f8112ef049cb834",
    channelThumbnail:
      "https://creater-dashboard.s3.amazonaws.com/channelThumbnail/jghsh-1706164738719.jpeg",
    channelThumbnailDetails: "65b20202d913da00288b280b",
    channelName: "Test Entertainment",
    channelHandle: "testingfourthstar",
    bannerImage:
      "https://creater-dashboard.s3.amazonaws.com/channelBanner/qwrhq-1705901785350.jpeg",
    bannerImageDetails: "65adfed93f8112ef049cb8e8",
    subscriptionPrice: 60,
    agreeToTerms: false,
    channelCategory: "657189a1939000bd49d005e4",
    channelSubCategory: [
      "657191a7939000bd49d006e0",
      "657191ba939000bd49d006e3",
    ],
    createdAt: "2024-01-22T05:37:44.155Z",
    updatedAt: "2024-01-25T06:39:03.110Z",
    content: [
      {
        _id: "65ae57093f8112ef049cbf3d",
        user: "65adfdb33f8112ef049cb834",
        channel: "65adff283f8112ef049cb8f5",
        category: "music",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/vnpiz-1705924285263.mp4",
        mediaFileDetails: "65ae56bd3f8112ef049cbf2a",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/bskac-1705924356107.jpeg",
        thumbnailDetails: "65ae57043f8112ef049cbf34",
        contentHighlights: [
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65ae57233f8112ef049cbf5e",
          },
        ],
        sneakPeakImages: [],
        sneakPeakImagesDetails: [],
        tag: ["music video"],
        contentPreference: "subscription",
        tags: [],
        createdAt: "2024-01-22T11:52:41.040Z",
        contentName: "music content",
        description: "music description",
        contentSlug: "musiccontent_147",
        mediaFileS3Url: null,
        mediaFileData: null,
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/bskac-1705924356107.jpeg",
      },
      {
        _id: "65ae558a3f8112ef049cbeb8",
        user: "65adfdb33f8112ef049cb834",
        channel: "65adff283f8112ef049cb8f5",
        category: "2D-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/spocl-1705923901066.mp4",
        mediaFileDetails: "65ae553d3f8112ef049cbea9",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/riawg-1705923973823.jpeg",
        thumbnailDetails: "65ae55853f8112ef049cbeaf",
        contentHighlights: [
          {
            highlightHeading: "2d heading",
            highlightDescription: "2d description",
            _id: "65ae55b93f8112ef049cbee7",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/xomiv-1705924007058.jpeg",
            _id: "65ae55a73f8112ef049cbebc",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65ae55a73f8112ef049cbebc",
            _id: "65ae55b93f8112ef049cbee5",
          },
        ],
        tag: ["2d video content"],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-22T11:46:18.600Z",
        contentName: "2d content",
        description: "2d description",
        contentSlug: "2dcontent_35",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/spocl-1705923901066.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/riawg-1705923973823.jpeg",
      },
      {
        _id: "65ae0f483f8112ef049cba9d",
        user: "65adfdb33f8112ef049cb834",
        channel: "65adff283f8112ef049cb8f5",
        category: "360-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/xiuhp-1705905855914.mp4",
        mediaFileDetails: "65ae0ebf3f8112ef049cba8e",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/dniwl-1705905986135.jpeg",
        thumbnailDetails: "65ae0f423f8112ef049cba94",
        contentHighlights: [
          {
            highlightHeading: "heading1",
            highlightDescription: "highlight description",
            _id: "65ae0f8c3f8112ef049cbafe",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/vsfgf-1705906031991.jpeg",
            _id: "65ae0f6f3f8112ef049cbad3",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65ae0f6f3f8112ef049cbad3",
            _id: "65ae0f8c3f8112ef049cbafc",
          },
        ],
        tag: ["360 video"],
        contentPreference: "paid",
        tags: [],
        createdAt: "2024-01-22T06:46:32.145Z",
        contentName: "360 content",
        description: "360 description",
        currency: "USD",
        price: 35,
        contentSlug: "360content_858",
        mediaFileS3Url: null,
        mediaFileData: null,
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/dniwl-1705905986135.jpeg",
      },
      {
        _id: "65ae54953f8112ef049cbe30",
        user: "65adfdb33f8112ef049cb834",
        channel: "65adff283f8112ef049cb8f5",
        category: "180-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/qjbry-1705923706913.mp4",
        mediaFileDetails: "65ae547a3f8112ef049cbe21",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/smdin-1705923725252.jpeg",
        thumbnailDetails: "65ae548d3f8112ef049cbe27",
        contentHighlights: [
          {
            highlightHeading: "180 heading",
            highlightDescription: "180 description",
            _id: "65ae54d33f8112ef049cbe5f",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/mtpkw-1705923768522.jpeg",
            _id: "65ae54b83f8112ef049cbe34",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65ae54b83f8112ef049cbe34",
            _id: "65ae54d33f8112ef049cbe5d",
          },
        ],
        tag: ["180 content"],
        contentPreference: "subscription",
        tags: [],
        createdAt: "2024-01-22T11:42:13.291Z",
        contentName: "180 content",
        description: "180 description",
        contentSlug: "180content_667",
        mediaFileS3Url: null,
        mediaFileData: null,
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/smdin-1705923725252.jpeg",
      },
      {
        _id: "65b0e1d83f8112ef049cea16",
        user: "65adfdb33f8112ef049cb834",
        channel: "65adff283f8112ef049cb8f5",
        category: "volumetric-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/rcvdw-1706090947567.mp4",
        mediaFileDetails: "65b0e1c33f8112ef049cea07",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/bfedy-1706090963643.jpeg",
        thumbnailDetails: "65b0e1d33f8112ef049cea0d",
        contentHighlights: [
          {
            highlightHeading: "heading",
            highlightDescription: "description",
            _id: "65b0e22c3f8112ef049cea45",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/jybro-1706091032619.jpeg",
            _id: "65b0e2183f8112ef049cea1a",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65b0e2183f8112ef049cea1a",
            _id: "65b0e22c3f8112ef049cea43",
          },
        ],
        tag: ["medical"],
        contentPreference: "paid",
        tags: [],
        createdAt: "2024-01-24T10:09:28.322Z",
        contentName: "medical",
        description: "description",
        currency: "USD",
        price: 45,
        contentSlug: "medical_881",
        mediaFileS3Url: null,
        mediaFileData: null,
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/bfedy-1706090963643.jpeg",
      },
      {
        _id: "65b0e1113f8112ef049ce998",
        user: "65adfdb33f8112ef049cb834",
        channel: "65adff283f8112ef049cb8f5",
        category: "volumetric-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/juqds-1706090702432.mp4",
        mediaFileDetails: "65b0e0ce3f8112ef049ce989",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/iakgv-1706090764597.jpeg",
        thumbnailDetails: "65b0e10c3f8112ef049ce98f",
        contentHighlights: [
          {
            highlightHeading: "meta",
            highlightDescription: "verse",
            _id: "65b0e1473f8112ef049ce9c7",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/tcozv-1706090802291.jpeg",
            _id: "65b0e1323f8112ef049ce99c",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65b0e1323f8112ef049ce99c",
            _id: "65b0e1473f8112ef049ce9c5",
          },
        ],
        tag: ["metaverse"],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-24T10:06:09.831Z",
        contentName: "metaverse ",
        description: "description",
        contentSlug: "metaverse_626",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/juqds-1706090702432.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/iakgv-1706090764597.jpeg",
      },
      {
        _id: "65b0e01f3f8112ef049ce913",
        user: "65adfdb33f8112ef049cb834",
        channel: "65adff283f8112ef049cb8f5",
        category: "volumetric-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/sncpj-1706090493015.mp4",
        mediaFileDetails: "65b0dffd3f8112ef049ce8fe",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/pjzix-1706090520900.jpeg",
        thumbnailDetails: "65b0e0183f8112ef049ce907",
        contentHighlights: [
          {
            highlightHeading: "heading",
            highlightDescription: "description",
            _id: "65b0e09d3f8112ef049ce94f",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/wmsul-1706090632993.jpeg",
            _id: "65b0e0883f8112ef049ce924",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65b0e0883f8112ef049ce924",
            _id: "65b0e09d3f8112ef049ce94d",
          },
        ],
        tag: ["astronaut"],
        contentPreference: "subscription",
        tags: [],
        createdAt: "2024-01-24T10:02:07.230Z",
        contentName: "astronaut",
        description: "description",
        contentSlug: "astronaut_401",
        mediaFileS3Url: null,
        mediaFileData: null,
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/pjzix-1706090520900.jpeg",
      },
    ],
  },
  {
    _id: "65ae63183f8112ef049cc16c",
    user: "65ae57223f8112ef049cbf55",
    channelThumbnail:
      "https://creater-dashboard.s3.amazonaws.com/channelThumbnail/umavl-1705927139783.jpeg",
    channelThumbnailDetails: "65ae61e33f8112ef049cc145",
    channelName: "fourth Star Metaverse",
    channelHandle: "fourthstar",
    bannerImage:
      "https://creater-dashboard.s3.amazonaws.com/channelBanner/grwfv-1705927419055.jpeg",
    bannerImageDetails: "65ae62fb3f8112ef049cc15f",
    subscriptionPrice: 20,
    agreeToTerms: false,
    channelCategory: "65718a4c939000bd49d00642",
    channelSubCategory: [
      "65719833939000bd49d00737",
      "65719839939000bd49d0073a",
    ],
    createdAt: "2024-01-22T12:44:08.644Z",
    updatedAt: "2024-01-23T08:28:31.774Z",
    content: [
      {
        _id: "65afab133f8112ef049cd7e2",
        user: "65ae57223f8112ef049cbf55",
        channel: "65ae63183f8112ef049cc16c",
        category: "music",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/pnszt-1706011223745.mp4",
        mediaFileDetails: "65afaa573f8112ef049cd7b6",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/vlycw-1706011405807.png",
        thumbnailDetails: "65afab0d3f8112ef049cd7d6",
        contentHighlights: [
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65afab443f8112ef049cd802",
          },
        ],
        sneakPeakImages: [],
        sneakPeakImagesDetails: [],
        tag: ["music", "first", "melodious"],
        contentPreference: "subscription",
        tags: [],
        createdAt: "2024-01-23T12:03:31.138Z",
        contentName: "My music",
        description: "This is my first music ",
        contentSlug: "Mymusic_572",
        mediaFileS3Url: null,
        mediaFileData: null,
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/vlycw-1706011405807.png",
      },
      {
        _id: "65afa0d83f8112ef049cd0aa",
        user: "65ae57223f8112ef049cbf55",
        channel: "65ae63183f8112ef049cc16c",
        category: "2D-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/xdjqq-1706008534655.mp4",
        mediaFileDetails: "65af9fd63f8112ef049ccf98",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/oiqdu-1706008786045.png",
        thumbnailDetails: "65afa0d23f8112ef049cd0a1",
        contentHighlights: [
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65afa13c3f8112ef049cd15e",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/kemnk-1706008818980.png",
            _id: "65afa0f23f8112ef049cd0d2",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65afa0f23f8112ef049cd0d2",
            _id: "65afa13c3f8112ef049cd15c",
          },
        ],
        tag: ["space", "ray"],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-23T11:19:52.209Z",
        contentName: "2D video",
        description: "This is my 1st 2D video",
        contentSlug: "2Dvideo_334",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/xdjqq-1706008534655.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/oiqdu-1706008786045.png",
      },
      {
        _id: "65b226b6d913da00288b2f51",
        user: "65ae57223f8112ef049cbf55",
        channel: "65ae63183f8112ef049cc16c",
        category: "360-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/echvy-1706174104806.mp4",
        mediaFileDetails: "65b22698d913da00288b2f42",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/nwhxs-1706174129079.png",
        thumbnailDetails: "65b226b1d913da00288b2f48",
        contentHighlights: [
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65b226e8d913da00288b2f80",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/wziis-1706174161029.png",
            _id: "65b226d1d913da00288b2f55",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65b226d1d913da00288b2f55",
            _id: "65b226e8d913da00288b2f7e",
          },
        ],
        tag: ["space", "360", "180", "moon"],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-25T09:15:34.516Z",
        contentName: "New space",
        description: "This is in the space ",
        contentSlug: "Newspace_588",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/echvy-1706174104806.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/nwhxs-1706174129079.png",
      },
      {
        _id: "65ae66a03f8112ef049cc19c",
        user: "65ae57223f8112ef049cbf55",
        channel: "65ae63183f8112ef049cc16c",
        category: "360-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/zelgl-1705928043809.mp4",
        mediaFileDetails: "65ae656b3f8112ef049cc18d",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/uvkqc-1705928345894.jpeg",
        thumbnailDetails: "65ae66993f8112ef049cc193",
        contentHighlights: [],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/jqgwd-1705928586017.jpeg",
            _id: "65ae678a3f8112ef049cc1a0",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65ae678a3f8112ef049cc1a0",
            _id: "65af8bce3f8112ef049cc733",
          },
        ],
        tag: ["space ", "metaverse"],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-22T12:59:12.042Z",
        contentName: "My Metaverse",
        description: "This is new metaverse",
        contentSlug: "MyMetaverse_367",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/zelgl-1705928043809.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/uvkqc-1705928345894.jpeg",
      },
      {
        _id: "65af91913f8112ef049ccab4",
        user: "65ae57223f8112ef049cbf55",
        channel: "65ae63183f8112ef049cc16c",
        category: "180-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/udpsb-1706004859801.mp4",
        mediaFileDetails: "65af917b3f8112ef049cca99",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/lpkoh-1706004877770.jpeg",
        thumbnailDetails: "65af918d3f8112ef049ccaa8",
        contentHighlights: [
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65af92553f8112ef049ccb62",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/qvvcm-1706005049271.jpeg",
            _id: "65af92393f8112ef049ccb35",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65af92393f8112ef049ccb35",
            _id: "65af92553f8112ef049ccb60",
          },
        ],
        tag: ["moon", "space"],
        contentPreference: "paid",
        tags: [],
        createdAt: "2024-01-23T10:14:41.701Z",
        contentName: "!80 video",
        description: "This is my 1st 180 video",
        currency: "USD",
        price: 20,
        contentSlug: "!80video_130",
        mediaFileS3Url: null,
        mediaFileData: null,
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/lpkoh-1706004877770.jpeg",
      },
    ],
  },
  {
    _id: "65af8cb83f8112ef049cc798",
    user: "65af7a563f8112ef049cc525",
    channelThumbnail:
      "https://creater-dashboard.s3.amazonaws.com/channelThumbnail/zhksf-1706003466216.png",
    channelThumbnailDetails: "65af8c0a3f8112ef049cc75d",
    channelName: "Space Channel",
    channelHandle: "divya1234",
    bannerImage:
      "https://creater-dashboard.s3.amazonaws.com/channelBanner/smnrt-1706003532247.png",
    bannerImageDetails: "65af8c4c3f8112ef049cc77b",
    subscriptionPrice: 50,
    agreeToTerms: false,
    channelCategory: "657189a1939000bd49d005e4",
    channelSubCategory: [
      "657191a7939000bd49d006e0",
      "657192b9939000bd49d006fe",
    ],
    createdAt: "2024-01-23T09:54:00.403Z",
    updatedAt: "2024-01-23T09:54:00.403Z",
    content: [
      {
        _id: "65b0a7bf3f8112ef049ce4a8",
        user: "65af7a563f8112ef049cc525",
        channel: "65af8cb83f8112ef049cc798",
        category: "music",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/gbkcd-1706075824372.mp4",
        mediaFileDetails: "65b0a6b03f8112ef049ce499",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/mfcdo-1706076089225.png",
        thumbnailDetails: "65b0a7b93f8112ef049ce49f",
        contentHighlights: [
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65b0a8123f8112ef049ce4c0",
          },
        ],
        sneakPeakImages: [],
        sneakPeakImagesDetails: [],
        tag: [],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-24T06:01:35.006Z",
        contentName: "Yoga theme ",
        description: "Yoga theme added ",
        contentSlug: "Yogatheme_603",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/gbkcd-1706075824372.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/mfcdo-1706076089225.png",
      },
      {
        _id: "65b0a62a3f8112ef049ce42a",
        user: "65af7a563f8112ef049cc525",
        channel: "65af8cb83f8112ef049cc798",
        category: "2D-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/rjzpu-1706075671232.mp4",
        mediaFileDetails: "65b0a6173f8112ef049ce41b",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/eqnmm-1706075685934.png",
        thumbnailDetails: "65b0a6253f8112ef049ce421",
        contentHighlights: [
          {
            highlightHeading: "witness celestial wonders",
            highlightDescription:
              "Celestial melodies by artist will take you on an interstellar journey. Experience her enchanting performance that transcends galaxies. Get ready to be captivated by her celestial talent.",
            _id: "65b0a68a3f8112ef049ce459",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/hmyvw-1706075750902.png",
            _id: "65b0a6663f8112ef049ce42e",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65b0a6663f8112ef049ce42e",
            _id: "65b0a68a3f8112ef049ce457",
          },
        ],
        tag: [],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-24T05:54:50.838Z",
        contentName: "Yoga theme ",
        description: "Yoga theme added ",
        contentSlug: "Yogatheme_312",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/rjzpu-1706075671232.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/eqnmm-1706075685934.png",
      },
      {
        _id: "65af8ecc3f8112ef049cc8ea",
        user: "65af7a563f8112ef049cc525",
        channel: "65af8cb83f8112ef049cc798",
        category: "360-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/jomxx-1706004143579.mp4",
        mediaFileDetails: "65af8eaf3f8112ef049cc8e1",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/tnsxc-1706003990922.png",
        thumbnailDetails: "65af8e163f8112ef049cc8d8",
        contentHighlights: [
          {
            highlightHeading: "witness celestial wonders",
            highlightDescription:
              "Celestial melodies by artist will take you on an interstellar journey. Experience her enchanting performance that transcends galaxies. Get ready to be captivated by her celestial talent.",
            _id: "65af91d13f8112ef049ccadd",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/ugvdp-1706004876073.png",
            _id: "65af918c3f8112ef049ccaa5",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65af918c3f8112ef049ccaa5",
            _id: "65af91d13f8112ef049ccadb",
          },
        ],
        tag: [],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-23T10:02:52.508Z",
        contentName: "Space",
        description: "Space theme added ",
        contentSlug: "Space_55",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/jomxx-1706004143579.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/tnsxc-1706003990922.png",
      },
      {
        _id: "65b0a53a3f8112ef049ce38b",
        user: "65af7a563f8112ef049cc525",
        channel: "65af8cb83f8112ef049cc798",
        category: "180-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/zbjtc-1706075387288.mp4",
        mediaFileDetails: "65b0a4fb3f8112ef049ce371",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/tkiis-1706075444568.png",
        thumbnailDetails: "65b0a5343f8112ef049ce382",
        contentHighlights: [
          {
            highlightHeading: "Galactic Soundscapes",
            highlightDescription:
              "Journey through the cosmos with electrifying performances by interstellar musicians. Witness the fusion of music and technology in an otherworldly setting, where soundwaves transcend the boundaries of space.",
            _id: "65b0a5913f8112ef049ce3d0",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/mbgnj-1706075523378.png",
            _id: "65b0a5833f8112ef049ce3a5",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65b0a5833f8112ef049ce3a5",
            _id: "65b0a5913f8112ef049ce3ce",
          },
        ],
        tag: [],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-24T05:50:50.827Z",
        contentName: "Yoga Theme ",
        description: "Yoga theme added ",
        contentSlug: "YogaTheme_521",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/zbjtc-1706075387288.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/tkiis-1706075444568.png",
      },
      {
        _id: "65b0a4803f8112ef049ce2dc",
        user: "65af7a563f8112ef049cc525",
        channel: "65af8cb83f8112ef049cc798",
        category: "volumetric-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/lnnkw-1706075217577.mp4",
        mediaFileDetails: "65b0a4513f8112ef049ce2cb",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/nexwo-1706075258791.png",
        thumbnailDetails: "65b0a47a3f8112ef049ce2d3",
        contentHighlights: [
          {
            highlightHeading: "witness celestial wonders",
            highlightDescription:
              "Celestial melodies by artist will take you on an interstellar journey. Experience her enchanting performance that transcends galaxies. Get ready to be captivated by her celestial talent.",
            _id: "65b0a4e43f8112ef049ce339",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/jwqfu-1706075348133.png",
            _id: "65b0a4d43f8112ef049ce2e9",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65b0a4d43f8112ef049ce2e9",
            _id: "65b0a4e43f8112ef049ce337",
          },
        ],
        tag: [],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-24T05:47:44.758Z",
        contentName: "Yoga theme ",
        description: "Yoga theme added",
        contentSlug: "Yogatheme_842",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/lnnkw-1706075217577.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/nexwo-1706075258791.png",
      },
    ],
  },
  {
    _id: "65af9bf83f8112ef049ccdcb",
    user: "65af9a783f8112ef049ccd54",
    channelThumbnail:
      "https://creater-dashboard.s3.amazonaws.com/channelThumbnail/rftlr-1706007463821.png",
    channelThumbnailDetails: "65af9ba73f8112ef049ccda9",
    channelName: "Yoga Channel",
    channelHandle: "yogatheme",
    bannerImage:
      "https://creater-dashboard.s3.amazonaws.com/channelBanner/kipld-1706007510550.png",
    bannerImageDetails: "65af9bd63f8112ef049ccdb5",
    subscriptionPrice: 100,
    agreeToTerms: false,
    channelCategory: "65718a72939000bd49d00648",
    channelSubCategory: ["6585564be994b7dd4237791c"],
    createdAt: "2024-01-23T10:59:04.453Z",
    updatedAt: "2024-01-23T10:59:04.453Z",
    content: [
      {
        _id: "65afa3b63f8112ef049cd2ec",
        user: "65af9a783f8112ef049ccd54",
        channel: "65af9bf83f8112ef049ccdcb",
        category: "2D-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/tjftf-1706009504277.mp4",
        mediaFileDetails: "65afa3a03f8112ef049cd2d7",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/loeft-1706009521899.png",
        thumbnailDetails: "65afa3b13f8112ef049cd2e3",
        contentHighlights: [
          {
            highlightHeading: "Galactic Soundscapes",
            highlightDescription:
              "Journey through the cosmos with electrifying performances by interstellar musicians. Witness the fusion of music and technology in an otherworldly setting, where soundwaves transcend the boundaries of space.",
            _id: "65afa4033f8112ef049cd349",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/xtoml-1706009588715.png",
            _id: "65afa3f43f8112ef049cd31e",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65afa3f43f8112ef049cd31e",
            _id: "65afa4033f8112ef049cd347",
          },
        ],
        tag: [],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-23T11:32:06.895Z",
        contentName: "Yoga Theme ",
        description: "Yoga theme added",
        contentSlug: "YogaTheme_391",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/tjftf-1706009504277.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/loeft-1706009521899.png",
      },
      {
        _id: "65af9f893f8112ef049ccf87",
        user: "65af9a783f8112ef049ccd54",
        channel: "65af9bf83f8112ef049ccdcb",
        category: "360-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/ugveu-1706007589192.mp4",
        mediaFileDetails: "65af9c253f8112ef049ccdfd",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/myhsf-1706008452049.png",
        thumbnailDetails: "65af9f843f8112ef049ccf7e",
        contentHighlights: [
          {
            highlightHeading: "Galactic Soundscapes",
            highlightDescription:
              "Journey through the cosmos with electrifying performances by interstellar musicians. Witness the fusion of music and technology in an otherworldly setting, where soundwaves transcend the boundaries of space.\n",
            _id: "65af9ffa3f8112ef049ccfe0",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/fbkod-1706008553295.png",
            _id: "65af9fe93f8112ef049ccfa1",
          },
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/hvffp-1706008553293.png",
            _id: "65af9fe93f8112ef049ccf9f",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65af9fe93f8112ef049ccfa1",
            _id: "65af9ffa3f8112ef049ccfdc",
          },
          {
            image: "65af9fe93f8112ef049ccf9f",
            _id: "65af9ffa3f8112ef049ccfdd",
          },
        ],
        tag: [],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-23T11:14:17.049Z",
        contentName: "Yoga theme",
        description: "Yoga theme added",
        contentSlug: "Yogatheme_928",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/ugveu-1706007589192.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/myhsf-1706008452049.png",
      },
      {
        _id: "65afa0763f8112ef049cd057",
        user: "65af9a783f8112ef049ccd54",
        channel: "65af9bf83f8112ef049ccdcb",
        category: "180-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/cjfxl-1706008635168.mp4",
        mediaFileDetails: "65afa03b3f8112ef049cd040",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/qpdnl-1706008689186.png",
        thumbnailDetails: "65afa0713f8112ef049cd04c",
        contentHighlights: [
          {
            highlightHeading: "witness celestial wonders",
            highlightDescription:
              "Celestial melodies by artist will take you on an interstellar journey. Experience her enchanting performance that transcends galaxies. Get ready to be captivated by her celestial talent.",
            _id: "65afa1053f8112ef049cd0fc",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/zcymc-1706008804685.png",
            _id: "65afa0e43f8112ef049cd0b5",
          },
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/czvct-1706008804688.png",
            _id: "65afa0e43f8112ef049cd0b7",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65afa0e43f8112ef049cd0b5",
            _id: "65afa1053f8112ef049cd0f8",
          },
          {
            image: "65afa0e43f8112ef049cd0b7",
            _id: "65afa1053f8112ef049cd0f9",
          },
        ],
        tag: [],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-23T11:18:14.183Z",
        contentName: "Yoga 180 video",
        description: "Yoga 180 video added",
        contentSlug: "Yoga180video_235",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/cjfxl-1706008635168.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/qpdnl-1706008689186.png",
      },
      {
        _id: "65afa32c3f8112ef049cd24c",
        user: "65af9a783f8112ef049ccd54",
        channel: "65af9bf83f8112ef049ccdcb",
        category: "volumetric-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/lyund-1706008896015.mp4",
        mediaFileDetails: "65afa1403f8112ef049cd18a",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/ctfsh-1706009384681.png",
        thumbnailDetails: "65afa3283f8112ef049cd243",
        contentHighlights: [
          {
            highlightHeading: "witness celestial wonders",
            highlightDescription:
              "Celestial melodies by artist will take you on an interstellar journey. Experience her enchanting performance that transcends galaxies. Get ready to be captivated by her celestial talent.\nGalactic Soundscapes",
            _id: "65afa38d3f8112ef049cd294",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/zgkov-1706009464577.png",
            _id: "65afa3783f8112ef049cd253",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65afa3783f8112ef049cd253",
            _id: "65afa38d3f8112ef049cd292",
          },
        ],
        tag: [],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-23T11:29:49.002Z",
        contentName: "Yoga theme ",
        description: "Yoga theme added",
        contentSlug: "Yogatheme_847",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/lyund-1706008896015.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/ctfsh-1706009384681.png",
      },
    ],
  },
  {
    _id: "65afa09c3f8112ef049cd081",
    user: "65af915b3f8112ef049cca4a",
    channelThumbnail:
      "https://creater-dashboard.s3.amazonaws.com/channelThumbnail/bfmhj-1707108978564.png",
    channelThumbnailDetails: "65c06a72d694b556b4fb3019",
    channelName: "Fourth Star Entertainment",
    channelHandle: "fourthstarentertainment",
    bannerImage:
      "https://creater-dashboard.s3.amazonaws.com/channelBanner/kgvhw-1707109044965.png",
    bannerImageDetails: "65c06ab4d694b556b4fb3040",
    subscriptionPrice: 20,
    agreeToTerms: false,
    channelCategory: "65718a34939000bd49d00639",
    channelSubCategory: ["657197f4939000bd49d0072c"],
    createdAt: "2024-01-23T11:18:52.363Z",
    updatedAt: "2024-02-05T04:57:32.034Z",
    content: [
      {
        _id: "65afa8d63f8112ef049cd6b1",
        user: "65af915b3f8112ef049cca4a",
        channel: "65afa09c3f8112ef049cd081",
        category: "music",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/pdpyg-1706010707030.mp4",
        mediaFileDetails: "65afa8533f8112ef049cd646",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/vrjmw-1706010832562.png",
        thumbnailDetails: "65afa8d03f8112ef049cd6a8",
        contentHighlights: [
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65afa8f93f8112ef049cd6cd",
          },
        ],
        sneakPeakImages: [],
        sneakPeakImagesDetails: [],
        tag: ["arabian"],
        contentPreference: "subscription",
        tags: [],
        createdAt: "2024-01-23T11:53:58.301Z",
        contentName: "Arabian Music",
        description:
          "quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        contentSlug: "ArabianMusic_385",
        mediaFileS3Url: null,
        mediaFileData: null,
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/vrjmw-1706010832562.png",
      },
      {
        _id: "65afa7113f8112ef049cd4af",
        user: "65af915b3f8112ef049cca4a",
        channel: "65afa09c3f8112ef049cd081",
        category: "2D-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/uphwy-1706010264524.mp4",
        mediaFileDetails: "65afa6983f8112ef049cd490",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/beurj-1706010379594.png",
        thumbnailDetails: "65afa70b3f8112ef049cd4a6",
        contentHighlights: [
          {
            highlightHeading:
              "voluptate velit esse cillum dolore eu fugiat nulla pariatur. ",
            highlightDescription:
              "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in ",
            _id: "65afa79e3f8112ef049cd50d",
          },
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65afa79e3f8112ef049cd50e",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/jxmyp-1706010500419.png",
            _id: "65afa7843f8112ef049cd4c1",
          },
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/vaenn-1706010500416.png",
            _id: "65afa7843f8112ef049cd4bf",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65afa7843f8112ef049cd4c1",
            _id: "65afa79e3f8112ef049cd509",
          },
          {
            image: "65afa7843f8112ef049cd4bf",
            _id: "65afa79e3f8112ef049cd50a",
          },
        ],
        tag: ["fire"],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-23T11:46:25.435Z",
        contentName: "Woman Made of Fire",
        description:
          "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ",
        contentSlug: "WomanMadeofFire_13",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/uphwy-1706010264524.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/beurj-1706010379594.png",
      },
      {
        _id: "65c22865d694b556b4fb4053",
        user: "65af915b3f8112ef049cca4a",
        channel: "65afa09c3f8112ef049cd081",
        category: "360-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/gnosb-1707223123891.mp4",
        mediaFileDetails: "65c22853d694b556b4fb403f",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/phneo-1707223137234.png",
        thumbnailDetails: "65c22861d694b556b4fb4047",
        contentSlug: "thVkKHlk",
        contentHighlights: [
          {
            highlightHeading:
              "Proin tincidunt augue et ultrices vehicula. Sed tristique euismod justo, vel s",
            highlightDescription:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla scelerisque felis nec gravida feugiat. Vestibulum venenatis, lectus at efficitur consectetur, felis eros consectetur purus, vitae vehicula magna est id neque. Vivamus auctor est id turpis congue, vitae feugiat mauris hendrerit. Sed a turpis nisi. Vivamus ac turpis nec leo malesuada sollicitudin nec id tortor. Integer ut rhoncus velit. Donec interdum nulla ac risus posuere, eu lacinia mauris viverra. Sed vel sapien ac felis rhoncus ultricies. Nullam ac ultrices urna. In hac habitasse platea dictumst. Vivamus eu est ac sem faucibus congue. Vivamus nec elit quam. Phasellus et ligula eget odio efficitur feugiat nec nec purus.",
            _id: "65c2289bd694b556b4fb40c7",
          },
          {
            highlightHeading: "",
            highlightDescription:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla scelerisque felis nec gravida feugiat. Vestibulum venenatis, lectus at efficitur consectetur, felis eros consectetur purus, vitae vehicula magna est id neque. Vivamus auctor est id turpis congue, vitae feugiat mauris hendrerit. Sed a turpis nisi. Vivamus ac turpis nec leo malesuada sollicitudin nec id tortor. Integer ut rhoncus velit. Donec interdum nulla ac risus posuere, eu lacinia mauris viverra. Sed vel sapien ac felis rhoncus ultricies. Nullam ac ultrices urna. In hac habitasse platea dictumst. Vivamus eu est ac sem faucibus congue. Vivamus nec elit quam. Phasellus et ligula eget odio efficitur feugiat nec nec purus.",
            _id: "65c2289bd694b556b4fb40c8",
          },
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65c2289bd694b556b4fb40c9",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/deywq-1707223174210.png",
            _id: "65c22886d694b556b4fb405f",
          },
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/ulbig-1707223174217.png",
            _id: "65c22886d694b556b4fb4061",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65c22886d694b556b4fb405f",
            _id: "65c2289bd694b556b4fb40c3",
          },
          {
            image: "65c22886d694b556b4fb4061",
            _id: "65c2289bd694b556b4fb40c4",
          },
        ],
        tag: ["Raccoon", "Jungle life"],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-02-06T12:39:01.791Z",
        contentModerated: true,
        contentName: "If i ever get a chance to live like a Raccoon",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla scelerisque felis nec gravida feugiat. Vestibulum venenatis, lectus at efficitur consectetur, felis eros consectetur purus, vitae vehicula magna est id neque. Vivamus auctor est id turpis congue, vitae feugiat mauris hendrerit. Sed a turpis nisi. Vivamus ac turpis nec leo malesuada sollicitudin nec id tortor. Integer ut rhoncus velit. Donec interdum nulla ac risus posuere, eu lacinia mauris viverra. Sed vel sapien ac felis rhoncus ultricies. Nullam ac ultrices urna. In hac habitasse platea dictumst. Vivamus eu est ac sem faucibus congue. Vivamus nec elit quam. Phasellus et ligula eget odio efficitur feugiat nec nec purus.",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/gnosb-1707223123891.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/phneo-1707223137234.png",
      },
      {
        _id: "65c2276cd694b556b4fb3f75",
        user: "65af915b3f8112ef049cca4a",
        channel: "65afa09c3f8112ef049cd081",
        category: "360-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/cgjye-1707222876019.mp4",
        mediaFileDetails: "65c2275cd694b556b4fb3f61",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/ullbt-1707222886584.png",
        thumbnailDetails: "65c22766d694b556b4fb3f69",
        contentSlug: "thijxlyD",
        contentHighlights: [
          {
            highlightHeading:
              "Proin tincidunt augue et ultrices vehicula. Sed tristique euismod justo, vel s",
            highlightDescription:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla scelerisque felis nec gravida feugiat. Vestibulum venenatis, lectus at efficitur consectetur, felis eros consectetur purus, vitae vehicula magna est id neque. Vivamus auctor est id turpis congue, vitae feugiat mauris hendrerit. Sed a turpis nisi. Vivamus ac turpis nec leo malesuada sollicitudin nec id tortor. Integer ut rhoncus velit. Donec interdum nulla ac risus posuere, eu lacinia mauris viverra. Sed vel sapien ac felis rhoncus ultricies. Nullam ac ultrices urna. In hac habitasse platea dictumst. Vivamus eu est ac sem faucibus congue. Vivamus nec elit quam. Phasellus et ligula eget odio efficitur feugiat nec nec purus.",
            _id: "65c227a2d694b556b4fb3fe9",
          },
          {
            highlightHeading:
              "Proin tincidunt augue et ultrices vehicula. Sed tristique euismod justo, vel s",
            highlightDescription:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla scelerisque felis nec gravida feugiat. Vestibulum venenatis, lectus at efficitur consectetur, felis eros consectetur purus, vitae vehicula magna est id neque. Vivamus auctor est id turpis congue, vitae feugiat mauris hendrerit. Sed a turpis nisi. Vivamus ac turpis nec leo malesuada sollicitudin nec id tortor. Integer ut rhoncus velit. Donec interdum nulla ac risus posuere, eu lacinia mauris viverra. Sed vel sapien ac felis rhoncus ultricies. Nullam ac ultrices urna. In hac habitasse platea dictumst. Vivamus eu est ac sem faucibus congue. Vivamus nec elit quam. Phasellus et ligula eget odio efficitur feugiat nec nec purus.",
            _id: "65c227a2d694b556b4fb3fea",
          },
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65c227a2d694b556b4fb3feb",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/tizpk-1707222919804.png",
            _id: "65c22787d694b556b4fb3f81",
          },
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/horgi-1707222919807.png",
            _id: "65c22787d694b556b4fb3f83",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65c22787d694b556b4fb3f81",
            _id: "65c227a2d694b556b4fb3fe5",
          },
          {
            image: "65c22787d694b556b4fb3f83",
            _id: "65c227a2d694b556b4fb3fe6",
          },
        ],
        tag: ["Water", "ocean monsters", "deadly", "creatures"],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-02-06T12:34:52.743Z",
        contentModerated: true,
        contentName: "Ocean Monsters deadly predators",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla scelerisque felis nec gravida feugiat. Vestibulum venenatis, lectus at efficitur consectetur, felis eros consectetur purus, vitae vehicula magna est id neque. Vivamus auctor est id turpis congue, vitae feugiat mauris hendrerit. Sed a turpis nisi. Vivamus ac turpis nec leo malesuada sollicitudin nec id tortor. Integer ut rhoncus velit. Donec interdum nulla ac risus posuere, eu lacinia mauris viverra. Sed vel sapien ac felis rhoncus ultricies. Nullam ac ultrices urna. In hac habitasse platea dictumst. Vivamus eu est ac sem faucibus congue. Vivamus nec elit quam. Phasellus et ligula eget odio efficitur feugiat nec nec purus.",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/cgjye-1707222876019.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/ullbt-1707222886584.png",
      },
      {
        _id: "65c2265dd694b556b4fb3e97",
        user: "65af915b3f8112ef049cca4a",
        channel: "65afa09c3f8112ef049cd081",
        category: "360-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/grnas-1707222601134.mp4",
        mediaFileDetails: "65c22649d694b556b4fb3e83",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/umbse-1707222613404.png",
        thumbnailDetails: "65c22655d694b556b4fb3e8b",
        contentSlug: "thNwFlDB",
        contentHighlights: [
          {
            highlightHeading:
              "Proin tincidunt augue et ultrices vehicula. Sed tristique euismod justo, vel s",
            highlightDescription:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla scelerisque felis nec gravida feugiat. Vestibulum venenatis, lectus at efficitur consectetur, felis eros consectetur purus, vitae vehicula magna est id neque. Vivamus auctor est id turpis congue, vitae feugiat mauris hendrerit. Sed a turpis nisi. Vivamus ac turpis nec leo malesuada sollicitudin nec id tortor. Integer ut rhoncus velit. Donec interdum nulla ac risus posuere, eu lacinia mauris viverra. Sed vel sapien ac felis rhoncus ultricies. Nullam ac ultrices urna. In hac habitasse platea dictumst. Vivamus eu est ac sem faucibus congue. Vivamus nec elit quam. Phasellus et ligula eget odio efficitur feugiat nec nec purus.",
            _id: "65c2268bd694b556b4fb3f0b",
          },
          {
            highlightHeading:
              "Proin tincidunt augue et ultrices vehicula. Sed tristique euismod justo, vel s",
            highlightDescription:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla scelerisque felis nec gravida feugiat. Vestibulum venenatis, lectus at efficitur consectetur, felis eros consectetur purus, vitae vehicula magna est id neque. Vivamus auctor est id turpis congue, vitae feugiat mauris hendrerit. Sed a turpis nisi. Vivamus ac turpis nec leo malesuada sollicitudin nec id tortor. Integer ut rhoncus velit. Donec interdum nulla ac risus posuere, eu lacinia mauris viverra. Sed vel sapien ac felis rhoncus ultricies. Nullam ac ultrices urna. In hac habitasse platea dictumst. Vivamus eu est ac sem faucibus congue. Vivamus nec elit quam. Phasellus et ligula eget odio efficitur feugiat nec nec purus.",
            _id: "65c2268bd694b556b4fb3f0c",
          },
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65c2268bd694b556b4fb3f0d",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/ihzrq-1707222649690.png",
            _id: "65c22679d694b556b4fb3ea3",
          },
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/bacrt-1707222649693.png",
            _id: "65c22679d694b556b4fb3ea5",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65c22679d694b556b4fb3ea3",
            _id: "65c2268bd694b556b4fb3f07",
          },
          {
            image: "65c22679d694b556b4fb3ea5",
            _id: "65c2268bd694b556b4fb3f08",
          },
        ],
        tag: ["Merry Christmas"],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-02-06T12:30:21.665Z",
        contentModerated: true,
        contentName: "Merry Christmas",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla scelerisque felis nec gravida feugiat. Vestibulum venenatis, lectus at efficitur consectetur, felis eros consectetur purus, vitae vehicula magna est id neque. Vivamus auctor est id turpis congue, vitae feugiat mauris hendrerit. Sed a turpis nisi. Vivamus ac turpis nec leo malesuada sollicitudin nec id tortor. Integer ut rhoncus velit. Donec interdum nulla ac risus posuere, eu lacinia mauris viverra. Sed vel sapien ac felis rhoncus ultricies. Nullam ac ultrices urna. In hac habitasse platea dictumst. Vivamus eu est ac sem faucibus congue. Vivamus nec elit quam. Phasellus et ligula eget odio efficitur feugiat nec nec purus.",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/grnas-1707222601134.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/umbse-1707222613404.png",
      },
      {
        _id: "65afa5f73f8112ef049cd3dc",
        user: "65af915b3f8112ef049cca4a",
        channel: "65afa09c3f8112ef049cd081",
        category: "180-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/bfbdv-1706009968257.mp4",
        mediaFileDetails: "65afa5703f8112ef049cd386",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/hhnhh-1706010098093.png",
        thumbnailDetails: "65afa5f23f8112ef049cd3d3",
        contentHighlights: [
          {
            highlightHeading: "Lorem ipsum dolor sit amet",
            highlightDescription:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            _id: "65afa66b3f8112ef049cd448",
          },
          {
            highlightHeading: "Lorem ipsum dolor sit amet",
            highlightDescription:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            _id: "65afa66b3f8112ef049cd449",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/amywo-1706010202081.png",
            _id: "65afa65a3f8112ef049cd403",
          },
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/pdpad-1706010202077.png",
            _id: "65afa65a3f8112ef049cd401",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65afa65a3f8112ef049cd403",
            _id: "65afa66b3f8112ef049cd444",
          },
          {
            image: "65afa65a3f8112ef049cd401",
            _id: "65afa66b3f8112ef049cd445",
          },
        ],
        tag: ["Venice"],
        contentPreference: "subscription",
        tags: [],
        createdAt: "2024-01-23T11:41:43.210Z",
        contentName: "Visit to Venice",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        contentSlug: "VisittoVenice_211",
        mediaFileS3Url: null,
        mediaFileData: null,
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/hhnhh-1706010098093.png",
      },
      {
        _id: "65afa8113f8112ef049cd591",
        user: "65af915b3f8112ef049cca4a",
        channel: "65afa09c3f8112ef049cd081",
        category: "volumetric-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/ntlbn-1706010546363.mp4",
        mediaFileDetails: "65afa7b23f8112ef049cd558",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/ksbtx-1706010634598.png",
        thumbnailDetails: "65afa80a3f8112ef049cd588",
        contentHighlights: [
          {
            highlightHeading:
              "quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            highlightDescription:
              "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ",
            _id: "65afa8463f8112ef049cd60a",
          },
          {
            highlightHeading:
              "quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            highlightDescription:
              "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ",
            _id: "65afa8463f8112ef049cd60b",
          },
          {
            highlightHeading:
              "quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            highlightDescription:
              "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ",
            _id: "65b89aaca37be42646c21819",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/wongj-1706010675865.png",
            _id: "65afa8333f8112ef049cd5b8",
          },
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/bzgef-1706010675862.png",
            _id: "65afa8333f8112ef049cd5b6",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65afa8333f8112ef049cd5b8",
            _id: "65c21349d694b556b4fb3683",
          },
          {
            image: "65afa8333f8112ef049cd5b6",
            _id: "65c21349d694b556b4fb3684",
          },
        ],
        tag: ["stars"],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-23T11:50:41.221Z",
        contentName: "Beyond the stars",
        description:
          "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ",
        currency: "USD",
        price: 20,
        contentModerated: true,
        contentSlug: "Beyondthestars_858",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/ntlbn-1706010546363.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/ksbtx-1706010634598.png",
      },
    ],
  },
  {
    _id: "65afac963f8112ef049cd92c",
    user: "65afab9a3f8112ef049cd8a6",
    channelThumbnail:
      "https://creater-dashboard.s3.amazonaws.com/channelThumbnail/tlzco-1706011782370.png",
    channelThumbnailDetails: "65afac863f8112ef049cd91f",
    channelName: "Art and Design",
    channelHandle: "artndesign",
    bannerImage:
      "https://creater-dashboard.s3.amazonaws.com/channelBanner/qqyfc-1706011778706.png",
    bannerImageDetails: "65afac823f8112ef049cd919",
    subscriptionPrice: 20,
    agreeToTerms: false,
    channelCategory: "657189fe939000bd49d00629",
    channelSubCategory: ["658554b8e994b7dd423778ea"],
    createdAt: "2024-01-23T12:09:58.911Z",
    updatedAt: "2024-01-23T12:09:58.911Z",
    content: [
      {
        _id: "65afb27b3f8112ef049cdfeb",
        user: "65afab9a3f8112ef049cd8a6",
        channel: "65afac963f8112ef049cd92c",
        category: "music",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/pvjey-1706013290066.mp4",
        mediaFileDetails: "65afb26a3f8112ef049cdfc2",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/gwskk-1706013304751.png",
        thumbnailDetails: "65afb2783f8112ef049cdfd8",
        contentHighlights: [
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65afb29c3f8112ef049ce014",
          },
        ],
        sneakPeakImages: [],
        sneakPeakImagesDetails: [],
        tag: ["Rajasthan"],
        contentPreference: "subscription",
        tags: [],
        createdAt: "2024-01-23T12:35:07.956Z",
        contentName: "Rajasthani Folk Song",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
        contentSlug: "RajasthaniFolkSong_819",
        mediaFileS3Url: null,
        mediaFileData: null,
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/gwskk-1706013304751.png",
      },
      {
        _id: "65afb1f03f8112ef049cdefb",
        user: "65afab9a3f8112ef049cd8a6",
        channel: "65afac963f8112ef049cd92c",
        category: "2D-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/qnequ-1706012563971.mp4",
        mediaFileDetails: "65afaf933f8112ef049cdcf4",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/yxolh-1706013162699.png",
        thumbnailDetails: "65afb1ea3f8112ef049cdef2",
        contentHighlights: [
          {
            highlightHeading:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse",
            highlightDescription:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            _id: "65afb22b3f8112ef049cdf4d",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/tepgh-1706013208156.png",
            _id: "65afb2183f8112ef049cdf02",
          },
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/mghpd-1706013208153.png",
            _id: "65afb2183f8112ef049cdf00",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65afb2183f8112ef049cdf02",
            _id: "65afb22b3f8112ef049cdf49",
          },
          {
            image: "65afb2183f8112ef049cdf00",
            _id: "65afb22b3f8112ef049cdf4a",
          },
        ],
        tag: ["Birds"],
        contentPreference: "paid",
        tags: [],
        createdAt: "2024-01-23T12:32:48.320Z",
        contentName: "Little birds",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        currency: "USD",
        price: 20,
        contentSlug: "Littlebirds_204",
        mediaFileS3Url: null,
        mediaFileData: null,
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/yxolh-1706013162699.png",
      },
      {
        _id: "65afaf493f8112ef049cdbe1",
        user: "65afab9a3f8112ef049cd8a6",
        channel: "65afac963f8112ef049cd92c",
        category: "360-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/dryul-1706012083424.mp4",
        mediaFileDetails: "65afadb33f8112ef049cdb2e",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/hxgwk-1706012484749.png",
        thumbnailDetails: "65afaf443f8112ef049cdbd8",
        contentHighlights: [
          {
            highlightHeading: "Enjoy the Kpop concert footage",
            highlightDescription:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            _id: "65afaf833f8112ef049cdc84",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/uyrqv-1706012529122.png",
            _id: "65afaf713f8112ef049cdc1e",
          },
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/auwxp-1706012529141.png",
            _id: "65afaf713f8112ef049cdc21",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65afaf713f8112ef049cdc1e",
            _id: "65b2234bd913da00288b2b36",
          },
          {
            image: "65afaf713f8112ef049cdc21",
            _id: "65b2234bd913da00288b2b37",
          },
        ],
        tag: ["Kpop"],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-23T12:21:29.693Z",
        contentName: "Kpop star night",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        currency: "USD",
        price: 20,
        contentSlug: "Kpopstarnight_201",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/dryul-1706012083424.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/hxgwk-1706012484749.png",
      },
      {
        _id: "65afad6c3f8112ef049cda94",
        user: "65afab9a3f8112ef049cd8a6",
        channel: "65afac963f8112ef049cd92c",
        category: "180-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/vqkyg-1706011926581.mp4",
        mediaFileDetails: "65afad163f8112ef049cd9f6",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/bfbej-1706012007204.png",
        thumbnailDetails: "65afad673f8112ef049cda8b",
        contentHighlights: [
          {
            highlightHeading:
              ", quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            highlightDescription:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
            _id: "65afada93f8112ef049cdaf6",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/apjte-1706012044832.png",
            _id: "65afad8c3f8112ef049cda9f",
          },
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/cwhrd-1706012044835.png",
            _id: "65afad8c3f8112ef049cdaa1",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65afad8c3f8112ef049cda9f",
            _id: "65afada93f8112ef049cdaf2",
          },
          {
            image: "65afad8c3f8112ef049cdaa1",
            _id: "65afada93f8112ef049cdaf3",
          },
        ],
        tag: [],
        contentPreference: "subscription",
        tags: [],
        createdAt: "2024-01-23T12:13:32.129Z",
        contentName: "King of Jungle",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        contentSlug: "KingofJungle_388",
        mediaFileS3Url: null,
        mediaFileData: null,
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/bfbej-1706012007204.png",
      },
      {
        _id: "65afacd53f8112ef049cd95b",
        user: "65afab9a3f8112ef049cd8a6",
        channel: "65afac963f8112ef049cd92c",
        category: "volumetric-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/urufe-1706011843555.mp4",
        mediaFileDetails: "65afacc33f8112ef049cd94c",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/zaavn-1706011858075.png",
        thumbnailDetails: "65afacd23f8112ef049cd952",
        contentHighlights: [
          {
            highlightHeading:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore",
            highlightDescription:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            _id: "65afad093f8112ef049cd9b8",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/uvpeh-1706011900205.png",
            _id: "65afacfc3f8112ef049cd96f",
          },
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/hxqbx-1706011900207.png",
            _id: "65afacfc3f8112ef049cd971",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65afacfc3f8112ef049cd96f",
            _id: "65afad093f8112ef049cd9b4",
          },
          {
            image: "65afacfc3f8112ef049cd971",
            _id: "65afad093f8112ef049cd9b5",
          },
        ],
        tag: ["volcano"],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-23T12:11:01.792Z",
        contentName: "Fuming Volcano island",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        contentSlug: "FumingVolcanoisland_366",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/urufe-1706011843555.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/zaavn-1706011858075.png",
      },
    ],
  },
  {
    _id: "65afad3b3f8112ef049cda06",
    user: "65afab823f8112ef049cd8a1",
    channelThumbnail:
      "https://creater-dashboard.s3.amazonaws.com/channelThumbnail/lfovr-1706011954891.png",
    channelThumbnailDetails: "65afad323f8112ef049cd9fc",
    channelName: "Entertainment",
    channelHandle: "entertainment",
    bannerImage:
      "https://creater-dashboard.s3.amazonaws.com/channelBanner/crqwy-1706011911574.png",
    bannerImageDetails: "65afad073f8112ef049cd991",
    subscriptionPrice: 25,
    agreeToTerms: false,
    channelCategory: "657189a1939000bd49d005e4",
    channelSubCategory: [
      "65719246939000bd49d006ec",
      "6571925d939000bd49d006ef",
      "6571926b939000bd49d006f2",
    ],
    createdAt: "2024-01-23T12:12:43.463Z",
    updatedAt: "2024-01-23T12:13:13.759Z",
    content: [
      {
        _id: "65afb1773f8112ef049cdeb2",
        user: "65afab823f8112ef049cd8a1",
        channel: "65afad3b3f8112ef049cda06",
        category: "music",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/wrtrv-1706012947161.mp4",
        mediaFileDetails: "65afb1133f8112ef049cde9a",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/kfvhs-1706013041875.png",
        thumbnailDetails: "65afb1713f8112ef049cdea9",
        contentHighlights: [
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65afb1a03f8112ef049cdeca",
          },
        ],
        sneakPeakImages: [],
        sneakPeakImagesDetails: [],
        tag: ["Street", "dance", "cool"],
        contentPreference: "paid",
        tags: [],
        createdAt: "2024-01-23T12:30:47.420Z",
        contentName: "Dance",
        description: "Street Dance",
        currency: "USD",
        price: 12,
        contentSlug: "Dance_386",
        mediaFileS3Url: null,
        mediaFileData: null,
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/kfvhs-1706013041875.png",
      },
      {
        _id: "65afb0b03f8112ef049cdde3",
        user: "65afab823f8112ef049cd8a1",
        channel: "65afad3b3f8112ef049cda06",
        category: "2D-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/wyppv-1706012781552.mp4",
        mediaFileDetails: "65afb06d3f8112ef049cddd4",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/wxyko-1706012843000.png",
        thumbnailDetails: "65afb0ab3f8112ef049cddda",
        contentHighlights: [
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65afb0ea3f8112ef049cde46",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/pugub-1706012877773.png",
            _id: "65afb0cd3f8112ef049cdde7",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65afb0cd3f8112ef049cdde7",
            _id: "65afb0ea3f8112ef049cde44",
          },
        ],
        tag: ["headphone", "silence", "peace"],
        contentPreference: "subscription",
        tags: [],
        createdAt: "2024-01-23T12:27:28.261Z",
        contentName: "Peace music",
        description: "This is Silent music",
        contentSlug: "Peacemusic_557",
        mediaFileS3Url: null,
        mediaFileData: null,
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/wxyko-1706012843000.png",
      },
      {
        _id: "65afaff43f8112ef049cdd09",
        user: "65afab823f8112ef049cd8a1",
        channel: "65afad3b3f8112ef049cda06",
        category: "360-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/xahln-1706012572765.mp4",
        mediaFileDetails: "65afaf9c3f8112ef049cdcf7",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/ujmnj-1706012654122.png",
        thumbnailDetails: "65afafee3f8112ef049cdd00",
        contentHighlights: [],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/ruaxp-1706012699553.jpeg",
            _id: "65afb01b3f8112ef049cdd0d",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65afb01b3f8112ef049cdd0d",
            _id: "65b22466d913da00288b2d8e",
          },
        ],
        tag: ["Burning fire", "Dance", "Fire dance"],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-23T12:24:20.513Z",
        contentName: "360 ",
        description: "360 video",
        currency: "USD",
        price: 43,
        contentSlug: "360_320",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/xahln-1706012572765.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/ujmnj-1706012654122.png",
      },
      {
        _id: "65afaf153f8112ef049cdbc4",
        user: "65afab823f8112ef049cd8a1",
        channel: "65afad3b3f8112ef049cda06",
        category: "180-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/cjbje-1706012350328.mp4",
        mediaFileDetails: "65afaebe3f8112ef049cdbb5",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/qkgfk-1706012431801.png",
        thumbnailDetails: "65afaf0f3f8112ef049cdbbb",
        contentHighlights: [
          {
            highlightHeading:
              "Celestial melodies by artist will take you on an interstellar journey.",
            highlightDescription:
              "Experience her enchanting performance that transcends galaxies.",
            _id: "65afaf653f8112ef049cdc16",
          },
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65afaf653f8112ef049cdc17",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/rraus-1706012495768.jpeg",
            _id: "65afaf4f3f8112ef049cdbe5",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65afaf4f3f8112ef049cdbe5",
            _id: "65afaf653f8112ef049cdc14",
          },
        ],
        tag: ["space music"],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-23T12:20:37.308Z",
        contentName: "Dance",
        description: "This is dancing video",
        contentSlug: "Dance_837",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/cjbje-1706012350328.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/qkgfk-1706012431801.png",
      },
      {
        _id: "65afae173f8112ef049cdb3d",
        user: "65afab823f8112ef049cd8a1",
        channel: "65afad3b3f8112ef049cda06",
        category: "volumetric-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/tqham-1706012018181.mp4",
        mediaFileDetails: "65afad723f8112ef049cda98",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/rknkj-1706012178439.png",
        thumbnailDetails: "65afae123f8112ef049cdb34",
        contentHighlights: [
          {
            highlightHeading: "witness celestial wonders",
            highlightDescription:
              "Celestial melodies by artist will take you on an interstellar journey.",
            _id: "65afaea63f8112ef049cdb7a",
          },
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65afaea63f8112ef049cdb7b",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/vftwf-1706012286493.png",
            _id: "65afae7e3f8112ef049cdb49",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65afae7e3f8112ef049cdb49",
            _id: "65afaea63f8112ef049cdb78",
          },
        ],
        tag: ["CD", "DVD", "Music", "OLD"],
        contentPreference: "subscription",
        tags: [],
        createdAt: "2024-01-23T12:16:23.086Z",
        contentName: "Singer's aMic",
        description: "This is singer's mic ",
        contentSlug: "Singer'saMic_710",
        mediaFileS3Url: null,
        mediaFileData: null,
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/rknkj-1706012178439.png",
      },
    ],
  },
  {
    _id: "65afd9343f8112ef049ce0a4",
    user: "65ae52dc3f8112ef049cbe06",
    channelThumbnail:
      "https://creater-dashboard.s3.amazonaws.com/channelThumbnail/wfxkk-1706023134548.png",
    channelThumbnailDetails: "65afd8de3f8112ef049ce089",
    channelName: "C - Test Channel",
    channelHandle: "ctestchannel",
    bannerImage:
      "https://creater-dashboard.s3.amazonaws.com/channelBanner/vziko-1706023182468.png",
    bannerImageDetails: "65afd90e3f8112ef049ce097",
    subscriptionPrice: 0,
    agreeToTerms: false,
    channelCategory: "657189a1939000bd49d005e4",
    channelSubCategory: [
      "657191a7939000bd49d006e0",
      "657191ba939000bd49d006e3",
    ],
    createdAt: "2024-01-23T15:20:20.209Z",
    updatedAt: "2024-01-23T17:20:58.722Z",
    content: [
      {
        _id: "65afdb2b3f8112ef049ce0d3",
        user: "65ae52dc3f8112ef049cbe06",
        channel: "65afd9343f8112ef049ce0a4",
        category: "2D-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/wpzyn-1706023607198.mp4",
        mediaFileDetails: "65afdab73f8112ef049ce0c4",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/bsxjc-1706023719035.png",
        thumbnailDetails: "65afdb273f8112ef049ce0ca",
        contentHighlights: [
          {
            highlightHeading: "testing 2d",
            highlightDescription: "test info here",
            _id: "65afe2bd3f8112ef049ce102",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/fmtfd-1706023785406.png",
            _id: "65afdb693f8112ef049ce0d7",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65afdb693f8112ef049ce0d7",
            _id: "65afe2bd3f8112ef049ce100",
          },
        ],
        tag: ["entertainment"],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-23T15:28:43.108Z",
        contentName: "test 2d video",
        description: "testing 2d upload",
        contentSlug: "test2dvideo_580",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/wpzyn-1706023607198.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/bsxjc-1706023719035.png",
      },
    ],
  },
  {
    _id: "65b0a4d83f8112ef049ce2f3",
    user: "65b0a0d43f8112ef049ce20c",
    channelThumbnail:
      "https://creater-dashboard.s3.amazonaws.com/channelThumbnail/ujufo-1706075214315.png",
    channelThumbnailDetails: "65b0a44e3f8112ef049ce2c5",
    channelName: "Space TEchnology",
    channelHandle: "technoguru",
    bannerImage:
      "https://creater-dashboard.s3.amazonaws.com/channelBanner/xemvn-1706075314097.png",
    bannerImageDetails: "65b0a4b23f8112ef049ce2e0",
    subscriptionPrice: 30,
    agreeToTerms: false,
    channelCategory: "65718a4c939000bd49d00642",
    channelSubCategory: [
      "65719833939000bd49d00737",
      "65719839939000bd49d0073a",
    ],
    createdAt: "2024-01-24T05:49:12.272Z",
    updatedAt: "2024-01-24T05:49:12.272Z",
    content: [
      {
        _id: "65b0d2893f8112ef049ce7e0",
        user: "65b0a0d43f8112ef049ce20c",
        channel: "65b0a4d83f8112ef049ce2f3",
        category: "music",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/lwckx-1706086959121.mp4",
        mediaFileDetails: "65b0d22f3f8112ef049ce7cf",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/njgle-1706087039109.png",
        thumbnailDetails: "65b0d27f3f8112ef049ce7d7",
        contentHighlights: [
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65b0d2be3f8112ef049ce7f8",
          },
        ],
        sneakPeakImages: [],
        sneakPeakImagesDetails: [],
        tag: ["mp3", "mp4", "music"],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-24T09:04:09.643Z",
        contentName: "Mp3 Music",
        description: "This is mp3 music",
        contentSlug: "Mp3Music_673",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/lwckx-1706086959121.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/njgle-1706087039109.png",
      },
      {
        _id: "65b0d17c3f8112ef049ce737",
        user: "65b0a0d43f8112ef049ce20c",
        channel: "65b0a4d83f8112ef049ce2f3",
        category: "2D-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/hsmgx-1706086721713.mp4",
        mediaFileDetails: "65b0d1413f8112ef049ce728",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/mgmuj-1706086773861.png",
        thumbnailDetails: "65b0d1753f8112ef049ce72e",
        contentHighlights: [
          {
            highlightHeading:
              "Journey through the cosmos with electrifying performances by interstellar musicians. ",
            highlightDescription:
              " Witness the fusion of music and technology in an otherworldly setting, where soundwaves transcend the boundaries of space.",
            _id: "65b0d20f3f8112ef049ce76e",
          },
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65b0d20f3f8112ef049ce76f",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/ebryr-1706086897258.png",
            _id: "65b0d1f13f8112ef049ce73d",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65b0d1f13f8112ef049ce73d",
            _id: "65b0d20f3f8112ef049ce76c",
          },
        ],
        tag: ["Song", "2D", "music"],
        contentPreference: "subscription",
        tags: [],
        createdAt: "2024-01-24T08:59:40.808Z",
        contentName: "@2D video",
        description: "This is my new 2D entertainment video",
        contentSlug: "@2Dvideo_915",
        mediaFileS3Url: null,
        mediaFileData: null,
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/mgmuj-1706086773861.png",
      },
      {
        _id: "65b0d0733f8112ef049ce6c2",
        user: "65b0a0d43f8112ef049ce20c",
        channel: "65b0a4d83f8112ef049ce2f3",
        category: "360-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/gdiaq-1706076614569.mp4",
        mediaFileDetails: "65b0a9c63f8112ef049ce623",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/akznl-1706076654758.png",
        thumbnailDetails: "65b0a9ee3f8112ef049ce629",
        contentHighlights: [],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/qcfep-1706086648105.png",
            _id: "65b0d0f83f8112ef049ce6c6",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65b0d0f83f8112ef049ce6c6",
            _id: "65b22432d913da00288b2d12",
          },
        ],
        tag: ["Technology"],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-24T08:55:15.128Z",
        contentName: "Round video",
        description: "This is my first 360 video",
        currency: "USD",
        price: 10,
        contentSlug: "Roundvideo_169",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/gdiaq-1706076614569.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/akznl-1706076654758.png",
      },
      {
        _id: "65b0a9693f8112ef049ce5a9",
        user: "65b0a0d43f8112ef049ce20c",
        channel: "65b0a4d83f8112ef049ce2f3",
        category: "180-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/yqlxo-1706076340643.mp4",
        mediaFileDetails: "65b0a8b43f8112ef049ce59a",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/nqlye-1706076514335.png",
        thumbnailDetails: "65b0a9623f8112ef049ce5a0",
        contentHighlights: [
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65b0a9a13f8112ef049ce5d8",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/jjdcs-1706076556764.png",
            _id: "65b0a98c3f8112ef049ce5ad",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65b0a98c3f8112ef049ce5ad",
            _id: "65b0a9a13f8112ef049ce5d6",
          },
        ],
        tag: ["space", "180"],
        contentPreference: "subscription",
        tags: [],
        createdAt: "2024-01-24T06:08:41.433Z",
        contentName: "180 video of space technology",
        description: "my first space technology",
        contentSlug: "180videoofspacetechnology_855",
        mediaFileS3Url: null,
        mediaFileData: null,
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/nqlye-1706076514335.png",
      },
      {
        _id: "65b0a54c3f8112ef049ce398",
        user: "65b0a0d43f8112ef049ce20c",
        channel: "65b0a4d83f8112ef049ce2f3",
        category: "volumetric-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/jglxe-1706075380837.mp4",
        mediaFileDetails: "65b0a4f43f8112ef049ce36e",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/vlowb-1706075461631.png",
        thumbnailDetails: "65b0a5453f8112ef049ce38f",
        contentHighlights: [
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65b0a88e3f8112ef049ce555",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/fqlld-1706076274174.png",
            _id: "65b0a8723f8112ef049ce52a",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65b0a8723f8112ef049ce52a",
            _id: "65b0a88e3f8112ef049ce553",
          },
        ],
        tag: ["Technology"],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-24T05:51:08.278Z",
        contentName: "Space technology",
        description: "This is my first space technology channel",
        contentSlug: "Spacetechnology_636",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/jglxe-1706075380837.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/vlowb-1706075461631.png",
      },
    ],
  },
  {
    _id: "65b0dff13f8112ef049ce8dd",
    user: "65b0d3253f8112ef049ce822",
    channelThumbnail:
      "https://creater-dashboard.s3.amazonaws.com/channelThumbnail/pzejs-1706090471492.png",
    channelThumbnailDetails: "65b0dfe73f8112ef049ce8d3",
    channelName: "Yoga",
    channelHandle: "yoga",
    bannerImage:
      "https://creater-dashboard.s3.amazonaws.com/channelBanner/cnlby-1706090362819.png",
    bannerImageDetails: "65b0df7a3f8112ef049ce894",
    subscriptionPrice: 28,
    agreeToTerms: false,
    channelCategory: "65718a25939000bd49d00637",
    channelSubCategory: ["65855612e994b7dd4237790c"],
    createdAt: "2024-01-24T10:01:21.439Z",
    updatedAt: "2024-01-24T10:01:21.439Z",
    content: [
      {
        _id: "65b0e62c3f8112ef049cecde",
        user: "65b0d3253f8112ef049ce822",
        channel: "65b0dff13f8112ef049ce8dd",
        category: "music",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/qodyu-1706092037276.mp4",
        mediaFileDetails: "65b0e6053f8112ef049ceccf",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/owfvm-1706092071123.png",
        thumbnailDetails: "65b0e6273f8112ef049cecd5",
        contentHighlights: [
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65b0e6643f8112ef049cecf6",
          },
        ],
        sneakPeakImages: [],
        sneakPeakImagesDetails: [],
        tag: ["padma", "padmasan", "padmasana"],
        contentPreference: "subscription",
        tags: [],
        createdAt: "2024-01-24T10:27:56.008Z",
        contentName: "Padmasana",
        description: "This is padmasana",
        contentSlug: "Padmasana_940",
        mediaFileS3Url: null,
        mediaFileData: null,
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/owfvm-1706092071123.png",
      },
      {
        _id: "65b0e4d23f8112ef049cec53",
        user: "65b0d3253f8112ef049ce822",
        channel: "65b0dff13f8112ef049ce8dd",
        category: "2D-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/prqyg-1706091651248.mp4",
        mediaFileDetails: "65b0e4833f8112ef049cec44",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/zwclf-1706091725474.png",
        thumbnailDetails: "65b0e4cd3f8112ef049cec4a",
        contentHighlights: [
          {
            highlightHeading:
              "journey through the cosmos with electrifying performances by interstellar musicians.",
            highlightDescription:
              "Witness the fusion of music and technology in an otherworldly setting, where soundwaves transcend the boundaries of space.",
            _id: "65b0e5553f8112ef049cec88",
          },
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65b0e5553f8112ef049cec89",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/whojk-1706091833542.png",
            _id: "65b0e5393f8112ef049cec57",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65b0e5393f8112ef049cec57",
            _id: "65b0e5553f8112ef049cec86",
          },
        ],
        tag: ["mat", "sitting yoga"],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-24T10:22:10.288Z",
        contentName: "Bound Angle pose",
        description: "This is bound angle pose",
        contentSlug: "BoundAnglepose_480",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/prqyg-1706091651248.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/zwclf-1706091725474.png",
      },
      {
        _id: "65b0e3ee3f8112ef049ceb7c",
        user: "65b0d3253f8112ef049ce822",
        channel: "65b0dff13f8112ef049ce8dd",
        category: "360-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/trmys-1706091369456.mp4",
        mediaFileDetails: "65b0e3693f8112ef049ceb6d",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/gpuar-1706091496453.png",
        thumbnailDetails: "65b0e3e83f8112ef049ceb73",
        contentHighlights: [],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/hgrxf-1706091581984.png",
            _id: "65b0e43d3f8112ef049ceb80",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65b0e43d3f8112ef049ceb80",
            _id: "65b223e9d913da00288b2c96",
          },
        ],
        tag: ["open yoga", "stretch"],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-24T10:18:22.633Z",
        contentName: "Mass yoga",
        description: "This is mass yoga",
        contentSlug: "Massyoga_431",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/trmys-1706091369456.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/gpuar-1706091496453.png",
      },
      {
        _id: "65b0e3093f8112ef049ceafa",
        user: "65b0d3253f8112ef049ce822",
        channel: "65b0dff13f8112ef049ce8dd",
        category: "180-video",
        mediaFile:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/iftpj-1706091213995.mp4",
        mediaFileDetails: "65b0e2cd3f8112ef049ceaeb",
        thumbnail:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/datrq-1706091266052.png",
        thumbnailDetails: "65b0e3023f8112ef049ceaf1",
        contentHighlights: [
          {
            highlightHeading: "",
            highlightDescription: "",
            _id: "65b0e3573f8112ef049ceb29",
          },
        ],
        sneakPeakImages: [
          {
            image:
              "https://creater-dashboard.s3.amazonaws.com/sneakPeak/geubj-1706091327025.png",
            _id: "65b0e33f3f8112ef049ceafe",
          },
        ],
        sneakPeakImagesDetails: [
          {
            image: "65b0e33f3f8112ef049ceafe",
            _id: "65b0e3573f8112ef049ceb27",
          },
        ],
        tag: ["stand", "standing", "yoga"],
        contentPreference: "free",
        tags: [],
        createdAt: "2024-01-24T10:14:33.820Z",
        contentName: "Meditation",
        description: "This meditation makes mind calm",
        contentSlug: "Meditation_301",
        mediaFileS3Url:
          "https://creater-dashboard.s3.amazonaws.com/videoContent/iftpj-1706091213995.mp4",
        mediaFileData: {
          low: "videoUrlLow.m3u8",
          medium: "videoUrlMedium.m3u8",
          high: "videoUrlHigh.m3u8",
        },
        thumbnailUrl:
          "https://creater-dashboard.s3.amazonaws.com/channelBanner/datrq-1706091266052.png",
      },
    ],
  },
];

const Channels = () => {
  let {
    data: channelContentData,
    isLoading,
    isSuccess,
    error,
    status,
    refetch,
  } = useGetChannelContentAssetQuery({ count: 1 }, { refetch: true });

  if (isLoading) {
    return (
      <div className="w-full h-full grid place-content-center">
        <Loader />
      </div>
    );
  }

  if (isSuccess && channelContentData?.response?.statusEnum == 1) {
    channelContentData = channelContentData?.response?.data;
  } else {
    return (
      <div className="h-full w-full grid place-content-center">
        <p className="text-white/50">Something went wrong</p>
      </div>
    );
  }

  return (
    <>
      {!isLoading && channelContentData?.response?.statusEnum === 1 && (
        <div>
          {" "}
          <div className="__channels_assets_heading mb-[32px]">
            <h4 className="font-medium font-heading text-[18px] tracking-[1px] max-[768px]:p-0 max-[768px]:text-[15px]">
              Channels
            </h4>
          </div>
          {channelContentData?.response?.data.map((channelData, index) => {
            return (
              <div key={channelData._id}>
                <ChannelsAllContent channelDetails={channelData} />
                {index < dummyChannelsData.length - 1 &&
                channelData?.content?.length > 0 ? (
                  <hr className="border-[0.1px] border-[#5A5A5A80] my-[35px] sm:mt-[40px] sm:mb-[56px]" />
                ) : null}
              </div>
            );
          })}
        </div>
      )}
      {!isLoading &&
        (channelContentData?.response?.statusEnum !== 1 ||
          channelContentData?.response?.data.length === 0) && (
          <div className="__explore_default_button flex justify-center items-center h-[50vh] max-[768px]:flex-none max-[1280px]:h-[100%] ">
            <Explore />
          </div>
        )}
      {isLoading && (
        <div className="__default_container mt-[20px] min-h-[300px] flex flex-col justify-center items-center">
          <div className="__no_friend_container flex flex-col">
            <h4 className="text-[16px] text-[#7A7A7A] font-body font-medium">
              <Loader />
            </h4>
          </div>
        </div>
      )}
    </>
  );
};

export default Channels;
