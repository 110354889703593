/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module utility-function
 *@developer Aditya P
 */

/*
    This file contains 3rd party utility function for handling complex logix and calculation
*/

import eyeOn from "assets/images/eye-on.svg";
import eyeOff from "assets/images/eye-off.svg";
import axios from "axios";
import { BASE_URL } from "constant/api/Endpoints";
import { EndpointsSlug } from "constant/api/Endpoints";
import webRequestHandler from "constant/api/apiInstance";
// Toggle Password Visibility Function
export const togglePasswordVisibility = (event) => {
  event.preventDefault();
  const previousSibling = event.currentTarget.previousElementSibling;
  if (previousSibling.tagName === "INPUT") {
    if (previousSibling.getAttribute("type") === "password") {
      previousSibling.setAttribute("type", "text");
      event.currentTarget.innerHTML = `<img src=${eyeOn} alt="PasswordVisibility" />`;
    } else {
      previousSibling.setAttribute("type", "password");
      event.currentTarget.innerHTML = `<img src=${eyeOff} alt="PasswordVisibility" />`;
    }
  }
};

// Validation for Email
export const isValidEmail = (email) => {
  const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
  return emailRegex.test(email);
};

// username
export const isValidUsername = (username) => {
  const userNameRegex = /^[a-zA-Z][a-zA-Z0-9]*$/;
  return userNameRegex.test(username);
};

// validationRules

export function isContainsSpecialChars(value) {
  const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  return specialChars.test(value);
}

export const isContainsNumber = (value) => {
  return /[0-9]/.test(value);
};

export const UPPERCASE_REGEX = new RegExp(/.*[A-Z]/);
export const LOWERCASE_REGEX = new RegExp(/.*[a-z]/);
export const NUMBER_REGEX = new RegExp(/.*\d/);
export const LENGTH_REGEX = new RegExp(/.{8,}$/);
export const SPECIAL_CHARS_REGEX = new RegExp(/[!@#$%^&*(),.?":{}|<>]/);

export const isStrongPassword = (password) => {
  // Check each condition independently
  const isUppercaseValid = UPPERCASE_REGEX.test(password);
  const isLowercaseValid = LOWERCASE_REGEX.test(password);
  const isNumberValid = NUMBER_REGEX.test(password);
  const isLengthValid = LENGTH_REGEX.test(password);
  const isSpecialCharValid = SPECIAL_CHARS_REGEX.test(password);

  // Return true if all conditions are met
  return (
    isUppercaseValid &&
    isLowercaseValid &&
    isNumberValid &&
    isLengthValid &&
    isSpecialCharValid
  );
};
export const PASSWORD_VALID_REGEX = new RegExp(
  `^(?=${[
    LENGTH_REGEX.source,
    UPPERCASE_REGEX.source,
    NUMBER_REGEX.source,
    SPECIAL_CHARS_REGEX.source,
  ].join(")(?=")}).*$`
);

/**
 *@desc handling string validation - contain only alphabets not contain any characters, digits or empty
 */
export const IsStringValid = (value) => {
  // value.match(/^\s*$/g) || !value.match(/^[A-Za-z0-9 ]+$/) || value.match(/\d/)
  if (value.match(/^\s*$/g)) {
    return false;
  }
  if (!value.match(/^[a-zA-Z]+$/)) {
    return false;
  }

  return true;
};

export function calculateTotalPages(totalDataCount, rowsPerPage) {
  return Math.ceil(totalDataCount / rowsPerPage);
}

export const getUsdToFstrPrice = async () => {
  const instance = webRequestHandler();
  try {
    const res = await instance.post(
      BASE_URL + EndpointsSlug.POST_CURRENCY_CONVERSION,
      {
        USD: 1,
      }
    );

    if (res.response?.statusEnum != 1) {
      throw new Error(
        res.response?.message || "Couldnt get latest price from api"
      );
    }

    let fstrPrice = res.response?.data?.platform_token?.split(" ")[0];
    let fstrMarketPrice = res.response?.data?.market_token?.split(" ")[0];
    let discount = res.response?.data?.discount?.split(" ")[0];
    discount = Number(discount).toFixed(10);

    sessionStorage.setItem("USDPLATFORMFSTRKEY", fstrPrice);
    sessionStorage.setItem("USDMARKETFSTRKEY", fstrMarketPrice);
    sessionStorage.setItem("FSTRDISCOUNT", discount);
  } catch (e) {
    console.error(e);
  }
};

export const convertUsdToFstrPrice = (usd) => {
  if (usd == undefined) {
    throw new Error("Must pass a valid usd price");
  }

  // both number and string are valid usd types

  let sanitizedUsdPrice = String(usd)?.replace(/[^0-9.]/g, "");

  // if more than one decimal is present
  if (sanitizedUsdPrice.split(".").length > 2) {
    sanitizedUsdPrice =
      sanitizedUsdPrice.split(".")[0] + "." + sanitizedUsdPrice.split(".")[1];
  }

  const oneUsdToFstr = JSON.parse(sessionStorage.getItem("USDPLATFORMFSTRKEY"));

  let fstrPrice = oneUsdToFstr * sanitizedUsdPrice;

  return fstrPrice.toFixed(6);
};

// Without Round off convert
export const convertActualUsdToFstrPrice = (usd) => {
  if (usd == undefined) {
    throw new Error("Must pass a valid usd price");
  }

  // both number and string are valid usd types

  let sanitizedUsdPrice = String(usd)?.replace(/[^0-9.]/g, "");

  // if more than one decimal is present
  if (sanitizedUsdPrice.split(".").length > 2) {
    sanitizedUsdPrice =
      sanitizedUsdPrice.split(".")[0] + "." + sanitizedUsdPrice.split(".")[1];
  }

  const oneUsdToFstr = JSON.parse(sessionStorage.getItem("USDPLATFORMFSTRKEY"));

  let fstrPrice = oneUsdToFstr * sanitizedUsdPrice;

  return fstrPrice;
};
// this function returns the price in usd against the market fstr token
export const convertUsdToMarketFstrUsd = (usd) => {
  if (usd == undefined) {
    throw new Error("Must pass a valid usd price");
  }

  // both number and string are valid usd types

  let sanitizedUsdPrice = String(usd)?.replace(/[^0-9.]/g, "");

  // if more than one decimal is present
  if (sanitizedUsdPrice.split(".").length > 2) {
    sanitizedUsdPrice =
      sanitizedUsdPrice.split(".")[0] + "." + sanitizedUsdPrice.split(".")[1];
  }

  const fstrPltToken = JSON.parse(sessionStorage.getItem("USDPLATFORMFSTRKEY"));
  const fstrMrktToken = JSON.parse(sessionStorage.getItem("USDMARKETFSTRKEY"));

  let finalInUsd = Number((fstrPltToken / fstrMrktToken) * sanitizedUsdPrice);
  // let finalInUsd = Number((fstrMrktToken / fstrPltToken) * usd).toFixed(10);

  return finalInUsd;
};

export const getFstrDiscount = () => {
  return JSON.parse(sessionStorage.getItem("FSTRDISCOUNT"));
};

export const sanitizeStringToNumber = (price) => {
  price = String(price);
  let sanitizedPrice = price?.replace(/[^0-9.]/g, "");

  // if more than one decimal is present
  if (sanitizedPrice.split(".").length > 2) {
    sanitizedPrice =
      sanitizedPrice.split(".")[0] + "." + sanitizedPrice.split(".")[1];
  }

  return sanitizedPrice;
};
export function cropWithEllipsis(str, maxLength) {
  if (str?.length > maxLength) {
    return str?.substring(0, maxLength - 3) + "...";
  }
  return str;
}

export function removeTrailingZeroes(number) {
  // Convert the number to a string to remove trailing zeroes
  const numberwithDecimal = Number(number).toFixed(4);
  const numberString = numberwithDecimal.toString();

  // Convert the string back to a number
  const trimmedNumber = parseFloat(numberString);

  return trimmedNumber;
}
