import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, EndpointsSlug } from "constant/api/Endpoints";
// Custom base query with error handling
import { baseQueryWithReauth } from "constant/api/apiInstance";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Marketplace-api-calls
 *@developer Vuppuluri Anhad, Amardeep Singh
 */

export const MarketplaceSlice = createApi({
  reducerPath: "Marketplace",
  baseQuery: baseQueryWithReauth({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken") || "";
      headers.set("Content-type", "application/json; charset=UTF-8");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    GetListMarketplaceClothing: builder.query({
      query: ({
        searchQuery = "",
        page = 1,
        limit = 1000,
        clothType = "",
      }) => ({
        url:
          EndpointsSlug.MARKETPLACE_CLOTHING +
          "?searchQuery=" +
          searchQuery +
          "&page=" +
          page +
          "&limit=" +
          limit +
          "&clothType=" +
          clothType,
        method: "GET",
      }),
    }),

    GetSingleClothing: builder.query({
      query: (payload = "") => ({
        url: EndpointsSlug.GET_SINGLE_CLOTHING + "/" + payload,
        method: "GET",
      }),
    }),

    // postEquipDefaultAvatar: builder.mutation({
    //   query: (payload) => ({
    //     url: EndpointsSlug.EQUIP_DEFAULT_CLOTHING_AVATAR,
    //     method: "POST",
    //     body: payload,
    //   }),
    // }),
    // postWearAllItems: builder.mutation({
    //   query: (payload) => ({
    //     url: EndpointsSlug.WEAR_ALL_ITEMS,
    //     method: "POST",
    //     body: payload,
    //   }),
    // }),
    GetListMarketplaceAvatar: builder.query({
      query: ({ searchQuery = "", page = 1, limit = 1000, species = "" }) => ({
        url:
          EndpointsSlug.MARKETPLACE_AVATAR +
          "?searchQuery=" +
          searchQuery +
          "&page=" +
          page +
          "&limit=" +
          limit +
          "&species=" +
          species,
        method: "GET",
      }),
    }),
    GetListMarketplaceCompanion: builder.query({
      query: ({ searchQuery = "", page = 1, limit = 1000, species = "" }) => ({
        url:
          EndpointsSlug.MARKETPLACE_COMPANION +
          "?searchQuery=" +
          searchQuery +
          "&page=" +
          page +
          "&limit=" +
          limit +
          "&species=" +
          species,
        method: "GET",
      }),
    }),
    GetSingleAvatar: builder.query({
      query: (payload = "") => ({
        url: EndpointsSlug.GET_SINGLE_AVATAR + "/" + payload,
        method: "GET",
      }),
    }),
    GetSingleCompanion: builder.query({
      query: (payload = "") => ({
        url: EndpointsSlug.GET_SINGLE_COMPANION + "/" + payload,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetListMarketplaceClothingQuery,
  useGetListMarketplaceAvatarQuery,
  useGetListMarketplaceCompanionQuery,
  useGetSingleClothingQuery,
  useGetSingleAvatarQuery,
  useGetSingleCompanionQuery,
  //   usePostEquipDefaultAvatarMutation,
  //   usePostWearAllItemsMutation,
} = MarketplaceSlice;
