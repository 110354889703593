/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Avatar-wardrobe-component
 *@developer Shubham K
 */

import { createContext, useEffect, useState } from "react";
import { useGetListIapAssetsQuery } from "store/slicer/Iap";

export const WardrobeContext = createContext();

const WardrobeContextProvider = ({ children }) => {
  let { data, isFetching, isError, isSuccess, refetch } =
    useGetListIapAssetsQuery({
      category: "all",
    });

  if (!isFetching && data.response.statusEnum != 1) {
    isError = true;
  }
  const [refetchData, setRefetchData] = useState(false);

  useEffect(() => {
    if (!isFetching) {
      refetch();
    }
  }, [refetchData]);

  const [selectedItems, setSelectedItems] = useState([]);

  console.log("si", selectedItems);

  // get selected items from api and store it in state
  useEffect(() => {
    if (!isFetching && isSuccess && data?.response?.statusEnum == 1) {
      const sI = data.response.data.filter((item) => {
        if (item)
          return (
            item.isEquiped &&
            (item.iapCategory == "avatar" || item.iapCategory == "clothing")
          );
      });
      setSelectedItems(sI);
    }
  }, [isFetching, data]);

  const selectItem = (type, id) => {
    if (!type || !id) {
      return;
    }

    let updatedArr = selectedItems.slice();

    if (type == "avatar") {
      const avtToSelect = data?.response.data.find((item) => {
        return item?.iapCategory == "avatar" && item.iapId == id;
      });

      updatedArr = updatedArr.map((item) => {
        if (item?.iapCategory == "avatar") {
          return avtToSelect;
        }
        return item;
      });
    } else {
      const itemToSelect = data?.response.data.find((item) => {
        return type.toLowerCase().includes(item?.iapType) && item.iapId == id;
      });

      const similarSelectedItem = updatedArr.find((item) =>
        type.toLowerCase().includes(item.iapType)
      );

      // we are checking if an item of similar type is already preesent in the selectedItems arr, if it is then we are simply replacing it, if its not then we are pushing the new item to select to selectedItems arr
      if (similarSelectedItem) {
        updatedArr = updatedArr.map((item) => {
          if (type.toLowerCase().includes(item.iapType)) {
            return itemToSelect;
          }
          return item;
        });
      } else {
        updatedArr.push(itemToSelect);
      }
    }

    setSelectedItems(updatedArr);
  };

  const values = {
    wardrobeData: data,
    isFetching,
    isError,
    isSuccess,
    selectedItems,
    selectItem,
    setRefetchData,
  };

  return (
    <WardrobeContext.Provider value={values}>
      {children}
    </WardrobeContext.Provider>
  );
};

export default WardrobeContextProvider;
