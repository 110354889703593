import React from "react";
import ClockIcon from "assets/images/clock_icon.png";
import PopupWrapper from "../PopupWrapper";
import { Link } from "react-router-dom";

const TransactionProcessing = ({
  open,
  onClose,
  canClose,
  processingText = "We are processing your reqeust",
  showLink = false,
  showProcessingTip=false,
}) => {
  return (
    <PopupWrapper open={open} onClose={onClose} canClose={canClose}>
      <div className="px-4 py-8 flex flex-col justify-center items-center gap-4 font-body bg-[#242424] rounded-[16px]">
        <div className="h-[55px] w-[55px] mb-4 rounded-full animate-pulse">
          <img className="h-full w-full object-cover" src={ClockIcon} alt="processing" />
        </div>
        <p className="text-[24px] leading-[36px] text-center font-semibold">Processing</p>
        <p className="text-[18px] leading-[30px] text-center font-semibold text-white/70">
          {processingText}
        </p>
        {showProcessingTip ?<p className="text-[17px] leading-[30px] text-center font-semibold text-[#fbbc5e]">
          Simply click on your transaction in MetaMask, select 'Speed up', and choose a higher gas fee. Keep in mind that higher gas fees may incur additional costs.
        </p> : <></>}
        {showLink ? (
          <>
            <p className="text-[20px] leading-[30px] text-center font-semibold text-white/70">
              You can wait here or go back and Explore!!!
            </p>
            <Link
              to={`/marketplace`}
              className="mt-3 font-semibold text-[16px] leading-[19.36px] text-center text-[#fbbc5e]"
            >
              Go back to Marketplace
            </Link>
          </>
        ) : (
          <></>
        )}
      </div>
    </PopupWrapper>
  );
};

export default TransactionProcessing;
