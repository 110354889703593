import React from "react";
import Alert from "components/popup/Alert/Alert";
import { useNavigate } from "react-router-dom";

const Success = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen">
      <Alert
        open={true}
        canClose={false}
        heading="Transaction Successful"
        message="Congratulations! Your purchase is successful. Please note that it may take some time to verify the transaction. Once verified, the item will be added to your assets."
        buttonText="Go To My Assets"
        onClose={() => navigate("/my-assets?tab=0")}
      />
    </div>
  );
};

export default Success;
