import React from "react";
import Explore from "components/Explore/Explore";
import AssetCardOpen from "components/card/assets/AssetCardOpen";
import { AvatarCardClass } from "constant";

const Avatar = ({ dataAvatar }) => {
  return (
    <>
      {/* This __explore_default_button class is a conditional component and it will appears when user don't have any assets */}

      {dataAvatar && dataAvatar.length > 0 ? (
        <div className="__clothing_assets_main pl-[15px] max-[768px]:p-0">
          <div className="__clothing_assets_heading mb-[10px]">
            <h4 className="font-medium font-heading text-[18px] tracking-[1px] px-[10px] max-[768px]:p-0 max-[768px]:text-[15px]">
              Avatar
            </h4>
          </div>

          <div className="_assets_container grid grid-cols-4 md:grid-cols-4 max-[1200px]:md:grid-cols-3 max-[768px]:grid-cols-2 gap-x-5 gap-y-4 lg:gap-y-8 mb-14 md:mb-28 mx-auto relative z-10 mt-[30px]">
            {dataAvatar?.map((_vitem, index) => {
              return (
                <AssetCardOpen
                  key={_vitem?.slug}
                  image={_vitem.img}
                  channelID={_vitem.iapType}
                  contentID={_vitem.slug}
                  baseRoute={
                    _vitem.iapCategory !== "avatar"
                      ? `/marketplace/${_vitem.iapCategory}`
                      : `/marketplace/avatars`
                  }
                  name=""
                  title={_vitem.title}
                  cardClass={AvatarCardClass}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <div class="__explore_default_button flex justify-center items-center h-[50vh] max-[768px]:flex-none max-[1280px]:h-[100%]">
          <Explore />
          {/* <Loader/> */}
        </div>
      )}
    </>
  );
};

export default Avatar;
