/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module shadow-frame
 *@developer Aditya P
 */

const ShadowFrame = ({ className }) => {
  return (
    <div
      className={`${className} shadow-frame absolute pointer-events-none`}
    ></div>
  );
};

export default ShadowFrame;
