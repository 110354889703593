import React from "react";
import SubscribeImg from "assets/images/myassets/EARTH CITY.png";
import Explore from "components/Explore/Explore";
import dateFormat from "dateformat";

const Subscription = ({ item }) => {
  console.log("ittems", item);
  const isSubscribe = true;
  return (
    <>
      {item && item.length > 0 ? (
        <>
          {item?.map((_vitem) => {
            return (
              <>
                <div className="__subscription_main_container w-[100%] mt-[60px] max-[768px]:mt-[40px] mb-[20px] max-[768px]:p-0">
                  <div className="__subscribe__main__list__container">
                    <div className="__subscribe_list flex pl-[10px]">
                      <img
                        src={_vitem.channelThumbnail || SubscribeImg}
                        alt="subscribeIMG"
                        className="rounded-[50%] max-[768px]:mt-[10px] w-[55px] h-[55px] max-[768px]:w-[40px] max-[768px]:h-[40px]"
                      />
                      <div className="pl-[20px] mt-[4px] max-[768px]:pl-[10px] max-[768px]:mt-[8px] max-[1280px]:mt-[15px]">
                        <h5 className="__list_title text-[16px] max-[768px]:text-[12px]">
                          {_vitem.channelName}{" "}
                          <a
                            href={`/marketplace/entertainment/channels/${_vitem?.channelHandle}`}
                            className="text-[12px] pl-[10px] text-[#FBBC5E] hover:underline hover:cursor-pointer max-[768px]:text-[8px] max-[768px]:p-0"
                          >
                            Go to channel
                          </a>
                        </h5>
                        <p className="__list__subtitle text-[13px] text-[grey] max-[768px]:text-[7px]">
                          Subscription renews on{" "}
                          {dateFormat(_vitem?.endDate * 1000, "mmmm dd, yyyy")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[100%] mt-[30px] h-[2px] max-[768px]:pl-[15px] max-[768px]:mt-[15px]">
                  <hr className="border-[0.1px] border-[#5A5A5A80]" />
                </div>
              </>
            );
          })}
        </>
      ) : (
        <div
          data-testid="explore-default-button"
          class="__explore_default_button mt-12 flex justify-center items-center h-[50vh] max-[768px]:flex-none max-[1280px]:h-[100%]"
        >
          <Explore />
          {/* <Loader/> */}
        </div>
      )}
    </>
  );
};

export default Subscription;
