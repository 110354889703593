/**
 * This file is part of FourthStar Before Login Layout
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module AuthLayout
 *@developer Amardeep Singh
 */

import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, Grid, Container } from "@mui/material";
import logo from "assets/images/logo.png";
import { NavLink } from "react-router-dom";
// import StorefrontIcon from '@mui/icons-material/Storefront';
import Footer from "components/footer/Index.jsx";
import HeroOnboarding from "assets/images/hero-onboarding.png";
import LoginBackground from "assets/images/login-background.png";
/* The code defines a functional component called `Index` that render children using outlet */
const Index = ({ onBoardingComplete = false }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {/* <Toaster position="top-center" /> */}
      {isMobile && (
        <div
          className={`relative overflow-hidden ${
            onBoardingComplete ? "!bg-cover !bg-no-repeat" : ""
          }`}
          style={{
            background:
              onBoardingComplete == true ? `url('${HeroOnboarding}')` : "",
          }}
        >
          {!onBoardingComplete && <Box className="leftShadow"></Box>}
          {!onBoardingComplete && <Box className="rightShadow"></Box>}
          <Grid container className="min-h-auth-m xl:min-h-auth relative">
            <Container
              maxWidth="xl"
              className="!flex flex-wrap items-center justify-center p-36 md:py-32"
            >
              <NavLink to="/">
                <img
                  src={logo}
                  className="cursor-pointer auth-logo absolute"
                  alt="Network problem"
                />
              </NavLink>
              <Outlet />
            </Container>
          </Grid>
          <Footer />
        </div>
      )}

      {!isMobile && (
        <div className="flex overflow-hidden h-full relative">
          <Box className="rightShadow"></Box>
          <div className={`relative w-[55%]`}>
            <img
              src={LoginBackground}
              alt="login background"
              className="relative w-[100%] h-[100%] object-cover"
            />
            <NavLink to="/">
              <img
                src={logo}
                className="cursor-pointer auth-logo absolute"
                alt="Network problem"
              />
            </NavLink>
            <div className="top-0 left-0 flex flex-col flex-wrap mt-[400px] xl:mt-[500px] 2xl:mt-[550px] mx-[90px] absolute ">
              <h1 className="font-bold text-[32px] leading-9">
                Step into the Future of Entertainment and Gaming!
              </h1>
              <p className="font-[500] mt-3">
                Join us to unlock a new dimension of entertainment. Dive into a
                world where you can explore, create, and connect like never
                before.
              </p>
            </div>
          </div>
          <div className={`relative overflow-hidden w-[45%] min-h-[100vh]`}>
            <Grid container className="min-h-auth-m xl:min-h-auth relative">
              <Container
                maxWidth="xl"
                className="!flex flex-wrap items-center justify-center p-36 md:py-32"
              >
                <Outlet />
              </Container>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};

export default Index;
