/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module user
 *@developer Sameer <sameer@shadowcast.io>
 */

import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import NotFound from "components/not-found/Index";
// import Apartments from 'pages/marketplace/apartments/Index';
// import Ships from "pages/marketplace/ships/Index";
// import Entertainments from 'pages/marketplace/entertainment/Index';

/* The code defines a functional component called `Index` that serves as a marketplace page with tabs. */
const Index = ({ value, element }) => {
  const navigate = useNavigate();
  const [allCategory] = useState([
    { label: "Apartments", coming: false },
    { label: "Ships", coming: false },
    { label: "Entertainment", coming: false },
    { label: "Avatars", coming: false },
    { label: "Clothing", coming: true },
    { label: "Companions", coming: true },
    { label: "Gaming", coming: true },
  ]);
  const [values, setValues] = useState(0);

  /**
   * The handleChange function is used to navigate to different pages based on the selected value.
   */
  const handleChange = (event, newValue) => {
    console.log(newValue);
    event.preventDefault();
    switch (newValue) {
      case 0:
        navigate("/marketplace/apartments");
        break;
      case 1:
        navigate("/marketplace/ships");
        break;
      case 2:
        navigate("/marketplace/entertainment");
        break;
      case 3:
        navigate("/marketplace/avatars/");
        break;
      case 4:
        navigate("/marketplace/clothing/");
        break;
      case 5:
        navigate("/marketplace/companions/");
        break;
      case 6:
        navigate("/marketplace/gaming/");
        break;
      default:
        navigate("/marketplace/");
        break;
    }
  };

  useEffect(() => {
    if (value) setValues(+value);
  }, [value]);
  return (
    <div className="min-h-[calc(100vh-170px)] container app-container mx-auto pb-16 px-2 md:px-3 xl:px-6 2xl:px-10 relative z-10">
      <ul
        className="flex overflow-x-auto text-sm md:text-base font-semibold text-center py-5 px-2 md:py-8 md:px-4"
        data-testid="tablist"
      >
        {allCategory?.map((category, i) => {
          return (
            <li className="mr-8" key={i} role="tab">
              <a
                href="#"
                onClick={(e) => handleChange(e, i)}
                aria-current={values === i ? "page" : ""}
                className={`inline-block text-[#7A7A7A] hover:text-white ${
                  values === i ? "text-white" : ""
                }`}
              >
                {!category?.coming ? (
                  category?.label
                ) : (
                  <>
                    {category?.label}
                    <span className="ml-2 text-xs clip-text font-medium whitespace-nowrap">
                      Coming Soon
                    </span>
                  </>
                )}
              </a>
            </li>
          );
        })}
      </ul>
      <div className="marketplace_tabs">
        {element ? element : <NotFound className="h-screen" />}
      </div>
    </div>
  );
};

export default Index;
