import { useState, useEffect } from "react";
import EventCard from "../components/eventcard/EventCard";

import card1 from "assets/images/event_card_1.png";
import card2 from "assets/images/event_card_2.png";
import card3 from "assets/images/event_card_3.png";
import card4 from "assets/images/event_card_4.png";
import card5 from "assets/images/event_card_5.png";
import FilterDropdown from "../components/filterdropdown/FilterDropDown";
import { useGetEventsListQuery } from "store/slicer/Events";
import dateFormat from "dateformat";
import Loader from "components/Loader/Loader";
import { Link } from "react-router-dom";

const LiveQuest = () => {
  const [pageNum, setPageNum] = useState(1);
  const [allEventsData, setAllEventsData] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [isError, setIsError] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All");

  const handleFilterChange = (selectedValue) => {
    setSelectedFilter(selectedValue);
  };

  const filterOptions = [
    "All",
    "Volumetric Events",
    "360 Events",
    "180 Events",
  ];

  const {
    data: currentEventsData,
    isFetching,
    isSuccess,
    isError: currentIsError,
  } = useGetEventsListQuery({ searchQuery: "quest", page: pageNum });

  useEffect(() => {
    if (
      !isFetching &&
      isSuccess &&
      currentEventsData.response.statusEnum === 1
    ) {
      console.log("allEventsData", allEventsData);
      setTotalPages(currentEventsData.response?.data?.pagination.totalPages);
      const formattedEventsData = currentEventsData.response.data.data.map(
        (item) => {
          let eventType = "Volumetric";
          if (item.venueType?.includes("360")) {
            eventType = "360";
          }
          if (item.venueType?.includes("180")) {
            eventType = "180";
          }
          if (item.venueType?.includes("2d")) {
            eventType = "2D";
          }
          if (item.venueType?.includes("music")) {
            eventType = "Music";
          }
          return {
            ...item,
            id: item._id,
            type: eventType,
            title: item.name,
            channelName: item.creatorDetails.channelName,
            dateAndTime: dateFormat(item.startTime, "mmmm dd, yyyy, h:MM TT"),
            location: item.venueName,
            price: item.priceFiat,
            imgUrl: item.promoBanner,
          };
        }
      );
      if (pageNum > 1) {
        setAllEventsData((prevEventsData) => [
          ...prevEventsData,
          ...formattedEventsData,
        ]);
      } else {
        setAllEventsData(() => [...formattedEventsData]);
      }
    }
  }, [isFetching, isSuccess, currentEventsData]);

  const handlePageChange = () => {
    setPageNum((prevPageNum) => prevPageNum + 1);
  };

  const dummyEventsData = [
    {
      id: 1,
      type: "360",
      title: "Cave Culinary Quest: 360 Culinary Adventure",
      channelName: "Stellar Sounds Entertainment",
      dateAndTime: "November 12, 2024, 8:30 PM GMT + 5.3",
      location: "360 Lounge",
      price: "48.29",
      imgUrl: card1,
    },
    {
      id: 2,
      type: "180",
      title: "Cave Culinary Quest: 360 Culinary Adventure",
      channelName: "Stellar Sounds Entertainment",
      dateAndTime: "November 12, 2024, 8:30 PM GMT + 5.3",
      location: "180 Lounge",
      price: "48.29",
      imgUrl: card2,
    },
    {
      id: 3,
      type: "360",
      title: "Cave Culinary Quest: 360 Culinary Adventure",
      channelName: "Stellar Sounds Entertainment",
      dateAndTime: "November 12, 2024, 8:30 PM GMT + 5.3",
      location: "360 Lounge",
      price: "48.29",
      imgUrl: card3,
    },
    {
      id: 4,
      type: "360",
      title: "Cave Culinary Quest: 360 Culinary Adventure",
      channelName: "Stellar Sounds Entertainment",
      dateAndTime: "November 12, 2024, 8:30 PM GMT + 5.3",
      location: "360 Lounge",
      price: "48.29",
      imgUrl: card4,
    },
    {
      id: 5,
      type: "360",
      title: "Cave Culinary Quest: 360 Culinary Adventure",
      channelName: "Stellar Sounds Entertainment",
      dateAndTime: "November 12, 2024, 8:30 PM GMT + 5.3",
      location: "360 Lounge",
      price: "48.29",
      imgUrl: card5,
    },
  ];

  let filterdEventsData = allEventsData;

  if (selectedFilter == "Volumetric Events") {
    filterdEventsData = filterdEventsData.filter((item) =>
      item.venueType.toLowerCase().includes("vol")
    );
  }
  if (selectedFilter == "360 Events") {
    filterdEventsData = filterdEventsData.filter((item) =>
      item.venueType.toLowerCase().includes("360")
    );
  }
  if (selectedFilter == "180 Events") {
    filterdEventsData = filterdEventsData.filter((item) =>
      item.venueType.toLowerCase().includes("180")
    );
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <h2 className="font-heading text-[20px] leading-[30px]">
          Upcoming Events
        </h2>
        <div className="pt-3">
          <FilterDropdown
            options={filterOptions}
            onChange={handleFilterChange}
          />
        </div>
      </div>
      {filterdEventsData?.length > 0 && (
        <>
          <div className="mt-[40px] flex justify-center lg:justify-between items-center gap-y-[20px] flex-wrap">
            {filterdEventsData.map((item, ind) => (
              <EventCard key={item?.id} data={item} />
            ))}
          </div>

          <div className="flex justify-center">
            {!isFetching && totalPages && totalPages > pageNum && (
              <button
                onClick={handlePageChange}
                className="inline-flex border cursor-pointer text-sm md:text-base leading-none rounded-2xl border-sec btn-outline hover:text-black hover:font-semibold px-6 py-3 md:py-4 md:px-8 mt-6 font-medium"
              >
                Load More
              </button>
            )}
          </div>
        </>
      )}
      {isFetching && (
        <div className="h-[400px] grid place-content-center">
          <Loader />
        </div>
      )}
      {!isFetching &&
        (!filterdEventsData || filterdEventsData?.length == 0) && (
          <div className="h-[60vh] flex flex-col gap-3 items-center justify-center ">
            <p className="font-heading font-semibold clip-text">Coming Soon</p>
            <p className="text-[#7A7A7A] font-semibold">
              Try exploring other exciting contents in marketplace.
            </p>
            <Link
              to="/marketplace"
              className="border-[1.5px] border-sec text-sm mt-4 rounded-lg max-w-[250px] hover:bg-sec hover:text-dark font-bold text-center w-full py-3 px-4"
            >
              Browse other content
            </Link>
          </div>
        )}
    </>
  );
};

export default LiveQuest;
