import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, EndpointsSlug } from "constant/api/Endpoints";
// Custom base query with error handling
import { baseQueryWithReauth } from "constant/api/apiInstance";
/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Api-Calls-For-Admin
 *@developer Shubham K
 */

export const AdminSlice = createApi({
  reducerPath: "Admin",
  baseQuery: baseQueryWithReauth({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken") || "";
      headers.set("Content-type", "application/json; charset=UTF-8");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    postReportContent: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.REPORT_CONTENT,
        method: "POST",
        body: payload,
      }),
    }),
    postReportChannel: builder.mutation({
      query: (payload) => ({
        url: EndpointsSlug.REPORT_CHANNEL,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { usePostReportContentMutation, usePostReportChannelMutation } =
  AdminSlice;
