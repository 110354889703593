/* eslint-disable jsx-a11y/img-redundant-alt */

import React, { useState, useEffect } from "react";
import ModalCloseIcon from "assets/images/myassets/charm_cross.png";
// import OrderItem from "../../../assets/images/order-item.png";
// import OrderItem2 from "../../../assets/images/order-item2.png";
// import OrderItem3 from "../../../assets/images/order-item3.png";
import { useGetMyOrderDetailQuery } from "store/slicer/MyOrders";
import Loader from "components/Loader/Loader";
import RefundItemModal from "../RefundItem/RefundItemModal";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Order-Item-Modal
 *@developer Amardeep Singh
 */

// order item modal component
const MyOrderItemModal = ({ handleModalStatus, orderId }) => {
  const { data, isLoading, refetch } = useGetMyOrderDetailQuery({ orderId });
  const [orderDetail, setOrderDetail] = useState([]);
  const [refundIssue, setRefundIssue] = useState(false);
  const [issueItem, setIssueItem] = useState(null);

  useEffect(() => {
    if (data?.response?.data?.products?.length) {
      setOrderDetail(data.response.data.products);
    }
  }, [data, orderId]);

  const cancelRefund = () => {
    setRefundIssue(false);
    refetch();
  };

  return (
    <>
      <div className="flex bg-[#00000099] opacity-100 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[9990] outline-none focus:outline-none">
        Overlay
      </div>
      <div
        data-testid="invite-friend-modal"
        className="__invite_search_component hs-overlay flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[9999] outline-none focus:outline-none max-[768px]:p-[20px]"
      >
        <div
          className={`${
            refundIssue ? "bg-[#1f1d1d]" : "bg-[#2C2C2C]"
          } __modal__container w-[45%] max-[768px]:w-[100%] max-h-[400px] max-[1920px]:max-h-[500px] max-[768px]:h-auto max-[1280px]:w-[50%] rounded-[8px] transition-all hs-overlay-open:opacity-100 hs-overlay-open:duration-500 overflow-auto`}
        >
          <div className="__modal_close_icon_container flex justify-end p-[10px]">
            <div
              onClick={handleModalStatus}
              className="bg-[#FFFFFF1A] hover:bg-[#FFFFFF4D] hover:cursor-pointer flex flex-col justify-center items-center rounded-[50%] w-[20px] h-[20px]"
            >
              <img
                src={ModalCloseIcon}
                className="w-[10px] h-[10px] "
                alt="modal_close_btn"
              />
            </div>
          </div>
          <div className="__modal_content_main px-[35px] mb-[20px] max-[768px]:p-0">
            <div className="__modal_heading text-left px-[10px]">
              <h4 className="font-heading text-[20px] ">Order Items</h4>
            </div>
            {orderDetail.map((item, index) => {
              return (
                <div
                  key={index}
                  className="__input_group_main py-[18px] max-[768px]:p-0 max-[768px]:mt-[15px]"
                >
                  <div className="__transaction__table__content_main w-[100%] mt-[20px] overflow-x-scroll">
                    <table className="table-auto w-full">
                      <tbody>
                        <tr
                          className={`[&>td]:px-[10px] [&>td]:py-[15px] [&>td]:text-[14px] ${
                            index === orderDetail.length - 1
                              ? "[&>td]:border-b-0 "
                              : "[&>td]:border-b [&>td]:border-b-[#5A5A5A80]"
                          }  flex `}
                        >
                          <td>
                            <img
                              src={
                                item?.detailedProductInfo.thumbnail ||
                                item?.detailedProductInfo?.smallThumbnail
                                  ?.url ||
                                item?.detailedProductInfo.channelThumbnail ||
                                item?.detailedProductInfo.promoBanner ||
                                item.smallThumbnail?.url
                              }
                              alt="No Image"
                              className="w-[169px] h-[85px]"
                            />
                          </td>
                          <td className="mt-2 w-[40%]">
                            <div>
                              {item.assetName ||
                                item.contentName ||
                                item.channelName ||
                                item.name}
                            </div>
                            <div className="mt-1 text-[#d3c3c380]">
                              {item.productType}
                            </div>
                          </td>
                          <td className="mt-2 text-center ">
                            Price:{" "}
                            {item?.amount?.$numberDecimal || item?.amount}{" "}
                            {data?.response?.data?.currency}
                            {!item.refund && (
                              <p
                                className="text-[10px] text-[#FBBC5E] font-semibold cursor-pointer "
                                onClick={() => {
                                  setRefundIssue(true);
                                  setIssueItem(item);
                                }}
                              >
                                Any Issues with this Item?
                              </p>
                            )}
                            {item.refund &&
                              item?.refund?.adminApprovalStatus ===
                                "pending" && (
                                <p className="text-[12px] text-[#7A7A7A] font-semibold ">
                                  Refund request Initiated
                                </p>
                              )}
                            {item.refund &&
                              item?.refund?.adminApprovalStatus ===
                                "approved" && (
                                <p className="text-[12px] text-white/50 font-semibold ">
                                  Refund credited
                                </p>
                              )}
                            {item.refund &&
                              item?.refund?.adminApprovalStatus ===
                                "rejected" && (
                                <p className="text-[12px] text-white/50 font-semibold ">
                                  Refund rejected
                                </p>
                              )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}
            {isLoading && orderDetail.length === 0 && (
              <div className="__default_container mt-[20px] min-h-[300px] flex flex-col justify-center items-center">
                <div className="__no_friend_container flex flex-col">
                  <h4 className="text-[16px] text-[#7A7A7A] font-body font-medium">
                    <Loader />
                  </h4>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {refundIssue && (
        <RefundItemModal
          cancelRefund={cancelRefund}
          orderId={data?.response?.data?._id}
          productDetail={issueItem}
        />
      )}
    </>
  );
};

export default MyOrderItemModal;
