/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Avatar-wardrobe-component
 *@developer Shubham K
 */

import React, { useState, useContext } from "react";
import RotatePopup from "components/popup/MyAvatar/Rotate/RotatePopup";
import { WardrobeContext } from "./WardrobeContextProvider";

import topwearImg from "assets/images/topwear_item.png";
import bottomwearImg from "assets/images/bottomwear_item.png";
import suitImg from "assets/images/suit_item.png";

const Item = ({ equipped = false, img, largeImg = "", type, id }) => {
  const { selectItem } = useContext(WardrobeContext);

  let defaultImg = topwearImg;
  if (type?.includes("bottomwear")) {
    defaultImg = bottomwearImg;
  }
  if (type?.includes("suit")) {
    defaultImg = suitImg;
  }

  const onClickEquip = () => {
    if (equipped) {
      return;
    }
  };

  let bgColor = "#4B4335";

  if (type?.toLowerCase() == "topwear") {
    bgColor = "#4B4335";
  } else if (type?.toLowerCase() == "bottomwear") {
    bgColor = "#453D32";
  } else if (type?.toLowerCase() == "suits") {
    bgColor = "#453D31";
  }

  const [showPreview, setShowPreview] = useState(false);

  const onClickPreview = () => {
    setShowPreview(true);
  };

  const onPreviewClose = () => {
    setShowPreview(false);
  };

  return (
    <>
      {showPreview && (
        <RotatePopup
          open={showPreview}
          onClose={onPreviewClose}
          img={largeImg}
          type={type}
        />
      )}
      <div
        className={`relative overflow-hidden flex flex-col h-[150px] w-[150px] rounded-[8px] hover:border hover:border-[#fbbc5e]  group`}
        style={{
          backgroundColor: bgColor,
        }}
      >
        <div
          className="hidden group-hover:flex absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-2 py-1 cursor-pointer justify-center items-center gap-1 rounded-[8px] bg-black/20 hover:bg-black/50 transition-all"
          onClick={onClickPreview}
        >
          <div>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_3983_11677)">
                <path
                  d="M4.92188 4.11309V2.63652C4.92188 2.3998 4.72969 2.20996 4.49531 2.20996C4.25859 2.20996 4.06875 2.40215 4.06875 2.63652V4.11309H2.5125C2.27578 4.11309 2.08594 4.30527 2.08594 4.53965C2.08594 4.77637 2.27812 4.96621 2.5125 4.96621H4.06875V6.36777C4.06875 6.60449 4.26094 6.79434 4.49531 6.79434C4.73203 6.79434 4.92188 6.60215 4.92188 6.36777V4.96855H6.39375C6.62812 4.96855 6.82031 4.77637 6.82031 4.53965C6.82031 4.30293 6.62812 4.11309 6.39375 4.11309H4.92188Z"
                  fill="white"
                />
                <path
                  d="M11.7004 10.242L9.26992 7.81152C9.05664 7.59824 8.7707 7.50215 8.4918 7.51621L8.05352 7.07793C8.43789 6.52715 8.86914 5.65996 8.86914 4.53027C8.86914 2.90605 7.99023 1.83027 7.61289 1.44355C7.3082 1.13184 6.17852 0.112305 4.45352 0.112305C2.84102 0.112305 1.76992 0.981836 1.3832 1.35449C0.131641 2.56621 0.0332031 4.09199 0.0332031 4.53262C0.0332031 6.20137 0.961328 7.2959 1.36211 7.68965C1.7582 8.07871 2.84102 8.97402 4.45117 8.97168C4.4793 8.97168 4.50977 8.97168 4.53789 8.97168C5.68398 8.94824 6.55117 8.47949 7.10195 8.06465L7.52383 8.48418C7.50977 8.76543 7.60586 9.04902 7.81914 9.26231L10.2496 11.6928C10.4488 11.892 10.7137 11.9928 10.9762 11.9928C11.2387 11.9928 11.5012 11.892 11.7027 11.6928C12.1012 11.292 12.1012 10.6428 11.7004 10.242ZM6.64023 6.65605C6.37305 6.93262 5.63242 7.58184 4.51211 7.60527C3.36836 7.62402 2.60195 6.99121 2.32305 6.71699C2.10742 6.50371 1.40195 5.71621 1.40195 4.53262C1.40195 4.23027 1.46992 3.17559 2.33008 2.34121C2.53867 2.13965 3.30742 1.4834 4.45117 1.4834C5.6418 1.4834 6.42227 2.18652 6.6332 2.40215C6.83711 2.61074 7.50039 3.37949 7.50039 4.53262C7.50039 5.63887 6.89805 6.38418 6.64023 6.65605Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_3983_11677">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <span>Preview</span>
        </div>
        {equipped && (
          <svg
            className="absolute top-1 right-1"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="20" height="20" rx="10" fill="#FBBC5E" />
            <path
              d="M15.1805 7.01409L15.3462 6.84116L15.1805 6.66823L14.3748 5.82707L14.1944 5.63867L14.0138 5.82698L8.14272 11.9506L5.98604 9.70455L5.80549 9.51652L5.62517 9.70477L4.81946 10.5459L4.65382 10.7189L4.81946 10.8918L7.96232 14.1729L8.14286 14.3614L8.3234 14.1729L15.1805 7.01409Z"
              fill="black"
              stroke="black"
              strokeWidth="0.5"
            />
          </svg>
        )}
        <div className="__IMAGE_CONTAINER__  h-full group-hover:h-[80%] transition-all">
          <img
            className={`${
              type == "suits" ? "w-[90%] h-[90%]" : "w-full h-full"
            } object-contain mx-auto`}
            src={img}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = defaultImg;
            }}
            alt="item"
          />
        </div>
        <div
          className={`scale-y-0 opacity-0 group-hover:opacity-100  group-hover:scale-y-100 bg-[#28231980] ${
            equipped
              ? "text-[#25B39E]"
              : "text-[#FBBC5E] filter hover:brightness-75"
          } font-bold rounded-b-[8px] text-[14px] grid place-content-center py-[3px] cursor-pointer h-[20%] transition-all delay-100 origin-bottom`}
          onClick={onClickEquip}
        >
          <p
            className="text-center"
            onClick={() => {
              selectItem(type, id);
            }}
          >
            {equipped ? "Already Equipped" : "Equip this item"}
          </p>
        </div>
      </div>
    </>
  );
};

export default Item;
