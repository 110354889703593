import React from "react";
import PopupWrapper from "../PopupWrapper";
import AlertIcon from "assets/images/alert_icon.png";

const DeletionError = ({
  open,
  onClose,
  canClose,
  message = "Something went wrong when try to delete the account.",
}) => {
  return (
    <PopupWrapper open={open} onClose={onClose} canClose={canClose}>
      <div className="relative bg-[#2c2c2c] rounded-[8px] py-4 px-4">
        <img
          src={AlertIcon}
          className="w-[52px] h-[52px] mx-auto rounded-full object-cover"
          alt=""
        />
        <h1 className="mx-auto mt-8 font-body font-bold text-[24px] leading-[36px] text-center text-white">
          Account Deletion Fail
        </h1>
        <p className="mt-8 font-body font-normal text-[20px] leading-[24px] text-center">
          {message}
        </p>
        <p className="mt-8 font-body font-normal text-[16px] leading-[24px] text-center">
          Please try again.
        </p>
        <div className="mt-8 w-full md:w-3/4 lg:w-1/2 mx-auto flex justify-center items-center">
          <button
            to="/me"
            className="w-full btn-gradient rounded-[8px] font-Inter font-semibold text-[16px] leading-[19.36px] text-center py-3 px-2 text-black"
            onClick={onClose}
          >
            Go back to My Profile
          </button>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default DeletionError;
