import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL_CREATOR, EndpointsSlug } from 'constant/api/Endpoints'
// Custom base query with error handling
import { baseQueryWithReauth } from "constant/api/apiInstance";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Entertainment-Data-Fetching-Api-Calls
 *@developer Shubham K
 */

 export const CreatorSlice = createApi({
    reducerPath:"Creator",
    baseQuery: baseQueryWithReauth({
        baseUrl: BASE_URL_CREATOR,
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem('accessToken') || "";
            headers.set('Content-type', 'application/json; charset=UTF-8')
            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
                
            }
            return headers
        },
    }),
    endpoints: (builder) =>({
        getAllCategoryDataByChannelId:builder.query({
            query:(channelId="")=>({
                url:EndpointsSlug.GET_ALL_CAT_DATA + "/" +channelId,
                method: 'GET'
            })
        }),        
    })
 })

 export const {
    useGetAllCategoryDataByChannelIdQuery

 } = CreatorSlice