import React, { useEffect } from "react";
import AssetMusicIcon from "assets/images/myassets/ph_play-fill.png";
import { Link } from "react-router-dom";
import {
  convertUsdToFstrPrice,
  cropWithEllipsis,
  removeTrailingZeroes,
} from "utils";
import { useLazyGetSingleUserAssetsByTokenQuery } from "store/slicer/userProfile";

const AssetCardOpen = ({
  cardClass,
  title,
  isComing,
  Mkey,
  category,
  baseRoute,
  image,
  channelID,
  contentID,
  price,
  star,
  setShowComingSoonPopup,
  myAsset = false,
  itemId = "",
}) => {
  const clothingLink = baseRoute + "/" + title?.split(" ").join("-");

  // fetching all user assets here and checking if user already owns this asset, calling this here wouldnt create an issue since the request will be cached and its better than calling this api in every page
  let [
    GetSingleUserAssetsByToken,
    { data: userAssetsData, isFetching, isSuccess, isError },
  ] = useLazyGetSingleUserAssetsByTokenQuery();

  if (!isFetching && isSuccess && userAssetsData?.response?.statusEnum == 1) {
    userAssetsData = userAssetsData?.response?.data;
    userAssetsData = userAssetsData.map((item) => item.content_id);
  } else {
    userAssetsData = [];
  }
  const handleClick = () => {
    if (isComing) {
      setShowComingSoonPopup && setShowComingSoonPopup(true);
    }
  };
  let userOwnsAsset = false;

  if (userAssetsData.includes(itemId)) {
    userOwnsAsset = true;
  }

  useEffect(() => {
    localStorage.getItem("accessToken") && GetSingleUserAssetsByToken();
  }, []);
  return (
    <Link
      to={`${
        !isComing
          ? category === "CLOTHLAN"
            ? clothingLink
            : baseRoute + "/" + channelID + "/" + contentID
          : "#"
      }`}
      onClick={handleClick}
    >
      <div className={cardClass.cardMainclass}>
        <div
          className={cardClass.cardMediaClass}
          style={{
            backgroundImage: `url('${image}')`,
          }}
          onError={({ target }) => {
            // Hide the div if the image fails to load
            target.style.display = "none";
          }}
        >
          {Mkey === "music" ? (
            <div className="w-[50px] h-[50px]">
              <img src={AssetMusicIcon} alt="music+icon" />
            </div>
          ) : image === null ? (
            <></>
          ) : (
            <img src={image} className="w-full object-cover rounded-[10px]" />
          )}
        </div>
        <h5 title={title} className={cardClass.cardtitleClass}>
          {cropWithEllipsis(title, 22)}
        </h5>
        {userOwnsAsset && (
          <span className="text-[14px] font-body font-bold bg-[#FBBC5E]/5 px-2 py-1 rounded-[4px] text-[#6BFEF6]">
            Added to assets
          </span>
        )}
        {!userOwnsAsset &&
          !isComing &&
          price &&
          price.toLowerCase() == "free" && (
            <span className="text-[14px] font-body font-bold bg-[#FBBC5E]/5 px-2 py-1 rounded-[4px]">
              Free
            </span>
          )}
        {!userOwnsAsset &&
          !isComing &&
          price &&
          price.toLowerCase() != "free" && (
            <span className="text-[14px] font-body font-bold bg-[#FBBC5E]/5 px-2 py-1 rounded-[4px] whitespace-nowrap">
              {removeTrailingZeroes(
                convertUsdToFstrPrice(price.split(" ")[0])
              ) +
                " FSTR " +
                "( " +
                Number(price.split(" ")[0]).toFixed(2) +
                " " +
                price.split(" ")[1] +
                " )"}
              {/* {star
              ? Number(star).toFixed(2) +
                " FSTR " +
                "( " +
                Number(price.split(" ")[0]).toFixed(2) +
                " " +
                price.split(" ")[1] +
                " )"
              : Number(price.split(" ")[0]).toFixed(2) +
                " " +
                price.split(" ")[1]} */}
            </span>
          )}
        {isComing && (
          <span className="text-[14px] px-[6px] py-1 font-body text-[#F3A151] font-medium">
            Coming Soon
          </span>
        )}
        {(title === "Starlight Celestial Suit" && <></>) ||
          (title === "Stellar Sentinel Armor" && <></>) ||
          (category === "CLOTHLAN" && (
            <span className="pl-[6px] font-body  px-[6px] py-1 text-[#F3A151] font-medium">
              Coming Soon
            </span>
          ))}
      </div>
    </Link>
  );
};

export default AssetCardOpen;
