/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Apartment-Reducer-functions
 *@developer Aditya P
 */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, EndpointsSlug } from "constant/api/Endpoints";

// Custom base query with error handling
import { baseQueryWithReauth } from "constant/api/apiInstance";

export const ApartmentSlice = createApi({
  reducerPath: "apartment",
  baseQuery: baseQueryWithReauth({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    GetAllApartment: builder.query({
      query: ({ limit, page }) => ({
        url: EndpointsSlug.GET_ALL_APARTMENT,
        method: "GET",
        params: { limit, page },
      }),
    }),
    GetSingleApartment: builder.query({
      query: (payload = "") => ({
        url: EndpointsSlug.GET_SINGLE_APARTMENT + "/" + payload,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetAllApartmentQuery, useGetSingleApartmentQuery } =
  ApartmentSlice;
