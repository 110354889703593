/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Free
 *@developer Sudhanshu
 */

import React from "react";
import CoinIcon from "assets/images/coin-icon.png";
import DummyCart from "assets/images/dummy-cart.png";
import { useLocation, useNavigate } from "react-router-dom";

const Free = ({ title, handleData, isLocked }) => {
  const handleClick = () => {
    handleData("MyAsset");
  };
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      {isLocked ? (
        <div className="relative">
          <img src={DummyCart} className="blur w-full" alt="" />
          <div
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-white font-bold w-full flex justify-center items-center"
            style={{
              zIndex: 10, // Ensures the text is on top of the image
            }}
          >
            <button
              type="button"
              className="flex font-bold text-sm md:text-base rounded-lg btn-gradient hover:text-white w-auto text-black py-3 px-5 mt-3 items-center"
              onClick={() =>
                navigate(`/signin?ref=${location.pathname}`, {
                  replace: true,
                })
              }
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className="mb-1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.7734 10.9092C11.3506 10.9092 11.0078 10.5664 11.0078 10.1436V5.6853C11.0078 4.18271 9.78534 2.9602 8.28271 2.9602C6.78012 2.9602 5.55762 4.18267 5.55762 5.6853V8.27833C5.55762 8.70117 5.21484 9.04395 4.79199 9.04395C4.36915 9.04395 4.02637 8.70117 4.02637 8.27833V5.6853C4.02637 3.33833 5.93574 1.42896 8.28271 1.42896C10.6297 1.42896 12.5391 3.33833 12.5391 5.6853V10.1436C12.5391 10.5664 12.1963 10.9092 11.7734 10.9092Z"
                  fill="currentColor"
                />
                <path
                  d="M12.9411 15.5455H3.62432C2.75447 15.5455 2.04932 14.8404 2.04932 13.9705V8.58403C2.04932 7.71419 2.75447 7.00903 3.62432 7.00903H12.9411C13.811 7.00903 14.5161 7.71419 14.5161 8.58403V13.9705C14.5161 14.8404 13.811 15.5455 12.9411 15.5455Z"
                  fill="currentColor"
                />
                <path
                  d="M3.41587 14.2762H3.33696C3.08843 14.2762 2.88696 14.0747 2.88696 13.8262V8.72832C2.88696 8.47979 3.08843 8.27832 3.33696 8.27832H3.41587C3.6644 8.27832 3.86587 8.47979 3.86587 8.72832V13.8262C3.86587 14.0747 3.6644 14.2762 3.41587 14.2762Z"
                  fill="currentColor"
                />
              </svg>
              &nbsp; Sign In To Continue
            </button>
          </div>
        </div>
      ) : (
        <>
          <h4
            className="text-white text-base mb-0"
            dangerouslySetInnerHTML={{ __html: title }}
          ></h4>
          <button
            type="button"
            onClick={handleClick}
            className="font-bold rounded-lg border-[1.5px] border-sec justify-center w-full py-3 px-5 flex gap-2 mt-5"
          >
            Add to my Assets
          </button>
        </>
      )}
    </>
  );
};

export default Free;
