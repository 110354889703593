import React from "react";

const TagsChip = ({
  styleClass,
  keyId,
  text,
  icon,
  onAddHandle,
  onRemoveHandle,
  type,
}) => {
  return (
    <div
      className={`__tags__chips__card w-max text-center py-[12px] px-[20px] rounded-[20px] ${styleClass} max-[768px]:py-[6px] max-[768px]:px-[10px] cursor-pointer`}
      onClick={
        type === "ADDED" ? () => onRemoveHandle(text) : () => onAddHandle(text)
      }
    >
      <div className="__content__section flex justify-center gap-[12px]">
        <span className="text-[14px] font-body font-medium max-[768px]:text-[10px]">
          {text}
        </span>
        <span className="hover:cursor-pointer flex flex-col justify-center">
          <img
            className="w-[14px] h-[14px] rounded-[50%] hover:bg-[#00000027] p-[2px]"
            src={icon}
            alt={`__tags__${type}`}
          />
        </span>
      </div>
    </div>
  );
};

export default TagsChip;
