import Loader from "components/Loader/Loader";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetSingleNotificationQuery } from "store/slicer/notification";
import ShadowFrame from "components/shadow-frame";
import { timeAgo } from "constant";

const SingleNotification = () => {
  const { notifyId } = useParams();
  const navigate = useNavigate();

  const { data, isLoading, error } = useGetSingleNotificationQuery({
    notificationId: notifyId,
  },{ count: 1 },{ refetchOnMountOrArgChange: true });

  return (
    <div className="__my_notification__container__main mb-[100px] container mx-auto px-[100px] py-[60px] h-[100vh] max-[768px]:px-[15px] max-[768px]:py-[40px] max-[1280px]:px-[15px] relative">
      <ShadowFrame
        className="z-[0] w-[350px] md:w-[250px] h-[350px] md:h-[400px] rounded-[250px] md:rounded-[400px] left-[-10%] max-[768px]:left-[-50%] top-[50%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]"
      />
      <ShadowFrame
        className="z-[0] w-[250px] md:w-[250px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[10%] max-[768px]:right-[50%] top-[60%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]"
      />
      <button
        onClick={() => navigate(-1)}
        className="inline-flex gap-2 text-white font-semibold text-xs md:text-sm leading-normal bg-white/[0.10] hover:bg-white/[0.90] hover:text-dark rounded-3xl py-1.5 px-1.5 pr-3 items-center  top-3 left-3"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M12.5 15L7.5 10L12.5 5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span>Back</span>
      </button>

      {isLoading ? (
        <div className="flex pt-[20px]">
          <Loader />
        </div>
      ) : data?.response?.statusEnum === 1 ? (
        data?.response?.data === null ? (
          <span>No Data</span>
        ) : (
          <div className="__notification__header__main relative mt-[25px]">
            <h4 className="font-heading text-[24px] max-[768px]:text-[18px]">
              {data?.response?.data?.title}
            </h4>

            <div className="__single__notification__content__section my-[20px]">
              <div className="__content__body py-[15px] px-[10px] whitespace-pre-line rounded-[8px] bg-[#2C2C2C] [&>p]:p-0">
                <p>{data?.response?.data?.message}</p>
                <div className="pt-[15px]">
                  <span className="font-body font-medium text-[#FFFFFF99] text-[14px]">
                    {timeAgo(data?.response?.data?.receivedAt)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="pt-[20px]">
          <p>There is some problem while fetching the data</p>
        </div>
      )}
    </div>
  );
};

export default SingleNotification;
