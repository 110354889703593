/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Local-Redux-Store
 *@developer Aditya P
 *@desc Handling Local state management for FourthStar Project
 */

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { UserProfile } from "./slicer/userProfile";
import { UserFriend } from "./slicer/friends";
import { EntertainmentSlice } from "./slicer/Entertainment";
import { UserAssets } from "./slicer/userAssets";
import { CreatorSlice } from "./slicer/Creator";
import { UserNotification } from "./slicer/notification";
import { AnalyticsSlice } from "./slicer/Analytics";
import { AdminSlice } from "./slicer/Admin";
import { ApartmentSlice } from "./slicer/Apartments";
import { CartSlice } from "./slicer/Cart";
import { EventsSlice } from "./slicer/Events";
import { IapSlice } from "./slicer/Iap";
import { MarketplaceSlice } from "./slicer/Marketplace";
import { MyOrders } from "./slicer/MyOrders";
import { UserSubscriptions } from "./slicer/MyAssets/Subscriptions";
import { HelpCenter } from "./slicer/HelpCenter";
import { ShipSlice } from "./slicer/Ships";

// We use all related local API calls for different modules and section of the pages
// Configure the middleware and dynamic reducer function name according to your API needs
export const LocalStore = configureStore({
  reducer: {
    [UserProfile.reducerPath]: UserProfile.reducer,
    [UserFriend.reducerPath]: UserFriend.reducer,
    [EntertainmentSlice.reducerPath]: EntertainmentSlice.reducer,
    [CreatorSlice.reducerPath]: CreatorSlice.reducer,
    [UserAssets.reducerPath]: UserAssets.reducer,
    [UserNotification.reducerPath]: UserNotification.reducer,
    [AnalyticsSlice.reducerPath]: AnalyticsSlice.reducer,
    [AdminSlice.reducerPath]: AdminSlice.reducer,
    [ApartmentSlice.reducerPath]: ApartmentSlice.reducer,
    [CartSlice.reducerPath]: CartSlice.reducer,
    [EventsSlice.reducerPath]: EventsSlice.reducer,
    [IapSlice.reducerPath]: IapSlice.reducer,
    [MarketplaceSlice.reducerPath]: MarketplaceSlice.reducer,
    [MyOrders.reducerPath]: MyOrders.reducer,
    [UserSubscriptions.reducerPath]: UserSubscriptions.reducer,
    [HelpCenter.reducerPath]: HelpCenter.reducer,
    [ShipSlice.reducerPath]: ShipSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(UserProfile.middleware)
      .concat(UserFriend.middleware)
      .concat(EntertainmentSlice.middleware)
      .concat(CreatorSlice.middleware)
      .concat(UserAssets.middleware)
      .concat(UserNotification.middleware)
      .concat(AnalyticsSlice.middleware)
      .concat(AdminSlice.middleware)
      .concat(ApartmentSlice.middleware)
      .concat(CartSlice.middleware)
      .concat(IapSlice.middleware)
      .concat(MarketplaceSlice.middleware)
      .concat(EventsSlice.middleware)
      .concat(MyOrders.middleware)
      .concat(UserSubscriptions.middleware)
      .concat(HelpCenter.middleware)
      .concat(ShipSlice.middleware),
});

setupListeners(LocalStore.dispatch);
