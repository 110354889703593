/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Avatar-wardrobe-component
 *@developer Shubham K
 */

import React from "react";

const SideNavIcon = ({ img, selected, onClickHandler }) => {
  return (
    <div
      className={`rounded-[8px] h-[65px] w-[65px] grid place-content-center bg-black/60 cursor-pointer hover:border hover:border-[#FBBC5E]/50 ${
        selected ? "border border-[#FBBC5E]" : ""
      }`}
      onClick={onClickHandler}
    >
      <img className="pointer-events-none" src={img} alt="wardrobe_nav_thumb" />
    </div>
  );
};

export default SideNavIcon;
