/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module OtpVerification
 *@developer Sudhanshu <<sudhanshut@instaacoders.com>>
 */

import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "store/slicer/userAuth/authActions";
import { clearMsgState } from "store/slicer/userAuth";
import { isValidEmail } from "utils";
import Alert from "components/popup/Alert/Alert";

/* This code is a React component for getting OTP is you forgot account passwords.
 */
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [alertMessage, setAlertMessage] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [successAlert, setSuccessAlert] = useState(true);

  // called when alert popup is closed
  const onCloseAlert = () => {
    setAlertMessage("");
    setSuccessAlert("");
    setAlertHeading("");
    navigate("/");
  };

  const [formData, setFormData] = useState({
    email: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
  });

  // Function to validate the form data
  const validateForm = () => {
    let isValid = checkEmail(formData.email);
    return isValid;
  };

  const { loading, userInfo, userToken, error, success, successMsg } =
    useSelector((state) => state.auth);

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const isFormValid = () => {
    return isValidEmail(formData.email);
  };
  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      formData.email = formData.email.toLowerCase();
      dispatch(forgotPassword(formData));
    }
  };

  const isRequired = (value) => (value === "" ? false : true);
  // checkEmail
  const checkEmail = (value) => {
    let valid = false;
    const fieldInput = value.trim();
    if (!isRequired(fieldInput)) {
      setFormErrors({
        ...formErrors,
        email: "Email is required",
      });
    } else if (!isValidEmail(fieldInput)) {
      setFormErrors({
        ...formErrors,
        email: "Invalid Email Address",
      });
    } else {
      setFormErrors({
        ...formErrors,
        email: "",
      });
      valid = true;
    }
    return valid;
  };

  useEffect(() => {
    if (error) {
      setAlertMessage(error);
      setSuccessAlert(false);
    }
    if (userInfo && userToken && userInfo?.isVerified) {
      navigate("/");
    }
    // redirect user to otp verification if registration was successful
    if (success && successMsg) {
      setAlertMessage(
        "Open the email and click the reset link to create a strong new password for your account."
      );
      setAlertHeading("We've sent a password reset link to your email address");
      setSuccessAlert(true);
      setTimeout(() => {
        dispatch(clearMsgState());
        // navigate('/otp-verification');

        setFormData({
          email: "",
        });
      }, 5000);
      //
    }
  }, [navigate, userInfo, userToken, error, success, successMsg]);

  return (
    <>
      <Box className="w-full max-w-[700px] rounded-lg border border-[#363636] p-5 md:p-[32px] lg:p-[58px] signin-form">
        <Box className="text-white max-w-[500px] mx-auto">
          <h1 className="text-lg lg:text-xl text-center font-heading mb-[28px]">
            Enter Email
          </h1>
          <p className="text-center mb-[40px]">
            Enter email address to receive Password Reset Link.{" "}
          </p>
          <form className="auth-form mb-5" onSubmit={handleSubmit}>
            <div className="mb-4">
              {/* <label htmlFor="password" className="font-medium text-white/[.80] text-sm">Email Address</label> */}
              <div className="mt-2">
                <input
                  type="email"
                  name="email"
                  className={`rounded-lg w-full bg-transparent border border-white focus:border-[#f6f6f6] font-normal py-3 px-5 leading-normal font-semibold outline-none autofill:bg-transparent ${
                    formErrors.email ? "!border-error" : ""
                  }`}
                  id="email"
                  placeholder="user@email.com"
                  value={formData.email}
                  onChange={handleInputChange}
                  onBlur={(e) => checkEmail(e.target.value)}
                />
                {formErrors.email && (
                  <span className="text-error text-sm">{formErrors.email}</span>
                )}
              </div>
            </div>

            <button
              type="submit"
              className="font-bold rounded-lg btn-gradient w-full text-black py-3 px-5 mt-6 flex justify-center"
              disabled={!isFormValid() || loading}
            >
              {loading ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Sending Link...
                </>
              ) : (
                "Send Link"
              )}
            </button>
          </form>
          <div className="text-center">
            <NavLink
              to={"/signin"}
              className="font-semibold text-sec hover:text-secDark w-full mt-[14px]"
            >
              Back to login
            </NavLink>
          </div>
        </Box>
      </Box>
      {alertMessage && (
        <Alert
          canClose={false}
          open={Boolean(alertMessage)}
          onClose={onCloseAlert}
          message={alertMessage}
          heading={alertHeading}
          isSuccess={successAlert}
          buttonText={"Got It!"}
        />
      )}
    </>
  );
};

export default ForgotPassword;
