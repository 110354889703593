import React from "react";
import { useState, useEffect } from "react";
import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";

import nureal from "assets/images/nureal.png";
import ChannelImage from "assets/images/channel.png";
import { Fade, Zoom } from "react-reveal";
import PremAptImg from "assets/images/apartment-banner.jpeg";

import { ChannelContentTab } from "constant";
import ShadowFrame from "components/shadow-frame";
import All from "./All";
import Premium from "./Premium";

import { useGetSingleChannelQuery } from "store/slicer/Entertainment";
import Flag from "components/report/Flag";
import AssetsReportModal from "components/Modal/Report/AssetsReportModal";
import { GlobalStore } from "store/GlobalStore";
import Loader from "components/Loader/Loader";
import ChannelPremium from "components/buy-cards/channelPremium";
import { useCheckUserSubscriptionsMutation } from "store/slicer/userAssets";
import BuyPopupIap from "components/popup/BuyPopupIap/BuyPopupIap";
import { convertActualUsdToFstrPrice } from "utils";
import CreatorInfo from "components/popup/CreatorInfo";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Channel-Page
 *@developer Sudhanshu
 */

const ChannelContent = () => {
  const { channelId } = useParams();

  let [searchParams, setSearchParams] = useSearchParams();
  const [channelSubscribed, setChannelSubscribed] = useState(false);
  const [buyPopupOpen, setBuyPopupOpen] = useState(false);
  const [creatorInfoOpen, setCreatorInfoOpen] = useState(false);
  const [isPopupCart, setIsPopupCart] = useState(false);
  const [IsItemAdded, setIsItemAdded] = useState(false);
  const [popupCurrency, setPopupCurrency] = useState("fstr");

  const location = useLocation();
  const navigate = useNavigate();

  const accessToken = localStorage.getItem("accessToken");
  // need to use redux global state directly because two stores have been setup in the app
  const authState = GlobalStore.getState()?.auth;

  // // user not signed in
  // if (!accessToken) {
  //   navigate(`/signin?ref=${location.pathname}`, {
  //     replace: true,
  //   });
  // }

  // // user signed in but did not verify his email
  // if (accessToken && !authState?.userInfo?.isVerified) {
  //   navigate(`/otp-verification`, {
  //     replace: true,
  //   });
  // }

  const {
    data: channelData = {
      response: {
        data: {},
      },
    },
  } = useGetSingleChannelQuery(channelId);

  //channel details
  const {
    channelThumbnail,
    channelName,
    channelHandle,
    bannerImage,
    _id: cId,
    subscriptionPrice,
  } = channelData?.response?.data;

  const [checkSubscription, checkSubscriptionInfo] =
    useCheckUserSubscriptionsMutation();
  async function checkIfOwnedAsset() {
    try {
      const subscriptionInfo = await checkSubscription({
        channelId: cId,
      });
      const isOwned = subscriptionInfo?.data?.response?.data;
      setChannelSubscribed(isOwned);
    } catch (e) {
      console.error("Error while checking user asset:", e);
    }
  }

  const [parentActivetab, setParentActivetab] = useState({
    id: 0,
    activeTab: "all",
  });

  const [IstooltipHover, setTooltipHover] = useState(false);

  const HandletooltipHover = () => {
    setTooltipHover(!IstooltipHover);
  };

  const [isreportModal, setReportModal] = useState(false);

  const handleCloseReportModal = () => {
    setReportModal(!isreportModal);
  };

  const [thumbLoaded, setThumbLoaded] = useState(false);

  const handleClick = async () => {
    setBuyPopupOpen(true);
    setIsPopupCart(false);
  };

  useEffect(() => {
    if (accessToken && cId ) checkIfOwnedAsset();
  }, [cId]);

  const onPopupClose = () => {
    setBuyPopupOpen(false);
  };

  const onPurchaseCurrencyChange = (currency) => {
    setPopupCurrency(currency);
  };

  const onPopupActionHandler = () => {
    // handle logic for usd currency here
    if (popupCurrency === "usd") {
      onPopupClose();
      navigate(
        `/checkout?isSubscription=true&channelId=${channelData?.response?.data?._id}`
      );
    }

    // handle logic for purchasing from wallet here
    else if (popupCurrency === "fstr") {
      onPopupClose();
      navigate(
        `/checkoutfstr?isSubscription=true&channelId=${channelData?.response?.data?._id}`
      );
    }
  };

  const checkout = () => {
    if (popupCurrency === "usd") {
      navigate(
        `/checkout?productType=channel&amount=${subscriptionPrice}&currency=usd&productId=${cId}&productName=${channelName}`
      );
    } else if (popupCurrency === "fstr") {
      navigate(
        `/checkoutfstr?productType=channel&amount=${convertActualUsdToFstrPrice(
          subscriptionPrice
        )}&cproductId=${cId}&productName=${channelName}`
      );
    }
  };

  return (
    <>
      <div>
        <div className="lg:min-h-[350px] mb-6 md:mb-9 z-10 relative bg-[#333] rounded flex justify-center items-center">
          <Zoom>
            <img
              src={bannerImage}
              alt=""
              className="h-[350px] w-auto bg-cover "
              style={{ objectFit: "fill", width: "-webkit-fill-available" }}
            />
          </Zoom>
          <Zoom delay={500}>
            <button
              onClick={() => navigate(-1)}
              className="inline-flex gap-2 text-white font-semibold text-xs md:text-sm leading-normal bg-white/[0.10] hover:bg-white/[0.90] hover:text-dark rounded-3xl py-1.5 px-1.5 pr-3 items-center absolute top-3 left-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M12.5 15L7.5 10L12.5 5"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Back</span>
            </button>
          </Zoom>
        </div>

        <div className="relative flex items-center mb-[17px] mt-[10px] sm:mt-[-70px]  pl-4 sm:pl-6">
          <div className="z-[12] relative flex-shrink-0">
            <img
              className="w-[60px] h-[60px] sm:w-[154px] sm:h-[154px] border-2 sm:border-4 border-black rounded-full object-cover  mr-[10px] sm:mr-[20px] z-[12] opacity-0 transition-opacity"
              src={channelThumbnail}
              alt="main image"
              onLoad={({ currentTarget }) => {
                setThumbLoaded(true);
                currentTarget.onLoad = null;
                currentTarget.style.opacity = "1";
              }}
              onError={({ currentTarget }) => {
                currentTarget.onError = null;
                // load default channel image for invalid img
                currentTarget.src = ChannelImage;
              }}
            />
            {!thumbLoaded && (
              <div className="absolute top-0 left-0 w-[60px] h-[60px] sm:w-[154px] sm:h-[154px] border-2 sm:border-4 border-black/30 rounded-full  z-[12] grid place-content-center bg-[#212120]/30">
                <div className="mb-2 ml-2">
                  <Loader />
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-between w-full ml-1">
            <div className="__channel__heading__title__section flex gap-x-[10px] ">
              <div className="">
                <div className="flex flex-row ">
                  <span
                    className="text-xs sm:text-2xl font-semibold mr-[10px] sm:mr-[20px]"
                    style={{ textWrap: "nowrap" }}
                  >
                    {channelName}
                  </span>
                  <ChannelPremium
                    type="ChannelPremium"
                    isLocked={!accessToken}
                    justAdded={channelSubscribed}
                    price={subscriptionPrice}
                    handleData={handleClick}
                  />
                </div>
                <div className="flex flex-row">
                  <span
                    className="__channel__username__ text-xl text-[lightgray]"
                    style={{ textWrap: "nowrap" }}
                  >
                    {channelHandle === null || channelHandle === undefined
                      ? ""
                      : `@${channelHandle}`}
                  </span>
                  <span
                    className="ml-4 text-[#FBBC5E] text-base font-semibold cursor-pointer text-center mt-1"
                    onClick={() => setCreatorInfoOpen(true)}
                  >
                    About channel
                  </span>
                </div>
              </div>
            </div>

            <div
              onMouseEnter={HandletooltipHover}
              onMouseLeave={HandletooltipHover}
              className="flex justify-center items-center"
            >
              <div
                onClick={() => {
                  setReportModal(!isreportModal);
                }}
                className={`__report__tooltip__section transition-all delay-100 ${
                  IstooltipHover ? "" : "hidden"
                } hover:cursor-pointer border border-[#FBBC5E] bg-gradient-to-r from-[#FBBC5E33] to-[#F3A15133] px-[12px] py-[2px] rounded-[8px] text-[12px] font-medium font-body mr-[10px] max-[768px]:mr-[10px]`}
              >
                Report this channel
              </div>
              <Flag
                onClick={() => {
                  setReportModal(!isreportModal);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {creatorInfoOpen && (
        <CreatorInfo
          open={creatorInfoOpen}
          onClose={() => setCreatorInfoOpen(false)}
          canClose={true}
          channelDetail={channelData?.response?.data}
        />
      )}
      {buyPopupOpen && (
        <BuyPopupIap
          open={buyPopupOpen}
          onClose={onPopupClose}
          isCart={isPopupCart}
          addedToCart={IsItemAdded}
          loading={false}
          onCurrencyChange={onPurchaseCurrencyChange}
          actionBtnHandler={onPopupActionHandler}
          proceedToCheckoutHandler={checkout}
          isSubscription={true}
          priceUsd={subscriptionPrice}
        />
      )}

      <div
        data-testid="my-assets-main"
        className="__my_assets_main container mx-auto  relative"
      >
        <ShadowFrame className="w-[250px] md:w-[775px] h-[250px] md:h-[775px] rounded-[250px] md:rounded-[775px] -left-[80px] md:-left-[300px] -top-[80px] md:-top-[250px]" />
        <ShadowFrame className="w-[250px] md:w-[775px] h-[250px] md:h-[775px] rounded-[250px] md:rounded-[775px] -right-[80px] md:-right-[300px] -bottom-[80px] md:top-[450px]" />
        <div
          data-testid="parent-tabs"
          className="__myassets_parentTabs_main 
                      border-gray-50 
                      px-[15px] 
                      py-[15px] 
                      [&>ul]:pb-[15px]
                      max-[768px]:p-0
                      z-10 relative"
        >
          <ul
            className={`flex gap-8 [&>li]:font-semibold [&>li]:cursor-pointer`}
          >
            {ChannelContentTab?.map((tabs, ind) => {
              return (
                <li
                  key={tabs.key}
                  className={`text-[#fff] text-xl max-[768px]:text-[12px] transition-all pb-2 border-b-2 ${
                    parentActivetab.activeTab === tabs.key
                      ? "border-[#FBBC5E]"
                      : "text-[gray] border-transparent"
                  }`}
                  onClick={() => setParentActivetab({ activeTab: tabs.key })}
                >
                  {tabs.name}
                </li>
              );
            })}
          </ul>
          {/* <hr className="border-[0.1px] border-[#5A5A5A80]" /> */}
        </div>
        <div className="relative z-10">
          {/* My Assets Container */}
          {parentActivetab.activeTab === "all" && (
            <All justAdded={channelSubscribed} />
          )}

          {/* My Avatar Container */}
          {parentActivetab.activeTab === "premium" && (
            <Premium loginStatus={true} />
          )}
        </div>
      </div>
      {isreportModal ? (
        <AssetsReportModal
          handleModalStatus={handleCloseReportModal}
          contentType="channel"
          idToReport={cId}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ChannelContent;
