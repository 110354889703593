import { NavLink } from "react-router-dom";

const RefundCancellationPolicy = () => {
  return (
    <div className="m-[100px] ">
      <div className="my-[20px] ">
        <p className="text-[28px] font-semibold ">
          Refund and Cancellation Policy
        </p>
        <p className="py-[10px] mt-[20px] font-medium text-white/50 ">
          Our Refund and Cancellation policy is designed to ensure a fair and
          consistent experience for all users. Please read the following policy
          carefully before making a purchase:
        </p>
      </div>
      <div className="my-[20px] ">
        <p className="text-[24px] font-medium ">In App Purchases</p>
        <ol className="list-disc list-inside mt-[20px] flex flex-col gap-y-[10px] font-medium text-white/50 ">
          <li>
            In-app purchases (IAPs) can be cancelled within{" "}
            <span className="text-white ">24 hours</span> of purchase.
          </li>
          <li>
            Refunds for cancelled IAPs may take up to{" "}
            <span className="text-white ">48 hours </span>to process.
          </li>
          <li>
            Refunds will not be applicable if users have already used the IAP in
            the game.
          </li>
        </ol>
      </div>
      <div className="my-[20px] ">
        <p className="text-[24px] font-medium ">Entertainment Contents</p>
        <ol className="list-disc list-inside mt-[20px] flex flex-col gap-y-[10px] font-medium text-white/50 ">
          <li>
            In-app purchases (IAPs) can be cancelled within{" "}
            <span className="text-white ">24 hours</span> of purchase.
          </li>
          <li>
            Refunds for cancelled IAPs may take up to{" "}
            <span className="text-white ">48 hours </span> to process.
          </li>
          <li>
            Refunds are not available for digital content that has been fully or
            partially consumed.
          </li>
        </ol>
      </div>
      <div className="my-[20px] ">
        <p className="text-[24px] font-medium ">Subscriptions</p>
        <ol className="list-disc list-inside mt-[20px] flex flex-col gap-y-[10px] font-medium text-white/50 ">
          <li>
            Users can cancel their subscription at any time. Refunds are{" "}
            <span className="text-white ">not applicable</span> to
            subscriptions.
          </li>
          <li>
            After cancelling, users can access the subscribed content until the
            end of the{" "}
            <span className="text-white ">current billing month</span>. After
            this period, access to the content will be revoked.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default RefundCancellationPolicy;
