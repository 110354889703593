import React from "react";
import DefaultFriend from "assets/images/default_friend.png";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Your-Friend-Card
 *@developer Aditya P
 */

const FriendProfileCard = ({
  fkey,
  h_card,
  userData,
  handleModal,
  handleUnblock,
  loader,
}) => {
  return (
    <div
      onClick={() => handleModal(userData)}
      className={`__list_card_main flex flex-col justify-center items-center max-[668px]:w-[100%] py-[10px] w-[218px] h-[${h_card}] bg-gradient-to-r from-[#FBBC5E0D] to-[#F3A1510D] rounded-[8px] hover:cursor-pointer hover:border-[#FFFFFF80] [&>img]:hover:scale-[1.10]`}
    >
      <img
        src={userData?.photo === null ? DefaultFriend : userData?.photo}
        alt="f_avatar"
        className="w-[100px] h-[100px] rounded-full transition duration-300 ease-in-out object-cover"
      />
      <span className="mt-[15px] text-[16px]">@{userData?.userName}</span>
      <span className="text-[14px] text-[#FFFFFF80]">{`${userData?.firstName} ${
        userData?.lastName === null ? "" : userData?.lastName
      }`}</span>
      {fkey === "BLOCKED" ? (
        <button
          onClick={() => handleUnblock(userData?._id)}
          className="mt-[15px] text-[13px] border border-[#7A7A7A] rounded-[8px] py-[6px] px-[16px] bg-gradient-to-r hover:from-[#FBBC5E] hover:to-[#F3A151] hover:text-[#000] hover:font-semibold hover:border-[#FBBC5E]"
        >
          {loader ? "Unblock..." : "Unblock User"}
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default FriendProfileCard;
