import Loader from "components/Loader/Loader";
import Web3 from "web3";
import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import MetaMaskIcon from "assets/images/metamask-icon.png";
import StarBigIcon from "assets/images/star_big.png";

import { GlobalStore } from "store/GlobalStore";

import ShadowFrame from "components/shadow-frame";

import SelectWalletAccount from "components/popup/SelectWalletAccount/SelectWalletAccount";
import WalletConnectSuccess from "components/popup/WalletConnectSuccess/WalletConnectSuccess";
import abiJson from "constant/abi-json";

const CHAIN_ID = process.env.REACT_APP_WEB3_CHAIN_ID;
const CHAIN_NAME = process.env.REACT_APP_WEB3_CHAIN_NAME;
const CHAIN_RPC_URL = process.env.REACT_APP_WEB3_CHAIN_RPC_URL;
const CURRENCY_NAME = process.env.REACT_APP_WEB3_NATIVE_CURRENCY_NAME;
const CURRENCY_SYMBOL = process.env.REACT_APP_WEB3_NATIVE_CURRENCY_SYMBOL;
const FSTR_CONTRACT = process.env.REACT_APP_WEB3_FSTR_CONTRACT;

const Index = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = localStorage.getItem("accessToken");
  // need to use redux global state directly because two stores have been setup in the app
  const authState = GlobalStore.getState()?.auth;

  // user not signed in
  if (!accessToken) {
    navigate(`/signin?ref=${location.pathname}`, {
      replace: true,
    });
  }

  // user signed in but did not verify his email
  if (accessToken && !authState?.userInfo?.isVerified) {
    navigate(`/otp-verification`, {
      replace: true,
    });
  }

  const [loading, setLoading] = useState(false);
  const [metamaskInstalled, setMetamaskInstalled] = useState(false);
  const [isConnected, setIsConencted] = useState(false);

  const [web3Instance, setWeb3Instance] = useState(null);
  const [rejectedWalletConnect, setRejectedWalletConnect] = useState(false);
  const [rejectedChainDownload, setRejectedChainDownload] = useState(false);
  const [rejectedChainSwitch, setRejectedChainSwitch] = useState(false);

  const [allAccounts, setAllAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const selectedAccountRef = useRef(null);
  const switchingAccount = useRef(false);
  const [showAccountSelectPopup, setShowAccountSelectPopup] = useState(false);

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const [balance, setBalance] = useState(0);

  // const checkMetamask = async () => {
  //   // Check if MetaMask is installed
  //   if (window.ethereum && window.ethereum.isMetaMask) {
  //     // MetaMask is installed
  //     setMetamaskInstalled(true);
  //     console.log("MetaMask is installed");
  //     return true;
  //   } else {
  //     // MetaMask is not installed
  //     setMetamaskInstalled(false);
  //     console.error("MetaMask not detected!");
  //     return false;
  //   }
  // };

  const checkMetamask = async () => {
    try {
      // Check if MetaMask or an Ethereum provider is available
      const isMetamaskAvailable = window.ethereum && window.ethereum.isMetaMask;

      if (isMetamaskAvailable) {
        setMetamaskInstalled(true);
        console.log("MetaMask is installed and available");
        return true;
      } else if (navigator.userAgent.includes("MetaMask")) {
        // Check if the user is using the MetaMask mobile browser
        setMetamaskInstalled(true);
        console.log("MetaMask detected in mobile browser");
        return true;
      } else {
        // No MetaMask detected
        setMetamaskInstalled(false);
        console.error("MetaMask not detected");
        return false;
      }
    } catch (error) {
      console.error("Error checking MetaMask:", error);
      setMetamaskInstalled(false);
      return false;
    }
  };

  /**
   * Checks the FSTR contract for selected account balance and updates in the UI
   * @param {Object} w3Instance - Web3 Instance
   * @param {String} address - User's current selected account
   */

  const updateBalance = async (w3Instance, address) => {
    try {
      // Initialize contract
      const FSTRContract = new w3Instance.eth.Contract(abiJson, FSTR_CONTRACT);
      // Fetch the balance
      const balanceInWei = await FSTRContract.methods.balanceOf(address).call();
      // Convert the balance in wei to ether
      const balanceInNumber = w3Instance.utils.fromWei(balanceInWei, "ether");
      setBalance(balanceInNumber);
    } catch (error) {
      console.log(`updateBalance `, error.code);
    }
  };

  // Initialize Web3
  const initWeb3 = async () => {
    if (!metamaskInstalled) {
      return;
    }
    setLoading(true);
    setRejectedWalletConnect(false);
    if (window.ethereum) {
      const w3Instance = new Web3(window.ethereum);
      try {
        setLoading(true);
        // Request account access if needed
        await window.ethereum.enable();
        setWeb3Instance(w3Instance);

        // choosing accounts

        const accounts = await w3Instance.eth.getAccounts();

        if (accounts?.length <= 1) {
          localStorage.setItem("web3FromAddress", accounts[0]);
          setSelectedAccount(accounts[0]);
          selectedAccountRef.current = accounts[0];
        } else {
          setAllAccounts(accounts);

          if (!selectedAccountRef.current || switchingAccount.current) {
            setLoading(false);

            setShowAccountSelectPopup(true);
          }
          while (!selectedAccountRef.current || switchingAccount.current) {
            await new Promise((resolve) => {
              setTimeout(() => {
                console.log("waiting for user to select account");
                resolve();
              }, 200);
            });
          }
          setShowAccountSelectPopup(false);
          console.log("selected account", selectedAccountRef.current);
        }

        // switching chains
        await switchChain(w3Instance);

        await updateBalance(w3Instance, selectedAccountRef.current);
        if (!isConnected) {
          setShowSuccessPopup(true);
        }
      } catch (error) {
        console.log("User Denied account access", error);
        setRejectedWalletConnect(true);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    } else if (window.web3) {
      // Use the injected web3 instance
      setWeb3Instance(new Web3(window.web3.currentProvider));
    } else {
      console.log("No web3 instance injected, please install MetaMask");
    }
  };

  const switchChain = async (web3Instance) => {
    if (!metamaskInstalled || !web3Instance) {
      //   return;
    }

    setLoading(true);

    if (window.ethereum.networkVersion == CHAIN_ID) {
      setIsConencted(true);
    } else if (window.ethereum.networkVersion != CHAIN_ID) {
      setRejectedChainDownload(false);
      setRejectedChainSwitch(false);
      setLoading(true);
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: web3Instance.utils.toHex(+CHAIN_ID) }],
        });
        // reaching here means, we have successfully switched chains,
        setRejectedChainDownload(false);
        setRejectedChainSwitch(false);
        setLoading(false);
        setIsConencted(true);
      } catch (err) {
        setLoading(false);
        // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
          try {
            setLoading(true);
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainName: CHAIN_NAME,
                  chainId: web3Instance.utils.toHex(+CHAIN_ID),
                  nativeCurrency: {
                    name: CURRENCY_NAME,
                    decimals: 18,
                    symbol: CURRENCY_SYMBOL,
                  },
                  rpcUrls: [CHAIN_RPC_URL],
                },
              ],
            });

            // reaching here means, our chain has just been added to metamask, we should now re request the chain switch from user
            await switchChain(web3Instance);
          } catch (error) {
            console.log("user rejected adding the chain", error);
            setRejectedChainDownload(true);
            setLoading(false);
          }
        } else if (err.code == 4001) {
          console.log("user rejected chain switch");
          setRejectedChainSwitch(true);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const disconnectAccount = async () => {
    const w3Instance = new Web3(window.ethereum);
    if (w3Instance) {
      try {
        // Clear the current provider
        await window.ethereum.request({
          method: "eth_requestAccounts",
          params: [{ eth_accounts: {} }],
        });
        // Update current account state
        setAllAccounts([]);
        localStorage.setItem("web3FromAddress", null);
        setSelectedAccount(null);
        selectedAccountRef.current = null;
        setIsConencted(false);
      } catch (error) {
        console.error("Error disconnecting account:", error);
      }
    }
  };

  // this will check if metamask extension is installed or not in user's browser
  useEffect(() => {
    checkMetamask();

    const checkAndReload = async () => {
      const isInstalled = await checkMetamask();
      if (!isInstalled) {
        // Refresh the current page
        window.location.reload();
      }
    };

    document.addEventListener("visibilitychange", checkAndReload);
    return () => {
      return document.removeEventListener("visibilitychange", checkAndReload);
    };
  }, []);

  // effect to check if an account is already linked for the first render

  useEffect(() => {
    const checkIfAlreadyConnected = async () => {
      if (!window.ethereum) {
        return;
      }

      if (
        window.ethereum.selectedAddress &&
        window.ethereum.networkVersion == CHAIN_ID
      ) {
        try {
          const w3Instance = new Web3(window.ethereum);
          const accounts = await w3Instance.eth.getAccounts();

          if (accounts?.length > 0) {
            localStorage.setItem("web3FromAddress", accounts[0]);
            setSelectedAccount(accounts[0]);
            selectedAccountRef.current = accounts[0];
            setAllAccounts(accounts);
            setIsConencted(true);
            await updateBalance(w3Instance, accounts[0]);
          }
        } catch {}
      }
    };

    // delaying it since ethereum object is not initializing instantly
    const t = setTimeout(checkIfAlreadyConnected, 100);

    return () => {
      return clearTimeout(t);
    };
  }, []);

  useEffect(() => {
    if (switchingAccount.current) {
      initWeb3();
    }
  }, [switchingAccount.current]);

  if (loading) {
    return (
      <div className="relative w-full min-h-screen flex flex-col justify-center items-center">
        <Loader />
        <ShadowFrame className="w-[250px] md:w-[400px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[60%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
      </div>
    );
  }

  return (
    <div className="relative min-h-screen w-full">
      {isConnected && (
        <div className="mx-auto mt-[100px] xl:mt-[150px] border border-[#656565] w-full max-w-[468px] rounded-[8px] py-8 bg-[#656565]/20">
          <h1 className="mx-auto text-center font-bold text-[20px] leading-[30px]">
            FSTR Wallet
          </h1>
          <div className="mt-8 mx-auto w-[113px] h-[110px] rounded-full ">
            <img
              className="w-full h-full object-contain"
              src={StarBigIcon}
              alt=""
            />
          </div>
          <p className="mt-8 text-center font-medium text-[20px] leading-[30px]">
            FSTR Balance:{" "}
            <span className="font-bold text-[#fbbc5e]">{balance} FSTR</span>
          </p>
          <div className="mt-4 mx-auto flex items-center justify-center gap-1">
            <div className="h-[20px] w-[20px] rounded-full object-contain grid place-content-center p-1 bg-white">
              <img
                className=" h-[20x] w-[20px] object-contain"
                src={MetaMaskIcon}
                alt=""
                srcset=""
              />
            </div>
            <span className="text-white/70 font-medium text-center text-[10px] leading-[15px]">
              {selectedAccount}
            </span>
          </div>
          <p className="mt-2 text-center font-semibold text-[10px] leading-[12.19px] text-[#25b39e]">
            Connected
          </p>
          {allAccounts?.length > 1 && (
            <span
              className="mt-8 text-[#fbbc5e] hover:text-[#fbbc5e]/70 cursor-pointer border  rounded-[8px] py-2 px-4 block max-w-[130px] text-center mx-auto  text-[10px] leading-[12.19px] font-semibold"
              onClick={() => {
                setShowAccountSelectPopup(true);
                switchingAccount.current = true;
                selectedAccountRef.current = null;
              }}
            >
              Switch Account
            </span>
          )}

          <div className="mt-14 border border-[#656565]"></div>

          <div className="mt-8 w-full lg:w-3/4 mx-auto flex justify-between items-center p-2">
            <Link
              to="/marketplace"
              className="w-[48%] btn-gradient text-black font-bold rounded-[8px] px-2 py-3 text-center text-[12px] leading-[14.63px]"
            >
              Explore Marketplace
            </Link>
            <Link
              to="https://buy.fourthstar.com/"
              target="_blank"
              className="w-[48%] btn-gradient text-black font-bold rounded-[8px] px-2 py-3 text-center text-[12px] leading-[14.63px]"
            >
              {" "}
              FSTR Airdrop{" "}
            </Link>
            {/* <button className="w-[48%] btn-gradient text-black font-bold rounded-[8px] px-2 py-3 text-center text-[12px] leading-[14.63px]">
              FSTR Airdrop
            </button> */}
          </div>
        </div>
      )}
      {showAccountSelectPopup && (
        <SelectWalletAccount
          open={showAccountSelectPopup}
          accounts={allAccounts}
          canClose={true}
          onClose={() => {
            setShowAccountSelectPopup(false);
            switchingAccount.current = false;
          }}
          onSelectAccount={(index) => {
            localStorage.setItem("web3FromAddress", allAccounts[index]);
            setSelectedAccount(allAccounts[index]);
            selectedAccountRef.current = allAccounts[index];
          }}
        />
      )}
      {showSuccessPopup && (
        <WalletConnectSuccess
          open={showSuccessPopup}
          onClose={() => {
            setShowSuccessPopup(false);
          }}
        />
      )}
      {!isConnected && (
        <div className="mx-auto mt-[100px] xl:mt-[150px]">
          <h2 className="font-normal text-[20px] leading-[30px] text-center font-heading">
            Connect MetaMask Wallet
          </h2>
          <div className="mt-8 w-fit rounded-full border border-[#898989] p-4 mx-auto">
            <img
              className="w-[56px] h-[52px] object-contain"
              src={MetaMaskIcon}
              alt="metamask-icon"
            />
          </div>
          <p className="mt-12 text-center font-medium leading-[24px] font-body">
            <span className="font-semibold">Step 1:</span> Click on “Get
            Started” to connect your MetaMask wallet.
          </p>
          <p className="text-[#9e9b9b] text-center font-medium leading-[24px] font-body">
            (If you don't have a Metamask wallet, go to{" "}
            <a
              href="https://metamask.io/"
              target="_blank"
              className="underline text-[#FBBC5E] cursor-pointer"
              rel="noreferrer"
            >
              https://metamask.io/
            </a>{" "}
            to setup your wallet first and then return to this page.)
          </p>
          <p className="mt-4 text-center font-medium leading-[24px] font-body">
            <span className="font-semibold">Step 2:</span> Log in to your
            MetaMask wallet. Authorize the connection, and you're all set.
          </p>

          <button
            className="mt-12 btn-gradient block mx-auto text-black font-bold text-[16px] leading-[19.5px] rounded-[6px] px-6 py-3 text-center"
            onClick={() => {
              initWeb3();
            }}
            disabled={!metamaskInstalled}
          >
            Connect Wallet
          </button>

          {!metamaskInstalled && (
            <>
              <p className="mx-auto mt-5 text-center text-[16px] leading-[19.5px] font-medium mb-10">
                You dont have MetaMask installed.{" "}
                <a
                  className="font-bold text-[#fbbc5e]"
                  href="https://metamask.io/download.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Click Here
                </a>{" "}
                to download it
                <div className="flex justify-center text-[#9e9b9b] text-center font-light text-[14px]">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.25 4.25H8.75V5.75H7.25V4.25ZM7.25 7.25H8.75V11.75H7.25V7.25ZM8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5ZM8 14C4.6925 14 2 11.3075 2 8C2 4.6925 4.6925 2 8 2C11.3075 2 14 4.6925 14 8C14 11.3075 11.3075 14 8 14Z"
                      fill="#939393"
                    />
                  </svg>
                  Note: This functionality is only available on the web version <br/>
                  and cannot be accessed on mobile devices.
                </div>
              </p>
            </>
          )}
          {rejectedWalletConnect && (
            <p className="mx-auto mt-5 text-center text-[16px] leading-[19.5px] font-medium text-error">
              To proceed you must approve the connection request
            </p>
          )}
          {rejectedChainDownload && (
            <p className="mx-auto mt-5 text-center text-[16px] leading-[19.5px] font-medium text-error">
              To proceed you must add the required FSTR chain
            </p>
          )}
          {rejectedChainSwitch && (
            <p className="mx-auto mt-5 text-center text-[16px] leading-[19.5px] font-medium text-error">
              To proceed you must switch to the required FSTR chain
            </p>
          )}
        </div>
      )}
      <ShadowFrame className="w-[250px] md:w-[400px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[60%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
    </div>
  );
};

export default Index;
