import OutlineButton from "components/buttons/outline-button/OutlineButton";
import React from "react";
import { Link } from "react-router-dom";

const Explore = ({ active = true }) => {
  return (
    <div className="__assets_default_explore_main">
      <div className="__explore_container text-center">
        <h3 className="font-body font-semibold text-[16px] lg:text-[20px] leading-[30px] text-center">
          {active ? "No Active Subscription!" : "No Cancelled Subscriptions!"}
        </h3>
        <p className="mt-4 font-medium text-[grey]">
          {active
            ? "It looks like you don't have an active subscription. To access active subscriptions, please consider subscribing."
            : "You don't have any cancelled subscriptions."}
        </p>
        <Link to="/marketplace/entertainment">
          <OutlineButton
            btnclass="border-[2px] 
                    text-[#fff] 
                      font-medium 
                      border-[#FBBC5E4D] 
                      mt-[25px] 
                      py-[3px] 
                      text-[14px] 
                      px-[30px] 
                      rounded-[7px] 
                      hover:bg-[#FBBC5E4D] 
                      hover:text-[#fff] 
                      hover:border-[#FBBC5E4D]
                      max-[768px]:text-[9px]
                      max-[768px]:px-[15px]
                      max-[768px]:mt-[15px]
                      max-[1280px]:text-[10px]
                      max-[1280px]:px-[13px]
                      max-[1280px]:mt-[15px]
                      "
            btntext="Explore Marketplace"
          />
        </Link>
      </div>
    </div>
  );
};

export default Explore;
