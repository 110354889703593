import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AlphaBotImage from "assets/images/Alpha Bot King.png";
import DracoImage from "assets/images/draco guardian.jpg";
import GoblinImage from "assets/images/Sci-fi goblin.png";
import ExoSuitImage from "assets/images/exo suite.png";
import AlphaLeoImage from "assets/images/Alpha leo.png";
import RoboticDogImage from "assets/images/Robotic dog.png";
import Gremlin from "assets/images/Gremlin.png";
import Gizmo from "assets/images/Gizmo.png";
import Vanguard from "assets/images/Vanguard.png";
import Hover from "assets/images/Hover.png";

import ComingSoon1Image from "assets/images/coming soon comp 1.png";
import ComingSoon2Image from "assets/images/coming soon comp 2.png";
import ComingSoon3Image from "assets/images/coming soon comp 3.png";
import ComingSoon4Image from "assets/images/coming soon comp 4.png";
import ComingSoon5Image from "assets/images/coming soon comp 5.png";

import { convertUsdToFstrPrice, removeTrailingZeroes } from "utils";
import { useLazyGetSingleUserAssetsByTokenQuery } from "store/slicer/userProfile";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Companion-Landing-SliderCard
 */
//  /marketplace/gaming/:category/:item
const SliderCards = ({
  item,
  id,
  baseRoute,
  category,
  type,
  price,
  setShowComingSoonPopup,
}) => {
  const CompanionLink = baseRoute + "/" + id + "/" + item?.IAPslug;
  const isComing =
    item.isComing ||
    item.heading == "coming soon" ||
    item.paymentType === "coming_soon";
  // fetching all user assets here and checking if user already owns this asset, calling this here wouldnt create an issue since the request will be cached and its better than calling this api in every page
  let [
    GetSingleUserAssetsByToken,
    { data: userAssetsData, isFetching, isSuccess, isError },
  ] = useLazyGetSingleUserAssetsByTokenQuery();

  if (!isFetching && isSuccess && userAssetsData?.response?.statusEnum == 1) {
    userAssetsData = userAssetsData?.response?.data;
    userAssetsData = userAssetsData.map((item) => item.content_id);
  } else {
    userAssetsData = [];
  }
  const handleClick = () => {
    if (isComing) {
      setShowComingSoonPopup && setShowComingSoonPopup(true);
    }
  };
  let userOwnsAsset = false;

  if (userAssetsData.includes(item._id)) {
    userOwnsAsset = true;
  }
  const randomImages = [
    ComingSoon1Image,
    ComingSoon2Image,
    ComingSoon3Image,
    ComingSoon4Image,
    ComingSoon5Image,
  ];
  const renderImage = (item) => {
    if (item.heading) {
      if (item.heading === "Alpha Bot King") {
        console.log("itemm", item);
        return <img src={AlphaBotImage} className="rounded-[16px]" />;
      } else if (item.heading === "Draco Guardian") {
        return <img src={DracoImage} className="rounded-[16px]" />;
      } else if (item.heading === "Exo suit") {
        return <img src={ExoSuitImage} className="rounded-[16px]" />;
      } else if (item.heading === "Goblin") {
        return <img src={GoblinImage} className="rounded-[16px]" />;
      } else if (item.heading === "Robotic Dog") {
        return <img src={RoboticDogImage} className="rounded-[16px]" />;
      } else if (item.heading === "Alpha Leo") {
        return <img src={AlphaLeoImage} className="rounded-[16px]" />;
      } else if (item.heading === "Gremlin") {
        return <img src={Gremlin} className="rounded-[16px]" />;
      } else if (item.heading === "Gizmo") {
        return <img src={Gizmo} className="rounded-[16px]" />;
      } else if (item.heading === "Vanguard") {
        return <img src={Vanguard} className="rounded-[16px]" />;
      } else if (item.heading === "Hover") {
        return <img src={Hover} className="rounded-[16px]" />;
      } else {
        // If none of the conditions match, render one of the random images
        return isComing ? (
          <img
            src={randomImages[Math.floor(Math.random() * randomImages.length)]}
            className="rounded-[16px]"
          />
        ) : null;
      }
    } else {
      // If item?.headings doesn't exist, render a random image based on item?.isComing
      return isComing ? (
        <img
          src={randomImages[Math.floor(Math.random() * randomImages.length)]}
          className="rounded-[16px]"
        />
      ) : null;
    }
  };

  useEffect(() => {
    localStorage.getItem("accessToken") && GetSingleUserAssetsByToken();
  }, []);

  return (
    <Link
      to={`${
        type === "COMP" && item.IAPslug && !isComing ? "/" + CompanionLink : "#"
      }`}
      onClick={handleClick}
    >
      <div
        key={item?.id}
        className={`__card_body hover:cursor-pointer hover:scale-[1.024] transition duration-300 ease-in-out p-1`}
      >
        <div
          className={`__card_banner bg-[#242424] ${
            id === "gaming"
              ? "w-auto h-[337px] max-[768px]:w-[100%] max-[1200px]:w-auto"
              : ""
          } w-[297px] h-[327px] rounded-[16px]`}
        >
          {item.thumbnail && (
            <img
              className="h-full w-full object-cover rounded-[16px]"
              src={item.thumbnail}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.style.display = "none";
              }}
            />
          )}
          {/* {item?.heading === "Alpha Bot King" ? (
            <img src={AlphaBotImage} className="rounded-[16px]" />
          ) : (
            <></>
          )}
          {item?.heading === "Draco Guardian" ? (
            <img src={DracoImage} className="rounded-[16px]" />
          ) : (
            <></>
          )}
          {item?.heading === "Exo suit" ? (
            <img src={ExoSuitImage} className="rounded-[16px]" />
          ) : (
            <></>
          )}
          {item?.heading === "Goblin" ? (
            <img src={GoblinImage} className="rounded-[16px]" />
          ) : (
            <></>
          )} */}
          {!item.thumbnail && renderImage(item)}
        </div>
        <div className="__card_heading mt-[8px] pl-[5px]">
          <h5 className="text-[14px] font-body">
            {type === "COMP" ? item?.heading : ""}
          </h5>
        </div>
        <div className="__card_exception_main pl-[5px]">
          {isComing ? (
            <span className="text-[14px] font-body py-1 text-[#F3A151] font-medium">
              Coming Soon&nbsp;
            </span>
          ) : (
            ""
          )}
          {item.price &&
            Number(item.price.split(" ")[0]) != 0 &&
            String(item.price).toLowerCase() != "free" &&
            !isComing &&
            !userOwnsAsset && (
              <span className="text-[14px] font-body font-bold bg-[#FBBC5E]/5 px-2 py-1 rounded-[4px]">
                {removeTrailingZeroes(
                  convertUsdToFstrPrice(item.price.split(" ")[0])
                ) +
                  " FSTR " +
                  "( " +
                  Number(item.price.split(" ")[0])?.toFixed(2) +
                  " USD )"}
              </span>
            )}
          {(!item.price ||
            Number(item.price.split(" ")[0]) == 0 ||
            String(item.price).toLowerCase() == "free") &&
            !isComing &&
            !userOwnsAsset && (
              <span className="text-[14px] font-body font-bold bg-[#FBBC5E]/5 px-2 py-1 rounded-[4px]">
                Free
              </span>
            )}

          {userOwnsAsset && (
            <span className="text-[14px] font-body font-bold bg-[#FBBC5E]/5 px-2 py-1 rounded-[4px] text-[#6BFEF6]">
              Added to assets
            </span>
          )}
        </div>
      </div>
    </Link>
  );
};

export default SliderCards;
