/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Volumetric-List_page
 *@developer Sudhanshu
 */

import React, { useEffect, useRef, useState } from "react";
import Explore from "components/Explore/Explore";
import Loader from "components/Loader/Loader";
import { EntertainmentCardClass } from "constant";
import PremiumLockIcon from "assets/images/myassets/material-symbols_lock-outline.png";
import AssetCardOpen from "components/card/assets/AssetCardOpen";
import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import VolumetricCard from "components/card/marketplace/entertainment/VolumetricCard";
import { useGetVolumetricVideosQuery } from "store/slicer/Entertainment";
import { Link, useSearchParams } from "react-router-dom";
import { generateVideoPageUrl } from "utils/generateUrls";
import { convertUsdToFstrPrice } from "utils";
import { calculateTotalPages } from "utils";

const Index = () => {
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNum, setPageNum] = useState(1);
  const [allVolumetricData, setAllVolumetricData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const limit = 10;
  const [searchText, setSearchText] = useState(searchParams.get("q") || "");
  const [searchInputText, setSearchInputText] = useState(
    searchParams.get("q") || ""
  );
  const searchTimeout = useRef(null);
  const inputRef = useRef(null);

  const handlePageChange = () => {
    setPageNum((prevPageNum) => prevPageNum + 1);
  };
  const handleSearchInputChange = (e) => {
    setSearchInputText(inputRef.current.value);

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    const ts = setTimeout(() => {
      setPageNum(1);
      setSearchText(inputRef.current.value);
    }, 1000);

    searchTimeout.current = ts;
  };
  // const entertainmentData
  // const isFetching= false
  // const IsDataAvailable = false
  // const totalCount = 0
  let {
    data: entertainmentData,
    isFetching,
    isSuccess: IsDataAvailable,
    isError,
    totalCount,
    refetch,
  } = useGetVolumetricVideosQuery({
    searchQuery: searchText,
    page: pageNum,
    limit: limit,
  });
  useEffect(() => {
    if (
      !isFetching &&
      IsDataAvailable &&
      entertainmentData.response.statusEnum == 1
    ) {
      setTotalPages(
        calculateTotalPages(entertainmentData.response?.data?.totalCount, limit)
      );
      // entertainmentData = entertainmentData?.response?.data?.content;
      const formattedEntertainmentData =
        entertainmentData?.response?.data?.content.map((item) => {
          const contentPref = item.contentPreference;
          const title = item.contentName;
          let subtitleButton = "Free";
          let tagType = "Free";

          if (contentPref == "subscription") {
            subtitleButton = `<img src=${PremiumLockIcon} class="w-[24px] h-[24px] mr-4 " alt='lockIcon'/> Unlock Premium`;
            tagType = "Premium";
          }

          if (contentPref == "premium" || contentPref == "paid") {
            subtitleButton = `${item.price} USD | ${convertUsdToFstrPrice(
              item.price ?? 0
            )} FSTR`;
            tagType = "Paid";
          }

          return {
            ...item,
            channel: item?.channel?.channelHandle || item.channel?._id,
            title,
            subtitleButton,
            tagType,
            cardClass: EntertainmentCardClass,
          };
        });
      if (pageNum > 1) {
        console.log("tottal", pageNum, formattedEntertainmentData);

        setAllVolumetricData((prevEntertainmentData) => [
          ...prevEntertainmentData,
          ...formattedEntertainmentData,
        ]);
      } else {
        setAllVolumetricData(() => [...formattedEntertainmentData]);
      }
    }
  }, [isFetching, IsDataAvailable, entertainmentData]);
  // if (IsDataAvailable && entertainmentData.response.statusEnum == 1) {
  //   entertainmentData = entertainmentData?.response?.data?.content;
  //   entertainmentData = entertainmentData.map((item) => {
  //     const contentPref = item.contentPreference;
  //     const title = item.contentName;
  //     let subtitleButton = "Free";
  //     let tagType = "Free";

  //     if (contentPref == "subscription") {
  //       subtitleButton = `<img src=${PremiumLockIcon} class="w-[24px] h-[24px] mr-4 " alt='lockIcon'/> Unlock Premium`;
  //       tagType = "Premium";
  //     }

  //     if (contentPref == "premium" || contentPref == "paid") {
  //       subtitleButton = `US$ ${item.price}`;
  //       tagType = "Paid";
  //     }

  //     return {
  //       ...item,
  //       channel: item.channel.channelHandle || item.channel._id,
  //       title,
  //       subtitleButton,
  //       tagType,
  //       cardClass: EntertainmentCardClass,
  //     };
  //   });
  // } else {
  //   entertainmentData = [];
  // }

  const handleFilterChange = (selectedValue) => {
    setSelectedFilter(selectedValue);
  };

  const filterOptions = ["All", "Free", "Premium", "Buy"];

  //  comment the below 2 declaration out after testing

  // IsDataAvailable = true;
  // entertainmentData = [
  //   {
  //     title: "StellarVerse Spectacle: Cosmic Symphony",
  //     subtitleButton: `<img src=${PremiumLockIcon} class="w-[24px] h-[24px] mr-4 " alt='lockIcon'/> Unlock Premium`,
  //     cardClass: EntertainmentCardClass,
  //     tagType: "Premium",
  //   },
  //   {
  //     title: "StellarVerse Spectacle: Cosmic Symphony",
  //     subtitleButton: "Free",
  //     cardClass: EntertainmentCardClass,
  //     tagType: "Free",
  //   },
  //   {
  //     title: "StellarVerse Spectacle: Cosmic Symphony",
  //     subtitleButton: "US$ 9.99",
  //     cardClass: EntertainmentCardClass,
  //     tagType: "Paid",
  //   },
  //   {
  //     title: "StellarVerse Spectacle: Cosmic Symphony",
  //     subtitleButton: "Free",
  //     cardClass: EntertainmentCardClass,
  //     tagType: "Free",
  //   },
  //   {
  //     title: "StellarVerse Spectacle: Cosmic Symphony",
  //     subtitleButton: "US$ 9.99",
  //     cardClass: EntertainmentCardClass,
  //     tagType: "Paid",
  //   },
  //   {
  //     title: "StellarVerse Spectacle: Cosmic Symphony",
  //     subtitleButton: "Free",
  //     cardClass: EntertainmentCardClass,
  //     tagType: "Free",
  //   },
  // ];

  // Filter the entertainmentData based on the selected filter
  const filteredData = allVolumetricData.filter((item) => {
    if (selectedFilter === "All") {
      return true;
    } else if (
      selectedFilter === "Free" &&
      item.subtitleButton.includes("Free")
    ) {
      return true;
    } else if (
      selectedFilter === "Premium" &&
      item.subtitleButton.includes("Unlock Premium")
    ) {
      return true;
    } else if (
      selectedFilter === "Buy" &&
      item.subtitleButton.includes("USD")
    ) {
      return true;
    }
    return false;
  });
  const mostTrending = [
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Free",
      id: "1",
    },
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Free",
      id: "2",
      tags: "Volcap",
    },
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Paid",
      price: "US$ 9.99",
      id: "3",
    },
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Free",
      id: "4",
    },
    {
      title: "StellarVerse Spectacle: Cosmic Symphony",
      productType: "Free",
      id: "5",
    },
  ];

  return (
    <>
      {/* {IsDataAvailable ? (
        <div className="__entertainment_assets_main  pt-4 max-[768px]:p-0">
          <div className="__entertainment_assets_heading mb-[29px] lg:mb-[39px] sm:flex justify-between">
            <h4 className="font-medium font-heading text-[18px] lg:text-[28px] tracking-[1px]  max-[768px]:p-0 max-[768px]:text-[15px] mb-4 sm:mb-0">
              Volumetric Video
            </h4>
            <div className="pt-3">
              <FilterDropdown
                options={filterOptions}
                onChange={handleFilterChange}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">

            {filteredData.map((item, index) => (
                <VolumetricCard
                key={item._id}
                tags="Volumetric"
                // slider ={mostTrending}
                type="trending"
                name=""
                title={item.title}
                subtitleButton={item.subtitleButton}
                tagType={item.tagType}
                imageUrl={item.thumbnail || item.mediaFile}
                // tags ="vol"
                thumbnailIcon={false}
                link={`/marketplace/entertainment/content/volumetric-video/${item.channel}/${item._id}`}
                />
            ))}


          </div>
          <div className="volumetric-card-right-shadow"></div>

        </div>
      ) : (
        <div class="__explore_default_button flex justify-center items-center h-[70vh] max-[768px]:flex-none ">
          <Loader/>
        </div>
      )} */}
      <div className="mt-4 relative ml-auto w-full sm:max-w-[380px]">
        <svg
          className="absolute top-1/2 -translate-y-1/2 left-3"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.4351 10.0629H10.7124L10.4563 9.8159C11.3838 8.7401 11.8935 7.36667 11.8925 5.94626C11.8925 4.7702 11.5438 3.62055 10.8904 2.64269C10.237 1.66484 9.30832 0.902692 8.22179 0.452634C7.13525 0.00257642 5.93966 -0.115179 4.7862 0.114258C3.63274 0.343696 2.57322 0.910021 1.74162 1.74162C0.910021 2.57322 0.343696 3.63274 0.114258 4.7862C-0.115179 5.93966 0.00257642 7.13525 0.452634 8.22179C0.902692 9.30832 1.66484 10.237 2.64269 10.8904C3.62055 11.5438 4.7702 11.8925 5.94626 11.8925C7.4191 11.8925 8.77301 11.3528 9.8159 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94626 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94626C1.82962 3.66838 3.66838 1.82962 5.94626 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94626C10.0629 8.22413 8.22413 10.0629 5.94626 10.0629Z"
            fill="#CFCFCF"
          />
        </svg>
        <input
          className="w-full px-3 py-2 sm:py-3 pl-[34px] bg-transparent border border-[#5C5C5C] rounded-lg placeholder:text-[#cfcfcf] placeholder:text-[14px] placeholder:leading-[9.57px]"
          type="text"
          name="searchquery"
          id="searchquery"
          placeholder="Search"
          onChange={handleSearchInputChange}
          value={searchInputText}
          ref={inputRef}
        />
      </div>
      <div className="__entertainment_assets_main  max-[768px]:p-0">
        <div className="__entertainment_assets_heading mt-4 mb-[29px] lg:mb-[39px] sm:flex justify-between">
          <h4 className="font-medium font-heading text-[18px] lg:text-[28px] tracking-[1px]  max-[768px]:p-0 max-[768px]:text-[15px] mb-4 sm:mb-0">
            Volumetric Video
          </h4>
          <div className="pt-3">
            <FilterDropdown
              options={filterOptions}
              onChange={handleFilterChange}
            />
          </div>
        </div>
        {filteredData.length > 0 && (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
              {filteredData.map((item, index) => (
                <VolumetricCard
                  key={item._id}
                  tags="Volumetric"
                  // slider ={mostTrending}
                  type="trending"
                  name=""
                  title={item.title}
                  subtitleButton={item.subtitleButton}
                  tagType={item.tagType}
                  imageUrl={item.thumbnail || item.mediaFile}
                  // tags ="vol"
                  thumbnailIcon={false}
                  link={`/marketplace/entertainment/${generateVideoPageUrl(
                    "volumetric-video",
                    item.channel,
                    item.contentSlug
                  )}`}
                  itemId={item._id}
                />
              ))}
            </div>
            <div className="flex justify-center">
              {!isFetching && totalPages && totalPages > pageNum && (
                <button
                  onClick={handlePageChange}
                  className="inline-flex border cursor-pointer text-sm md:text-base leading-none rounded-2xl border-sec btn-outline hover:text-black hover:font-semibold px-6 py-3 md:py-4 md:px-8 mt-6 font-medium"
                >
                  Load More
                </button>
              )}
            </div>
          </>
        )}
        <div className="volumetric-card-right-shadow"></div>
      </div>
      {isFetching && (
        <div class="__explore_default_button flex justify-center items-center h-[70vh] max-[768px]:flex-none">
          <Loader />
        </div>
      )}

      {filteredData.length == 0 && !isFetching && searchText.length == 0 && (
        <div className="h-[60vh] flex flex-col gap-3 items-center justify-center ">
          <p className="font-heading font-semibold clip-text">Coming Soon</p>
          <p className="text-[#7A7A7A] font-semibold">
            Try exploring other exciting contents in marketplace.
          </p>
          <Link
            to="/marketplace"
            className="border-[1.5px] border-sec text-sm mt-4 rounded-lg max-w-[250px] hover:bg-sec hover:text-dark font-bold text-center w-full py-3 px-4"
          >
            Browse other content
          </Link>
        </div>
      )}
      {filteredData.length == 0 && !isFetching && searchText.length != 0 && (
        <div className="h-[60vh] flex flex-col gap-3 items-center justify-center ">
          <p className="text-[#7A7A7A] font-semibold">No results found</p>
        </div>
      )}
    </>
  );
};

export default Index;
