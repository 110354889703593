import React from "react";
import CheckIcon from "assets/images/check_icon.png";
import PopupWrapper from "../PopupWrapper";
import { Link } from "react-router-dom";

const TransactionSuccess = ({ open, onClose, canClose }) => {
  return (
    <PopupWrapper open={open} onClose={onClose} canClose={canClose}>
      <div className="px-4 py-8 flex flex-col justify-center items-center gap-4 font-body bg-[#242424] rounded-[16px]">
        <div className="h-[55px] w-[55px] rounded-full">
          <img
            className="h-full w-full object-cover"
            src={CheckIcon}
            alt="processing"
          />
        </div>
        <p className="text-[24px] leading-[36px] text-center font-bold">
          Transaction Successful!
        </p>
        <p className="text-[16px] leading-[24px] text-center font-normal text-white">
          Congratulations! Your purchase is successful. You can now enjoy your
          new items in the Oculus app.
        </p>

        <Link
          to={`/marketplace`}
          className="mt-3 font-semibold text-[16px] leading-[19.36px] text-center text-[#fbbc5e]"
        >
          Go back to Marketplace
        </Link>
      </div>
    </PopupWrapper>
  );
};

export default TransactionSuccess;
