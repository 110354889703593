// utils/s3.ts
import AWS from "aws-sdk";

AWS.config.update({
  apiVersion: "latest",
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY_ID,
  },
  region: process.env.REACT_APP_AWS_S3_BUCKET_REGION,
});
const s3 = new AWS.S3();

const contentTypeMapping = {
  ".png": "image/png",
  ".jpg": "image/jpg",
  ".jpeg": "image/jpeg",
  ".gif": "image/gif",
  ".webp": "image/webp",
};

export async function uploadToS3(file, bucketName, key) {
  const fileExtension = key.slice(key.lastIndexOf("."));

  // Determine the content type based on the file extension
  const contentType =
    contentTypeMapping[fileExtension.toLowerCase()] ||
    "application/octet-stream";

  const params = {
    Bucket: bucketName,
    Key: key,
    Body: file,
    ContentType: contentType, // Set the content type based on the file extension
  };

  try {
    const s3result = await s3.upload(params).promise();
    return s3result;
  } catch (error) {
    return {
      status: false,
      statusCode: error.statusCode,
      message: error.message,
    };
  }
}

export async function removeFromS3(bucketName, key) {
  const params = {
    Bucket: bucketName,
    Key: key,
  };

  await s3.deleteObject(params, (err, data) => {
    if (err) {
      console.error("Error deleting object:", err);
    } else {
      console.log("Object deleted successfully:", data);
    }
  });
  return;
}

// Other S3-related functions can also be defined here.
