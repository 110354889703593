import Explore from "components/Explore/Explore";
import React from "react";

import card5 from "assets/images/event_card_5.png";
import MyEventCard from "./components/MyEventCard";
import { useGetUserEventsListQuery } from "store/slicer/Events";
import Loader from "components/Loader/Loader";
import dateFormat from "dateformat";

const MyEventsHome = () => {
  let {
    data: userEventsData,
    isFetching: userEventsFetching,
    isError: userEventsError,
    isSuccess: userEventsDataAvailable,
  } = useGetUserEventsListQuery({
    refetchOnMountOrArgChange: true,
  });

  if (
    !userEventsFetching &&
    userEventsDataAvailable &&
    userEventsData.response.statusEnum == 1
  ) {
    userEventsData = userEventsData.response.data.data;
  } else {
    userEventsData = [];
    userEventsError = true;
  }

  if (userEventsFetching) {
    return (
      <div className="h-[500px] w-full grid place-content-center">
        <Loader />
      </div>
    );
  }

  if (
    !userEventsFetching &&
    userEventsDataAvailable &&
    userEventsData?.length == 0
  ) {
    return (
      <div class="__explore_default_button h-[500px] w-full grid place-content-center">
        <Explore />
      </div>
    );
  }

  let filteredEventsData = userEventsData?.map((item) => {
    let eventId = item.eventId;
    // item = item.eventDetails;
    let eventType = "Volumetric";
    if (item.venueType?.includes("360")) {
      eventType = "360";
    }
    if (item.venueType?.includes("180")) {
      eventType = "180";
    }
    if (item.venueType?.includes("2d")) {
      eventType = "2D";
    }
    if (item.venueType?.includes("music")) {
      eventType = "Music";
    }
    return {
      ...item,
      id: eventId,
      type: item?.eventDetails?.venueType,
      title: item?.eventDetails.name,
      channelName: item?.creatorDetails?.channelName,
      dateAndTime: dateFormat(
        item?.eventDetails.startTime,
        "mmmm dd, yyyy, h:MM TT"
      ),
      location: item?.eventDetails.venueName,
      price: item?.eventDetails.priceFiat,
      imgUrl: item?.eventDetails.promoBanner,
      dateOnly: dateFormat(item.createdAt, "mmmm dd, yyyy"),
    };
  });

  return (
    <div className="px-3 mb-16">
      <h2 className="mt-8 mb-8 text-white text-[24px] leading-[36px] font-heading">
        My Events
      </h2>
      <div className="w-full flex max-lg:justify-between justify-start flex-wrap gap-[20px] max-md:[&>div]:w-full max-lg:[&>div]:w-[48%]  xl:[&>div]:max-w-[max(32%,407px)]">
        {filteredEventsData.map((item) => (
          <MyEventCard key={item.id} data={item} />
        ))}
      </div>
    </div>
  );
};

export default MyEventsHome;
