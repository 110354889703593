/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module ConfirmPassword
 *@developer Sudhanshu <<sudhanshut@instaacoders.com>>
 */

import { useRef, useState } from "react";
import { Box } from "@mui/material";
import { isStrongPassword, togglePasswordVisibility } from "utils/index";
import eyeOff from "assets/images/eye-off.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPassword,
  verifyForgetPasswordLink,
} from "store/slicer/userAuth/authActions";
import { clearMsgState } from "store/slicer/userAuth";
import { useEffect } from "react";
import Alert from "components/popup/Alert/Alert";
import { isContainsSpecialChars, isContainsNumber } from "utils";

/* This code is a React component for setting reset password with confirm functionality .
 */
const ConfirmPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    loading,
    userInfo,
    userToken,
    error,
    success,
    successMsg,
    pwdTokenVerified,
    pwdResetComplete,
  } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState({
    password: "",
    confirmPassword: "",
  });
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(true);
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const [alertMessage, setAlertMessage] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [successAlert, setSuccessAlert] = useState(true);

  // called when alert popup is closed
  const onCloseAlert = () => {
    setAlertMessage("");
    setSuccessAlert("");
    setAlertHeading("");
  };

  const isContainsUpperCase = (value) => {
    return /[A-Z]/.test(value);
  };

  const isContainsLowerCase = (value) => {
    return /[a-z]/.test(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "password") {
      const isValid = isStrongPassword(value);
      let message = "Password is not strong enough";

      if (!isContainsNumber(value)) {
        message = "Password must contain a number";
      }
      if (!isContainsSpecialChars(value)) {
        message = "Password must contain a special character";
      }
      if (!isContainsUpperCase(value)) {
        message = "Password must contain an uppercase letter";
      }
      if (!isContainsLowerCase(value)) {
        message = "Password must contain a lowercase letter";
      }

      if (!value.trim()) {
        message = "Please enter a password";
      }

      setIsPasswordValid(isValid);
      if (!isValid) {
        setFormErrors({
          ...formErrors,
          password: message,
        });
      } else {
        setFormErrors({
          ...formErrors,
          password: "",
        });
      }
    }
    if (name === "confirmPassword") {
      const isValid = isStrongPassword(value);
      let message = "Password is not strong enough";

      if (!isContainsNumber(value)) {
        message = "Password must contain a number";
      }
      if (!isContainsSpecialChars(value)) {
        message = "Password must contain a special character";
      }
      if (!isContainsUpperCase(value)) {
        message = "Password must contain an uppercase letter";
      }
      if (!isContainsLowerCase(value)) {
        message = "Password must contain a lowercase letter";
      }
      setIsPasswordValid(isValid);
      if (!isValid) {
        setFormErrors({
          ...formErrors,
          confirmPassword: message,
        });
      } else if (formData.password !== value) {
        setFormErrors({
          ...formErrors,
          confirmPassword: "Passwords do not match.",
        });
      } else {
        setFormErrors({
          ...formErrors,
          confirmPassword: "",
        });
      }
    }
  };

  const isFormValid = () => {
    return (
      formData.password.length >= 8 &&
      isPasswordValid &&
      formData.password === formData.confirmPassword
    );
  };

  useEffect(() => {
    if (!searchParams.get("passwordResetToken")) {
      navigate("/signin");
    } else {
      const payload = {
        passwordResetToken: searchParams.get("passwordResetToken"),
      };

      dispatch(verifyForgetPasswordLink(payload));
    }
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("isForgot")) {
      // navigate("/signin");
    }
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      setPasswordMatchError("Passwords do not match.");
    } else {
      setPasswordMatchError(""); // Reset password match error
      // let payload = {
      //   email: localStorage.getItem("isForgot"),
      //   password: formData.confirmPassword
      // }
      let payload = {
        passwordResetToken: searchParams.get("passwordResetToken"),
        password: formData.confirmPassword,
      };
      dispatch(resetPassword(payload));
    }
  };

  useEffect(() => {
    if (error) {
      setAlertMessage(error);
      setSuccessAlert(false);
    }
    // if (!userInfo) {
    //   navigate('/signup');
    // }
    if (userInfo && userToken && userInfo?.isVerified) {
      navigate("/");
    }
    // redirect user to otp verification if registration was successful
    if (success && successMsg && pwdTokenVerified && pwdResetComplete) {
      setAlertMessage(successMsg);
      setAlertHeading("Password Changed!");
      setSuccessAlert(true);
      setTimeout(() => {
        dispatch(clearMsgState());
        navigate("/signin");
        setFormData({
          password: "",
          confirmPassword: "",
        });
      }, 2000);

      //
    }
  }, [navigate, userInfo, userToken, error, success, successMsg]);

  const submitBtn = useRef(null);

  return (
    <>
      <Box className="w-full max-w-[700px] rounded-lg border border-[#363636] p-5 md:p-[32px] lg:p-[58px] signin-form">
        <Box className="text-white max-w-[500px] mx-auto">
          <h1 className="text-lg lg:text-xl text-center font-heading mb-[36px]">
            Reset Password
          </h1>

          <form className="auth-form mb-5" onSubmit={handleSubmit}>
            <div className="mb-6">
              <div className="mt-2 flex relative">
                <input
                  type="password"
                  name="password"
                  className={`rounded-lg w-full bg-transparent border border-white focus:border-[#f6f6f6] autofill:bg-transparent font-normal py-3 px-5 leading-normal font-semibold outline-none pr-[40px] placeholder-normal ${
                    !isPasswordValid ? "!border-error" : ""
                  }`}
                  id="password"
                  placeholder="Enter new password"
                  value={formData.password}
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      e.preventDefault();
                      if (submitBtn.current && !submitBtn.current.disabled) {
                        submitBtn.current.click();
                      }
                    }
                  }}
                />
                <span
                  data-testid="PasswordVisibility"
                  className="vector absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer text-white"
                  onClick={(e) => togglePasswordVisibility(e)}
                >
                  <img src={eyeOff} alt="PasswordVisibility" />
                </span>
              </div>
              {!isPasswordValid && formErrors.password && (
                <span className="text-error text-sm">
                  {formErrors.password}
                </span>
              )}
            </div>
            <div className="mb-6">
              <div className="mt-2 flex relative">
                <input
                  type="password"
                  name="confirmPassword"
                  className={`rounded-lg w-full bg-transparent border border-white focus:border-[#f6f6f6] autofill:bg-transparent font-normal py-3 px-5 leading-normal font-semibold outline-none pr-[40px] placeholder-normal ${
                    !isConfirmPasswordValid ? "!border-error" : ""
                  }`}
                  id="confirmPassword"
                  value={formData.confirmPassword}
                  placeholder="Confirm new password"
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      e.preventDefault();
                      if (submitBtn.current && !submitBtn.current.disabled) {
                        submitBtn.current.click();
                      }
                    }
                  }}
                />
                <button
                  data-testid="ConfPasswordVisibility"
                  className="vector absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer text-white"
                  onClick={(e) => togglePasswordVisibility(e)}
                  tabIndex={-1}
                >
                  <img src={eyeOff} alt="PasswordVisibility" />
                </button>
              </div>

              {formErrors.confirmPassword && (
                <span className="text-error text-sm">
                  {formErrors.confirmPassword}
                </span>
              )}
            </div>

            <button
              type="submit"
              className="font-bold rounded-lg btn-gradient w-full text-black py-3 px-5 flex justify-center"
              disabled={!isFormValid() || loading}
              ref={submitBtn}
            >
              {loading ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Processing...
                </>
              ) : (
                "Change Password"
              )}
            </button>
          </form>
        </Box>
      </Box>
      {alertMessage && (
        <Alert
          open={Boolean(alertMessage)}
          onClose={onCloseAlert}
          message={alertMessage}
          heading={alertHeading}
          isSuccess={successAlert}
        />
      )}
    </>
  );
};

export default ConfirmPassword;
