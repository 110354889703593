import React, { useState } from "react";
import { ClothTabs } from "constant";
import {
  Formal,
  Casual,
  SpaceSuit,
  ExoSuit,
} from "components/assets/myassets/clothing/index";
import Explore from "components/Explore/Explore";
import Loader from "components/Loader/Loader";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module Assets-Clothing-Module
 */

const Clothing = ({ dataClothing }) => {
  const [clothActiveTab, setclothActiveTab] = useState({
    id: 0,
    activetabs: "spacesuit",
    name: "Space Suits",
    component: (
      <SpaceSuit
        data={dataClothing.filter((item) => item.iapSuperType === "spacesuit")}
        myAsset={true}
      />
    ),
  });

  const handleClickAsset = (tabs) => {
    let result;
    switch (tabs.key) {
      case "exosuit":
        result = dataClothing.filter((item) => item.iapSuperType === "exosuit");
        setclothActiveTab({
          ...tabs,
          activetabs: "exosuit",
          component: <ExoSuit data={result} myAsset={true} />,
        });
        break;
      case "formal":
        result = dataClothing.filter((item) => item.iapSuperType === "formal");
        setclothActiveTab({
          ...tabs,
          activetabs: "formal",
          component: <Formal data={result} myAsset={true} />,
        });
        break;
      case "casual":
        result = dataClothing.filter((item) => item.iapSuperType === "casual");
        setclothActiveTab({
          ...tabs,
          activetabs: "casual",
          component: <Casual data={result} myAsset={true} />,
        });
        break;
      default:
        result = dataClothing.filter(
          (item) => item.iapSuperType === "spacesuit"
        );
        setclothActiveTab({
          ...tabs,
          activetabs: "spacesuit",
          component: <SpaceSuit data={result} myAsset={true} />,
        });
        break;
    }
  };
  return (
    <>
      {/* <div className="h-[50vh]">
        <div class="__explore_default_button flex justify-center items-center h-[50vh] max-[768px]:flex-none max-[1280px]:h-[100%]">
          <Explore />
        </div>
      </div> */}
      {/* This __explore_default_button class is a conditional component and it will appears when user don't have any assets */}

      {dataClothing && dataClothing.length > 0 ? (
        <div className="__clothing_assets_main max-[768px]:p-[15px]">
          <div className="__clothing_assets_heading mb-[10px]">
            <h4 className="font-medium font-heading text-[18px] tracking-[1px] px-[10px] max-[768px]:p-0 max-[768px]:text-[15px]">
              Clothing
            </h4>
          </div>

          <div className="__entertaiments__tabs_main mt-[22px] max-[768px]:w-auto max-[768px]:p-0 max-[1280px]:p-0">
            <ul
              className="
            pl-[15px] 
            flex 
            overflow
            gap-[20px] 
            hover:[&>li]:cursor-pointer 
            max-[768px]:overflow-x-scroll 
            max-[768px]:p-0 
            max-[768px]:[&>li]:underline-offset-1
            "
            >
              {ClothTabs?.map((tabs, ind) => {
                return (
                  <li
                    key={tabs?.key}
                    className={`${
                      clothActiveTab.activetabs === tabs.key
                        ? "text-[#fff] bg-gradient-to-r from-[#FBBC5E] to-[#F3A151] bg-[length:100%_2px] bg-no-repeat bg-bottom font-medium"
                        : "text-[gray]"
                    }`}
                    onClick={() => handleClickAsset(tabs)}
                  >
                    {tabs?.name}
                  </li>
                );
              })}
            </ul>
          </div>

          {clothActiveTab.component}
        </div>
      ) : (
        <div className="__explore_default_button flex justify-center items-center h-[50vh] max-[768px]:flex-none max-[1280px]:h-[100%] ">
          <Explore />
        </div>
      )}
    </>
  );
};

export default Clothing;
